import { AfterContentChecked, ChangeDetectionStrategy, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';

import { Carousel, LoadOffersApiService, OffersApiService } from '@frontend/promo-homewidget';
import { CarouselDefaultConfig } from '@frontend/promo-utils';
import { DeviceService } from '@frontend/vanilla/core';
import { Subject, takeUntil } from 'rxjs';
import Swiper from 'swiper';
import { Navigation, Pagination } from 'swiper/modules';
import { SwiperOptions } from 'swiper/types/swiper-options';

@Component({
    selector: 'offers-carousel',
    templateUrl: 'offers-carousel.component.html',
    changeDetection: ChangeDetectionStrategy.Default,
})
export class OffersCarouselComponent implements OnInit, OnDestroy, AfterContentChecked {
    @ViewChild('swiperContainer') swiperRef: ElementRef;
    config: SwiperOptions;
    carousel: Carousel;
    arrows: boolean = true;
    swiper: Swiper;
    private readonly destroy$ = new Subject<void>();
    isSwiperInit: any;

    constructor(
        private deviceService: DeviceService,
        private carouselDefaultConfig: CarouselDefaultConfig,
        public loadOffersApiService: LoadOffersApiService,
        public offerService: OffersApiService,
    ) {}

    ngOnInit() {
        this.loadOffersApiService.loadCarousel(false);
        this.arrows = !this.deviceService.isTouch;
        this.config = Object.assign(
            {
                modules: [Navigation, Pagination],
                loop: true,
                slidesPerView: 1,
                navigation: { nextEl: '.vn-carousel > .carousel-next', prevEl: '.vn-carousel > .carousel-previous' },
            },
            this.carouselDefaultConfig.defaultConfig,
        );
        this.offerService.carouselDetails$.pipe(takeUntil(this.destroy$)).subscribe((carousel) => {
            this.carousel = carousel;
            this.arrows = carousel?.arrows && !this.deviceService.isTouch && carousel?.carouselItems?.length > 1;
            if (this.config) {
                this.config.autoplay = this.carousel?.autoplay
                    ? {
                          delay: this.carousel?.interval,
                          disableOnInteraction: false,
                          stopOnLastSlide: true,
                          pauseOnMouseEnter: this.carousel?.pauseOnHover ? this.carousel?.pauseOnHover : false,
                      }
                    : false;
                this.config.loop = this.carousel?.loop ? this.carousel?.loop : true;
                this.config.pagination = this.carousel?.dots
                    ? {
                          el: '.swiper-pagination',
                          clickable: true,
                          hideOnClick: false,
                      }
                    : false;
            }
        });
    }

    ngAfterContentChecked() {
        if (this.swiperRef?.nativeElement && !this.isSwiperInit) {
            this.isSwiperInit = true;
            this.swiper = new Swiper(this.swiperRef.nativeElement, this.config);
        }
    }
    swipePrev() {
        if (this.swiper) {
            this.swiper?.slidePrev();
        }
    }

    swipeNext() {
        if (this.swiper) {
            this.swiper?.slideNext();
        }
    }

    ngOnDestroy() {
        if (this.swiper) this.swiper.destroy();
        this.destroy$.next();
        this.destroy$.complete();
    }
}
