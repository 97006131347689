import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { RouterModule } from '@angular/router';

import { OffersLibModule, SharedOffersModule } from '@frontend/promo-homewidget';
import { PlainLinkComponent } from '@frontend/vanilla/core';
import { PageMatrixComponent } from '@frontend/vanilla/features/content';
import { DslPipe } from '@frontend/vanilla/shared/browser';
import { LetDirective } from '@ngrx/component';

import { ErrorHandlerService } from '../errorHandling/error-handler.service';
import { PromoHttpErrorInterceptor } from '../errorHandling/promo-http-error.interceptor';
import { ServerErrorComponent } from '../errorHandling/server-error.component';
import { PromoFaqsComponent } from '../promo-faqs/promo-faqs.component';
import { SeoPageContentComponent } from '../seo-page/seo-page-content/seo-page-content.component';
import { OffersCarouselComponent } from './offers-carousel/offers-carousel.component';
import { OffersHomePageComponent } from './offers-home-page/offers-home-page.component';
import { OffersNavigationModule } from './offers-navigation/offers-navigation.module';
import { OffersNavigationService } from './offers-navigation/offers-navigation.service';
import { OffersPopupFilterComponent } from './offers-popup-filter/offers-popup-filter.component';
import { OffersPopUpFilterService } from './offers-popup-filter/offers-popup-filter.service';
import { OffersRoutingModule } from './offers-routing.module';

@NgModule({
    imports: [
        CommonModule,
        MatDialogModule,
        FormsModule,
        OffersLibModule,
        OffersNavigationModule,
        RouterModule,
        SharedOffersModule,
        LetDirective,
        PageMatrixComponent,
        PlainLinkComponent,
        PromoFaqsComponent,
        OffersRoutingModule,
        SeoPageContentComponent,
    ],
    declarations: [OffersHomePageComponent, ServerErrorComponent, OffersPopupFilterComponent, OffersCarouselComponent],
    providers: [
        OffersNavigationService,
        ErrorHandlerService,
        DslPipe,
        OffersPopUpFilterService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: PromoHttpErrorInterceptor,
            multi: true,
        },
    ],
})
export class OffersModule {}
