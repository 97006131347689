import { ChangeDetectionStrategy, Component, Input, OnChanges } from '@angular/core';

import { ContentLink, MenuActionOrigin, MenuActionsService, ToastrQueueService, UserService } from '@frontend/vanilla/core';
import { BehaviorSubject, Observable, combineLatest, map, tap } from 'rxjs';

import { OffersButtonClass, OffersConstants } from '../../../offers/common-constants/offers-constants';
import { Offer, OfferOptInResult, OfferTeaserClientContent } from '../../../offers/models';
import { OfferContentConfiguration } from '../../../offers/offer-content.client-config';
import { LoadOffersApiService } from '../../../offers/offers-service/load-offers.service';
import { SpanishRegulatoryHelperService } from '../../../spanish-regulatory/spanish-regulatory-helper.service';
import { OffersWebTrackingConstants } from '../../../tracking/offers-web-analytic-constants';
import { OffersWebAnalyticModel } from '../../../tracking/offers-web-analytic.model';
import { OptInTrackService } from '../../../tracking/opt-in-track.service';
import { TrackingModelService } from '../../../tracking/tracking-model-service';
import { OfferTeaserService } from '../offer-teaser.service';
import { OfferTeaserOptInService } from './offer-teaser-opt-in.service';

interface OptInViewModel extends OfferOptInResult, OfferTeaserClientContent {}

interface OfferTeaserCtaViewModel extends OptInViewModel {
    showButtonSpinner: boolean;
}

@Component({
    selector: 'offer-teaser-cta',
    templateUrl: 'offer-teaser-cta.component.html',
    providers: [OfferTeaserOptInService],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OfferTeaserCtaComponent implements OnChanges {
    @Input() offer: Offer;
    @Input() teaserNumber: number;
    @Input() product: string;
    @Input() moreInfoCta: ContentLink;
    @Input() isGroupedPage: boolean = false;
    @Input() offerType: string = '';
    isOptInPresent: boolean;
    optInCta: ContentLink;
    moreInfoCtaClass: string;

    vm$: Observable<OfferTeaserCtaViewModel>;

    private _optInVm$: Observable<OptInViewModel>;

    private readonly _showButtonSpinner$ = new BehaviorSubject(false);
    private readonly _optInStatus$: Observable<any>;

    private readonly _webAnalyticModel = new OffersWebAnalyticModel();
    OptedIndata: any;
    constructor(
        private offerTeaserService: OfferTeaserService,
        private offerTeaserOptInService: OfferTeaserOptInService,
        private userService: UserService,
        private optInTrackService: OptInTrackService,
        private menuActionsService: MenuActionsService,
        private trackingModelService: TrackingModelService,
        private spanishRegulatoryHelperService: SpanishRegulatoryHelperService,
        public loadOffersApiService: LoadOffersApiService,
        public offerContentConfiguration: OfferContentConfiguration,
        public toast: ToastrQueueService,
    ) {
        this._optInStatus$ = this.offerTeaserOptInService.optInStatus$.pipe(
            tap((optInStatus: OfferOptInResult) => {
                if (optInStatus.optInClicked) {
                    this._showButtonSpinner$.next(false);
                    if (optInStatus.showOptedInMessage) {
                        this.optInTrackService.trackSuccessMessage(this._webAnalyticModel);
                        if (offerContentConfiguration.isBetMgmTileRedesignEnabled) {
                            this.toast.add('promooptinsuccess');
                            this.loadOffersApiService.offerTeaserClientContent$.subscribe((data) => {
                                this.OptedIndata = data.optedInMessage;
                            });
                            this.offerTeaserService.updateOptedInMessage(this.offer.offerMetadata.crmServiceId, this.OptedIndata);
                        }
                    } else {
                        this.optInTrackService.trackErrorMessage(this._webAnalyticModel);
                    }
                }
            }),
        );
    }

    ngOnChanges(): void {
        this.loadOptInCta();

        const showOptedInMessage = this.offer.offerMetadata.showOptedInMessage;
        const isOptInValid = !this.userService.isAuthenticated || this.offer.offerMetadata.isOptinValid;

        this._optInVm$ = combineLatest([
            this.loadOffersApiService.offerTeaserClientContent$,
            this.spanishRegulatoryHelperService.isOptInAllowed$(this.offer.offerMetadata.isGeneral),
            this._optInStatus$,
        ]).pipe(
            tap(([, isOptInAllowed]) => {
                this.isOptInPresent = this.offer.offerMetadata.isOptinPresent && isOptInAllowed;
                this.moreInfoCtaClass = this.getMoreInfoCtaClass(showOptedInMessage);
            }),
            map(([offerTeaserClientContent, isOptInAllowed, optInStatus]) => {
                return {
                    ...offerTeaserClientContent,
                    optInClicked: false,
                    isOptInValid: isOptInValid,
                    showOptInButton: isOptInValid && this.offer.offerMetadata.showOptinButton && isOptInAllowed,
                    showOptedInMessage: showOptedInMessage || optInStatus.showOptedInMessage,
                    showOptedInErrorMessage: optInStatus.showOptedInErrorMessage,
                };
            }),
        );

        this.vm$ = combineLatest([this._optInVm$, this._showButtonSpinner$]).pipe(
            map(([optInVm, showButtonSpinner]) => ({ ...optInVm, showButtonSpinner: showButtonSpinner })),
        );
    }

    loadOptInCta() {
        const ctaLinks = this.offer.offerContent?.ctaLinks;
        if (ctaLinks !== undefined && Object.keys(ctaLinks).length > 0) {
            this.optInCta = ctaLinks[OffersConstants.Claim] ?? ({} as ContentLink);
        }
    }

    navigateToOfferMoreInfo() {
        this.offerTeaserService.navigateToOfferMoreInfo(
            this.teaserNumber,
            this.moreInfoCta,
            this.offer,
            true,
            this.product,
            this.isGroupedPage,
            this.offerType,
        );
    }

    private getMoreInfoCtaClass(showOptedInMessage: boolean): string {
        if (!this.offerContentConfiguration.isBetMgmTileRedesignEnabled) {
            if (this.isOptInPresent || showOptedInMessage) {
                return OffersButtonClass.BtnLink;
            } else {
                return this.moreInfoCta?.attributes?.class ? this.moreInfoCta.attributes.class : OffersButtonClass.BtnSecondary;
            }
        } else {
            if (
                this.isOptInPresent ||
                showOptedInMessage ||
                (this.offer?.offerContent?.betNowCta && this.offerContentConfiguration?.enableBetNowCta)
            ) {
                return OffersButtonClass.BtnTertiaryMr2;
            } else {
                return this.moreInfoCta?.attributes?.class ? OffersButtonClass.BtnTertiary : OffersButtonClass.BtnSecondary;
            }
        }
    }

    optIn_Click(event: Event) {
        if (this.isOptInPresent) {
            event.stopPropagation();
            this.trackAnalytics();
            if (this.userService.isAuthenticated) {
                this._showButtonSpinner$.next(true);
                const offerMetadata = this.offer.offerMetadata;
                if (offerMetadata.optInApi && offerMetadata.optInApi !== '') {
                    this.offerTeaserOptInService.promotionOptIn(offerMetadata.crmServiceId, offerMetadata.optInApi);
                }
            } else {
                this.goToLogin();
            }
        }
    }

    private trackAnalytics() {
        this.webAnalyticModelTracking();
        this.trackingModelService.submitTracking({
            ...this._webAnalyticModel,
            LabelEvent: this.product,
            PositionEvent: this._webAnalyticModel.contentPosition,
            LocationEvent: this._webAnalyticModel.position,
            PromoOfferName: this._webAnalyticModel.promoOfferName,
            ActionEvent: OffersWebTrackingConstants.Click,
            EventDetails: OffersWebTrackingConstants.OptInCta,
            CategoryEvent: OffersWebTrackingConstants.PromoHub,
            siteSubSection: OffersWebTrackingConstants.PromoOffersPage,
            Product: this.offer.offerMetadata.applicableProducts.toString(),
        });
    }

    webAnalyticModelTracking() {
        this._webAnalyticModel.page = OffersWebTrackingConstants.Promo;
        this._webAnalyticModel.position = this.offer?.offerMetadata?.serviceSubType
            ? this.offer?.offerMetadata?.serviceSubType?.toLowerCase()
            : this.offer?.offerMetadata?.serviceType?.toLowerCase();
        this._webAnalyticModel.tab = OffersWebTrackingConstants.offersPage;
        this._webAnalyticModel.labelEvent = this.product;
        this._webAnalyticModel.contentPosition = this.teaserNumber.toString();
        this._webAnalyticModel.campaignId = this.offer.offerMetadata.crmServiceId;
        this._webAnalyticModel.product = this.offer.offerMetadata.applicableProducts.toString();
        this._webAnalyticModel.promoOfferName = this.offer?.offerContent?.title;
        this._webAnalyticModel.promotionType = this.offer?.offerMetadata?.isSpecialOffer
            ? OffersWebTrackingConstants.StaticPromotion
            : OffersWebTrackingConstants.DynamicPromotion;
    }

    private goToLogin() {
        this.menuActionsService.invoke('gotoLogin', MenuActionOrigin.OfferButton, [undefined, undefined, { returnUrl: '' }]);
    }
}
