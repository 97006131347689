import { Injectable } from '@angular/core';

import { PokerTicketFilter, RegulatoryApiService } from '@frontend/promo-homewidget';
import { ApiService } from '@frontend/promo-utils';
import { BehaviorSubject, Observable } from 'rxjs';

import { PokerTicketDetails } from '../poker-ticket-models/poker-ticket-content-details';

@Injectable({
    providedIn: 'root',
})
export class PokerTicketDetailsService {
    activePokerFilter$ = new BehaviorSubject<string>('');

    constructor(
        private apiService: ApiService,
        private regulatoryApiService: RegulatoryApiService,
    ) {}

    loadPokerTicketDetails(id: string): Observable<PokerTicketDetails> {
        const pokerTickets$ = this.apiService.get('pokerticket', { ticketId: id }, { showSpinner: true });
        return this.regulatoryApiService.fetchDataWithReasonCode(pokerTickets$);
    }

    loadPokerTicketFilterContent(): Observable<PokerTicketFilter> {
        return this.apiService.get('pokerTicketFilter');
    }
}
