import { ContentImage, ContentLink } from '@frontend/vanilla/core';

export class OfferDetails {
    offerDetailsList: Offer[];
    offerCategories: { [key: string]: OfferCategory };
}

export class OfferCategory {
    categoryTitle: string;
    viewAllLink: ContentLink;
    productImage: ContentImage;
    subtitle: string;
    text: string;
    offerDetailsIndexes: number[];
    hideLHSlider: boolean = false;
    groupedOffersCount: number;
    noOfGroupedOffersPerOfferType: { [product: string]: number };
    freeOffersCarouselConfig: Carousel;
    viewAllCount: number;
    class: string;
}

export class OffersProductStyle {
    textColour: string;
    textSize: number;
    textFont: string;
}

export class FreeSpinsProgress {
    offerId: string;
    progressBar: OfferProgress;
}

export class FilteredOfferByCategory {
    offer: Offer;
    product: string;
}

export class Offer {
    offerMetadata: OfferMetadata;
    offerContent: OfferContent;
}

export class OfferMetadata {
    order: string;
    crmServiceId: string;
    serviceType: string;
    serviceSubType: string;
    offerState: string;
    applicableProducts: string[];
    cmsItemIdentifier: string;
    tag: string;
    offerProgress: OfferProgress;
    endDate: Date;
    startDate: Date;
    formattedEndDate: string;
    isNewOffer: boolean;
    isOfferActive: boolean;
    isSpecialOffer: boolean;
    isWelcomeOffer: boolean;
    showExpiryDate: boolean;
    cta: string[];
    isOptinPresent: boolean;
    showOptedInMessage: boolean;
    showOptinButton: boolean;
    isOptinValid: boolean;
    isSpecialOfferOptin: boolean;
    isGroupedTile: boolean;
    hideBehindGroup: boolean;
    optInApi: string;
    isFreeOffer: boolean;
    offersCount: string;
    isGeneral: boolean;
    showOptedInStatus: boolean;
    showExpiredStatus: boolean;
    offerBadges: string[];
    rewardTargetingType: string;
}

export interface OfferProgress {
    targetVal: string;
    currentVal: string;
}

export class OfferContent {
    title: string;
    description: string;
    keyTermsAndConditions: string;
    teaserClass: string;
    product: string;
    teaserImage: ContentImage;
    offerTypeIcon: ContentImage;
    ctaLinks: { [key: string]: ContentLink };
    primaryCta: ContentLink;
    moreInfoCta: ContentLink;
    placeHolderCta: ContentLink;
    offerType: OfferType;
    offerStateString: string;
    eligibleLeagueName: string;
    sportsGameType: string;
    applicableSports: string;
    expiryDateClass: string;
    expiryTimeCountDown: string;
    ticketId: string;
    betNowCta: ContentLink;
}

export interface OfferTeaserClientContent {
    newTag: string;
    expiresTag: string;
    expiresColon: string;
    badgesDot: string;
    optInMessage: string;
    optedInMessage: string;
    optedInErrorMessage: string;
    optInExpiredMessage: string;
    optInInvalidMessage: string;
    optInUserNotEligibleMessage: string;
}

export interface OfferOptInResult {
    optInClicked: boolean;
    showOptedInErrorMessage: boolean;
    showOptedInMessage: boolean;
}

export class OfferType {
    cssClass: string;
    text: string;
}

export class Carousel {
    autoplay: boolean;
    interval: number;
    arrows: boolean;
    dots: boolean;
    loop: boolean;
    pauseOnHover: boolean;
    slidesPerView: string;
    slidesPerGroup: number;
    spaceBetween: string;
    freeMode: string;
    breakpointConfig: string;
    carouselCssClasses: string;
    carouselItems: Carousel[];
}

export class CarouselItem {
    text: string;
}

export enum OfferGroupsCategoryTypes {
    NEW_OFFERS = 'new_offers',
    OffersInProgress = 'offersinprogress',
    InProgress = 'inprogress',
    Special_Offers = 'special_offers',
    All = 'all',
}

export enum OfferCrmServiceType {
    Bonus = 'bonus',
    FreeSpins = 'free_spin',
    BingoTicket = 'bingo_ticket',
    GoldenChips = 'golden_coin',
    OddsBoost = 'ODDSBOOST_TOKEN',
    RiskFreeBet = 'RISKFREE_TOKEN',
}

export enum NavigationTab {
    Home = 'Home',
}

export class OfferFiltersModel {
    selectedFiltersDict: { [product: string]: string[] };
    isNewSelected: boolean = false;
    isActiveSelected: boolean = false;
}

export class OfferProduct {
    title: string;
    messages: OfferProductMessages;
    filterOffers: SelectedFilters[];
}

export class OfferProductMessages {
    iconUrl: string;
    product: string;
    webTrackingTitle: string;
}

export class SelectedFilters {
    title: string;
    message: FilterMessage;
    isChecked: boolean;
    product: string;
    count: number = 0;
    specialOffersCount: number = 0;
    activeOffersCount: number = 0;
    newOffersCount: number = 0;
    newAndActiveOffersCount: number = 0;
    isNewSelected: boolean;
    isActiveSelected: boolean;
    offerProductTitle: string;
}

export class FilterMessage {
    product: string;
    serviceType: Array<string>;
    linkedWith: string;
    webTrackingTitle: string;
}

export class FilterOffersContent {
    offerProduct: OfferProduct[];
    filterOffersTabs: FilterOffersTabs[];
    filterOffersText: FilterOffersText;
    tabPosition: string;
}

export class FilterOffersTabs {
    title: string;
    name: string;
    isActive: boolean;
    webTrackingText: string;
}

export class FilterOffersText {
    title: string;
    messages: FilterOffersTextMessages;
}

export class FilterOffersTextMessages {
    clearAll: string;
    showResults: string;
    close: string;
    noResultFound: string;
}

export class PokerTicketFilterTabs {
    title: string;
    name: string;
    subType: string;
    serviceType: string;
    messages: any;
    isActive: boolean = false;
}

export class PokerTicketFilter {
    pokerTicketFilters: PokerTicketFilterTabs[];
}

export interface FiltersSelected {
    totalCount: number;
    selectedFilterOfferIndexes: { [key: string]: number[] };
}
