import { Injectable } from '@angular/core';

import { CommonService } from '@frontend/promo-homewidget';
import { ToastrQueueService } from '@frontend/vanilla/core';
import { Actions, EffectNotification, OnRunEffects, createEffect, ofType } from '@ngrx/effects';
import { Observable } from 'rxjs';
import { exhaustMap, takeUntil, tap } from 'rxjs/operators';

import {
    loadTargetPromotionData_Fail,
    targetPromotionDestroyed,
    targetPromotionInit,
    userOptInFailedForPromotion,
    userOptInInvalidPromoIdForPromotion,
    userOptInSuccessForPromotion,
    userOptedFailedForExpiredPromo,
    userOptedFailedForPromotion,
} from './target-promotions.actions';

@Injectable()
export class TargetPromoEffects implements OnRunEffects {
    optinSuccess$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(userOptInSuccessForPromotion.type),
                tap(() => {
                    this.toastrQueueService.clear();
                    this.toastrQueueService.add('PromoOptinSuccess');
                }),
            );
        },
        { dispatch: false },
    );

    optinFail$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(userOptInFailedForPromotion.type),
                tap(() => {
                    this.toastrQueueService.clear();
                    this.toastrQueueService.add('PromoOptinFail');
                }),
            );
        },
        { dispatch: false },
    );

    optinFailInvalidPromoId$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(userOptInInvalidPromoIdForPromotion.type),
                tap(() => {
                    this.toastrQueueService.clear();
                    this.toastrQueueService.add('PromoOptInInvalidPromoId');
                }),
            );
        },
        { dispatch: false },
    );

    alreadyOptedIn$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(userOptedFailedForPromotion.type),
                tap(() => {
                    this.toastrQueueService.clear();
                    this.toastrQueueService.add('PromoOptedIn');
                }),
            );
        },
        { dispatch: false },
    );

    expiredPromo$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(userOptedFailedForExpiredPromo.type),
                tap(() => {
                    this.toastrQueueService.clear();
                    this.toastrQueueService.add('PromoOptInExpired');
                }),
            );
        },
        { dispatch: false },
    );

    loadDataFailed$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(loadTargetPromotionData_Fail.type),
                tap(() => {
                    this.commonService.goToOverviewPage();
                }),
            );
        },
        { dispatch: false },
    );

    ngrxOnRunEffects(resolvedEffects$: Observable<EffectNotification>) {
        return this.actions$.pipe(
            ofType(targetPromotionInit.type),
            exhaustMap(() => resolvedEffects$.pipe(takeUntil(this.actions$.pipe(ofType(targetPromotionDestroyed.type))))),
        );
    }

    constructor(
        private actions$: Actions,
        private toastrQueueService: ToastrQueueService,
        private commonService: CommonService,
    ) {}
}
