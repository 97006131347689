import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { OffersEnhancementGuard } from '../client-configs/guards/offers-enhancement-guard';
import { OffersGuard } from '../client-configs/guards/offers.guard';
import { OffersHomePageComponent } from './offers-home-page/offers-home-page.component';

export const routes: Routes = [
    {
        path: '',
        canActivate: [OffersGuard, OffersEnhancementGuard],
        children: [
            {
                path: 'grouped',
                loadChildren: () =>
                    import(
                        /* webpackChunkName: "offers-filter-group-view-module" */ './offers-filter-group-view/offers-filter-group-view.module'
                    ).then((m) => m.OffersFilterGroupViewModule),
            },
            {
                path: 'p/:product',
                loadChildren: () =>
                    import(/* webpackChunkName: "offers-navigation-view-module" */ './offers-navigation-view/offers-navigation-view.module').then(
                        (m) => m.OffersNavigationViewModule,
                    ),
            },
            {
                path: ':product',
                loadChildren: () =>
                    import(/* webpackChunkName: "offers-by-group-module" */ './offers-by-group/offers-by-group.module').then(
                        (m) => m.OffersByGroupModule,
                    ),
            },
            {
                path: '',
                component: OffersHomePageComponent,
            },
        ],
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
    providers: [OffersGuard, OffersEnhancementGuard],
})
export class OffersRoutingModule {}
