import { OverlayRef } from '@angular/cdk/overlay';
import { NgClass } from '@angular/common';
import { Component, Inject, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';

import { DynamicHtmlDirective } from '@frontend/vanilla/core';
import { RxFor } from '@rx-angular/template/for';
import { RxIf } from '@rx-angular/template/if';
import { Subject, takeUntil } from 'rxjs';

import { SmartRewardsConstants } from '../../../constants/smart-rewards-constant';
import { EdsEventContent } from '../../../models/base-content';
import { SubEventCompletionData } from '../../../models/monthly-prize-draw-report';
import { PCImageText } from '../../../models/smart-eds-widget-details';
import { SmartRewardsService } from '../../../services/smart-reward-service/smart-rewards.service';
import { GENERIC_MODAL_CONTENT } from '../../../shared/dialog/OverlayDialogModal';
import { SmartRewardTrackingHelperService } from '../../../shared/tracking/smart-reward-tracking-helper.service';
import { CompletedChallengeTileComponent } from '../completed-challenge-tile/completed-challenge-tile.component';

@Component({
    standalone: true,
    selector: 'ph-completed-entries-dialog',
    templateUrl: './completed-entries-dialog.component.html',
    styleUrl: './completed-entries-dialog.component.scss',
    imports: [NgClass, RxFor, RxIf, CompletedChallengeTileComponent, DynamicHtmlDirective],
    encapsulation: ViewEncapsulation.None,
})
export class CompletedEntriesDialogComponent implements OnInit, OnDestroy {
    smartRewardClientContent: PCImageText;
    completedEventsList: SubEventCompletionData[];
    completedEntriesCount: number = 0;
    edsEventsData: EdsEventContent[];
    private readonly destroy$ = new Subject<void>();
    constructor(
        @Inject(GENERIC_MODAL_CONTENT) public data: any,
        private overlayRef: OverlayRef,
        private smartRewardService: SmartRewardsService,
        private trackingHelperService: SmartRewardTrackingHelperService,
    ) {}

    ngOnInit() {
        this.smartRewardClientContent = this.data?.smartRewardClientContent;
        this.edsEventsData = this.data?.edsEventsData;
        this.trackingHelperService.trackOnPageLoad(SmartRewardsConstants.MonthlyPrizeDrawDetails);
        this.smartRewardService.monthlyReport$.pipe(takeUntil(this.destroy$)).subscribe((res: any) => {
            if (Object.keys(res).length > 0) {
                this.completedEntriesCount = res?.completedEntriesCount;
                this.completedEventsList = res?.subEventCompletionList;
                this.updateChallengeTitle();
            }
        });
        this.overlayRef
            .backdropClick()
            .pipe(takeUntil(this.destroy$))
            .subscribe(() => this.overlayRef.dispose());
    }

    onClosePopup() {
        this.trackingHelperService.trackOnCtaClick(SmartRewardsConstants.CloseX, SmartRewardsConstants.MonthlyPrizeDrawDetails);
        this.overlayRef.detach();
    }

    updateChallengeTitle() {
        if (this.completedEventsList?.length > 0 && this.edsEventsData?.length > 0) {
            this.completedEventsList.forEach((x) => {
                const challengeTitleKey = x?.product?.toLowerCase() + SmartRewardsConstants.ChallengeTitle;
                const event = this.edsEventsData.find((y) => y.eventId == x.subEventId);
                x.challengeTitle =
                    (event?.eventTitle != null
                        ? event.eventTitle
                        : this.smartRewardClientContent?.parameters != null
                          ? this.smartRewardClientContent?.parameters[challengeTitleKey]
                          : '') ?? '';
            });
        }
    }

    ngOnDestroy() {
        this.overlayRef.detach();
        this.overlayRef.dispose();
        this.destroy$.next();
        this.destroy$.complete();
    }
}
