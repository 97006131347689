import { Component, ElementRef, HostBinding, HostListener, OnDestroy, OnInit } from '@angular/core';

import {
    AnchorTrackingHelperService,
    CustomElement,
    MenuActionOrigin,
    MenuActionsService,
    NavigationService,
    TrackingService,
    UrlService,
    UserService,
    WindowRef,
} from '@frontend/vanilla/core';
import { EdsGroupService } from '@frontend/vanilla/shared/eds-group';
import { OfferResponse, OfferType, OffersResourceService } from '@frontend/vanilla/shared/offers';
import { kebabCase } from 'lodash-es';
import { Subject, firstValueFrom } from 'rxjs';
import { catchError, filter, map, takeUntil } from 'rxjs/operators';

import { OfferButtonConfig } from './offer-button.client-config';
import { OfferStatus, QueryParam } from './offer-button.models';

/**
 * @stable
 */
@Component({
    standalone: true,
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: '[data-offer-id],[data-campaign-id]',
    template: '<ng-content />',
})
export class OfferButtonComponent implements OnInit, OnDestroy {
    @HostBinding('class.offer-button-loading') loading: boolean;
    @HostBinding() innerText: string = '';
    @HostBinding('attr.data-offer-status') offerStatus: string;

    private offerId: string;
    private offerType: string;
    private edsGroupId: string | null;
    private unsubscribe = new Subject<void>();
    private originalClass: string;
    private nativeElement: CustomElement;
    private document: Document;

    constructor(
        private elementRef: ElementRef<HTMLElement>,
        private user: UserService,
        private menuActionsService: MenuActionsService,
        private navigationService: NavigationService,
        private urlService: UrlService,
        private offersResourceService: OffersResourceService,
        private offerConfig: OfferButtonConfig,
        private edsGroupService: EdsGroupService,
        private windowRef: WindowRef,
        private anchorTrackingHelperService: AnchorTrackingHelperService,
        private trackingService: TrackingService,
    ) {
        this.document = this.windowRef.nativeWindow.document;
    }

    get isEdsGroupType(): boolean {
        return this.offerType?.toLowerCase() === OfferType.EDS_GROUP;
    }

    @HostListener('click', ['$event'])
    async onClick(event: Event) {
        event.preventDefault();

        if (!this.loading && this.offerStatus === OfferStatus.Offered) {
            if (!this.user.isAuthenticated) {
                this.gotoLogin(true);
            } else {
                this.updateButton(await this.makeRequest('POST'));
                const el = event.target as HTMLAnchorElement;
                const trackingEventName = this.anchorTrackingHelperService.getTrackingEventName(el);
                if (trackingEventName) {
                    const data = this.anchorTrackingHelperService.createTrackingData(el);
                    await this.trackingService.triggerEvent(trackingEventName, data);
                }
            }
        }
    }

    ngOnInit() {
        this.nativeElement = this.elementRef.nativeElement as CustomElement;
        this.offerConfig.whenReady.subscribe(async () => {
            this.offerId = this.getAttributeValue('data-offer-id') || this.getAttributeValue('data-campaign-id');
            this.offerType = this.elementRef.nativeElement.getAttribute('data-offer-type')!;
            this.edsGroupId = this.elementRef.nativeElement.getAttribute('data-eds-group-id');
            this.originalClass = this.elementRef.nativeElement.getAttribute('class') || '';
            this.elementRef.nativeElement.classList.add('offer-button-md');

            if (this.nativeElement.originalHtmlString) {
                this.elementRef.nativeElement.innerHTML = this.nativeElement.originalHtmlString;
            }

            this.navigationService.locationChange.pipe(takeUntil(this.unsubscribe)).subscribe(async () => {
                await this.loadButton();
            });
            await this.loadButton();

            if (this.isEdsGroupType) {
                this.edsGroupService.freshCampaignDetails
                    .pipe(
                        filter((edsGroupId: string) => edsGroupId === this.edsGroupId),
                        takeUntil(this.unsubscribe),
                    )
                    .subscribe(() => {
                        const status = this.edsGroupService.getCampaignStatus(this.offerId);
                        this.updateButton(status);
                    });
            }
        });
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    private async makeRequest(method: string): Promise<string> {
        this.loading = true;

        if (this.isEdsGroupType) {
            if (method === 'GET') {
                this.edsGroupService.refreshEdsGroupStatus.next(this.edsGroupId!);
                await firstValueFrom(this.edsGroupService.freshCampaignDetails.pipe(filter((groupId) => groupId === this.edsGroupId)));
            }
            return new Promise<string>((resolve) => {
                if (method === 'GET') {
                    resolve(this.edsGroupService.getCampaignStatus(this.offerId));
                } else {
                    this.edsGroupService.updateCampaignStatus(this.edsGroupId!, this.offerId);
                }
            });
        }

        return firstValueFrom(
            method === 'GET'
                ? this.offersResourceService.getStatus(this.offerType, this.offerId).pipe(
                      map((response: OfferResponse) => response.status),
                      catchError(() => Promise.resolve('error')),
                  )
                : this.offersResourceService.updateStatus(this.offerType, this.offerId).pipe(
                      map((response: OfferResponse) => response.status),
                      catchError(() => Promise.resolve('error')),
                  ),
        );
    }

    private updateButton(offerStatus: string, text?: string | null) {
        const status = kebabCase(offerStatus);
        const content = this.offerConfig.content?.messages;
        const configMessage = content && (content[status] || content[OfferStatus.Unknown]);

        const innerText = text || this.getMessage(status) || configMessage || '';
        const buttonClass = this.offerConfig.buttonClass?.messages;
        const klass = (buttonClass && buttonClass[status]) || this.originalClass;
        this.elementRef.nativeElement.setAttribute('class', klass);

        if (this.offerConfig.v2) {
            // remove previously added children to reset to an empty state.
            while (this.nativeElement.firstChild) {
                this.nativeElement.removeChild(this.nativeElement.firstChild);
            }
            const textSpanElement = this.document.createElement('span');

            const iconClass = this.offerConfig.iconClass?.messages?.[status] ?? '';
            if (iconClass) {
                const iconSpanElement = this.document.createElement('span');
                iconSpanElement.setAttribute('class', iconClass);
                this.nativeElement.appendChild(iconSpanElement);
            }

            textSpanElement.innerText = innerText;
            this.nativeElement.appendChild(textSpanElement);
        } else {
            this.innerText = innerText;
        }

        this.elementRef.nativeElement.classList.add('offer-button-md');
        this.offerStatus = status;
        this.loading = false;
    }

    private getMessage(status: string): string | null {
        return this.elementRef.nativeElement.getAttribute(`data-offer-message-${status}`);
    }

    private async loadButton() {
        const searchParam = this.navigationService.location.search;
        const offerIdQueryParam = searchParam.get(QueryParam.OfferId);
        const offerTypeQueryParam = searchParam.get(QueryParam.OfferType);

        if (this.user.isAuthenticated) {
            const status = await this.makeRequest('GET');
            this.updateButton(status);

            if (kebabCase(status) === OfferStatus.Offered && this.isSameOffer(offerIdQueryParam, offerTypeQueryParam)) {
                this.updateButton(await this.makeRequest('POST'));
            }
        } else {
            if (this.isSameOffer(offerIdQueryParam, offerTypeQueryParam)) {
                this.gotoLogin(false);
            }

            await new Promise((resolve) => setTimeout(resolve, 50));
            this.updateButton(OfferStatus.Offered, this.getMessage(OfferStatus.Offered));
        }
    }

    private getAttributeValue(name: string): any {
        if (this.nativeElement.originalAttributes) {
            return this.nativeElement.originalAttributes.get(name);
        }

        return this.elementRef.nativeElement.getAttribute(name);
    }

    private gotoLogin(appendOfferDetails: boolean) {
        const returnUrl = this.urlService.current();
        if (appendOfferDetails) {
            returnUrl.search.set(QueryParam.OfferId, this.offerId);
            returnUrl.search.set(QueryParam.OfferType, this.offerType);
        } else {
            // append dummy querystring to force navigation after the login
            returnUrl.search.set('triggernav', '1');
        }

        this.menuActionsService.invoke('gotoLogin', MenuActionOrigin.OfferButton, [undefined, undefined, { returnUrl: returnUrl.absUrl() }]);
    }

    private isSameOffer(offerId: string | null, offerType: string | null): boolean {
        return offerId == this.offerId && offerType == this.offerType;
    }
}
