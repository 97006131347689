import { Injectable } from '@angular/core';

import { ClientConfigProductName, LazyClientConfig, LazyClientConfigBase, LazyClientConfigService } from '@frontend/vanilla/core';

@LazyClientConfig({
    product: ClientConfigProductName.PROMO,
    key: 'prSpainAndNLLabel', // The key for this specific config
})
@Injectable({
    providedIn: 'root',
    deps: [LazyClientConfigService],
    useFactory: regulatoryConfigurationFactory,
})
export class RegulatoryConfiguration extends LazyClientConfigBase {
    enableSpanishRegulatory: boolean;
    enableNLRestrictions: boolean;
}

export function regulatoryConfigurationFactory(service: LazyClientConfigService) {
    return service.get(RegulatoryConfiguration);
}
