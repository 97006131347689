import { Injectable } from '@angular/core';

import { TrackingService } from '@frontend/vanilla/core';

import { ModelDefault, TrackingModel } from '../../models/tracking-model';

/**
 * @whatItDoes This service is for tracking, and created to remove duplicate code.
 *
 * @stable
 */
@Injectable({
    providedIn: 'root',
})
export class TrackingModelService {
    constructor(private tracking: TrackingService) {}
    /**
     * @whatItDoes Default value of each TrackingModel property is 'not applicable'. Change value according to the requirement.
     *
     * @stable
     */
    submitTracking(trackingModel: TrackingModel, eventName: string = 'Event.Tracking') {
        trackingModel.SiteSection = ModelDefault.Site;
        trackingModel.CategoryEvent = trackingModel.CategoryEvent ? trackingModel.CategoryEvent : ModelDefault.Site;
        trackingModel.LabelEvent = trackingModel.LabelEvent ? trackingModel.LabelEvent : ModelDefault.NA;
        trackingModel.ActionEvent = trackingModel.ActionEvent ? trackingModel.ActionEvent : ModelDefault.NA;
        trackingModel.PositionEvent = trackingModel.PositionEvent ? trackingModel.PositionEvent : ModelDefault.NA;
        trackingModel.LocationEvent = trackingModel.LocationEvent ? trackingModel.LocationEvent : ModelDefault.NA;
        trackingModel.EventDetails = trackingModel.EventDetails ? trackingModel.EventDetails : ModelDefault.NA;
        trackingModel.URLClicked = trackingModel.URLClicked ? trackingModel.URLClicked : ModelDefault.NA;
        trackingModel.campaignId = trackingModel.campaignId ? trackingModel.campaignId : ModelDefault.NA;
        trackingModel.messageType = trackingModel.messageType ? trackingModel.messageType : ModelDefault.NA;
        trackingModel.contentPosition = trackingModel.contentPosition ? trackingModel.contentPosition : ModelDefault.NA;
        trackingModel.Product = trackingModel.Product ? trackingModel.Product : '';
        trackingModel.PromoBonusCode = trackingModel.PromoBonusCode ? trackingModel.PromoBonusCode : '';
        trackingModel.PromoIntent = trackingModel.PromoIntent ? trackingModel.PromoIntent : '';

        this.tracking.triggerEvent(eventName, {
            'page.siteSection': trackingModel.SiteSection,
            'component.CategoryEvent': trackingModel.CategoryEvent,
            'component.LabelEvent': trackingModel.LabelEvent,
            'component.ActionEvent': trackingModel.ActionEvent,
            'component.PositionEvent': trackingModel.PositionEvent,
            'component.LocationEvent': trackingModel.LocationEvent,
            'component.EventDetails': trackingModel.EventDetails,
            'component.URLClicked': trackingModel.URLClicked,
            'component.ContentPosition': trackingModel.contentPosition,
            'campaignId': trackingModel.campaignId,
            'messageType': trackingModel.messageType,
            'page.siteSubSection': trackingModel.siteSubSection,
            'component.PromotionType': trackingModel.promotionType,
            'component.Product': trackingModel.Product,
            'component.PromoOfferName': trackingModel.PromoOfferName,
            'component.PromoBonusCode': trackingModel.PromoBonusCode,
            'component.PromoIntent': trackingModel.PromoIntent,
        });
    }
}
