import { ContentImage, ContentLink, ViewTemplateForClient } from '@frontend/vanilla/core';

import { BaseDetailsContent, BaseDetailsDataPoint, BaseMetaData, EligibleGamesResponse } from '../../base-details/base-details.model';
import { TargetPromotionContent } from '../../targetedPromotions/common/models/target-promotion-content';

export class Brat {
    bratContent: BratContent;
    bratMetaData: BratMetadata;
    multiOfferDetailsList: MultiOfferDetails[];
}

export interface MultiOfferDetails {
    dataPoints: BaseDetailsDataPoint[];
    promoId: number;
    pageContent: BaseDetailsContent;
    offerCode: string;
    eligibleGames: EligibleGamesResponse;
    tileSpecificData: OfferTileSpecificData;
    displayGameContributionNote: boolean;
    freeSpinPayOut: BaseDetailsDataPoint;
}

export interface OfferTileSpecificData {
    product: string;
    productImage: ContentImage;
    depositInfo: string;
    depositLimit: string;
    remainingDepositInfo: string;
    ctaLink: ContentLink;
    progress: BratProgress;
}

export interface BratProgress {
    initialAmount: string;
    targetAmount: string;
    currentRemainingAmount: string;
    currentProgressPercentage: string;
    currentProgressRemainingText: string;
    numberOfDepositsRemaining: string;
    depositsRemainingText: string;
    reusableDepositCount: number;
}
export interface BratContent extends TargetPromotionContent {
    bratTabs: BratTab[];
    description: string;
    takeYourPick: string;
}

export interface OverViewContent {
    promoTeaserInfo: string;
    additionalMessage: string;
    prizeMessage: string;
    additionalText: string;
    winUpto: string;
    image: ContentImage;
}

export interface HowItWorksContent {
    steps: Step[];
    manualDataPoints: ManualDataPoint[];
}

export interface BratTab {
    key: string;
    value: string;
}

export interface BratMetadata extends BaseMetaData {
    isAwarded: boolean;
    offerExpiryDate: Date;
    isDepositRequired: boolean;
    bonusId: string;
    bonusCode: string;
    awardBonusExpiryDays: string;
    postAcceptanceHasDeposit: string;
    usedFreeSpins: string;
    totalFreeSpins: string;
    freeSpinValidity: string;
    campaignType: string;
    cmsItemIdentifier: string;
    wageringMultiplier: number;
    wageringValidity: number;
    restrictionsType: number;
    claimBackAmount: number;
    crmServiceSubType: string;
    dropOnWithDraw: boolean;
    bonusType: number;
}

export interface Step {
    title: string;
    description: string;
    toolTipType: string;
}

export interface ManualDataPoint {
    title: string;
    description: string;
    toolTipType: string;
}

export class BratClientContent {
    public content: ViewTemplateForClient;
    public bratClientContent: ViewTemplateForClient;
    public bonusClientContent: ViewTemplateForClient;
}
