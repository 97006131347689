import { ContentImage, ContentLink } from '@frontend/vanilla/core';

export class FilterOffersV2 {
    advanceFilter: AdvanceFilter;
    mainFilter: FilterTabs[];
}

export class AdvanceFilter {
    title: string;
    description: string;
    imageIcon: ContentImage;
    messages: any;
    filterByProductType: FilterByProductType[];
    isAdvanceFilterActive: boolean = false;
    selectedOffersIndex: { [key: string]: number[] } = {};
    totalSelectedOffers: number = 0;
    webAnalyticName: string;
}

export interface FilterByProductType extends ProductFilter {
    filtersByOfferType: ProductFilter[];
}

export class ProductFilter {
    title: string;
    description: string;
    imageIcon: ContentImage;
    imageLink: ContentLink;
    product: string;
    serviceType: string[];
    subType: string[];
    excludeSubType: string[];
    count: number = 0;
    isActive: boolean = false;
    filteredOfferIndexes: number[];
    messages: any;
    webAnalyticName: string;
}

export class FilterTabs {
    title: string;
    iconUrl: ContentImage;
    name: string;
    messages: any;
    isActive: boolean = false;
    count: number = 0;
    filteredOfferIndexes: number[] = [];
    text: string;
}
