<ng-container *ngrxLet="vm$ as vm">
    <div *ngIf="vm" class="d-flex align-items-center offers-teaser-tiles">
        <ng-container *ngIf="!!offer?.offerContent?.primaryCta">
            <a
                [ngClass]="!!moreInfoCta?.attributes?.class ? moreInfoCta?.attributes?.class : 'btn btn-link btn-link-light'"
                class="btn-md"
                [attr.href]="moreInfoCta?.url"
                *ngIf="!!moreInfoCta?.url"
                (click)="navigateToOfferMoreInfo($event); $event?.stopPropagation()"
                target="{{ !!moreInfoCta?.attributes?.target ? moreInfoCta?.attributes?.target : '' }}">
                {{ moreInfoCta?.text }}
            </a>
            <a
                [ngClass]="
                    !!offer?.offerContent?.primaryCta?.attributes?.class ? offer?.offerContent?.primaryCta?.attributes?.class : 'btn btn-primary'
                "
                class="w-100 btn-md justify-content-center"
                *ngIf="isSpecialOfferOptIn && !offerContentConfiguration?.isBetMgmTileRedesignEnabled"
                data-offer-id
                [attr.data-offer-id]="Special_offer_Id"
                [attr.data-offer-type]="Special_offer_Type"
                [attr.data-offer-message-error]="vm?.optInInvalidMessage"
                [attr.data-offer-message-expired]="vm?.optInExpiredMessage"
                [attr.data-offer-message-invalid]="vm?.optInInvalidMessage"
                [attr.data-offer-message-not-offered]="vm?.optInUserNotEligibleMessage"
                [attr.data-offer-message-offered]="vm?.optInMessage"
                [attr.data-offer-message-opted-in]="vm?.optedInMessage"
                [attr.data-offer-message-opted-out]=""
                (click)="specialOffer_Click($event); $event?.stopPropagation()"
                id="NonBetmgmTileRedesign">
                {{ offer?.offerContent?.primaryCta?.text }}
            </a>
            <a
                [ngClass]="
                    !!offer?.offerContent?.primaryCta?.attributes?.class ? offer?.offerContent?.primaryCta?.attributes?.class : 'btn btn-primary'
                "
                class="w-100 btn-md justify-content-center"
                *ngIf="isSpecialOfferOptIn && offerContentConfiguration?.isBetMgmTileRedesignEnabled"
                data-offer-id
                [attr.data-offer-id]="Special_offer_Id"
                [attr.data-offer-type]="Special_offer_Type"
                [attr.data-offer-message-error]="vm?.optedInErrorMessage"
                [attr.data-offer-message-expired]="vm?.optInExpiredMessage"
                [attr.data-offer-message-invalid]="vm?.optInInvalidMessage"
                [attr.data-offer-message-not-offered]="vm?.optInUserNotEligibleMessage"
                [attr.data-offer-message-offered]="vm?.optInMessage"
                [attr.data-offer-message-opted-in]="vm?.optedInMessage"
                [attr.data-offer-message-opted-out]=""
                (click)="specialOffer_Click($event); $event?.stopPropagation()"
                id="BetmgmTileRedesign">
                {{ offer?.offerContent?.primaryCta?.text }}
            </a>
            <a
                [ngClass]="
                    !!offer?.offerContent?.primaryCta?.attributes?.class
                        ? offer?.offerContent?.primaryCta?.attributes?.class
                        : 'btn btn-link btn-link-light'
                "
                class="btn-md"
                *ngIf="!!offer?.offerContent.primaryCta?.url && !isSpecialOfferOptIn"
                (click)="trackClickEvent(teaserNumber, offer?.offerContent?.primaryCta, $event)"
                [attr.href]="offer?.offerContent?.primaryCta?.url"
                target="{{ !!offer?.offerContent?.primaryCta?.attributes?.target ? offer?.offerContent?.primaryCta?.attributes?.target : '' }}">
                {{ offer?.offerContent?.primaryCta?.text }}
            </a>
        </ng-container>
        <ng-container *ngIf="!!!offer?.offerContent?.primaryCta">
            <a
                [attr.href]="moreInfoCta?.url"
                [ngClass]="!!moreInfoCta?.attributes?.class ? moreInfoCta?.attributes?.class : 'btn btn-secondary single-info-btn more-info-btn'"
                class="btn-md"
                *ngIf="!!moreInfoCta?.url"
                (click)="navigateToOfferMoreInfo($event)"
                target="{{ !!moreInfoCta?.attributes?.target ? moreInfoCta?.attributes?.target : '' }}">
                {{ moreInfoCta?.text }}
            </a>
        </ng-container>
    </div>
</ng-container>
