import { Injectable } from '@angular/core';

import { ViewTemplate } from '@frontend/vanilla/core';
import { BehaviorSubject, Observable, map } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class SpanishRegulatoryHelperService {
    nonUserComplianceMessages$ = new BehaviorSubject<ViewTemplate>({});
    showNonComplianceErrorMessage$ = new BehaviorSubject(false);
    reasonCodes$ = new BehaviorSubject<string[]>([]);
    isRiskyPlayer: boolean;

    constructor() {
        this.setIsRiskyPlayer();
    }

    private setIsRiskyPlayer(): void {
        this.reasonCodes$.subscribe((reasonCodes: string[]) => {
            if (reasonCodes?.length > 0) {
                const reasonCode = this.getRiskyPlayerReasonCode(reasonCodes);
                this.isRiskyPlayer = !!reasonCode;
            }
        });
    }

    isOptInAllowed$(isGeneral: boolean): Observable<boolean> {
        return this.reasonCodes$.pipe(
            map((reasonCodes: string[]) => {
                return reasonCodes?.length === 0 || (!this.evaluateIfPlayerIsRisky(reasonCodes) && isGeneral);
            }),
        );
    }

    showNonComplianceErrorMessage(isDisabled: boolean = false) {
        const showErrorMessage = this.isRiskyPlayer || isDisabled;
        this.showNonComplianceErrorMessage$.next(showErrorMessage);
        if (showErrorMessage) {
            window.scrollTo(0, 0);
        }
    }

    getRiskyPlayerReasonCode(reasonCodes: string[]): string {
        return reasonCodes.find((x) => x !== 'R103' && x !== 'R104') ?? '';
    }

    evaluateIfPlayerIsRisky(reasonCodes: string[]): boolean {
        return !!this.getRiskyPlayerReasonCode(reasonCodes);
    }
}
