import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Injectable({
    providedIn: 'root',
})
export class RoutingHistory {
    constructor(private router: Router) {}

    public setPreviousUrl() {
        this.deleteRoute();
        if (this.router.url.includes('promo')) sessionStorage.setItem('promoPreviousUrl', this.router.url);
    }

    public setFilteredPreviousUrl() {
        sessionStorage.removeItem('filterPreviousUrl');
        if (this.router.url.includes('promo')) sessionStorage.setItem('filterPreviousUrl', this.router.url);
    }

    public setGroupedUrl() {
        sessionStorage.removeItem('groupedUrl');
        if (this.router.url.includes('promo')) sessionStorage.setItem('groupedUrl', this.router.url);
    }

    public setReturnUrl(activatedRoute: ActivatedRoute) {
        activatedRoute.queryParams.subscribe((params) => {
            if (params['returnUrl']) {
                const returnUrl = params['returnUrl'];
                sessionStorage.setItem('filterdeeplinkingreturnurl', returnUrl);
            }
        });
    }

    public clearReturnUrl() {
        sessionStorage.removeItem('filterdeeplinkingreturnurl');
    }

    public deleteRoute() {
        sessionStorage.removeItem('promoPreviousUrl');
    }

    public removeFilteredPreviousUrl() {
        sessionStorage.removeItem('filterPreviousUrl');
    }
}
