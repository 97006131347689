import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { Injectable, InjectionToken, Injector } from '@angular/core';

export const GENERIC_MODAL_CONTENT = new InjectionToken<any>('data');

@Injectable({
    providedIn: 'root',
})
export class OverlayDialogModal {
    constructor(
        private overlay: Overlay,
        private injector: Injector,
    ) {}

    openDialogModal(componentType: any, inputData: any, panelClass: string = 'generic-modal-popup') {
        const data = inputData;
        const overlayRef = this.overlay.create({
            hasBackdrop: true,
            scrollStrategy: this.overlay.scrollStrategies.block(),
            positionStrategy: this.overlay.position().global().centerHorizontally().centerVertically(),
            panelClass: [panelClass],
        });
        const component = new ComponentPortal(
            componentType,
            null,
            Injector.create({
                providers: [
                    { provide: GENERIC_MODAL_CONTENT, useValue: data },
                    { provide: OverlayRef, useValue: overlayRef },
                ],
                parent: this.injector,
            }),
        );

        overlayRef.attach(component);
    }
}
