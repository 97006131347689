<div *ngIf="!isOffersHistoryPage; else mobile_view_template">
    <div class="offers-in-progress-nav">
        <div class="filter-segmented-sec">
            <div class="form-control-tabs-segmented-v4">
                <ng-container *ngFor="let menuItem of menuList; let i = index">
                    <input
                        [disabled]="totalCount > 0"
                        type="radio"
                        [name]="'tabs'"
                        [value]="menuItem.type"
                        [id]="'tb' + i"
                        [checked]="menuItem.type === selectedItem"
                        class="form-control-tabs-segmented-v4-input"
                        [ngClass]="{ 'selected-item': totalCount > 0 }" />
                    <label
                        [for]="'tb' + i"
                        class="form-control-tabs-segmented-v4-label"
                        (click)="totalCount > 0 ? $event.stopPropagation() : itemClick(menuItem, i + 1)"
                        >{{ menuItem.name }}</label
                    >
                </ng-container>
            </div>
        </div>
    </div>
    <div class="offers-navigation-epcot">
        <nav *ngIf="menuItems?.length > 0" class="navbar navbar-expand-sm sub-nav-v2 sub-nav-wrapper" [ngClass]="menuContainer?.class">
            <ul class="tab-nav navbar-nav">
                <li *ngFor="let menuItem of menuItems; let i = index" class="tab-nav-item" [class.active]="menuItem.name === sourceItem">
                    <a
                        *ngIf="offersNavigationGroupedProducts?.length == 0"
                        (click)="processClick($event, menuItem, i + 1)"
                        class="tab-nav-link"
                        [attr.href]="menuItem?.url">
                        <div id="tab-{{ menuItem.name }}" class="tab-nav-text">
                            <span>{{ menuItem.text }}</span>
                        </div>
                    </a>
                    <a *ngIf="offersNavigationGroupedProducts?.length > 0" (click)="processClick($event, menuItem, i + 1)" class="tab-nav-link">
                        <div id="tab-{{ menuItem.name }}" class="tab-nav-text">
                            <span>{{ menuItem.text }}</span>
                        </div>
                    </a>
                </li>
            </ul>
        </nav>
    </div>
</div>
<ng-template #mobile_view_template class="offers-navigation navbar navbar-expand-sm sub-nav-wrapper">
    <div *ngIf="isOffersHistoryPage">
        <div *ngIf="mobileViewTitle">
            <lh-header-bar
                [content]="mobileViewTitle"
                [disableClose]="disableClose"
                (onClose)="processCancel()"
                [showBackButton]="showBackArrow"
                (onBack)="processBack()">
            </lh-header-bar>
        </div>
    </div>
</ng-template>
