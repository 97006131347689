import { createReducer, on } from '@ngrx/store';

import { MyCardsStateModel } from '../../models/my-cards/my-cards-state-models';
import { loadMyCardsData, myCardsDestroyed, userRevealedMyCard_Success } from './my-cards.actions';

export const initialState = new MyCardsStateModel();

export const myCardsReducer = createReducer(
    initialState,
    on(loadMyCardsData, (state, action) => ({
        ...state,
        ...action,
    })),
    on(userRevealedMyCard_Success, (state, action) => ({
        ...state,
        myCardsContent: {
            ...state.myCardsContent,
            myEarnedCardsContent: {
                ...action.myCardsContent.myEarnedCardsContent,
            },
            earnedCardDetails: [...action.myCardsContent.earnedCardDetails],
        },
        earnedCardResultDetails: {
            ...action.earnedCardResultDetails,
        },
    })),
    on(myCardsDestroyed, () => initialState),
);
