import { createFeatureSelector, createSelector } from '@ngrx/store';

import { CashbackStoreModel } from '../cashback-models/cashback-store-model';

export const cashbackModuleStateKey = 'cashbackModuleData';

//Selectors for store data
export const cashbackFeatureDetails = createFeatureSelector<CashbackStoreModel>(cashbackModuleStateKey);
export const cashbackState = (state: CashbackStoreModel) => state;
export const cashbackDetails = createSelector(cashbackFeatureDetails, cashbackState);

export const depositCriteria = createSelector(cashbackFeatureDetails, (state) => state.preRequisite);
export const preRequisiteProgress = createSelector(cashbackFeatureDetails, (state) => state.preRequisiteProgress);
