import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { DynamicHtmlDirective } from '@frontend/vanilla/core';
import { RxIf } from '@rx-angular/template/if';

@Component({
    standalone: true,
    selector: 'user-error-info',
    templateUrl: './user-error-info.component.html',
    styleUrl: './user-error-info.component.scss',
    changeDetection: ChangeDetectionStrategy.Default,
    imports: [DynamicHtmlDirective, NgClass, RxIf],
})
export class UserErrorInfoComponent {
    @Input() message: string;
    @Input() isRgStatus: boolean = false;

    constructor() {}
}
