<ng-container *ngrxLet="nLRegulatoryHelperService.displayPromoDetails$ as displayPromoDetails">
    <ng-container *ngIf="displayPromoDetails">
        <div
            *ngIf="allOffers?.offerDetailsList?.length > 0 && filterOffersContent"
            class="offers-navigation offers-navigation-filter-container py-3 pl-4">
            <ul class="navbar-wrapper nav nav-pills offers-filter-nav-v2">
                <li *ngIf="advanceFilter" class="d-flex align-items-center mr-2">
                    <a
                        id="advanceFilterPopupToggle"
                        class="pill-with-badge-v2 advance-filter"
                        [ngClass]="{ active: advanceFilter.totalSelectedOffers > 0 }"
                        (click)="filterPopUpClicked(advanceFilter.webAnalyticName)">
                        <i class="badge-icon theme-filters-vertical ui-icon-size-lg"></i>
                        <span class="badge-text ml-2">{{ advanceFilter.title }}</span>
                        <span class="badge badge-circle" *ngIf="advanceFilter.totalSelectedOffers > 0">{{ advanceFilter.totalSelectedOffers }}</span>
                    </a>
                </li>
                <li class="reset-filter-btn d-flex align-items-center" *ngIf="advanceFilter?.totalSelectedOffers > 0">
                    <a href="#" class="btn btn-link btn-link-dark" (click)="resetAdvanceFilter(); trackResetFilterOnClick()">
                        {{ advanceFilter?.messages?.resetfilter }}
                    </a>
                </li>
                <li *ngFor="let tab of mainFilterTabs; let i = index">
                    <div *ngIf="tab?.count > 0" class="mx-2">
                        <a
                            class="pill-with-badge-v2 main-filter"
                            *ngIf="tab?.count > 0"
                            [ngClass]="{ active: tab.isActive }"
                            (click)="applyMainFilter(tab, i)">
                            <span class="badge-text">{{ tab.title }}</span>
                            <span class="badge badge-circle">{{ tab.count }}</span>
                        </a>
                    </div>
                </li>
            </ul>
        </div>
        <div id="advanceFilterPopupClose" *ngIf="!!advanceFilter?.isAdvanceFilterActive" class="d-flex d-sm-none filters-mobile-dialog-header">
            <i class="badge-icon theme-filters-vertical ui-icon-size-lg"></i>
            <span class="badge-text ml-3">{{ advanceFilter?.title }}</span>
            <i class="ml-auto theme-ex ui-icon-size-lg close-filters-dialog d-flex align-items-center" (click)="filterPopUpClose()"></i>
        </div>
        <app-filter-popup
            id="advanceFilterPopup"
            *ngIf="!!advanceFilter?.isAdvanceFilterActive"
            [advanceFilter]="advanceFilter"
            [mainFilter]="mainFilterTabs"
            [offerCategories]="allOffers?.offerCategories"
            class="offers-popup-filters-container-v2"></app-filter-popup>
    </ng-container>
</ng-container>
