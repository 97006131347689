import { NgClass } from '@angular/common';
import { Component, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';

import { ContentImage, DeviceService, DynamicHtmlDirective, UserService } from '@frontend/vanilla/core';
import { RxFor } from '@rx-angular/template/for';
import { RxIf } from '@rx-angular/template/if';
import { Subject, takeUntil } from 'rxjs';

import { SmartRewardConfiguration } from '../../config/smart-reward-config';
import { SmartRewardsConstants } from '../../constants/smart-rewards-constant';
import { PCImageText } from '../../models/smart-eds-widget-details';
import { SmartRewardContent, SmartRewardMetaData } from '../../models/smart-rewards-details.model';
import { SmartRewardsService } from '../../services/smart-reward-service/smart-rewards.service';
import { OverlayDialogModal } from '../../shared/dialog/OverlayDialogModal';
import { OverlayInfoDialogComponent } from '../../shared/dialog/about-smart-rewards/overlay-info-dialog/overlay-info-dialog.component';
import { MonthlyPrizeProgressComponent } from '../../shared/monthly-prize-progress/monthly-prize-progress.component';
import { SmartRewardTrackingHelperService } from '../../shared/tracking/smart-reward-tracking-helper.service';
import { CompletedEntriesDialogComponent } from '../completed-entries/completed-entries-dialog/completed-entries-dialog.component';

@Component({
    standalone: true,
    selector: 'ph-monthly-prize-draw-widget',
    templateUrl: './monthly-prize-draw-widget.component.html',
    styleUrl: './monthly-prize-draw-widget.component.scss',
    imports: [DynamicHtmlDirective, MonthlyPrizeProgressComponent, NgClass, RxFor, RxIf],
})
export class MonthlyPrizeDrawWidgetComponent implements OnInit, OnDestroy, OnChanges {
    @Input() smartRewardContent: SmartRewardContent;
    @Input() smartRewardClientContent: PCImageText;
    @Input() smartRewardMetaData: SmartRewardMetaData;

    private readonly destroy$ = new Subject<void>();
    isAuthenticated: boolean = false;
    playerProgressPercentage: number = 0;
    playerProgressDescription: string;
    backGroundImage: ContentImage;
    completedEntriesCount: number;

    constructor(
        private overlayDialogModal: OverlayDialogModal,
        private deviceService: DeviceService,
        public user: UserService,
        private smartRewardService: SmartRewardsService,
        private trackingHelperService: SmartRewardTrackingHelperService,
        private smartRewardConfig: SmartRewardConfiguration,
    ) {}

    ngOnInit() {
        this.smartRewardService.monthlyReport$.subscribe((res) => {
            if (Object.keys(res)?.length > 0) {
                this.completedEntriesCount = res?.completedEntriesCount;
            }
        });

        this.smartRewardService.smartRewardCacheDetails$.pipe(takeUntil(this.destroy$)).subscribe((res) => {
            if (
                this.user?.isAuthenticated &&
                this.smartRewardConfig?.enableHelpScreenOnFirstLogin &&
                Object.keys(res)?.length > 0 &&
                !res?.cachedValue
            ) {
                this.openDialog(true, false);
                this.smartRewardService.setSmartRewardCacheDetails(SmartRewardsConstants.SmartRewardInfoKey, true);
            }
        });
    }

    ngOnChanges() {
        this.backGroundImage = this.deviceService.isMobilePhone
            ? this.smartRewardContent?.monthlyPrizeDrawContent?.mobileBackGroundImage
            : this.smartRewardContent?.monthlyPrizeDrawContent?.desktopBackGroundImage;
        this.getPlayerProgressPercentage();
    }

    openDialog(isInfoDialog: boolean = false, isTrackingRequired: boolean = true) {
        if (isTrackingRequired) {
            const ctaName = isInfoDialog ? SmartRewardsConstants.SmartRewardMoreInfo : SmartRewardsConstants.FindAllEntriesHere;
            this.trackingHelperService.trackOnCtaClick(ctaName, SmartRewardsConstants.MonthlyPrizeDraw);
        }

        const componentType = isInfoDialog ? OverlayInfoDialogComponent : CompletedEntriesDialogComponent;
        const data = isInfoDialog
            ? { howItWorksContent: this.smartRewardContent.howItWorksContent, isEdsWidget: false }
            : { smartRewardClientContent: this.smartRewardClientContent, edsEventsData: this.smartRewardContent?.edsEventsData };
        this.overlayDialogModal.openDialogModal(componentType, data);
    }

    getPlayerProgressPercentage() {
        if (this.smartRewardMetaData) {
            this.playerProgressPercentage =
                (Number(this.smartRewardMetaData?.numberOfTicketsWon) / Number(this.smartRewardMetaData?.configNumberofTickets)) * 100;
            this.playerProgressDescription = this.smartRewardMetaData?.numberOfTicketsWon + '/' + this.smartRewardMetaData?.configNumberofTickets;
        }
    }

    ngOnDestroy() {
        this.destroy$.next();
        this.destroy$.complete();
    }
}
