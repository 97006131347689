<div class="offers-teaser-v2" (click)="navigateToOfferMoreInfo(); $event.stopPropagation()">
    <div class="offers-teaser-v2-img-cont">
        <img [src]="offer?.offerContent?.teaserImage ? offer?.offerContent.teaserImage.src : ''" />
        <div class="active status-pills">
            {{ clientContentMessages?.active }}
        </div>
        <offer-type-badge *ngIf="betStationConfiguration.enableOfferTypeBadge" class="promotions-tab" [offer]="offer"></offer-type-badge>

        <div class="offers-teaser-v2-badges" [ngClass]="offer?.offerMetadata?.showExpiryDate ? 'offersdata' : 'offersnodata'">
            <div class="offer-badge txt-s offer-expire" *ngIf="offer?.offerMetadata?.showExpiryDate">
                <div class="badge-countdown">
                    <span class="badge-countdown__icon theme-clock"></span>
                    <div class="badge-countdown__text">
                        {{ offer?.offerContent?.expiryTimeCountDown }}
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="offers-teaser-v2-content">
        <h3 class="offers-teaser-v2-title">{{ offer?.offerContent?.title }}</h3>
        <div class="offers-teaser-v2-conditions" *ngIf="offer?.offerContent?.keyTermsAndConditions">
            <span class="mb-0 txt-xtr-small" [vnDynamicHtml]="offer?.offerContent?.keyTermsAndConditions"></span>
        </div>
    </div>
    <div class="offers-teaser-v2-actions betstation-offers" *ngIf="offer?.offerMetadata?.isSpecialOffer">
        <div class="betstation-offers-teaser-btn">
            <ng-container *ngIf="!!offer.offerContent.primaryCta">
                <a
                    [ngClass]="!!moreInfoCta?.attributes?.class ? moreInfoCta?.attributes?.class : 'btn btn-light btn-lg'"
                    class="w-100"
                    *ngIf="!!moreInfoCta?.url"
                    (click)="navigateToOfferMoreInfo(); $event.stopPropagation()">
                    {{ moreInfoCta?.text }}
                </a>
                <a
                    [ngClass]="
                        !!offer?.offerContent?.primaryCta?.attributes?.class
                            ? offer?.offerContent?.primaryCta?.attributes?.class
                            : 'btn btn-primary btn-lg'
                    "
                    class="w-100 btn-lg"
                    *ngIf="isSpecialOfferOptin"
                    data-offer-id
                    [attr.data-offer-id]="Special_offer_Id"
                    [attr.data-offer-type]="Special_offer_Type"
                    [attr.data-offer-message-error]="optinInvalidMessage"
                    [attr.data-offer-message-expired]="optinExpiredMessage"
                    [attr.data-offer-message-invalid]="optinInvalidMessage"
                    [attr.data-offer-message-not-offered]="optinUserNotEligibleMessage"
                    [attr.data-offer-message-offered]="optInMessage"
                    [attr.data-offer-message-opted-in]="optedInMessage"
                    [attr.data-offer-message-opted-out]=""
                    (click)="specialOffer_Click($event); $event.stopPropagation()">
                    {{ offer.offerContent.primaryCta.text }}
                </a>

                <a
                    [ngClass]="
                        !!offer?.offerContent?.primaryCta?.attributes?.class
                            ? offer?.offerContent?.primaryCta?.attributes?.class
                            : 'btn btn-light btn-lg'
                    "
                    class="w-100"
                    href="#"
                    *ngIf="!!offer.offerContent.primaryCta?.url && !isSpecialOfferOptin"
                    (click)="trackBetStationClick('opt-in', teaserNumber, offer.offerContent.primaryCta); $event.stopPropagation()">
                    {{ offer.offerContent.primaryCta?.text }}
                </a>
            </ng-container>
            <ng-container *ngIf="!!!offer.offerContent.primaryCta">
                <a
                    [ngClass]="!!moreInfoCta?.attributes?.class ? moreInfoCta?.attributes?.class : 'btn btn-light btn-lg'"
                    class="w-100 btn-lg"
                    *ngIf="!!moreInfoCta?.url"
                    (click)="navigateToOfferMoreInfo(); $event.stopPropagation()">
                    {{ moreInfoCta?.text }}
                </a>
            </ng-container>
        </div>
    </div>
</div>
