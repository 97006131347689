import { createAction, props } from '@ngrx/store';

import { ClickCardDetails } from '../click-card-models/click-card-details';
import { ClickCardRequest } from '../click-card-models/click-card-request';

export const clickCardInit = createAction('[ClickCard Promotion] Init');
export const clickCardDestroyed = createAction('[ClickCard Promotion] Destroyed');
export const loadClickCardData = createAction('[ClickCard Promotion] LoadData', props<ClickCardRequest>());
export const loadClickCardData_Success = createAction('[ClickCard Promotion] Load_Success', props<ClickCardDetails>());
export const loadToastr = createAction('[ClickCard Promotion] LoadToastr', props<ClickCardDetails>());
export const clickCardOptInSuccess = createAction('[ClickCard Promotion] Opt In Success', props<ClickCardDetails>());
export const revealCard = createAction('[ClickCard Promotion] RevealCard', props<ClickCardDetails>());
