import { Injectable } from '@angular/core';

import { FilterConfiguration, FilterOffersContent, FilterOffersV2 } from '@frontend/promo-homewidget';
import { ApiService } from '@frontend/promo-utils';
import { UserService } from '@frontend/vanilla/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class OffersPopUpFilterService {
    offersFilter$ = new BehaviorSubject(new FilterOffersContent());
    offersFilterV2$ = new BehaviorSubject(new FilterOffersV2());
    constructor(
        private apiService: ApiService,
        public userService: UserService,
        public filterConfiguration: FilterConfiguration,
    ) {
        this.loadOffersFilter();
        this.loadOffersFilterV2();
    }
    loadOffersFilter() {
        if (this.userService && this.userService.isAuthenticated && !this.filterConfiguration.isFilterV2Enabled) {
            this.apiService.get('offersFilter').subscribe((res) => {
                this.offersFilter$.next(res);
            });
        }
    }

    loadOffersFilterV2() {
        if (this.userService && this.userService.isAuthenticated && this.filterConfiguration.isFilterV2Enabled) {
            this.apiService.get('offersFilterV2').subscribe((res) => {
                this.offersFilterV2$.next(res);
            });
        }
    }
}
