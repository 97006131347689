import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

import { MediaQueryService } from '@frontend/vanilla/core';
import { Observable, map } from 'rxjs';

import { NLRegulatoryHelperService } from '../../spanish-regulatory/NL-regulatory/NL-regulatory-helper.service';
import { OffersProductStyle } from '../models';
import { LoadOffersApiService } from '../offers-service/load-offers.service';
import { BaseOverviewPageComponent } from './base-overview-page.component';

@Component({
    selector: 'offers-by-product',
    templateUrl: 'offers-by-product.component.html',
    changeDetection: ChangeDetectionStrategy.Default,
})
export class OffersByProductComponent extends BaseOverviewPageComponent implements OnInit {
    @Input() showTeaserSkeleton: boolean;
    @Input() offersProductStyle: OffersProductStyle;

    vm$: Observable<any>;

    constructor(media: MediaQueryService, public nLRegulatoryHelperService: NLRegulatoryHelperService, loadOffersApiService: LoadOffersApiService) {
        super(media, loadOffersApiService);
        this.vm$ = this.baseVm$.pipe(
            map((offersVm) => {
                return { ...offersVm, showSeeAllLink: offersVm.offerProducts?.length > 1 || this.isOffersLoadedFromDiffApp };
            }),
        );
    }

    override ngOnInit() {
        super.ngOnInit();
        this.loadOffersApiService.ClearGroupedFilter();
        this.loadOffersApiService.filteredProducts();
    }

    trackByKey(product: string) {
        return product;
    }
}
