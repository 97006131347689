@if (completedEvent?.product && smartRewardClientContent?.parameters) {
    <div class="smart-widget completed-challaenge d-flex">
        <div class="completed-challaenge__progress-bar">
            <div class="background-bar overlay-bg">
                <div class="progress--values">{{ completedEvent?.completedProgress }}</div>
                <div class="progress" [ngStyle]="{ width: '100%' }">
                    <div class="progress--thumb"><span class="progress--check theme-check"></span></div>
                </div>
            </div>
        </div>
        <div class="completed-challaenge__data">
            <div class="completed-challaenge__data--text">{{ completedEvent?.formattedCompletedDate }}</div>
            <div class="completed-challaenge__data--title">
                {{ completedEvent.challengeTitle }}
            </div>
            <div
                class="completed-challaenge__data--description"
                [vnDynamicHtml]="smartRewardClientContent?.parameters?.congratscompletedchallenge"></div>
        </div>
    </div>
}
