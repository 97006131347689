import { createAction, props } from '@ngrx/store';

import { CashbackDetails } from '../cashback-models/cashback-details';
import { CashbackRequest } from '../cashback-models/cashback-request';

export const cashbackInit = createAction('[CashBack Promotion] Init');
export const cashbackDestroyed = createAction('[CashBack Promotion] Destroyed');
export const loadCashbackData = createAction('[CashBack Promotion] LoadData', props<CashbackRequest>());
export const loadCashbackData_Success = createAction('[CashBack Promotion] Load_Success', props<CashbackDetails>());

export const loadCashbackDataOptin_Success = createAction('[CashBack Promotion] Load_Optin_Success', props<CashbackDetails>());
