import { CommonModule, PercentPipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { RouterModule } from '@angular/router';

import { DynamicHtmlDirective, PlainLinkComponent } from '@frontend/vanilla/core';
import { DslPipe } from '@frontend/vanilla/shared/browser';
import { LetDirective } from '@ngrx/component';

import { SharedOffersModule } from '../shared-offers/shared-offers.module';
import { CashierDepositOffersTeaserComponent } from './cashier-deposit/deposits-offers-teaser.component';
import { NoOffersComponent } from './no-offers/no-offers.component';
import { BaseOverviewPageComponent } from './offers-by-product/base-overview-page.component';
import { OffersByProductComponent } from './offers-by-product/offers-by-product.component';
import { OffersOverviewComponent } from './offers-overview/offers-overview.component';
import { OffersSuccessMessageComponent } from './offers-success-message/offers-success-message.component';
import { OffersTeaserGroupComponent } from './offers-teaser-group/offers-teaser-group.component';
import { GroupedOffersCountPipe } from './pipes/grouped-offers.pipe';
import { UserComplianceMessageComponent } from './user-compliance-message/user-compliance-message.component';

@NgModule({
    imports: [CommonModule, PlainLinkComponent, DynamicHtmlDirective, RouterModule, MatDialogModule, FormsModule, SharedOffersModule, LetDirective],
    declarations: [
        NoOffersComponent,
        OffersByProductComponent,
        OffersTeaserGroupComponent,
        UserComplianceMessageComponent,
        OffersOverviewComponent,
        BaseOverviewPageComponent,
        OffersSuccessMessageComponent,
        CashierDepositOffersTeaserComponent,
        GroupedOffersCountPipe,
    ],
    exports: [
        NoOffersComponent,
        OffersOverviewComponent,
        OffersTeaserGroupComponent,
        UserComplianceMessageComponent,
        CashierDepositOffersTeaserComponent,
        OffersSuccessMessageComponent,
        GroupedOffersCountPipe,
    ],
    providers: [DslPipe, PercentPipe, GroupedOffersCountPipe],
})
export class OffersLibModule {}
