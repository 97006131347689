import { Injectable } from '@angular/core';

import { ApiService } from '@frontend/promo-utils';
import { BehaviorSubject } from 'rxjs';

import { PromoClientConfiguration } from '../client-configs/promo-config';

@Injectable({
    providedIn: 'root',
})
export class SeoPageContentService {
    seoPageContent$ = new BehaviorSubject<any>({});
    constructor(
        private apiService: ApiService,
        private promoConfig: PromoClientConfiguration,
    ) {
        if (this.promoConfig?.isSeoEnabled) this.loadSeoContent();
    }

    loadSeoContent() {
        this.apiService?.get('getSeoPageContent')?.subscribe((data) => {
            this.seoPageContent$?.next(data);
        });
    }

    get getSeoContent() {
        return this.seoPageContent$?.asObservable();
    }
}
