import { OverlayRef } from '@angular/cdk/overlay';
import { NgClass } from '@angular/common';
import { Component, Inject, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';

import { ContentLink, DynamicHtmlDirective, NavigationService } from '@frontend/vanilla/core';
import { RxIf } from '@rx-angular/template/if';
import { Subject, takeUntil } from 'rxjs';

import { SmartRewardsConstants } from '../../constants/smart-rewards-constant';
import { RewardType } from '../../enum/reward-type';
import { AcceptOffer } from '../../models/base-meta-data';
import { RewardContent, RewardMetaData } from '../../models/reward';
import { SmartRewardRequest } from '../../models/smart-reward-request';
import { TrackingModel } from '../../models/tracking-model';
import { SmartRewardsService } from '../../services/smart-reward-service/smart-rewards.service';
import { ToastrHelperService } from '../../services/toastr-helper-service/toastr-helper.service';
import { GENERIC_MODAL_CONTENT } from '../dialog/OverlayDialogModal';
import { SmartRewardEligibleGamesComponent } from '../eligible-games/smart-reward-eligible-games.component';
import { SmartRewardTermsConditionsComponent } from '../terms-conditions/smart-reward-terms-conditions.component';
import { SmartRewardTrackingHelperService } from '../tracking/smart-reward-tracking-helper.service';

@Component({
    standalone: true,
    imports: [NgClass, RxIf, DynamicHtmlDirective, SmartRewardEligibleGamesComponent, SmartRewardTermsConditionsComponent],
    selector: 'smart-rewards-popup',
    templateUrl: './smart-rewards-popup.component.html',
    styleUrl: './smart-rewards-popup.component.scss',
    encapsulation: ViewEncapsulation.None,
})
export class SmartRewardsPopupComponent implements OnInit, OnDestroy {
    rewardMetaData: RewardMetaData;
    rewardContent: RewardContent;
    contentParameters: { [key: string]: string };
    progressBarWidthPercentage: number;
    collapseTermsAndConditions: boolean;
    onFailureActivation: boolean = false;
    isRewardActivated: boolean = false;
    locationEvent: string;
    currentProgressBarValue: string;
    tncTrackingModel: TrackingModel;
    public readonly destroy$ = new Subject<void>();

    constructor(
        @Inject(GENERIC_MODAL_CONTENT) public data: any,
        public overlayRef: OverlayRef,
        private smartRewardsService: SmartRewardsService,
        private navigationService: NavigationService,
        private toastrHelperService: ToastrHelperService,
        private trackingHelperService: SmartRewardTrackingHelperService,
    ) {
        this.locationEvent = this.data?.isEdsWidget ? SmartRewardsConstants.WidgetRewardDetails : SmartRewardsConstants.RewardDetails;
    }

    ngOnInit(): void {
        this.rewardMetaData = this.data?.reward?.rewardMetaData;
        this.rewardContent = this.data?.reward?.rewardContent;
        this.contentParameters = this.data?.contentParameters;
        this.currentProgressBarValue = this.data?.progressBar?.progressCurrentValue;
        this.progressBarWidthPercentage =
            this.data?.progressBar?.progressBarWidthPercentage == 0 ? '5' : this.data?.progressBar?.progressBarWidthPercentage;
        this.collapseTermsAndConditions = this.data?.collapseTermsAndConditions;

        this.createTncTrackingModel();
        this.trackingHelperService.trackOnPageLoad(
            this.locationEvent,
            this.rewardMetaData?.webTrackingPositionEvent,
            SmartRewardsConstants.RewardDetails,
        );

        this.overlayRef
            .backdropClick()
            .pipe(takeUntil(this.destroy$))
            .subscribe(() => this.overlayRef.dispose());
    }

    onPlayNowCtaClick(cta: ContentLink) {
        if (cta?.url) {
            this.navigationService.goTo(cta?.url);
        }
    }

    onActivateRewardCtaClick() {
        let rewardType: string;
        let rewardId: any;
        if (this.rewardMetaData?.rewardType?.toLocaleLowerCase() == RewardType.FreeSpins) {
            rewardType = RewardType.RewardTypeFreeSpin;
            rewardId = this.rewardMetaData?.bonusId;
        } else {
            rewardType = RewardType.BonusCode;
            rewardId = this.rewardMetaData?.rewardCode;
        }

        this.trackingHelperService.trackOnCtaClick(
            SmartRewardsConstants.ActivateReward,
            this.locationEvent,
            this.rewardMetaData?.webTrackingPositionEvent,
        );

        this.smartRewardsService
            .activateNow(rewardId, rewardType)
            .pipe(takeUntil(this.destroy$))
            .subscribe({
                next: (response: AcceptOffer) => {
                    this.validateResponse(response);
                },
                error: () => {
                    this.onFailureActivation = true;
                },
            });
    }

    validateResponse(response: AcceptOffer) {
        if (response?.code == 200 || response?.awardId != 0) {
            this.isRewardActivated = true;
            if (!this.data?.isEdsWidget) {
                this.smartRewardsService.loadRewardDetails();
            }
            const data = this.toastrHelperService.setToastrData(
                this.contentParameters?.rewardactivatedtitle,
                this.contentParameters?.rewardactivateddescription,
            );
            this.toastrHelperService.onToastrActivation$.next(data);
        } else this.onFailureActivation = true;
    }

    onClosePopup() {
        this.trackingHelperService.trackOnCtaClick(SmartRewardsConstants.CloseX, this.locationEvent, this.rewardMetaData?.webTrackingPositionEvent);
        this.overlayRef.detach();
    }

    createTncTrackingModel() {
        this.tncTrackingModel = this.trackingHelperService.createInteractionsTrackingModel(
            this.rewardMetaData?.webTrackingPositionEvent,
            this.locationEvent,
        );
    }

    ngOnDestroy() {
        this.overlayRef.dispose();
        this.overlayRef.detach();
        this.destroy$.next();
        this.destroy$.complete();
    }

    onGameLaunch(event: any) {
        if (event) {
            const request = { campaignId: '1', requestedFor: SmartRewardsConstants.EDSWidget, requestType: '' } as SmartRewardRequest;
            this.smartRewardsService.loadSmartWidgetDetails(request);
            this.overlayRef.dispose();
            this.overlayRef.detach();
        }
    }
}
