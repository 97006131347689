<user-compliance-message></user-compliance-message>
<ng-container *ngrxLet="nLRegulatoryHelperService.displayPromoDetails$ as displayPromoDetails">
    <ng-container *ngIf="displayPromoDetails">
        <ng-container *ngrxLet="vm$ as vm">
            <div class="offers-home-page" *ngIf="vm">
                <offers-skeleton
                    *ngIf="vm.showSkeleton; else offersLoaded"
                    [showTeaserSkeleton]="showTeaserSkeleton"
                    [productsLength]="products?.length">
                </offers-skeleton>
                <ng-template #offersLoaded>
                    <ng-content></ng-content>
                    <no-offer-message *ngIf="vm.isLobbyEmpty"></no-offer-message>
                    <div class="offers-teaser-groups has-slider wrapper-height" *ngIf="!vm.isLobbyEmpty">
                        <ng-container *ngFor="let product of vm.offerProducts; let i = index; trackBy: trackByKey">
                            <offers-teaser-group
                                *ngIf="product"
                                [offerDetailsList]="vm.offerDetailsList"
                                [productIndex]="i"
                                [productsLength]="vm.offerProducts.length"
                                [offerCategory]="vm.offerCategories[product]"
                                [product]="product"
                                [showLhSlider]="vm.showLhSlider"
                                [showTeaserGroupTitle]="true"
                                [showSeeAllLink]="vm.showSeeAllLink"
                                [offersProductStyle]="offersProductStyle"
                                [isOffersLoadedFromDiffApp]="isOffersLoadedFromDiffApp"
                                [seeAllLinkItemLength]="vm.seeAllLinkItemLength"
                                class="offers-teaser-group-wrapper items-3">
                            </offers-teaser-group>
                        </ng-container>
                    </div>
                </ng-template>
            </div>
        </ng-container>
    </ng-container>
</ng-container>
