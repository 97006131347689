import { Component, OnDestroy, OnInit } from '@angular/core';

import {
    FilterOffersContent,
    FilterOffersTabs,
    OfferProduct,
    OffersApiService,
    SelectedFilters,
    TrackingModelService,
} from '@frontend/promo-homewidget';
import { MediaQueryService, UtilsService } from '@frontend/vanilla/core';
import { cloneDeep } from 'lodash-es';
import { Subject, takeUntil } from 'rxjs';

import { OffersPopupFilterModel } from './offers-popup-filter.model';

@Component({
    template: '',
})
export abstract class OffersPopupFilterBaseComponent implements OnInit, OnDestroy {
    selectedFilters: SelectedFilters[];
    selectedTabs: FilterOffersTabs[];
    totalItemsByFilter: number = 0;
    totalItemsWithoutFilter: number = 0;
    finalItemByFilter: number = 0;
    filterTabs: FilterOffersTabs[];
    Offers: OfferProduct[];
    showResultButton: string;
    isMobile: boolean = false;
    private readonly destroy$ = new Subject<void>();

    constructor(
        public filterOffersContent: FilterOffersContent,
        public mediaObserver: MediaQueryService,
        public offersApiService: OffersApiService,
        public utility: UtilsService,
        public trackingModelService: TrackingModelService,
    ) {
        this.offersApiService.globalSelectedFilters.pipe(takeUntil(this.destroy$)).subscribe((filter: SelectedFilters[]) => {
            this.selectedFilters = cloneDeep(filter);
        });

        this.offersApiService.globalFilterTabs.pipe(takeUntil(this.destroy$)).subscribe((filterTabs: FilterOffersTabs[]) => {
            this.selectedTabs = cloneDeep(filterTabs);
        });
    }

    ngOnInit() {
        this.isMobile = this.mediaObserver.isActive('lt-sm');
    }

    selectedTab(filterTab: FilterOffersTabs) {
        filterTab.isActive = !filterTab.isActive;
        if (filterTab.isActive) {
            if (this.selectedTabs?.length > 0) {
                this.selectedTabs.forEach((x: FilterOffersTabs) => {
                    if (x.name === filterTab.name) x.isActive = filterTab.isActive;
                    else this.selectedTabs.push(filterTab);
                });
            } else this.selectedTabs.push(filterTab);
        } else {
            const index = this.selectedTabs.findIndex((x) => x.title === filterTab.title);
            if (index > -1) {
                this.selectedTabs.splice(index, 1);
            }
        }
        this.trackingModelService.submitTracking({
            LabelEvent: 'Promo Filters',
            ActionEvent: filterTab.isActive ? 'Select Filter - Clicked' : 'Deselect Filter - Clicked',
            PositionEvent: this.filterOffersContent.tabPosition,
            LocationEvent: 'Predifined',
            EventDetails: filterTab.webTrackingText,
        });
        this.selectAndUnselectTab();
        this.updateOffersCount();
        this.updateResultBtnTxt();
    }

    updateResultBtnTxt() {
        // Updating the text on Button 'Show Results'
        if (this.totalItemsByFilter > 0) {
            this.showResultButton = this.utility.format(this.filterOffersContent.filterOffersText.messages.showResults, this.totalItemsByFilter);
        } else {
            this.showResultButton = this.filterOffersContent.filterOffersText.messages.noResultFound;
        }
    }

    clearAllFilters() {
        // When user click on 'Clear All' link
        // Removing all items from 'selectedFilters' and 'selectedTabs'
        this.selectedFilters = [];
        this.selectedTabs = [];
        this.filterTabs.forEach((filterTab: FilterOffersTabs) => {
            filterTab.isActive = false;
        });
        this.Offers.forEach((offerProduct: OfferProduct) => {
            offerProduct.filterOffers.forEach((selectedFilters: SelectedFilters) => {
                selectedFilters.isChecked = false;
                selectedFilters.isActiveSelected = false;
                selectedFilters.isNewSelected = false;
            });
        });
        this.totalItemsByFilter = this.totalItemsWithoutFilter;
        this.updateResultBtnTxt();
        // Filter Web tracking 'When user click on ClearAll Cta'
        this.trackingModelService.submitTracking({
            LabelEvent: 'Promo Filters',
            ActionEvent: 'Clear All - Clicked',
            PositionEvent: this.filterOffersContent.tabPosition,
        });
    }

    selectAndUnselectTab() {
        this.selectedFilters = [];
        this.totalItemsByFilter = 0;
        this.Offers.forEach((offerProduct: OfferProduct) => {
            offerProduct.filterOffers.forEach((selectedFilters: SelectedFilters) => {
                selectedFilters.isChecked = false;
                selectedFilters.isActiveSelected = false;
                selectedFilters.isNewSelected = false;
            });
        });
        this.filterTabs.forEach((filterTab: FilterOffersTabs) => {
            if (filterTab.isActive) {
                if ((filterTab.name as any) === OffersPopupFilterModel.new) {
                    this.Offers.forEach((offerProduct: OfferProduct) => {
                        offerProduct.filterOffers.forEach((selectedFilter: SelectedFilters) => {
                            const index = this.selectedFilters.indexOf(selectedFilter);
                            selectedFilter.isNewSelected = true;
                            selectedFilter.isChecked = true;
                            if (index === -1) {
                                this.selectedFilters.push(selectedFilter);
                            } else {
                                this.selectedFilters.splice(index, 1);
                                this.selectedFilters.push(selectedFilter);
                            }
                        });
                    });
                } else if ((filterTab.name as any) === OffersPopupFilterModel.active) {
                    this.Offers.forEach((offerProduct: OfferProduct) => {
                        offerProduct.filterOffers.forEach((selectedFilter: SelectedFilters) => {
                            const index = this.selectedFilters.indexOf(selectedFilter);
                            selectedFilter.isActiveSelected = true;
                            selectedFilter.isChecked = true;
                            if (index === -1) {
                                this.selectedFilters.push(selectedFilter);
                            } else {
                                this.selectedFilters.splice(index, 1);
                                this.selectedFilters.push(selectedFilter);
                            }
                        });
                    });
                } else {
                    this.Offers.forEach((offerProduct: OfferProduct) => {
                        offerProduct.filterOffers.forEach((selectedFilter: SelectedFilters) => {
                            if (selectedFilter.message && selectedFilter.message.linkedWith === filterTab.name) {
                                selectedFilter.isChecked = true;
                                selectedFilter.isNewSelected = false;
                                selectedFilter.isActiveSelected = false;
                                this.selectedFilters.push(selectedFilter);
                            }
                        });
                    });
                }
            }
        });
    }

    updateOffersCount() {
        this.Offers.forEach((offerProduct: OfferProduct) => {
            offerProduct.filterOffers.forEach((selectedFilter: SelectedFilters) => {
                if (selectedFilter.isChecked) {
                    if (selectedFilter.isNewSelected) {
                        this.totalItemsByFilter += selectedFilter.newOffersCount;
                        this.finalItemByFilter = this.totalItemsWithoutFilter;
                    }
                    if (selectedFilter.isActiveSelected) {
                        this.totalItemsByFilter += selectedFilter.activeOffersCount;
                        this.finalItemByFilter = this.totalItemsWithoutFilter;
                    }
                    if (selectedFilter.isNewSelected && selectedFilter.isActiveSelected) {
                        this.totalItemsByFilter -= selectedFilter.newAndActiveOffersCount;
                        this.finalItemByFilter = this.totalItemsWithoutFilter;
                    }
                    if (!selectedFilter.isNewSelected && !selectedFilter.isActiveSelected) {
                        this.totalItemsByFilter += selectedFilter.count;
                        this.finalItemByFilter = this.totalItemsByFilter;
                    }
                }
            });
        });
        if (this.selectedFilters.length === 0) {
            this.totalItemsByFilter = this.totalItemsWithoutFilter;
        }
    }

    ngOnDestroy() {
        this.destroy$.next();
        this.destroy$.complete();
    }
}
