import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';

import { Observable } from 'rxjs';

import { BetAndGetDetailsResourceService } from '../bet-and-get/bet-and-get-service/bet-and-get-details-resource.service';

@Injectable()
export class BetAndGetDetailsV2Resolver implements Resolve<any> {
    constructor(private resource: BetAndGetDetailsResourceService) {}

    resolve(): Observable<any> {
        return this.resource.getContent();
    }
}
