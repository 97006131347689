import { Directive, HostListener, Input } from '@angular/core';

import { WindowRef } from '@frontend/vanilla/core';

@Directive({
    selector: '[iframeComm]',
    standalone: true,
})
export class IframeCommunicationDirective {
    @Input() isIframe: boolean;
    @Input() isGameClient: boolean;
    node: any;
    constructor(private windowRef: WindowRef) {}
    @HostListener('click', ['$event']) onClick($event: any) {
        if (this.isIframe) {
            const target = $event.target || $event.srcElement;
            if (this.isClickOnLink(target)) {
                this.windowRef.nativeWindow.parent.location.href = this.node.href;
                $event.stopPropagation();
            }
        }
    }

    sendMessageToParentWindow(eventName: string, eventData: any) {
        if (this.isIframe) this.windowRef.nativeWindow.parent.postMessage({ name: eventName, data: eventData }, '*');
    }

    isClickOnLink(target: any): boolean {
        this.node = null;
        if (target.nodeName === 'A' && target.href) {
            this.node = target;
        } else if (target.closest('a') && target.closest('a').href) {
            this.node = target.closest('a');
        }
        return this.node ? true : false;
    }
}
