import { NgClass } from '@angular/common';
import { Component, Input } from '@angular/core';

import { DynamicHtmlDirective } from '@frontend/vanilla/core';
import { RxIf } from '@rx-angular/template/if';

import { SmartRewardsConstants } from '../../constants/smart-rewards-constant';
import { TrackingModel } from '../../models/tracking-model';
import { TrackingModelService } from '../tracking/tracking-model-service';

@Component({
    standalone: true,
    imports: [NgClass, RxIf, DynamicHtmlDirective],
    selector: 'smart-reward-terms-conditions',
    styleUrl: './smart-reward-terms-conditions.component.scss',
    templateUrl: './smart-reward-terms-conditions.component.html',
})
export class SmartRewardTermsConditionsComponent {
    @Input() tncTitle: string;
    @Input() tncDescription: string;
    @Input() tncId: string = 'tnc';
    @Input() trackingModel: TrackingModel;
    @Input() collapseTermsAndConditions: boolean = false;
    toggle: boolean = false;

    constructor(private trackingModelService: TrackingModelService) {}

    tncToggle(id: string): void {
        this.toggle = !this.toggle;
        const tncClassList: any = document.getElementById(id + '__header')?.classList;
        const tncDescription: any = document.getElementById(id + '__body')?.classList;
        tncClassList.toggle('theme-down');
        tncClassList.toggle('theme-up');
        tncDescription.toggle('collapsible-visible');

        const action = this.toggle ? SmartRewardsConstants.Hide : SmartRewardsConstants.Expand;
        if (this.trackingModel) {
            this.trackingModel.EventDetails = SmartRewardsConstants.Tnc + action;
            this.trackingModelService.submitTracking(this.trackingModel);
        }
    }
}
