import { CUSTOM_ELEMENTS_SCHEMA, Component, Input, NO_ERRORS_SCHEMA, OnInit } from '@angular/core';

import { PromoDetailPageConfiguration } from '@frontend/promo-utils';
import { DeviceService, DynamicHtmlDirective } from '@frontend/vanilla/core';
import { RxIf } from '@rx-angular/template/if';

import { SmartRewardConfiguration } from '../../../config/smart-reward-config';
import { EdsEventContent, SuccessEventContent } from '../../../models/base-content';
import { PromoCountDownTimerDirective } from '../../../widget/eds-challenges-widget/count-down-timer.directive';
import { EdsEventSuccessComponent } from '../../eds-event-success/eds-event-success.component';
import { WeeklyGamesComponent } from '../../weekly-games/weekly-games.component';

@Component({
    standalone: true,
    selector: 'ph-challenges-widget',
    templateUrl: './challenges-widget.component.html',
    styleUrl: './challenges-widget.component.scss',
    imports: [WeeklyGamesComponent, DynamicHtmlDirective, EdsEventSuccessComponent, PromoCountDownTimerDirective, RxIf],
    schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
})
export class ChallengesWidgetComponent implements OnInit {
    @Input() eventData: EdsEventContent;
    @Input() contentParameters: { [key: string]: string };
    @Input() successEventContent: SuccessEventContent;
    @Input() isEdsWidget: boolean = false;

    showTimer: boolean;

    constructor(
        public deviceService: DeviceService,
        public detailPageClientConfig: PromoDetailPageConfiguration,
        public smartRewardConfig: SmartRewardConfiguration,
    ) {}

    ngOnInit(): void {
        if (new Date(this.eventData?.eventEndDate).getTime() > new Date().getTime()) {
            this.showTimer = true;
        }
    }
}
