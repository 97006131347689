import { createReducer, on } from '@ngrx/store';

import { Brat } from '../../model/brat-details.model';
import { BratActions } from '../action-types';

export interface BratState {
    callState: CallState;
    brat: Brat;
}
export type CallState = LoadingState | ErrorState;
export const enum LoadingState {
    INIT = 'INIT',
    LOADING = 'LOADING',
    LOADED = 'LOADED',
}
export interface ErrorState {
    errorMsg: string;
}

export function getError(callState: CallState): string | null {
    if ((callState as ErrorState).errorMsg !== undefined) {
        return (callState as ErrorState).errorMsg;
    }
    return null;
}

export const initialBratState: BratState = {
    callState: LoadingState.INIT,
    brat: new Brat(),
};

export const bratReducer = createReducer(
    initialBratState,

    on(BratActions.BRAT_LOADING, (state, action) => {
        return { ...state, ...action, callState: LoadingState.LOADING };
    }),
    on(BratActions.BRAT_INITIAL_STATE, () => {
        return initialBratState;
    }),
    on(BratActions.BRAT_LOADED, (state, action) => {
        return { ...state, ...action, callState: LoadingState.LOADED };
    }),
    on(BratActions.BRAT_LOAD_FAILED, (state, action) => {
        return {
            ...state,
            ...action,
            callState: { errorMsg: action.errorMessage },
        };
    }),
);
