import { Injectable } from '@angular/core';

import { ApiService } from '@frontend/promo-utils';
import { Observable } from 'rxjs';

import { StaticPromotionDetails } from './model/static-promotion.model';
import { StaticPromotionOptinResponse } from './model/sttaic-promotion-optin-response.model';

@Injectable({
    providedIn: 'root',
})
export class StaticPromotionService {
    constructor(private apiService: ApiService) {}

    loadStaticPromotionDetails(promoId: string): Observable<StaticPromotionDetails> {
        return this.apiService.get(
            'staticpromo',
            { promoId: promoId },
            {
                showSpinner: true,
            },
        );
    }

    optedIn(promoId: string): Observable<StaticPromotionOptinResponse> {
        return this.apiService.get('staticpromo/optin', { promoId: promoId });
    }
}
