import { Injectable } from '@angular/core';

import { RegulatoryApiService } from '@frontend/promo-homewidget';
import { ApiService } from '@frontend/promo-utils';
import { BehaviorSubject, Observable } from 'rxjs';

import { ClickCardDetails } from '../click-card-models/click-card-details';
import { ClickCardRequest } from '../click-card-models/click-card-request';

@Injectable({
    providedIn: 'root',
})
export class ClickCardService {
    clickCardDetails$ = new BehaviorSubject({} as ClickCardDetails);
    offerStatus$ = new BehaviorSubject('');
    constructor(
        private apiService: ApiService,
        private regulatoryApiService: RegulatoryApiService,
    ) {}

    getClickCardDetails(clickCardRequest: ClickCardRequest): Observable<ClickCardDetails> {
        const clickCard$ = this.apiService.get('clickcard', null, {
            params: clickCardRequest,
            showSpinner: true,
        });
        return this.regulatoryApiService.fetchDataWithReasonCode(clickCard$);
    }

    optedIn(promoId: string): Observable<ClickCardDetails> {
        return this.apiService.get('clickcard/optin', null, {
            params: { promoId },
        });
    }

    revealMyCard(cardId: string, promoId: string): Observable<ClickCardDetails> {
        const revealCardRequest = new ClickCardRequest(promoId, 'reveal', cardId);
        return this.apiService.get('clickcard/reveal', null, {
            params: revealCardRequest,
            showSpinner: true,
        });
    }
}
