import { Injectable } from '@angular/core';

import { UserService } from '@frontend/vanilla/core';

import { CommonConstants } from '../../constants/common-constants';

@Injectable({
    providedIn: 'root',
})
export class RGStatusService {
    constructor(private userService: UserService) {}

    claimsStatusCheck(): boolean {
        const data = {
            rgs: CommonConstants.RG_STATUS_GREEN,
            ecc: [
                CommonConstants.STATUS_CLOSED,
                CommonConstants.STATUS_SUSPICIOUS,
                CommonConstants.STATUS_FRAUD,
                CommonConstants.STATUS_SUSPENDED,
                CommonConstants.STATUS_RGCLOSED,
                CommonConstants.STATUS_CLOSEDINACTIVE,
                CommonConstants.STATUS_PERMENANTLYCLOSED,
                CommonConstants.STATUS_BLOCKED,
                CommonConstants.STATUS_ONCOOL_OFF,
            ],
            bs: 'true',
            tiercode: ['2', '8'],
        };
        return Object.entries(data).every(([key, value]) => {
            return this.statusCheck(key, value);
        });
    }

    statusCheck(claimType: string, value: any) {
        let status = true;
        const claims = this.userService.claims;
        switch (claimType) {
            case CommonConstants.CLAIM_RGS: {
                if (claims?.get(claimType) && claims?.get(claimType)?.toLowerCase() != value) {
                    status = false;
                }
                break;
            }
            case CommonConstants.CLAIM_TIERCODE:
            case CommonConstants.CLAIM_ECC: {
                if (claims?.get(claimType) && value.some((x: string) => claims?.get(claimType)?.toLowerCase() == x?.toLowerCase())) {
                    status = false;
                }
                break;
            }
            case CommonConstants.CLAIM_BS: {
                if (
                    claims?.get(CommonConstants.CLAIM_JURISDICTION) &&
                    claims?.get(CommonConstants.CLAIM_JURISDICTION) == 'GBR' &&
                    claims?.get(claimType) &&
                    claims?.get(claimType)?.toLowerCase() == value
                ) {
                    status = false;
                }
                break;
            }
        }
        return status;
    }
}
