import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';

import { Observable } from 'rxjs';

import { BratContentResourceService } from '../services/brat-content.resolver.service';

@Injectable()
export class BratContentResolver implements Resolve<any> {
    constructor(private resource: BratContentResourceService) {}

    resolve(): Observable<any> {
        return this.resource.getContent();
    }
}
