@if (showToastr) {
    <div class="smart-rewards-toaster" style="bottom: 80px">
        <div class="smart-rewards-toaster__content text-truncate">
            <div class="smart-rewards-toaster__title h6-v2 header-dark text-truncate">{{ data?.title }}</div>
            <div class="smart-rewards-toaster__desc txt-sm-v2 txt-color-0 text-truncate">{{ data?.description }}</div>
        </div>
        @if (data?.showCta) {
            @if (user?.isAuthenticated && data?.isOptedIn) {
                <div class="smart-rewards-toaster__cta">
                    <a class="btn btn-primary btn-sm theme-check">{{ data?.parameters?.optedin }}</a>
                </div>
            }
        }
    </div>
}
