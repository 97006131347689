export const __SEARCH_ACCESSIBLE__ = 'gs:presentation:search:accessible';
export const __LAUNCH_SEARCH__ = 'gs:presentation:launchsearch';
export const __PRESENTATION_BOOTSTRAPPED__ = 'gs:presentation:bootstrapped';
export const __FETCH_CONTEXT__ = 'gs:search:service:context:fetch';
export const __CONTEXT__ = 'gs:search:service:context:result';
export const __TRACK__ = 'gs:search:tracking';
export const __USER_EVENT__ = 'gs:productstub:vnuserevent';
export const __ENTRY_POINT_ACTIVE__ = 'gs:productstub:entrypoint:active';
export const __READ_CLIENT_CONFIG__ = 'gs:productstub:clientconfig:read';
export const __CLIENT_CONFIG_UPDATE_AVAILABLE__ = 'gs:productstub:clientconfig:updateavailable';
export const SearchIndexLoadEvent = 'gs:searchindex:load';
export const __BOOTSTRAP_APP__ = 'gs:search:bootstrap:app';

export const ServiceTokenHeader = '--x-globalsearch-service-servicetoken';
export const TransportTokenHeader = '--x-globalsearch-security-transporttoken';
export const SearchAppEnablerQuery = '--globalsearch.app.isenabled';
export const SearchContextWrittenEventIdentifier = 'gs:searchContextWritten';
export const ReadSearchContextEventIdentifier = 'gs:readSearchContext';
export const SearchAppLoadingEventIdentifier = 'gs:searchAppLoading';
export const SearchAppLoadedEventIdentifier = 'gs:searchAppLoaded';
export const SearchServiceInitializingEventIdentifier = 'gs:searchServiceInitializing';
export const SearchServiceInitializedEventIdentifier = 'gs:searchServiceInitialized';
export const SearchServiceLoadingEventIdentifier = 'gs:searchServiceLoading';
export const SearchServiceLoadedEventIdentifier = 'gs:searchServiceLoaded';
export const SearchContextLoadingEventIdentifier = 'gs:searchContextLoading';
export const SearchContextLoadedEventIdentifier = 'gs:searchContextLoaded';
export const SearchAppQueryEventIdentifier = 'gs:query';
export const SearchAppQueryResultEventIdentifier = 'gs:queryresult';
