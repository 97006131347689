@if (!eventData?.isSuccessEvent) {
    <div class="smart-widget smart-widget--lg smart-widget--challenges">
        <div class="smart-widget__content">
            <img
                class="smart-widget__bg px-1 pt-1"
                [attr.src]="eventData?.eventBackgroundImage?.src"
                [attr.alt]="eventData?.eventBackgroundImage?.alt"
                loading="lazy" />
            <div class="smart-widget__body">
                @if (!deviceService?.isMobilePhone || isEdsWidget) {
                    <div class="smart-badge">
                        <div class="smart-badge__content smart-badge__content--white">
                            <div class="smart-badge__title d-flex align-items-center smart-badge__title--white">{{ eventData?.product }}</div>
                            <img
                                class="smart-badge__content--edge"
                                [attr.src]="eventData?.eventBadge?.image?.src"
                                [attr.alt]="eventData?.eventBadge?.image?.alt" />
                        </div>
                    </div>
                }
                @if (showTimer && smartRewardConfig?.enableEventTimer) {
                    <div class="date-time theme-clock-solid" [countDownTimer]="eventData?.eventEndDate">
                        <span #hours>:</span>
                        <span #minutes>:</span>
                        <span #seconds>:</span>
                    </div>
                }
                <div class="smart-widget--challangesimg">
                    <img [attr.src]="eventData?.eventIcon?.src" [attr.alt]="eventData?.eventIcon?.src" width="60" />
                </div>
            </div>

            <div class="smart-widget__footer smart-widget__footer--bg">
                <div class="smart-widget__progress-bar">
                    <div class="background-bar overlay-bg">
                        <div
                            class="progress"
                            [style.width]="eventData?.eventProgressPercentage == 0 ? '5%' : eventData?.eventProgressPercentage + '%'">
                            <div class="progress--thumb">{{ eventData?.currentEventProgressValue }}</div>
                        </div>
                    </div>
                </div>
                <div class="smart-widget__title">{{ eventData?.eventTitle }}</div>
                <div class="smart-widget__description" [vnDynamicHtml]="eventData?.eventDescription"></div>
            </div>
        </div>
    </div>
    <div class="p-2">
        <div class="smart-widget__tnc pb-2" [vnDynamicHtml]="eventData?.keyTnc"></div>
        @if (eventData?.weeklyGames) {
            <ph-weekly-games
                [isEventTile]="true"
                [product]="eventData?.product"
                [weeklyGame]="eventData?.weeklyGames"
                [positionEvent]="eventData?.webTrackingPositionEvent"
                [isEdsWidget]="isEdsWidget"></ph-weekly-games>
        }
    </div>
}
@if (eventData?.isSuccessEvent) {
    <eds-event-success
        [eventData]="eventData"
        [isEdsWidget]="false"
        [collapseTermsAndConditions]="detailPageClientConfig?.collapseTermsAndConditions"
        [contentParameters]="contentParameters"
        [successEventContent]="successEventContent"></eds-event-success>
}
