import { AfterViewInit, ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { CommonService, FilterConfiguration, LoadOffersApiService, OfferContentConfiguration, RoutingHistory } from '@frontend/promo-homewidget';
import { DynamicLayoutService, MenuContentItem, NavigationService } from '@frontend/vanilla/core';
import { Subject, takeUntil } from 'rxjs';

import { CommonDetailsResourceService } from '../../details/Common/common-details-resource.service';
import { PokerTicketDetailsService } from '../../details/pokerticket/details/services/poker-ticket-details-service';
import { OffersNavigationV2Component } from '../offers-navigation-v2/offers-navigation-v2.component';
import { OffersNavigationComponent } from '../offers-navigation/offers-navigation.component';
import { OffersNavigationService } from '../offers-navigation/offers-navigation.service';

@Component({
    selector: 'offers-home-page',
    templateUrl: 'offers-home-page.component.html',
    changeDetection: ChangeDetectionStrategy.Default,
})
export class OffersHomePageComponent implements OnInit, AfterViewInit, OnDestroy {
    successMessage: string;
    showSuccessMessage: boolean;
    subNavigationItems: MenuContentItem[];
    productName: string = 'home';
    redesignOffersPageEnhanced: boolean;
    menuItems: MenuContentItem[];
    private readonly destroy$ = new Subject<void>();

    constructor(
        private routingHistoryService: RoutingHistory,
        private activatedRoute: ActivatedRoute,
        private filterConfiguration: FilterConfiguration,
        private commonDetailsService: CommonDetailsResourceService,
        private dynamicLayoutService: DynamicLayoutService,
        private pokerTicketService: PokerTicketDetailsService,
        private offersNavigationService: OffersNavigationService,
        private navigationService: NavigationService,
        private commonService: CommonService,
        public loadOffersApiService: LoadOffersApiService,
        public offerContentConfiguration: OfferContentConfiguration,
    ) {
        this.commonService.getOrientation();
        if (this.commonService.isOfferPageEnhanced$.value) {
            this.redesignOffersPageEnhanced = true;
        }
    }

    ngOnInit() {
        this.successMessage = this.redesignOffersPageEnhanced ? '' : this.commonDetailsService.getMessage();
        this.loadOffersApiService.isFilterV2Enabled$.next(this.filterConfiguration.isFilterV2Enabled);
        this.showSuccessMessage = !!this.successMessage;
        this.pokerTicketService.activePokerFilter$.next('');
        this.offersNavigationService.subNavigationItems.pipe(takeUntil(this.destroy$)).subscribe((res: any) => {
            if (res?.children?.length > 0) {
                this.subNavigationItems = res.children;
                if (this.subNavigationItems != undefined && this.subNavigationItems.length > 0 && this.redesignOffersPageEnhanced) {
                    this.navigationService.goTo(this.subNavigationItems[0].url);
                }
            }
        });
        this.activatedRoute.params.pipe(takeUntil(this.destroy$)).subscribe(() => {
            this.routingHistoryService.removeFilteredPreviousUrl();
            this.routingHistoryService.setPreviousUrl();
        });
        sessionStorage.removeItem('groupedUrl');
        this.dynamicLayoutService.setComponent(
            'header_subnav',
            this.redesignOffersPageEnhanced ? OffersNavigationV2Component : OffersNavigationComponent,
            {
                showInMobile: true,
                sourceItem: 'myoffers',
                showBackArrow: false,
                isHomePage: true,
                selectedItem: 'promotions',
            },
        );
    }

    ngAfterViewInit() {
        setTimeout(() => {
            this.showSuccessMessage = false;
        }, 5000);
    }

    ngOnDestroy(): void {
        this.dynamicLayoutService.removeComponent('header_subnav');
        this.destroy$.next();
        this.destroy$.complete();
    }
}
