import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class DepositsOffersEvents {
    private backButton$ = new BehaviorSubject<any>({});
    private applyOffer$ = new BehaviorSubject<any>({});

    sendBackButtonDetails(eventData: any) {
        this.backButton$.next(eventData);
    }

    sendBonusCodeDetails(eventData: any) {
        this.applyOffer$.next(eventData);
    }

    getBackButtonDetails(): Observable<any> {
        return this.backButton$.asObservable();
    }
    getBonusCodeDetails(): Observable<any> {
        return this.applyOffer$.asObservable();
    }
}
