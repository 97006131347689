import { Injectable } from '@angular/core';

import { ContentLink } from '@frontend/vanilla/core';
import { BehaviorSubject } from 'rxjs';

import { CommonService } from '../../common-service/common.service';
import { Offer } from '../../offers/models';
import { OfferContentConfiguration } from '../../offers/offer-content.client-config';
import { LoadOffersApiService } from '../../offers/offers-service/load-offers.service';
import { OffersApiService } from '../../offers/offers-service/offers.service';
import { RoutingHistory } from '../../route-history/route-history.service';
import { OffersWebTrackingConstants } from '../../tracking/offers-web-analytic-constants';
import { TrackingModelService } from '../../tracking/tracking-model-service';

@Injectable({
    providedIn: 'root',
})
export class OfferTeaserService {
    brandDetails: any;
    private OptedInMessageSource$ = new BehaviorSubject<{ [key: string]: string }>({});
    currentOptedInMessageSource$ = this.OptedInMessageSource$.asObservable();
    constructor(
        private offersApiService: OffersApiService,
        private clientConfig: OfferContentConfiguration,
        private trackingModelService: TrackingModelService,
        private routerHistory: RoutingHistory,
        public loadOffersApiService: LoadOffersApiService,
        private commonService: CommonService,
    ) {
        this.loadOffersApiService.multiBrandDetails$.subscribe((res) => {
            this.brandDetails = res;
        });
    }

    bindMoreInfoUrl(url: ContentLink, product: string, isGroupedTile: boolean = false) {
        const moreInfoUrl = { ...url };
        this.bindUrlForGroupedTile(moreInfoUrl, product, isGroupedTile);
        if (this.clientConfig?.enableOnePartyBrand && moreInfoUrl?.url && product && this.brandDetails && this.brandDetails[product]) {
            const splitUrl = moreInfoUrl.url?.split('.');
            splitUrl?.forEach((x, index) => {
                if (Object.values(this.brandDetails)?.includes(x)) {
                    splitUrl[index] = this.brandDetails[product];
                    moreInfoUrl.url = splitUrl.join('.');
                }
            });
        }
        return moreInfoUrl;
    }
    updateOptedInMessage(index: string, newvalue: string) {
        const currentOptedInMessagesource = this.OptedInMessageSource$.getValue();
        currentOptedInMessagesource[index] = newvalue;
        this.OptedInMessageSource$.next({ ...currentOptedInMessagesource });
    }
    bindUrlForGroupedTile(moreInfoUrl: ContentLink, product: string, isGroupedTile: boolean) {
        if (isGroupedTile && product) {
            moreInfoUrl.url = moreInfoUrl?.url + '?product=' + product;
        }
    }

    trackClickEvent(
        position: any,
        cta: ContentLink,
        product: string = '',
        isGroupedTile: boolean = false,
        serviceSubType: string = '',
        promoId: string = '',
        isMyGala: boolean = false,
        isGroupedPage: boolean = false,
        offerType: string = '',
        offer: Offer,
    ) {
        const serviceTypes = serviceSubType ? serviceSubType?.toLowerCase() : offer?.offerMetadata?.serviceType?.toLowerCase();
        this.trackingModelService.submitTracking({
            CategoryEvent: OffersWebTrackingConstants.PromoHub,
            LabelEvent: isGroupedPage ? OffersWebTrackingConstants.Group_ + offerType : product,
            ActionEvent: OffersWebTrackingConstants.PromoClicked,
            PositionEvent: position,
            EventDetails: OffersWebTrackingConstants.MoreInfoCta,
            URLClicked: cta?.url,
            LocationEvent: isGroupedTile ? OffersWebTrackingConstants.Group_ + serviceSubType : serviceTypes,
            campaignId: promoId,
            contentPosition: position,
            SiteSection: OffersWebTrackingConstants.PromoHub,
            Product: offer.offerMetadata.applicableProducts.toString(),
            PromoOfferName: offer?.offerContent?.title,
            siteSubSection: isGroupedPage ? OffersWebTrackingConstants.PromoGroupOffersPage : OffersWebTrackingConstants.PromoOffersHomePage,
            promotionType: offer?.offerMetadata?.isSpecialOffer
                ? OffersWebTrackingConstants.StaticPromotion
                : OffersWebTrackingConstants.DynamicPromotion,
        });
        if (isMyGala && cta?.text) {
            this.trackCarouselPromoCtaClick(cta.text, position, serviceSubType, product);
            this.trackAllTilesHyperLinksClick(cta.text, position, serviceSubType, product);
        }
    }

    trackCarouselPromoCtaClick(ctaName: string, position: any, serviceSubType: string, product: string) {
        this.trackingModelService.submitTracking({
            CategoryEvent: 'promotions',
            LabelEvent: 'my gala',
            ActionEvent: 'click',
            PositionEvent: serviceSubType,
            LocationEvent: product,
            EventDetails: ctaName,
            contentPosition: position,
        });
    }

    trackAllTilesHyperLinksClick(ctaName: string, position: any, serviceSubType: string, product: string) {
        this.trackingModelService.submitTracking({
            CategoryEvent: 'promotions',
            LabelEvent: 'my gala',
            ActionEvent: 'click',
            PositionEvent: serviceSubType,
            LocationEvent: product,
            EventDetails: ctaName,
            contentPosition: position,
        });
    }

    navigateToOfferMoreInfo(
        teaserNumber: number,
        moreInfoCta: ContentLink,
        offer: Offer,
        bindUpdateApiCall: boolean,
        product: string = '',
        isGroupedPage: boolean = false,
        offerType: string = '',
    ) {
        this.trackClickEvent(
            teaserNumber,
            moreInfoCta,
            product,
            offer.offerMetadata.isGroupedTile,
            this.commonService.getPositionEventForTracking(offer.offerMetadata.rewardTargetingType, offer.offerMetadata.serviceSubType),
            offer.offerMetadata.crmServiceId,
            false,
            isGroupedPage,
            offerType,
            offer,
        );
        const navigationCtaUrl = moreInfoCta?.url;
        if (bindUpdateApiCall) {
            this.offersApiService.updateOffer(offer.offerMetadata);
        }
        if (navigationCtaUrl) {
            if (offer.offerMetadata.isGroupedTile) this.routerHistory.setGroupedUrl();
            this.routerHistory.setPreviousUrl();
            this.offersApiService.goToOffersMoreInfo(navigationCtaUrl);
        }
    }
}
