import { Component, Inject, OnDestroy, OnInit, Optional } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';

import {
    FilterOffersContent,
    FilterOffersTabs,
    LoadOffersApiService,
    OfferDetails,
    OfferFiltersModel,
    OfferProduct,
    OffersApiService,
    SelectedFilters,
    TrackingModelService,
} from '@frontend/promo-homewidget';
import { MediaQueryService, UtilsService } from '@frontend/vanilla/core';
import { Subject, takeUntil } from 'rxjs';

import { OffersPopupFilterBaseComponent } from './offers-popup-filter-base.component';
import { OffersPopupFilterModel } from './offers-popup-filter.model';

@Component({
    selector: 'pr-offers-popup-filter',
    templateUrl: 'offers-popup-filter.component.html',
})
export class OffersPopupFilterComponent extends OffersPopupFilterBaseComponent implements OnInit, OnDestroy {
    offerDetails: OfferDetails;
    filtersByProductAndServiceType: OfferFiltersModel;
    private readonly destroyed$: Subject<boolean> = new Subject<boolean>();

    constructor(
        @Optional()
        @Inject(MAT_DIALOG_DATA)
        filterOffersContent: FilterOffersContent,
        private dialog: MatDialog,
        offersApiService: OffersApiService,
        utility: UtilsService,
        trackingModelService: TrackingModelService,
        mediaObserver: MediaQueryService,
        public loadOffersApiService: LoadOffersApiService,
    ) {
        super(filterOffersContent, mediaObserver, offersApiService, utility, trackingModelService);
    }

    override ngOnInit() {
        super.ngOnInit();

        this.Offers = this.filterOffersContent.offerProduct;
        this.filterTabs = this.filterOffersContent.filterOffersTabs;
        this.filtersByProductAndServiceType = new OfferFiltersModel();
        this.filtersByProductAndServiceType.selectedFiltersDict = {};

        this.loadOffersApiService.offerDetails$.pipe(takeUntil(this.destroyed$)).subscribe((offerDetails: OfferDetails) => {
            this.offerDetails = offerDetails;
        });

        this.mapOfferFilter();
        this.mapFilterTab();
        this.updateOffersCount();
        this.updateResultBtnTxt();
        // Filter Web tracking 'Filter Overlay loaded'
        this.trackingModelService.submitTracking({
            LabelEvent: 'Promo Filters',
            ActionEvent: 'Filters Overlay - Loaded',
            PositionEvent: this.filterOffersContent.tabPosition,
        });
    }

    mapOfferFilter() {
        this.Offers.forEach((offerProduct: OfferProduct) => {
            offerProduct.filterOffers.forEach((selectedFilter: SelectedFilters) => {
                // Add Offer Title need of Web Tracking
                selectedFilter.offerProductTitle = offerProduct.messages.webTrackingTitle;
                // It will Calculate total count of offers, New Offers and Active offers
                this.calculateTotalOffersCount(selectedFilter);
            });
        });
    }

    mapFilterTab() {
        this.filterTabs.forEach((filterTab: FilterOffersTabs) => {
            if (this.selectedTabs.length > 0) {
                const index = this.selectedTabs.findIndex((x) => x.title === filterTab.title);
                if (index > -1) {
                    filterTab.isActive = this.selectedTabs[index].isActive;
                }
            } else {
                filterTab.isActive = false;
            }
        });
    }

    closeDialog(isCrossClicked: boolean = false) {
        if (isCrossClicked) {
            // Filter Web tracking 'When user click on X btn'
            this.trackingModelService.submitTracking({
                LabelEvent: 'Promo Filters',
                ActionEvent: 'Close CTA - Clicked',
                PositionEvent: this.filterOffersContent.tabPosition,
            });
        }
        // Close dialog box
        this.dialog.closeAll();
    }

    filterSelected(selectedFilter: SelectedFilters, webTrackingTitle: string = '') {
        // When user click on any check-box
        if (this.selectedFilters && this.selectedFilters.length === 0) {
            this.totalItemsByFilter = 0;
        }

        const index = this.selectedFilters.findIndex(
            (x) => x.message?.product === selectedFilter.message?.product && x.title === selectedFilter.title,
        );

        if (index > -1) {
            // Filter Web tracking 'De-selecting the filter'
            if (webTrackingTitle) {
                this.trackingModelService.submitTracking({
                    LabelEvent: 'Promo Filters',
                    ActionEvent: 'Deselect Filter - Clicked',
                    PositionEvent: this.filterOffersContent.tabPosition,
                    LocationEvent: webTrackingTitle,
                    EventDetails: selectedFilter.message?.webTrackingTitle,
                });
            }
            this.filterTabs.forEach((filterTab: FilterOffersTabs) => {
                switch (filterTab.name.toLowerCase()) {
                    case OffersPopupFilterModel.active:
                    case OffersPopupFilterModel.new:
                    case selectedFilter.message?.linkedWith.toLowerCase():
                        filterTab.isActive = false;
                        this.selectedTabs.forEach((selectedTab: FilterOffersTabs) => {
                            if (selectedTab.name === filterTab.name) {
                                selectedTab.isActive = filterTab.isActive;
                            }
                        });
                        break;
                }
            });
            // If filterOffer already in the list it means user has un-selected the check-box. Splice that filterOffer from 'selectedFilters'
            selectedFilter.isChecked = false;
            this.selectedFilters.splice(index, 1);
            if (this.selectedFilters && this.selectedFilters.length === 0) {
                this.totalItemsByFilter = this.totalItemsWithoutFilter;
            } else {
                this.finalItemByFilter -= selectedFilter.count;
                this.totalItemsByFilter = this.finalItemByFilter;

                this.Offers.forEach((offerProduct: OfferProduct) => {
                    offerProduct.filterOffers.forEach((selectedFilter: SelectedFilters) => {
                        selectedFilter.isNewSelected = false;
                        selectedFilter.isActiveSelected = false;
                    });
                });
            }
        } else {
            selectedFilter.isChecked = true;
            // Filter Web tracking 'Selecting the filter'
            if (webTrackingTitle) {
                this.trackingModelService.submitTracking({
                    LabelEvent: 'Promo Filters',
                    ActionEvent: 'Select Filter - Clicked',
                    PositionEvent: this.filterOffersContent.tabPosition,
                    LocationEvent: webTrackingTitle,
                    EventDetails: selectedFilter.message?.webTrackingTitle,
                });
            }
            // Pushing selected filterOffer
            this.selectedFilters.push(selectedFilter);
            this.totalItemsByFilter += selectedFilter.count;
            this.finalItemByFilter = this.totalItemsByFilter;
        }
        this.updateResultBtnTxt();
    }

    resultsByFilter() {
        // Filter Web tracking 'When user click on Show Result Button'
        this.trackingModelService.submitTracking({
            LabelEvent: 'Promo Filters',
            ActionEvent: 'Show Filter Results - Clicked',
            PositionEvent: this.filterOffersContent.tabPosition,
            EventDetails: this.totalItemsByFilter > 0 ? this.utility.format('With Results {0}', this.totalItemsByFilter) : 'With No Results',
        });

        // Saving values to global variable, to display on 'Filter Button' on Nav bar and to preserve state of filters
        this.offersApiService.globalSelectedFilters.next(this.selectedFilters);
        this.offersApiService.globalFilterTabs.next(this.selectedTabs);
        this.createFiltersByProductAndServiceType();
        this.loadOffersApiService.filterV1$.next(this.filtersByProductAndServiceType);
        if (this.selectedFilters && this.selectedFilters.length > 0) {
            this.loadOffersApiService.areFiltersApplied$.next(true);
        } else {
            this.loadOffersApiService.areFiltersApplied$.next(false);
        }
        this.loadOffersApiService.filteredProducts();
        // Filter Web tracking 'De-selecting the filter'
        let selectedFilterTitles = '';
        let selectedFilterProduct = '';
        this.selectedFilters.forEach((filter: SelectedFilters) => {
            if (selectedFilterTitles.length > 0) {
                selectedFilterTitles += ' | ';
            }
            if (selectedFilterProduct.length > 0 && selectedFilterProduct.indexOf(filter.offerProductTitle) == -1) {
                selectedFilterProduct += ' | ';
            }
            if (selectedFilterProduct.indexOf(filter.offerProductTitle) == -1) {
                selectedFilterProduct += filter.offerProductTitle;
            }
            if (filter.message && filter.message.webTrackingTitle) {
                selectedFilterTitles += filter.message.webTrackingTitle;
            }
        });
        this.trackingModelService.submitTracking({
            LabelEvent: 'Promo Filters',
            ActionEvent: 'Show Filter Results - Submit',
            PositionEvent: this.filterOffersContent.tabPosition,
            LocationEvent: selectedFilterProduct,
            EventDetails: selectedFilterTitles,
        });
        this.closeDialog();
    }

    createFiltersByProductAndServiceType() {
        for (const selectedFilter of this.selectedFilters) {
            if (selectedFilter.message) {
                const product = selectedFilter.message.product;
                if (this.filtersByProductAndServiceType.selectedFiltersDict[product] === undefined) {
                    this.filtersByProductAndServiceType.selectedFiltersDict[product] = [];
                }
                if (selectedFilter.message.serviceType && Array.isArray(selectedFilter.message.serviceType)) {
                    this.filtersByProductAndServiceType.selectedFiltersDict[product] = this.filtersByProductAndServiceType.selectedFiltersDict[
                        product
                    ].concat(selectedFilter.message.serviceType);
                }
            }
        }
        this.filtersByProductAndServiceType.isNewSelected = this.selectedTabs?.find((x) => x?.name === OffersPopupFilterModel.new)?.isActive ?? false;
        this.filtersByProductAndServiceType.isActiveSelected =
            this.selectedTabs?.find((x) => x?.name === OffersPopupFilterModel.active)?.isActive ?? false;
    }

    private calculateTotalOffersCount(selectedFilter: SelectedFilters) {
        if (this.selectedFilters && this.selectedFilters.findIndex((x) => x.title === selectedFilter.title) > -1) {
            selectedFilter.isChecked = true;
        } else {
            selectedFilter.isChecked = false;
            selectedFilter.isActiveSelected = false;
            selectedFilter.isNewSelected = false;
        }
        selectedFilter.count = 0;
        selectedFilter.newOffersCount = 0;
        selectedFilter.activeOffersCount = 0;
        selectedFilter.newAndActiveOffersCount = 0;

        if (selectedFilter.message && selectedFilter.message.serviceType && selectedFilter.message.linkedWith) {
            for (const serviceType of selectedFilter.message.serviceType) {
                const offerCategory = this.offerDetails?.offerCategories[selectedFilter.message?.product];
                if (offerCategory === undefined) continue;

                for (const i of offerCategory.offerDetailsIndexes) {
                    const offerMetaData = this.offerDetails.offerDetailsList[i].offerMetadata;

                    if (offerMetaData?.serviceType?.toLowerCase() === serviceType.toLowerCase() && !offerMetaData.isGroupedTile) {
                        selectedFilter.count++;
                        this.totalItemsWithoutFilter++;
                        if (offerMetaData.isNewOffer) selectedFilter.newOffersCount++;
                        if (offerMetaData.isOfferActive) selectedFilter.activeOffersCount++;
                        if (offerMetaData.isNewOffer && offerMetaData.isOfferActive) selectedFilter.newAndActiveOffersCount++;
                    }
                }
            }
        }

        if (this.selectedFilters && this.selectedFilters.length === 0) {
            this.totalItemsByFilter += selectedFilter.count;
        }
    }

    override ngOnDestroy() {
        this.destroyed$.next(true);
        this.destroyed$.complete();
    }
}
