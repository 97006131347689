<ng-container *ngrxLet="vm$ as vm">
    <ng-container *ngIf="vm">
        <div *ngIf="offer?.offerContent?.ctaLinks" class="d-flex align-items-center offers-teaser-tiles">
            <!-- Use 'justify-content-center' class when have one button ( no 'w-100' class for btn)on the middle -->
            <a
                [attr.href]="moreInfoCta?.url"
                (click)="navigateToOfferMoreInfo(); $event.stopPropagation(); $event.preventDefault()"
                [ngClass]="moreInfoCtaClass"
                class="btn btn-md"
                id="moreInfoCta"
                >{{ moreInfoCta?.text }}</a
            >
            <a
                *ngIf="
                    (vm.showOptedInErrorMessage || vm.showOptedInMessage || (!vm.isOptInValid && isOptInPresent)) &&
                        !offerContentConfiguration.isBetMgmTileRedesignEnabled;
                    else ErrorandInValidState
                "
                class="offer-button w-100 offer-button-tiles offer-button-md"
                [ngClass]="{
                    'danger theme-error-i': vm.showOptedInErrorMessage,
                    'success theme-success-i': vm.showOptedInMessage,
                    'info theme-info': !vm.isOptInValid && !vm.showOptedInMessage && isOptInPresent
                }">
                <span *ngIf="vm.showOptedInErrorMessage">{{ vm.optedInErrorMessage }}</span>
                <span *ngIf="vm.showOptedInMessage">{{ vm.optedInMessage }}</span>
                <span *ngIf="!vm.isOptInValid && !vm.showOptedInMessage && isOptInPresent">{{ vm.optInInvalidMessage }}</span>
            </a>
            <a
                *ngIf="offer?.offerContent?.betNowCta && offerContentConfiguration?.enableBetNowCta"
                [href]="offer?.offerContent?.betNowCta?.url"
                (click)="$event.stopPropagation(); $event.preventDefault()"
                class="btn btn-md btn-primary w-100"
                id="betNowCta"
                >{{ offer?.offerContent?.betNowCta?.text }}</a
            >
            <a *ngIf="vm.showButtonSpinner" class="btn btn-primary btn-md btn-loading w-100"></a>

            <ng-template #ErrorandInValidState>
                <a
                    *ngIf="
                        (vm.showOptedInErrorMessage || vm.showOptedInMessage || (!vm.isOptInValid && isOptInPresent)) &&
                            offerContentConfiguration.isBetMgmTileRedesignEnabled;
                        else optInButton
                    "
                    class="btn btn-md w-100 offers-teaser-tiles-btn"
                    [ngClass]="{
                        'btn-secondary disabled': !vm.showOptedInMessage
                    }">
                    <span
                        [ngClass]="{
                            'danger theme-error-i': vm.showOptedInErrorMessage,
                            'success theme-success-i': vm.showOptedInMessage,
                            'warning theme-warning': !vm.isOptInValid && !vm.showOptedInMessage && isOptInPresent
                        }"></span>
                    <span *ngIf="vm.showOptedInErrorMessage">{{ vm.optedInErrorMessage }}</span>
                    <span *ngIf="vm.showOptedInMessage">{{ vm.optedInMessage }}</span>
                    <span *ngIf="!vm.isOptInValid && !vm.showOptedInMessage && isOptInPresent">{{ vm.optInInvalidMessage }}</span>
                </a>
                <ng-template #optInButton>
                    <button
                        *ngIf="vm.showOptInButton && !vm.optInClicked && !vm.showButtonSpinner && !vm.showOptedInErrorMessage && optInCta?.text"
                        (click)="optIn_Click($event); $event.stopPropagation()"
                        [ngClass]="!!optInCta.attributes?.class ? optInCta.attributes?.class : 'btn btn-primary'"
                        class="btn-md w-100">
                        {{ optInCta.text }}
                    </button>
                </ng-template>
            </ng-template>
        </div>
    </ng-container>
</ng-container>
