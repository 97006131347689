@if (!eventData?.isSuccessEvent) {
    <div (click)="openDialog()" class="smart-widget smart-widget--lg smart-widget--challenges">
        <div class="smart-widget__content">
            <img
                class="smart-widget__bg"
                [attr.src]="eventData.eventBackgroundImage?.src"
                [attr.alt]="eventData.eventBackgroundImage?.alt"
                loading="lazy" />

            <!-- <div class="smart-widget__body d-flex flex-column justify-content-between h-100"> -->
            <div class="smart-widget__body d-flex flex-column justify-content-end">
                @if (eventData?.eventEndDate && showTimer && smartRewardConfig?.enableEventTimer) {
                    <div class="smart-badge">
                        <div class="smart-badge__content smart-badge__content--white">
                            <div
                                class="smart-badge__title d-flex align-items-center smart-badge__title--white date-time theme-clock"
                                [countDownTimer]="eventData.eventEndDate">
                                <span #hours>:</span>
                                <span #minutes>:</span>
                                <span #seconds>:</span>
                            </div>
                            <img
                                class="smart-badge__content--edge"
                                [attr.src]="eventData?.eventBadge?.image?.src"
                                [attr.alt]="eventData?.eventBadge?.image?.alt" />
                        </div>
                    </div>
                }
                <div class="smart-widget__progress-bar">
                    <div class="d-flex align-items-baseline">
                        <div class="smart-widget__progress-bar--current-value">{{ eventData.currentEventProgressValue }}</div>
                        /
                        <div class="smart-widget__progress-bar--total-value">{{ eventData.currentEventTargetValue }}</div>
                    </div>
                    <div class="smart-widget__progress-bar--path">
                        <div
                            class="smart-widget__progress-bar--background d-flex align-items-end justify-content-end"
                            [ngStyle]="{ width: eventData?.eventProgressPercentage + '%' }"></div>
                    </div>
                </div>
            </div>
            <div class="smart-widget__footer smart-widget__footer--bg">
                <div class="smart-widget__title">{{ eventData?.eventTitle }}</div>
                <div class="smart-widget__description" [vnDynamicHtml]="eventData?.eventDescription"></div>
            </div>
        </div>
    </div>
}
@if (eventData?.isSuccessEvent) {
    <eds-event-success
        [eventData]="eventData"
        [contentParameters]="contentParameters"
        [successEventContent]="smartEdsWidgetContent?.successEventContent"></eds-event-success>
}
