import { ContentImage } from '@frontend/vanilla/core';

import { CriteriaCard } from './criteria-card/criteria-card';
import { CriteriaCardStatus } from './criteria-card/criteria-card-status';
import { EarnedCardDetails } from './earned-card/earned-card-details';
import { EarnedCardResultDetails } from './earned-card/earned-card-result-details';
import { MyEarnedCardsContent } from './earned-card/my-earned-cards-content';

export class MyCardsStateModel {
    myCardsMetaData: MyCardsMetaData;
    myCardsContent: MyCardsContent;
    earnedCardResultDetails: EarnedCardResultDetails;
    multiCardsImage: ContentImage;
    criteriaCardStatus: CriteriaCardStatus;
}

export interface MyCardsContent {
    criteriaCard: CriteriaCard;

    myEarnedCardsContent: MyEarnedCardsContent;
    earnedCardDetails: EarnedCardDetails[];
    earnedCardsImages: EarnedCardsImages;
}

export interface MyCardsMetaData {
    numberOfUnClickedCards: number;
    numberOfExpiredCards: number;
}

export class EarnedCardsImages {
    cardImage: ContentImage;
    freeCardImage: ContentImage;
}
