<div class="circular-progress-chart grace-period poker-sitandgo__progress text-center" [ngClass]="{ chart_progress: isEdsWidget }">
    <svg viewBox="0 0 34 34">
        <path
            d="M17 1
      a 16.9155 16.9155 0 0 1 0 33.831
      a 16.9155 16.9155 0 0 1 0 -33.831"
            class="chart-outer"></path>
        <path
            d="M17 2
      a 15.9155 15.9155 0 0 1 0 31.831
      a 15.9155 15.9155 0 0 1 0 -31.831"
            class="chart-progress-track"></path>
        @if (+progressPercentage > 0) {
            <path
                [attr.stroke-dasharray]="progressPercentage + ' ' + 100"
                d="M17 2
        a 15.9155 15.9155 0 0 1 0 31.831
        a 15.9155 15.9155 0 0 1 0 -31.831"
                class="chart-progress"
                [ngClass]="{ 'progress-color': progressPercentage > 50 }"></path>
        }

        @if (currentProgress) {
            <text alignment-baseline="central" text-anchor="middle" class="chart-value" font-weight="bold">
                {{ currentProgress }}/{{ totalProgress }}
            </text>
        }
        <!-- @if (totalProgress) {
            <text alignment-baseline="central" text-anchor="start" class="chart-value chart-value--gray" font-weight="bold">
                /{{ totalProgress }}
            </text>
        } -->
        @if (progressSubText) {
            <text alignment-baseline="central" text-anchor="middle" class="chart-status">
                {{ progressSubText }}
            </text>
        }
    </svg>
</div>
