import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { DynamicHtmlDirective, PlainLinkComponent } from '@frontend/vanilla/core';
import { OfferButtonComponent } from '@frontend/vanilla/features/offer-button';
import { IFrameComponent } from '@frontend/vanilla/shared/browser';
import { NgScrollbarModule } from 'ngx-scrollbar';

import { SharedOffersModule } from '../shared-offers/shared-offers.module';
import { BetStationOfferOverlayComponent } from './bet-station-offer-overlay/bet-station-offer-overlay.component';
import { BetStationOfferTilesComponent } from './bet-station-offer-tiles/bet-station-offer-tiles.component';
import { BetStationOfferTileTeaserComponent } from './bet-station-offers-teaser/bet-station-offer-tile-teaser.component';

@NgModule({
    declarations: [BetStationOfferTilesComponent, BetStationOfferTileTeaserComponent, BetStationOfferOverlayComponent],
    imports: [CommonModule, DynamicHtmlDirective, PlainLinkComponent, IFrameComponent, OfferButtonComponent, NgScrollbarModule, SharedOffersModule],
    exports: [BetStationOfferTilesComponent],
})
export class BetStationOffersModule {}
