import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

import { UserService } from '@frontend/vanilla/core';
import { Observable, map } from 'rxjs';

import { OffersConstants } from '../common-constants/offers-constants';
import { LoadOffersApiService } from '../offers-service/load-offers.service';

@Component({
    selector: 'no-offer-message',
    templateUrl: 'no-offers.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NoOffersComponent implements OnInit {
    @Input() customMessage: string;

    message$: Observable<string>;

    constructor(
        private userService: UserService,
        private loadOffersApiService: LoadOffersApiService,
    ) {}

    ngOnInit(): void {
        this.message$ = this.loadOffersApiService.clientContentMessages$.pipe(
            map((clientContentMessages) => {
                if (this.customMessage) {
                    return this.customMessage;
                }
                return (
                    (this.userService?.isAuthenticated
                        ? clientContentMessages[OffersConstants.NoMyOffers]
                        : clientContentMessages[OffersConstants.NoOffersWithoutLogin]) ?? ''
                );
            }),
        );
    }
}
