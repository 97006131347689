import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';

import { Observable } from 'rxjs';

import { FreebetDetailsResourceService } from './freebet-details-resource.service';

@Injectable()
export class FreebetsDetailsResolver implements Resolve<any> {
    constructor(private resource: FreebetDetailsResourceService) {}

    resolve(): Observable<any> {
        return this.resource.getContent();
    }
}
