<div class="generic-modal-wrapper actions-in-row gm-overlay-title completed-entries">
    <div class="generic-modal-title">
        <div class="generic-modal-wrapper__title">{{ smartRewardClientContent?.parameters?.completedentries }}({{ completedEntriesCount }})</div>
        <div class="generic-modal-wrapper__subtitle" [vnDynamicHtml]="smartRewardClientContent?.parameters?.entirescompletedinmonth"></div>
        <span class="ui-icon ui-close theme-ex generic-modal-wrapper__closeicon" (click)="onClosePopup()"></span>
    </div>
    <div class="generic-modal-content-wrapper">
        <div class="generic-modal-content">
            <div class="smart-completed_entries">
                @if (completedEventsList?.length > 0) {
                    @for (completedEvent of completedEventsList; track completedEvent) {
                        <ph-completed-challenge-tile
                            [completedEvent]="completedEvent"
                            [smartRewardClientContent]="smartRewardClientContent"></ph-completed-challenge-tile>
                    }
                } @else {
                    <div class="smart-norecords-found" [vnDynamicHtml]="smartRewardClientContent?.parameters?.norecordsfound"></div>
                }
            </div>
        </div>
    </div>
</div>
