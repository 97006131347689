<div class="smart-widget smart-widget--lg" [ngClass]="isEdsWidget ? 'smart-widget--congrats--sm' : 'smart-widget--congrats'">
    @if (!deviceService.isMobilePhone && !isEdsWidget) {
        <div class="smart-badge">
            <div class="smart-badge__content smart-badge__content--white">
                <div class="smart-badge__title d-flex align-items-center smart-badge__title--white">{{ eventData?.product }}</div>
                <img
                    class="smart-badge__content--edge"
                    [attr.src]="eventData?.eventBadge?.image?.src"
                    [attr.alt]="eventData?.eventBadge?.image?.alt" />
            </div>
        </div>
    }
    <div class="smart-widget__content smart-widgett__circle-overlay">
        <div class="smart-widget__circle-overlay--image-container">
            <img
                class="smart-widget__circle-overlay--loaderimg"
                [attr.src]="successEventContent?.successEventAnimation?.src"
                [attr.alt]="successEventContent?.successEventAnimation?.src" />
        </div>
        <div class="smart-widget__body smart-widget__circle-overlay--coins">
            <div class="smart-widget__body">
                <img
                    class="smart-widget__circle-overlay{{ isEdsWidget ? '--img-eds' : '--img' }}"
                    [attr.src]="successEventContent?.successEventIcon?.src"
                    [attr.alt]="successEventContent?.successEventIcon?.src"
                    width="60" />
                <div class="smart-widget__circle-overlay--title{{ isEdsWidget ? '--sm' : '' }}">{{ eventData?.successTitle }}</div>
                <div
                    class="smart-widget__circle-overlay--description"
                    [ngClass]="isEdsWidget ? 'smart-widget__circle-overlay--description--sm' : ''"
                    [vnDynamicHtml]="eventData?.successDescription"></div>
                @if (eventData?.showGoToRewardCta && successEventContent?.successEventCta?.text) {
                    <a
                        (click)="openRewardDetailPopup()"
                        class="btn btn-md btn-secondary d-inline-flex align-items-center justify-content-center smart-widget__reward-btn"
                        >{{ successEventContent?.successEventCta?.text }}</a
                    >
                }
            </div>
        </div>
    </div>
</div>
