import { NgClass } from '@angular/common';
import { Component, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

import { DynamicHtmlDirective, NavigationService, ViewTemplate } from '@frontend/vanilla/core';
import { Subject, takeUntil } from 'rxjs';

import { PromoClientConfiguration } from '../../client-configs/promo-config';
import { SeoPageContentService } from '../seo-page-content.service';

@Component({
    selector: 'ph-seo-page-content',
    templateUrl: './seo-page-content.component.html',
    standalone: true,
    imports: [DynamicHtmlDirective, NgClass],
})
export class SeoPageContentComponent implements OnInit, OnChanges, OnDestroy {
    @Input() pageName: string;
    showContent: boolean = true;
    keywordsContent: string;
    header: string;
    commonContent: ViewTemplate;
    hideSeoDetails: boolean = false;
    private readonly tagName = 'description';
    private readonly destroy$: Subject<boolean> = new Subject<boolean>();

    constructor(
        private seoPageContentService: SeoPageContentService,
        private navigationService: NavigationService,
        private meta: Meta,
        private title: Title,
        private promoConfig: PromoClientConfiguration,
    ) {}

    ngOnInit(): void {
        this.promoConfig?.whenReady?.subscribe(() => {
            this.showContent = !this.promoConfig?.collapseSeoBox;
        });
    }

    ngOnChanges(): void {
        this.commonContent = {} as ViewTemplate;
        this.keywordsContent = '';
        this.showContent = !this.promoConfig?.collapseSeoBox;
        this.promoConfig?.whenReady?.subscribe(() => {
            if (this.navigationService?.location?.url()?.includes('?filter=')) {
                this.hideSeoDetails = true;
            }
        });
        this.seoPageContentService?.getSeoContent?.pipe(takeUntil(this.destroy$))?.subscribe((seoResponse) => {
            if (seoResponse != null && Object.keys(seoResponse)?.length > 0) {
                this.commonContent = seoResponse?.seoCommonContent;
                if (seoResponse?.seoPages != null) {
                    const response = seoResponse?.seoPages[this.pageName];
                    if (response != null) {
                        this.keywordsContent = response?.content;
                        this.header = response?.h1Header;
                        this.title?.setTitle(response?.metaTitle || '');
                        this.meta?.updateTag({ name: this.tagName, content: response?.metaDescription || '' });
                    }
                }
            }
        });
    }

    toggle() {
        this.showContent = !this.showContent;
    }

    ngOnDestroy() {
        this.destroy$.next(true);
        this.destroy$.complete();
    }
}
