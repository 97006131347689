<div class="eds-smart-widget">
    <div class="eds-smart-widget__container">
        <div class="eds-smart-widget__content">
            <div class="eds-smart-widget__body">
                <div class="eds-smart-widget__heading">{{ smartEdsWidgetContent?.title }}</div>
                <div class="eds-smart-widget__description" [vnDynamicHtml]="smartEdsWidgetContent?.description"></div>

                @if (showOptinCta && user?.isAuthenticated && smartEdsWidgetContent?.optinCta?.text) {
                    <a
                        (click)="onClickCta(false)"
                        class="btn btn-primary eds-smart-widget__loginBtn"
                        [ngClass]="
                            smartEdsWidgetContent?.optinCta?.attributes?.class
                                ? smartEdsWidgetContent?.optinCta?.attributes?.class
                                : 'btn btn-primary'
                        ">
                        {{ smartEdsWidgetContent?.optinCta?.text }}
                    </a>
                }
                @if (!user?.isAuthenticated && smartEdsWidgetContent?.loginCta?.text) {
                    <div class="eds-smart-widget__loginInfo">
                        <a
                            class="btn btn-primary eds-smart-widget__loginBtn"
                            (click)="onClickCta(true)"
                            [ngClass]="
                                smartEdsWidgetContent?.loginCta?.attributes?.class
                                    ? smartEdsWidgetContent?.loginCta?.attributes?.class
                                    : 'btn btn-primary'
                            ">
                            {{ smartEdsWidgetContent?.loginCta?.text }}
                        </a>
                        <div class="d-sm-block">
                            <a (click)="onClickOpenDialog()" class="btn btn-link-underlined btn-link-dark theme-info-i eds-smart-widget__infoicon">{{
                                smartEdsWidgetContent?.parameters?.moreinfo
                            }}</a>
                        </div>
                    </div>
                }
            </div>
        </div>
    </div>
</div>
