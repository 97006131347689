import { NgClass } from '@angular/common';
import { AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';

import { ModelDefault } from '@frontend/promo-homewidget';
import { CarouselDefaultConfig, IframeCommunicationDirective } from '@frontend/promo-utils';
import { ContentLink, DeviceService, DynamicHtmlDirective, NavigationService } from '@frontend/vanilla/core';
import { RxFor } from '@rx-angular/template/for';
import { RxIf } from '@rx-angular/template/if';
import Swiper from 'swiper';
import { Navigation } from 'swiper/modules';
import { SwiperOptions } from 'swiper/types';

import { SmartRewardsConstants } from '../../constants/smart-rewards-constant';
import { WeeklyGame } from '../../models/smart-rewards-details.model';
import { SmartRewardsService } from '../../services/smart-reward-service/smart-rewards.service';
import { SmartRewardTrackingHelperService } from '../tracking/smart-reward-tracking-helper.service';
import { WeeklyGamesFormatPipe } from './weekly-games-format.pipe';

@Component({
    standalone: true,
    selector: 'ph-weekly-games',
    templateUrl: './weekly-games.component.html',
    styleUrl: './weekly-games.component.scss',
    imports: [DynamicHtmlDirective, RxFor, RxIf, NgClass],
    providers: [WeeklyGamesFormatPipe],
})
export class WeeklyGamesComponent implements OnInit, OnDestroy, AfterViewInit {
    @Input() weeklyGame: WeeklyGame;
    @Input() product: string = SmartRewardsConstants.Home;
    @Input() isEventTile: boolean = false;
    @Input() positionEvent: string;
    @Input() isEdsWidget: boolean = false;

    @ViewChild('swiperContainer') swiperRef: ElementRef;

    isMobile: boolean = false;
    breakpoints = {
        0: {
            slidesPerView: 3.4,
            slidesPerGroup: 3,
        },
        599: {
            slidesPerView: 8.4,
            slidesPerGroup: 8,
        },
    };
    public config: SwiperOptions;
    public arrows: boolean = false;
    swiper: Swiper;
    iframeComm: IframeCommunicationDirective;

    constructor(
        private deviceService: DeviceService,
        private weeklyGameFormatPipe: WeeklyGamesFormatPipe,
        private carouselDefaultConfig: CarouselDefaultConfig,
        private trackingHelperService: SmartRewardTrackingHelperService,
        private navigationService: NavigationService,
        private smartRewardService: SmartRewardsService,
    ) {}

    ngOnInit() {
        this.isMobile = this.deviceService.isMobilePhone;
        this.iframeComm = this.smartRewardService.getIframeDetails();
        this.config = Object.assign(
            {
                modules: [Navigation],
                slidesPerView: 3.4,
                slidesPerGroup: 3,
                navigation: {
                    nextEl: `.${this.product}.carousel-next`,
                    prevEl: `.${this.product}.carousel-previous`,
                },
            },
            this.carouselDefaultConfig.defaultConfig,
        );
        if (!this.isEventTile) {
            this.config.breakpoints = this.breakpoints;
        }
        this.arrows = this.carouselDefaultConfig.arrows && !this.isEventTile;
    }

    ngAfterViewInit(): void {
        this.swiper = new Swiper(this.swiperRef?.nativeElement, this.config);
        setTimeout(() => {
            const swiperNavigation = this.swiper?.navigation;
            if (swiperNavigation) {
                swiperNavigation.destroy();
                swiperNavigation.init();
                swiperNavigation.update();
            }
        });
    }

    trackOnClickCta(url: string, isViewAll: boolean = true, contentPosition: string = ModelDefault.NA) {
        const locationEvent =
            this.product?.toLowerCase() + ' ' + (this.isEdsWidget ? SmartRewardsConstants.DailyRewardsWidget : SmartRewardsConstants.DailyRewards);
        const ctaName = isViewAll ? SmartRewardsConstants.ViewAll : SmartRewardsConstants.PlayNow;
        this.trackingHelperService.trackOnCtaClick(ctaName, locationEvent, this.positionEvent, url, contentPosition);
    }

    launchGame(playNowCta: ContentLink, index: number) {
        if (this.isEventTile && playNowCta?.url != null && playNowCta?.attributes != null) {
            const gameLaunchName =
                (this.isMobile && playNowCta?.attributes['data-mobilegamename']
                    ? playNowCta?.attributes['data-mobilegamename']
                    : playNowCta?.attributes['data-gamename']) ?? '';
            if (gameLaunchName) {
                playNowCta.url = this.weeklyGameFormatPipe.transform(playNowCta.url, gameLaunchName) ?? '';
            }

            if (this.product.toLowerCase() != SmartRewardsConstants.Bingo) {
                playNowCta.url = playNowCta.url?.concat(
                    '&clickTimeStamp=',
                    new Date().getTime().toString(),
                    '&gamePosition=',
                    index?.toString(),
                    '&gameLaunchedLocation=promo hub:smartrewards',
                    '&gameLaunchCategory=smartrewards',
                    '&specialproduct=smartrewards',
                    '&recommendationType=casino',
                    '&returnurl=',
                    this.navigationService.location.absUrl(),
                );
            }

            this.trackOnClickCta(playNowCta.url, false, index?.toString());
            if (this.iframeComm?.isGameClient) {
                this.sendGameClientEvents(gameLaunchName, playNowCta?.url);
            } else {
                this.navigationService.goTo(playNowCta.url);
            }
        }
    }

    sendGameClientEvents(gameLaunchName: string, url: string) {
        if (this.product.toLowerCase() == SmartRewardsConstants.Bingo) {
            this.iframeComm.sendMessageToParentWindow(SmartRewardsConstants.OPEN_URL, {
                url: url,
                action: SmartRewardsConstants.SmartReward,
            });
        } else {
            this.iframeComm.sendMessageToParentWindow(SmartRewardsConstants.GAMEVARIANT_OPEN_GAME, {
                gameVariantName: gameLaunchName,
                action: SmartRewardsConstants.SmartReward,
            });
        }
    }

    onSlidePrev() {
        this.swiper?.slidePrev();
    }

    onSlideNext() {
        this.swiper?.slideNext();
    }

    ngOnDestroy() {
        this.swiper?.destroy();
    }
}
