export abstract class CommonConstants {
    //RGStatus
    public static readonly RG_STATUS_GREEN: string = 'green';
    public static readonly STATUS_SUSPENDED: string = 'suspended';
    public static readonly STATUS_CLOSED: string = 'CLOSED';
    public static readonly STATUS_SUSPICIOUS: string = 'SUSPICIOUS';
    public static readonly STATUS_FRAUD: string = 'FRAUD';
    public static readonly STATUS_RGCLOSED: string = 'RGCLOSED';
    public static readonly STATUS_CLOSEDINACTIVE: string = 'CLOSEDINACTIVE';
    public static readonly STATUS_PERMENANTLYCLOSED: string = 'PERMENANTLYCLOSED';
    public static readonly STATUS_BLOCKED: string = 'BLOCKED';
    public static readonly STATUS_ONCOOL_OFF: string = 'OnCool-Off';
    public static readonly CLAIM_RGS: string = 'rgs';
    public static readonly CLAIM_ECC: string = 'ecc';
    public static readonly CLAIM_BS: string = 'bs';
    public static readonly CLAIM_JURISDICTION: string = 'jurisdiction';
    public static readonly CLAIM_TIERCODE: string = 'tiercode';
}
