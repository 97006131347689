import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';

import { FilterConfiguration, RegulatoryConfiguration } from '@frontend/promo-homewidget';
import { ClientConfigService } from 'packages/vanilla/lib/core/src/client-config/client-config.service';

@Injectable()
export class OffersGuard implements CanActivate {
    constructor(
        private clientConfigService: ClientConfigService,
        private filterConfig: FilterConfiguration,
        private regulatoryConfig: RegulatoryConfiguration,
    ) {}

    async canActivate() {
        const configs = [] as any;
        if (!this.filterConfig.isConfigReady) configs.push(FilterConfiguration);
        if (!this.regulatoryConfig.isConfigReady) configs.push(RegulatoryConfiguration);
        if (configs.length > 0) await this.clientConfigService.load('', configs);
        return true;
    }
}
