import {
    AfterContentChecked,
    CUSTOM_ELEMENTS_SCHEMA,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    NO_ERRORS_SCHEMA,
    OnChanges,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';

import { CarouselDefaultConfig } from '@frontend/promo-utils';
import { DeviceService, DynamicHtmlDirective, UserService } from '@frontend/vanilla/core';
import { RxFor } from '@rx-angular/template/for';
import { RxIf } from '@rx-angular/template/if';
import Swiper from 'swiper';
import { Navigation } from 'swiper/modules';
import { SwiperOptions } from 'swiper/types';

import { BaseMetaData } from '../../models/base-meta-data';
import { SmartEdsWidgetContent } from '../../models/smart-eds-widget-details';
import { MonthlyPrizeProgressComponent } from '../../shared/monthly-prize-progress/monthly-prize-progress.component';
import { EdsChallengesWidgetComponent } from '../eds-challenges-widget/eds-challenges-widget.component';

@Component({
    selector: 'ph-post-optin-widget',
    standalone: true,
    imports: [RxIf, RxFor, EdsChallengesWidgetComponent, MonthlyPrizeProgressComponent, DynamicHtmlDirective],
    schemas: [NO_ERRORS_SCHEMA, CUSTOM_ELEMENTS_SCHEMA],
    templateUrl: './post-optin-widget.component.html',
    styleUrl: './post-optin-widget.component.scss',
})
export class PostOptinWidgetComponent implements OnInit, OnChanges, AfterContentChecked {
    @Input() smartEdsWidgetContent: SmartEdsWidgetContent;
    @Input() smartEdsWidgetMetaData: BaseMetaData;
    @Output() openDialog = new EventEmitter();

    @ViewChild('swiperContainer') swiperRef: ElementRef;

    isSwiperInit: boolean = false;
    playerProgressPercentage: number = 0;
    playerProgressDescription: string;
    breakpoints = {
        0: {
            slidesPerView: 1.5,
            slidesPerGroup: 1,
        },
        599: {
            slidesPerView: 3,
            slidesPerGroup: 3,
        },
    };
    public config: SwiperOptions;
    swiper: Swiper;
    isUserAuthenticated: boolean = false;
    arrows: boolean = false;

    constructor(
        private user: UserService,
        private carouselDefaultConfig: CarouselDefaultConfig,
        public deviceService: DeviceService,
    ) {}

    ngOnInit() {
        this.isUserAuthenticated = this.user.isAuthenticated;

        this.config = Object.assign(
            {
                modules: [Navigation],
                breakpoints: this.breakpoints,
                spaceBetween: 10,
                navigation: {
                    nextEl: `.carousel-next`,
                    prevEl: `.carousel-previous`,
                },
            },
            this.carouselDefaultConfig.defaultConfig,
        );
        this.arrows = !this.carouselDefaultConfig.arrows;
    }

    ngAfterContentChecked() {
        if (this.swiperRef?.nativeElement && !this.isSwiperInit) {
            this.swiper = new Swiper(this.swiperRef?.nativeElement, this.config);
            this.isSwiperInit = true;
            setTimeout(() => {
                const swiperNavigation = this.swiper?.navigation;
                if (swiperNavigation) {
                    swiperNavigation.destroy();
                    swiperNavigation.init();
                    swiperNavigation.update();
                }
            });
        }
    }

    onClickOpenDialog() {
        this.openDialog.emit();
    }

    ngOnChanges() {
        this.getPlayerProgressPercentage();
    }

    getPlayerProgressPercentage() {
        if (this.smartEdsWidgetMetaData) {
            this.playerProgressPercentage =
                (Number(this.smartEdsWidgetMetaData?.numberOfTicketsWon) / Number(this.smartEdsWidgetMetaData?.configNumberofTickets)) * 100;
        }
        this.playerProgressDescription = this.smartEdsWidgetMetaData?.numberOfTicketsWon + '/' + this.smartEdsWidgetMetaData?.configNumberofTickets;
    }

    swipePrev() {
        this.swiper?.slidePrev();
    }

    swipeNext() {
        this.swiper?.slideNext();
    }

    ngOnDestroy() {
        this.swiper?.destroy();
    }
}
