import { Component, Input, OnInit } from '@angular/core';

import { OfferContentConfiguration, TrackingModelService } from '@frontend/promo-homewidget';
import { ContentItem, DeviceService } from '@frontend/vanilla/core';
import { LetDirective } from '@ngrx/component';
import { Observable } from 'rxjs';

import { PromoFaqsService } from './promo-faqs.service';

@Component({
    selector: 'ph-promo-faqs',
    templateUrl: './promo-faqs.component.html',
    standalone: true,
    imports: [LetDirective],
})
export class PromoFaqsComponent implements OnInit {
    faqs$: Observable<ContentItem>;
    @Input() productName: string;
    constructor(
        private promofaqService: PromoFaqsService,
        public device: DeviceService,
        public offerContentConfiguration: OfferContentConfiguration,
        private trackingModelService: TrackingModelService,
    ) {}

    ngOnInit(): void {
        if (this.offerContentConfiguration?.promoFaqsSitecoreLocation) {
            this.faqs$ = this.promofaqService?.getContent();
        }
    }
    webTrackingFaqs(cta: any) {
        this.trackingModelService.submitTracking({
            CategoryEvent: 'promo hub',
            LabelEvent: 'help and contact',
            ActionEvent: 'click',
            EventDetails: 'open faqs',
            SiteSection: 'promo hub',
            siteSubSection: 'promo offers page',
            URLClicked: cta,
            LocationEvent: this.productName,
        });
    }
}
