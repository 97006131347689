import { Injectable } from '@angular/core';

import { ViewTemplate } from '@frontend/vanilla/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class NLRegulatoryHelperService {
    nonUserComplianceMessages$ = new BehaviorSubject<ViewTemplate>({});
    reasonCodes$ = new BehaviorSubject<string[]>([]);
    complianceKey$ = new BehaviorSubject<string>('');
    displayPromoDetails$ = new BehaviorSubject<boolean>(true);

    constructor() {
        this.setRGYellowPlayer();
    }

    private setRGYellowPlayer(): void {
        this.reasonCodes$.subscribe((reasonCodes: string[]) => {
            if (reasonCodes?.length > 0) {
                if (reasonCodes.some((x) => x === 'R106') && reasonCodes.every((x) => x !== 'R108')) {
                    this.complianceKey$.next('RGYAbove24YPlayer');
                    this.displayPromoDetails$.next(true);
                } else if (reasonCodes.some((x) => x === 'R108') && reasonCodes.some((x) => x === 'R106')) {
                    this.complianceKey$.next('RGYBelow24YPlayer');
                    this.displayPromoDetails$.next(false);
                } else if (reasonCodes.some((x) => x === 'R108') && reasonCodes.every((x) => x !== 'R106')) {
                    this.complianceKey$.next('Below24YearsPlayer');
                    this.displayPromoDetails$.next(false);
                }
            }
        });
    }
}
