<div class="betstation-overlay-popup">
    <div class="generic-modal-wrapper actions-in-row">
        <div class="generic-modal-title">
            <div class="header-ctrl-wrapper">
                <div class="header-ctrl d-flex justify-content-between align-items-center">
                    <div class="header-ctrl-txt text-truncate">{{ data?.overlayTitle }}</div>
                    <div (click)="closeModel()" class="header-ctrl-r">
                        <span class="ui-icon ui-icon-size-lg ui-close theme-ex"></span>
                    </div>
                </div>
            </div>
        </div>
        <div class="generic-modal-content-wrapper">
            <vn-iframe *ngIf="url && origin" [url]="url" [origin]="origin"></vn-iframe>
        </div>
    </div>
</div>
