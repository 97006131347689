export enum PromotionStatuses {
    //leader board
    Pending = 'Pending',
    CompletedWithPrize = 'CompletedWithPrize',
    CompletedWithoutPrize = 'CompletedWithoutPrize',
    //player Activity
    Prize = 'Prize',
    NoPrize = 'NoPrize',
    PayingOut = 'PayinOut',
}

export enum TargetPromotionStatus {
    NotStarted = 'NotStarted',
    Started = 'Started',
    Ended = 'Ended',
}
