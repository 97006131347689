import { Injectable } from '@angular/core';

import { TrackingService } from '@frontend/vanilla/core';

import { ModelDefault, TrackingModel } from './tracking-model';

/**
 * @whatItDoes This service is for tracking, and created to remove duplicate code.
 *
 * @stable
 */
@Injectable({
    providedIn: 'root',
})
export class TrackingModelService {
    constructor(private tracking: TrackingService) {}
    /**
     * @whatItDoes Default value of each TrackingModel property is 'not applicable'. Change value according to the requirement.
     *
     * @stable
     */
    submitTracking(trackingModel: TrackingModel | Array<TrackingModel>, eventName: string = 'Event.Tracking') {
        if (Array.isArray(trackingModel)) {
            const filters = new Array<any>();
            trackingModel?.forEach((model) => {
                filters.push(this.getTrackingModelDetails(model));
            });
            if (filters?.length > 0) this.tracking.triggerEvent(eventName, { filters });
        } else this.tracking.triggerEvent(eventName, this.getTrackingModelDetails(trackingModel));
    }

    getTrackingModelDetails(trackingModel: TrackingModel) {
        trackingModel.SiteSection = ModelDefault.Site;
        trackingModel.CategoryEvent = trackingModel.CategoryEvent ? trackingModel.CategoryEvent : ModelDefault.Site;
        trackingModel.LabelEvent = trackingModel.LabelEvent ? trackingModel.LabelEvent : ModelDefault.NA;
        trackingModel.ActionEvent = trackingModel.ActionEvent ? trackingModel.ActionEvent : ModelDefault.NA;
        trackingModel.PositionEvent = trackingModel.PositionEvent ? trackingModel.PositionEvent : ModelDefault.NA;
        trackingModel.LocationEvent = trackingModel.LocationEvent ? trackingModel.LocationEvent : ModelDefault.NA;
        trackingModel.EventDetails = trackingModel.EventDetails ? trackingModel.EventDetails : ModelDefault.NA;
        trackingModel.URLClicked = trackingModel.URLClicked ? trackingModel.URLClicked : ModelDefault.NA;
        trackingModel.campaignId = trackingModel.campaignId ? trackingModel.campaignId : ModelDefault.NA;
        trackingModel.messageType = trackingModel.messageType ? trackingModel.messageType : ModelDefault.NA;
        trackingModel.siteSubSection = trackingModel.siteSubSection ? trackingModel.siteSubSection : ModelDefault.NA;
        trackingModel.promotionType = trackingModel.promotionType ? trackingModel.promotionType : ModelDefault.NA;
        trackingModel.Product = trackingModel.Product ? trackingModel.Product : ModelDefault.NA;
        trackingModel.PromoOfferName = trackingModel.PromoOfferName ? trackingModel.PromoOfferName : ModelDefault.NA;
        trackingModel.PromoBonusCode = trackingModel.PromoBonusCode ? trackingModel.PromoBonusCode : ModelDefault.NA;
        trackingModel.contentPosition = trackingModel.contentPosition ? trackingModel.contentPosition : ModelDefault.NA;
        trackingModel.PromoIntent = trackingModel.PromoIntent ? trackingModel.PromoIntent : ModelDefault.NA;
        trackingModel.OfferFavouriteandCoins = trackingModel.OfferFavouriteandCoins ? trackingModel.OfferFavouriteandCoins : ModelDefault.NA;

        return {
            'component.CategoryEvent': trackingModel.CategoryEvent,
            'component.LabelEvent': trackingModel.LabelEvent,
            'component.ActionEvent': trackingModel.ActionEvent,
            'component.PositionEvent': trackingModel.PositionEvent,
            'component.LocationEvent': trackingModel.LocationEvent,
            'component.EventDetails': trackingModel.EventDetails,
            'component.URLClicked': trackingModel.URLClicked,
            'component.ContentPosition': trackingModel.contentPosition,
            'component.OfferFavouriteandCoins': trackingModel.OfferFavouriteandCoins,
            'campaignId': trackingModel.campaignId,
            'messageType': trackingModel.messageType,
            'page.siteSection': trackingModel.SiteSection,
            'page.siteSubSection': trackingModel.siteSubSection,
            'component.PromotionType': trackingModel.promotionType,
            'component.Product': trackingModel.Product,
            'component.PromoOfferName': trackingModel.PromoOfferName,
            'component.PromoBonusCode': trackingModel.PromoBonusCode,
            'component.PromoIntent': trackingModel.PromoIntent,
        };
    }
}
