import { createAction, props } from '@ngrx/store';

import { Brat } from '../model/brat-details.model';

export const BRAT_LOADING = createAction('[Brat Details] Load Brat', props<{ bratId: string }>());

export const BRAT_INITIAL_STATE = createAction('[Brat Details] Load Initial State Brat');

export const BRAT_LOADED = createAction('[Brat Details] Brat Loaded', props<{ brat: Brat }>());

export const BRAT_LOAD_FAILED = createAction('[Brat Details] Brat Load Failed', props<{ errorMessage: string }>());
