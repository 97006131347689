import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';

import { Observable } from 'rxjs';

import { OddBoostDetailsResourceService } from '../odd-boost/details/odd-boost-service/odd-boost-details-resource.service';

@Injectable()
export class OddBoostDetailsResolverV2 implements Resolve<any> {
    constructor(private resource: OddBoostDetailsResourceService) {}

    resolve(): Observable<any> {
        return this.resource.getContent();
    }
}
