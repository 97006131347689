import { NgClass } from '@angular/common';
import { AfterContentChecked, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';

import { CarouselDefaultConfig } from '@frontend/promo-utils';
import { DynamicHtmlDirective } from '@frontend/vanilla/core';
import { RxFor } from '@rx-angular/template/for';
import { RxIf } from '@rx-angular/template/if';
import Swiper from 'swiper';
import { Navigation } from 'swiper/modules';
import { SwiperOptions } from 'swiper/types';

import { SmartRewardsConstants } from '../../../../constants/smart-rewards-constant';
import { HowItWorksContent } from '../../../../models/smart-rewards-details.model';
import { ModelDefault } from '../../../../models/tracking-model';
import { SmartRewardTrackingHelperService } from '../../../tracking/smart-reward-tracking-helper.service';

@Component({
    standalone: true,
    selector: 'ph-smart-reward-info',
    templateUrl: './smart-reward-info.component.html',
    styleUrl: './smart-reward-info.component.scss',
    encapsulation: ViewEncapsulation.None,
    imports: [DynamicHtmlDirective, RxIf, RxFor, NgClass],
})
export class SmartRewardInfoComponent implements OnInit, OnDestroy, AfterContentChecked {
    @Input() howToContent: HowItWorksContent;
    @Input() isOverlay: boolean = false;
    @Input() isEdsWidget: boolean = false;

    public config: SwiperOptions;
    public arrows: boolean = false;
    swiper: Swiper;
    isSwiperInit: boolean = false;

    @ViewChild('swiperContainer') swiperRef: ElementRef;

    constructor(
        private carouselDefaultConfig: CarouselDefaultConfig,
        private trackingHelperService: SmartRewardTrackingHelperService,
    ) {}

    ngOnInit(): void {
        this.config = Object.assign(
            {
                modules: [Navigation],
                slidesPerView: this.howToContent?.benefitsIncludeDesc?.length > 4 ? 4 : this.howToContent?.benefitsIncludeDesc?.length,
                slidesPerGroup: this.howToContent?.benefitsIncludeDesc?.length > 4 ? 4 : this.howToContent?.benefitsIncludeDesc?.length,
                centerInsufficientSlides: true,
                navigation: {
                    nextEl: `.smart-info .carousel-next`,
                    prevEl: `.smart-info .carousel-previous`,
                },
            },
            this.carouselDefaultConfig.defaultConfig,
        );
        this.arrows = this.carouselDefaultConfig.arrows && !this.isOverlay;
    }

    ngAfterContentChecked() {
        if (this.swiperRef?.nativeElement && !this.isSwiperInit) {
            this.swiper = new Swiper(this.swiperRef?.nativeElement, this.config);
            this.isSwiperInit = true;
            setTimeout(() => {
                const swiperNavigation = this.swiper?.navigation;
                if (swiperNavigation) {
                    swiperNavigation.destroy();
                    swiperNavigation.init();
                    swiperNavigation.update();
                }
            });
        }
    }

    trackOnClick(url: string) {
        const locationEvent = this.isEdsWidget ? SmartRewardsConstants.WidgetSmartRewardMoreInfo : SmartRewardsConstants.SmartRewardMoreInfo;
        this.trackingHelperService.trackOnCtaClick(SmartRewardsConstants.GoToSmartRewardsHome, locationEvent, ModelDefault.NA, url);
    }

    onSlidePrev() {
        this.swiper?.slidePrev();
    }

    onSlideNext() {
        this.swiper?.slideNext();
    }

    ngOnDestroy() {
        if (this.swiper) this.swiper?.destroy();
    }
}
