@if (offerDetailsList?.length > 0) {
    <div class="offers-teaser-group">
        @if (offerCategory?.categoryTitle) {
            <div class="offers-teaser-group-header">
                <div class="offers-teaser-group-title otgt-title">
                    @if (!!offerCategory?.productImage) {
                        <img
                            class="otgt-image"
                            [attr.src]="offerCategory?.productImage?.src"
                            [attr.alt]="offerCategory?.productImage?.alt"
                            [width]="offerCategory?.productImage?.width"
                            [height]="offerCategory?.productImage?.height" />
                    }
                    <div class="otgt-wrapper">
                        <div class="otgt-header">
                            {{ offerCategory?.categoryTitle }}
                        </div>
                        @if (offerCategory?.subtitle) {
                            <div class="otgt-subheader">
                                {{ offerCategory?.subtitle }}
                            </div>
                        }
                    </div>
                </div>
                @if (offerDetailsList?.length > 0 && offerCategory?.viewAllLink?.url) {
                    <div class="offers-teaser-group-see-all">
                        <a href="javascript:void(0);" (click)="processViewAllClick()"
                            >{{ clientContentMessages?.ShowAll }} ({{ offerCategory?.viewAllCount }})</a
                        >
                    </div>
                }
            </div>
        }
        <div class="offers-teaser-group-items vn-carousel">
            @if (arrows) {
                <i [ngClass]="categoryType" class="theme-left carousel-previous" role="button" aria-label="Previous slide"></i>
                <i [ngClass]="categoryType" class="theme-right carousel-next" role="button" aria-label="Next slide"></i>
            }
            <div #swiperContainer class="swiper">
                <div class="swiper-wrapper">
                    @for (offer of offerDetailsList; track offer; let i = $index) {
                        <div class="swiper-slide">
                            <div class="offers-teaser-wrapper-single">
                                <offers-teaser class="offers-teaser-wrapper" [offer]="offer" [product]="categoryType" [teaserNumber]="i + 1">
                                </offers-teaser>
                            </div>
                        </div>
                    }
                </div>
            </div>
        </div>
    </div>
}
