export enum ModelDefault {
    NA = 'not applicable',
    Site = 'Promo Hub',
    Intent_Acquisition = 'Acquisition',
    Type_Dynamic = 'dynamic promotion',
}
/**
 * @whatItDoes This model has all tracking optional properties. Default value will be 'not applicable'
 *
 * @stable
 */
export class TrackingModel {
    SiteSection?: string;
    CategoryEvent?: string;
    LabelEvent?: string;
    ActionEvent?: string;
    PositionEvent?: string;
    LocationEvent?: string;
    EventDetails?: string;
    URLClicked?: string;
    campaignId?: string;
    messageType?: string;
    contentPosition?: string;
    siteSubSection?: string;
    promotionType?: string;
    Product?: string;
    PromoOfferName?: string;
    PromoBonusCode?: string;
    PromoIntent?: string;
    OfferFavouriteandCoins?: string;
}
