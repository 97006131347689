import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { RGStatusService } from '@frontend/promo-utils';
import { NavigationService, UserLoginEvent, UserService } from '@frontend/vanilla/core';
import { filter } from 'rxjs';

import { CoinEconomyService } from '../coin-economy.service';
import { CoinEconomyConstants } from '../constants/coin-economy-constants';

@Injectable()
export class UserStatusCheckGuard {
    constructor(
        private userService: UserService,
        private router: Router,
        private navigationService: NavigationService,
        private rgStatusService: RGStatusService,
        private coinEconomyService: CoinEconomyService,
    ) {}

    canActivate() {
        if (this.userService.isAuthenticated) {
            this.navigateToHomePage();
        } else {
            this.userService.events.pipe(filter((e: any) => e instanceof UserLoginEvent)).subscribe(() => {
                this.navigateToHomePage();
            });
        }
        return true;
    }

    navigateToHomePage() {
        const status = this.rgStatusService.claimsStatusCheck();
        if (!status) {
            const routeUrl = `/${this.navigationService.location.culture + this.coinEconomyService.getReplacedSubRoute(CoinEconomyConstants.HomePath)}`;
            this.router.navigate([routeUrl], { skipLocationChange: true });
        }
    }
}
