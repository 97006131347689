import { Injectable } from '@angular/core';

import { LoadOffersApiService, Offer, OfferContentConfiguration, OfferDetails } from '@frontend/promo-homewidget';
import { ApiService } from '@frontend/promo-utils';
import { DslService, MenuContentItem, UserLoginEvent, UserService, toBoolean } from '@frontend/vanilla/core';
import { BehaviorSubject, EMPTY, ReplaySubject, filter, first, map, switchMap } from 'rxjs';

import { PromoLatamNavigationConfiguration } from '../../client-configs/latam-navigation-config';

@Injectable({
    providedIn: 'root',
})
export class OffersNavigationService {
    _menuContentItem: BehaviorSubject<MenuContentItem> = new BehaviorSubject({} as MenuContentItem);
    enabled$: ReplaySubject<boolean> = new ReplaySubject();

    constructor(
        private apiService: ApiService,
        private dslService: DslService,
        public offerContentConfiguration: OfferContentConfiguration,
        private userService: UserService,
        private latamNavigationConfig: PromoLatamNavigationConfiguration,
        private loadOffersApiService: LoadOffersApiService,
    ) {
        this.getSubNavigationItems();
        this.userService.events.pipe(filter((e) => e instanceof UserLoginEvent)).subscribe(() => {
            this.getSubNavigationItems();
        });
        this.latamNavigationConfig.whenReady.pipe(first()).subscribe(() => {
            this.dslService
                .evaluateExpression<boolean>(this.latamNavigationConfig.hidePromotionBackButton)
                .subscribe((enabled) => this.enabled$.next(enabled));
        });
    }

    get subNavigationItems() {
        return this._menuContentItem.asObservable();
    }

    getSubNavigationItems() {
        if (!this.offerContentConfiguration.disableSubNavigation) {
            this.apiService
                .get('GetSubNavigationItems')
                .pipe(
                    switchMap((menuContentItem) => {
                        if (menuContentItem)
                            return this.dslService
                                .evaluateContent(menuContentItem.children)
                                .pipe(map((dslResponse) => ({ menuContentItem, dslResponse })));
                        else return EMPTY;
                    }),
                )
                .subscribe(({ menuContentItem, dslResponse }) => {
                    if (this.userService.isAuthenticated && this.offerContentConfiguration.hideEmptySubNavigation) {
                        this.filterNavigation(menuContentItem, dslResponse);
                    } else {
                        menuContentItem.children = dslResponse;
                        this._menuContentItem.next(menuContentItem);
                    }
                });
        }
    }

    private filterNavigation(menuContentItem: MenuContentItem, dslResponse: MenuContentItem[]) {
        this.loadOffersApiService.offerDetails$.subscribe((offers: OfferDetails) => {
            if (this.loadOffersApiService.areOffersLoaded) {
                menuContentItem.children = this.filterNavigationWithOffers(dslResponse, offers.offerDetailsList);
                this._menuContentItem.next(menuContentItem);
            }
        });
    }

    private filterNavigationWithOffers = (dslResponse: MenuContentItem[], offerDetailsList: Offer[]): MenuContentItem[] => {
        return dslResponse.filter(
            (menuItem: MenuContentItem) =>
                offerDetailsList.some((offer: Offer) => offer.offerMetadata.applicableProducts.includes(menuItem.name)) ||
                !toBoolean(menuItem.parameters['disable-when-no-promo']),
        );
    };
}
