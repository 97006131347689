import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';

import { Observable } from 'rxjs';

import { FreeSpinsDetailsResourceService } from '../freespins/details/freespins-service/freespins-details-resource.service';

@Injectable()
export class BonusSpinsDetailsV2Resolver implements Resolve<any> {
    constructor(private resource: FreeSpinsDetailsResourceService) {}

    resolve(): Observable<any> {
        return this.resource.getContent();
    }
}
