<div class="generic-modal-wrapper actions-in-row gm-overlay-title smart-reward-store">
    <div class="generic-modal-title">
        <span class="theme-close-small generic-modal-wrapper__closeicon" (click)="onClosePopup()"></span>
    </div>
    <div class="generic-modal-content-wrapper no-scroll">
        <div class="generic-modal-content">
            <div class="smart-widget">
                <img [attr.src]="rewardContent?.backgroundImage?.src" [attr.alt]="rewardContent?.backgroundImage?.alt" class="smart-widget__bg" />
                <div class="smart-widget__content">
                    <div class="smart-widget__body">
                        @if (rewardContent?.rewardBadge?.text) {
                            <div class="smart-badge">
                                <div class="smart-badge__content smart-badge__content--white">
                                    <div class="smart-badge__title d-flex align-items-center smart-badge__title--white">
                                        {{ rewardContent?.rewardBadge?.text }}
                                    </div>
                                    <img
                                        class="smart-badge__content--edge"
                                        [attr.src]="rewardContent?.rewardBadge?.image?.src"
                                        [attr.alt]="rewardContent?.rewardBadge?.image?.alt" />
                                </div>
                            </div>
                        }
                        <img
                            [attr.src]="rewardContent?.rewardIcon?.src"
                            [attr.alt]="rewardContent?.rewardIcon?.alt"
                            class="smart-widget__rewardimg" />
                        <div
                            class="smart-widget__title line-clamp-2 text-center smart-widget__title--{{
                                rewardMetaData?.rewardType?.toLowerCase()?.trim()
                            }}"
                            [vnDynamicHtml]="rewardContent?.title"></div>
                        <div
                            class="smart-widget__description text-center mb-3 line-clamp-3 text-white"
                            [vnDynamicHtml]="rewardContent?.description"></div>
                    </div>

                    <div class="smart-widget__footer">
                        <div class="smart-widget__countdown d-flex align-items-center justify-content-center">
                            <div class="smart-widget__countdown--expiry d-inline-flex align-items-center">
                                <span class="theme-clock d-flex pr-1"></span> {{ rewardContent?.expiryTimeCountDown }}
                            </div>
                        </div>
                        @if (rewardMetaData?.isAwarded || isRewardActivated) {
                            <div class="smart-progress">
                                <div class="smart-progress-bar__green d-flex" [style.width.%]="progressBarWidthPercentage">
                                    <span class="tooltip d-flex justify-content-center">
                                        <div class="smart-progress-bar__tooltip">
                                            <span class="theme-casino"></span><span>{{ currentProgressBarValue }}</span>
                                        </div>
                                    </span>
                                </div>
                                <div class="smart-progress-bar__white"></div>
                            </div>
                        }
                        <div class="ph-eligible-games">
                            <ph-smart-reward-eligible-games
                                [contentParameters]="contentParameters"
                                [isAwarded]="rewardMetaData?.isAwarded || isRewardActivated"
                                [isEdsWidget]="data?.isEdsWidget"
                                [rewardMetaData]="rewardMetaData"
                                [rewardContent]="rewardContent"
                                (onGameLaunch)="onGameLaunch($event)">
                            </ph-smart-reward-eligible-games>
                        </div>
                        @if (!rewardMetaData?.isAwarded && !isRewardActivated) {
                            <div class="smart-reward-store__footer d-flex justify-content-center pt-2">
                                <a
                                    class="btn-lg d-inline-flex align-items-center justify-content-center"
                                    (click)="onActivateRewardCtaClick()"
                                    [ngClass]="
                                        rewardContent?.activateRewardCta?.attributes?.class
                                            ? rewardContent?.activateRewardCta?.attributes?.class
                                            : 'btn btn-dark'
                                    ">
                                    {{ rewardContent?.activateRewardCta?.text }}
                                </a>
                            </div>
                        }
                    </div>
                </div>
            </div>
            <div class="smart-reward-store__tnc-container th-scroll">
                <div class="smart-reward-store__tnc-title text-center" [vnDynamicHtml]="rewardContent?.extendedKeyTermsAndConditions"></div>
                <div class="smart-reward-store__tnc">
                    <smart-reward-terms-conditions
                        [tncDescription]="rewardContent?.manualTermsAndConditions"
                        [collapseTermsAndConditions]="collapseTermsAndConditions"
                        [tncTitle]="contentParameters?.tnctitle"
                        [trackingModel]="tncTrackingModel"
                        [tncId]="'popuptnc'">
                    </smart-reward-terms-conditions>
                </div>
            </div>
        </div>
    </div>
</div>
