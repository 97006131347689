import { Injectable } from '@angular/core';

import { RegulatoryApiService } from '@frontend/promo-homewidget';
import { ApiService } from '@frontend/promo-utils';
import { BehaviorSubject, Observable } from 'rxjs';

import { Brat } from '../model/brat-details.model';

@Injectable({
    providedIn: 'root',
})
export class BratHttpService {
    bratDetails$: BehaviorSubject<Brat> = new BehaviorSubject({} as Brat);
    currentTab$: BehaviorSubject<string> = new BehaviorSubject('');

    constructor(
        private apiService: ApiService,
        private regulatoryApiService: RegulatoryApiService,
    ) {}

    getBratDetails(bratId: string): Observable<Brat> {
        const brat$ = this.apiService.get('brat', { bratId: bratId }, { showSpinner: true });
        return this.regulatoryApiService.fetchDataWithReasonCode(brat$);
    }

    dropBrat(bratId: string): Observable<Brat> {
        return this.apiService.get('brat/drop', { bratId: bratId }, { showSpinner: true });
    }
}
