/* eslint-disable no-console */
import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';

import { HeaderItemBase } from '@frontend/vanilla/features/header';

import { IGlobalSearchContext } from '../context/search';
import { __CONTEXT__, __ENTRY_POINT_ACTIVE__, __FETCH_CONTEXT__, __LAUNCH_SEARCH__, __PRESENTATION_BOOTSTRAPPED__ } from '../events';

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'vn-h-global-search',
    templateUrl: 'header-leftmenu-entry.component.html',
    styleUrls: ['header-leftmenu-entry.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class HeaderLeftMenuEntryComponent extends HeaderItemBase implements OnInit, OnDestroy {
    isEnabled = false;
    searchContext: any;

    constructor() {
        // private readonly menuActionService: MenuActionsService // private readonly searchLauncherService: SearchLauncherService,
        super();
        // this.menuActionService = this.menuActionService; // Hack
    }

    private displayIcon = true;
    private desktopClassValue = 'theme-search-i tab-nav-link';

    ngOnInit() {
        this.registerEventHandlers();
        this.raiseIsAppEnabledQuery();
    }

    ngOnDestroy() {
        this.unregisterEventHandlers();
    }

    showsearch() {
        dispatchEvent(new CustomEvent(__LAUNCH_SEARCH__, { detail: { launchChannel: 'desktop', launchedFrom: 'top' } }));
    }

    get canDisplayIcon(): boolean {
        return this.displayIcon;
    }

    get desktopClass(): string {
        return this.desktopClassValue;
    }

    private setState(searchContext: IGlobalSearchContext) {
        this.desktopClassValue = this.getDesktopClassFromClientConfig(searchContext);
        console.log('desktopClassvalue', this.desktopClassValue);
        if (this.desktopClassValue) {
            this.displayIcon = true;
        } else {
            this.displayIcon = true;
            this.desktopClassValue = 'theme-search tab-nav-link';
        }
    }

    private getDesktopClassFromClientConfig(searchContext: IGlobalSearchContext): string {
        const presentationContext = searchContext && searchContext.presentationContext;
        return (
            (presentationContext &&
                presentationContext.entryPoints &&
                presentationContext.entryPoints['sticky'] &&
                presentationContext.entryPoints['sticky'].parameters &&
                (presentationContext.entryPoints['sticky'].parameters['DesktopClass'] ||
                    presentationContext.entryPoints['sticky'].parameters['desktopClass'])) ||
            ''
        );
    }

    private raiseIsAppEnabledQuery() {
        dispatchEvent(new CustomEvent(__FETCH_CONTEXT__));
    }

    private registerEventHandlers() {
        addEventListener('gs:hideHeaderEntry', this.hideHeaderEntryEventHandler);
        addEventListener('gs:showHeaderEntry', this.showHeaderEntryEventHandler);
        addEventListener(__CONTEXT__, this.contextResultEventHandler);
        addEventListener(__PRESENTATION_BOOTSTRAPPED__, this.presentationBootstarppedEventHandler);
        addEventListener('message', this.messageEventHandler);
    }

    private unregisterEventHandlers() {
        removeEventListener('gs:hideHeaderEntry', this.hideHeaderEntryEventHandler);
        removeEventListener('gs:showHeaderEntry', this.showHeaderEntryEventHandler);
        removeEventListener(__CONTEXT__, this.contextResultEventHandler);
        removeEventListener(__PRESENTATION_BOOTSTRAPPED__, this.presentationBootstarppedEventHandler);
        removeEventListener('message', this.messageEventHandler);
    }

    private readonly hideHeaderEntryEventHandler = (event: Event): void => {
        this.isEnabled = false;
        if (event && event?.stopImmediatePropagation && typeof event?.stopImmediatePropagation === typeof (() => {})) {
            event?.stopImmediatePropagation();
        }
    };

    private readonly showHeaderEntryEventHandler = (event: Event): void => {
        this.isEnabled = true;
        if (event && event?.stopImmediatePropagation && typeof event?.stopImmediatePropagation === typeof (() => {})) {
            event?.stopImmediatePropagation();
        }
    };

    private readonly presentationBootstarppedEventHandler = (messageEvent: CustomEventInit) => {
        if (messageEvent && messageEvent.detail && messageEvent.detail) {
            const searchHost: any = messageEvent.detail;
            this.searchContext = searchHost.searchContext;
            this.setState(this.searchContext);
            this.isEnabled = searchHost.searchContext.appContext.isEnabled || false;
        }
        if (this.isEnabled) {
            dispatchEvent(new CustomEvent(__ENTRY_POINT_ACTIVE__, { detail: { activeFrom: 'header' } }));
        }
    };

    private readonly contextResultEventHandler = (customEvent: CustomEventInit) => {
        if (customEvent && customEvent?.detail && customEvent?.detail?.appContext) {
            this.isEnabled = customEvent?.detail?.appContext.isEnabled || false;
            this.setState(customEvent?.detail);
        }
        if (this.isEnabled) {
            dispatchEvent(new CustomEvent(__ENTRY_POINT_ACTIVE__, { detail: { activeFrom: 'header' } }));
        }
    };

    private readonly messageEventHandler = (messageEvent: MessageEvent): void => {
        if (
            messageEvent &&
            messageEvent.data &&
            messageEvent.data.eventIdentifier &&
            typeof messageEvent.data.eventIdentifier === typeof '' &&
            messageEvent.data.eventIdentifier?.trim()
        ) {
            switch (messageEvent.data.eventIdentifier?.trim().toLowerCase()) {
                case 'gs:hideheaderentry':
                    this.hideHeaderEntryEventHandler(messageEvent);
                    break;
                case 'gs:showheaderentry':
                    this.showHeaderEntryEventHandler(messageEvent);
                    break;
                default:
                    break;
            }
        }
    };
}
