export class SmartRewardsConstants {
    public static readonly SmartRewardClientContentPath = 'Promo/PromoHub/SmartReward/SmartRewardClientContent';
    public static readonly Default = 'default';
    public static readonly FreeSpin = 'freespin';
    public static readonly Bonus = 'bonus';
    public static readonly BingoTicket = 'bingoticket';
    public static readonly Home = 'home';
    public static readonly Optin = 'optin';
    public static readonly SmartRewardClass = 'smart-rewards';
    public static readonly Available = 'available';
    public static readonly PreOptinWelcomeMessage = 'preoptinwelcomemessage';
    public static readonly PostOptinWelcomeMessage = 'postoptinwelcomemessage';
    public static readonly EarnedRewardWelcomeMessage = 'earnedrewardwelcomemessage';
    public static readonly Last7daysWelcomeMessage = 'last7dayswelcomemessage';
    public static readonly ChallengeTitle = 'challengetitle';
    public static readonly BingoRewards = 'bingo rewards';
    public static readonly SmartRewardInfoKey = 'Smart_Reward_Info_Section';
    public static readonly Bingo = 'bingo';
    public static readonly EDSWidget = 'EDSWidget';
    public static readonly all = 'all';
    public static readonly games = 'games';
    public static readonly Expand = 'expand';
    public static readonly Tnc = 'T&C - ';
    public static readonly Hide = 'hide';
    public static readonly GoToLogin = 'gotoLogin';
    public static readonly ErrorCode = 424;
    public static readonly HomePage = 'promo/offers';
    public static readonly GAMEVARIANT_OPEN_GAME = 'GAMEVARIANT_OPEN_GAME';
    public static readonly SmartReward = 'SMARTREWARDS';
    public static readonly OPEN_URL = 'OPEN_URL';

    //Tracking
    public static readonly WithoutLogin = 'without login';
    public static readonly WithoutOptin = 'without optin';
    public static readonly SmartRewardsHome = 'smart rewards home';
    public static readonly LogIn = 'login';
    public static readonly StickyBanner = 'sticky banner';
    public static readonly Toaster = 'toaster';
    public static readonly OptinSuccess = 'opt-in success';
    public static readonly MonthlyPrizeDraw = 'monthly prize draw';
    public static readonly FindAllEntriesHere = 'find all entries here';
    public static readonly SmartRewardMoreInfo = 'smart rewards more info';
    public static readonly WidgetSmartRewardMoreInfo = 'widget smart rewards more info';
    public static readonly WidgetSmartRewardChallenge = 'widget smart rewards challenge';
    public static readonly MonthlyPrizeDrawDetails = 'monthly prize draw details';
    public static readonly Week = 'week';
    public static readonly Filters = 'filters';
    public static readonly SubFilters = 'sub filters';
    public static readonly CloseX = 'close x';
    public static readonly DailyRewards = 'daily rewards';
    public static readonly ViewAll = 'view all';
    public static readonly PlayNow = 'play now';
    public static readonly MyRewards = 'my rewards';
    public static readonly Widget = 'widget';
    public static readonly BackToOffers = 'back to offers';
    public static readonly GoToSmartRewardsHome = 'go to smart rewards home';
    public static readonly PromoHub = 'promo hub';
    public static readonly SmartRewards = 'smart rewards';
    public static readonly Load = 'load';
    public static readonly Click = 'click';
    public static readonly DynamicPromotions = 'dynamic promotions';
    public static readonly CustomPromotions = 'custom promotions';
    public static readonly GoToReward = 'go to reward';
    public static readonly RewardDetails = 'reward details';
    public static readonly OfferDetails = 'offer details';
    public static readonly ActivateReward = 'activate reward';
    public static readonly DailyRewardsWidget = 'daily rewards Widget';
    public static readonly WidgetRewardDetails = 'widget reward details';
    public static readonly ContentView = 'contentView';
    public static readonly InactiveReward = 'inactive reward';
    public static readonly ViewDetails = 'view details';
}
