import { Injectable } from '@angular/core';

import { ApiService } from '@frontend/promo-utils';
import { Observable } from 'rxjs';

import { FreebetClientContent } from '../freebetModels/freebet-content-details';

@Injectable()
export class FreebetDetailsResourceService {
    freebetClientContent: FreebetClientContent;

    constructor(private apiService: ApiService) {}

    getContent(): Observable<any> {
        return this.apiService.get('phClientContent', {
            paths: ['Promo/PromoHub/DetailPagesContent/Common/Content', 'Promo/PromoHub/DetailPagesContent/Freebet/FreebetClientContent'],
        });
    }
}
