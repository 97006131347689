import { NgClass } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';

import { ModelDefault } from '@frontend/promo-homewidget';
import { PromoDetailPageConfiguration } from '@frontend/promo-utils';
import { DynamicHtmlDirective } from '@frontend/vanilla/core';
import { RxIf } from '@rx-angular/template/if';

import { SmartRewardsConstants } from '../../../constants/smart-rewards-constant';
import { ProgressBar } from '../../../models/progress-bar';
import { Reward, RewardContent, RewardMetaData } from '../../../models/reward';
import { EligibleGames, PCImageText } from '../../../models/smart-eds-widget-details';
import { SmartRewardsService } from '../../../services/smart-reward-service/smart-rewards.service';
import { OverlayDialogModal } from '../../../shared/dialog/OverlayDialogModal';
import { SmartRewardsPopupComponent } from '../../../shared/smart-rewards-popup/smart-rewards-popup.component';
import { SmartRewardTrackingHelperService } from '../../../shared/tracking/smart-reward-tracking-helper.service';

@Component({
    standalone: true,
    selector: 'ph-rewards-widget',
    templateUrl: './rewards-widget.component.html',
    styleUrl: './rewards-widget.component.scss',
    imports: [RxIf, NgClass, DynamicHtmlDirective],
    providers: [OverlayDialogModal],
})
export class RewardsWidgetComponent implements OnInit {
    @Input() reward: Reward;
    @Input() position: number;
    @Input() smartRewardClientContent: PCImageText;

    rewardContent: RewardContent;
    rewardMetaData: RewardMetaData;
    progressBar: ProgressBar;

    constructor(
        private smartRewardsService: SmartRewardsService,
        public detailPageClientConfig: PromoDetailPageConfiguration,
        private overlayDialogModal: OverlayDialogModal,
        private trackingHelperService: SmartRewardTrackingHelperService,
    ) {}

    ngOnInit(): void {
        this.rewardContent = this.reward?.rewardContent;
        this.rewardMetaData = this.reward?.rewardMetaData;
        this.progressBar = this.smartRewardsService.bindProgressBarData(this.reward);
    }

    openRewardDetailPopup(ctaName: string) {
        if (this.reward?.rewardMetaData?.isAvailableReward) {
            this.smartRewardsService.smartRewardEligibleGames$.next({} as EligibleGames);
            this.smartRewardsService.getSmartRewardsEligibleGames(
                this.rewardMetaData?.rewardId,
                this.rewardMetaData?.isAwarded,
                this.rewardMetaData?.rewardType,
            );
            const data = {
                reward: this.reward,
                contentParameters: this.smartRewardClientContent?.parameters,
                collapseTermsAndConditions: this.detailPageClientConfig?.collapseTermsAndConditions,
                progressBar: this.progressBar,
            };
            this.overlayDialogModal.openDialogModal(SmartRewardsPopupComponent, data);
            this.trackingHelperService.trackOnCtaClick(
                ctaName?.toLowerCase(),
                SmartRewardsConstants.MyRewards,
                this.rewardMetaData?.webTrackingPositionEvent,
                ModelDefault.NA,
                this.position?.toString(),
            );
        }
    }
}
