import { Injectable } from '@angular/core';

import { ApiService } from '@frontend/promo-utils';
import { Observable } from 'rxjs';

import { OddBoostClientContent } from '../odd-boost-models/odd-boost-content-details';

@Injectable()
export class OddBoostDetailsResourceService {
    oddBoostClientContent: OddBoostClientContent;

    constructor(private apiService: ApiService) {}

    getContent(): Observable<any> {
        return this.apiService.get('phClientContent', {
            paths: ['Promo/PromoHub/DetailPagesContent/Common/Content', 'Promo/PromoHub/DetailPagesContent/OddBoost/OddBoostClientContent'],
        });
    }
}
