<div *ngIf="promoClientConfigLoaded">
    <ng-content select="offers-carousel"></ng-content>

    <offers-by-product
        [products]="products"
        [showTeaserSkeleton]="showTeaserSkeleton"
        [offersProductStyle]="offersProductStyle"
        [isOffersLoadedFromDiffApp]="shouldLoadPromoClientConfig">
        <ng-content select="ph-promo-faqs"></ng-content>
    </offers-by-product>
</div>
