@if (showAllEligibleGameText) {
    <div class="smart-reward-store__eligiblegames">
        @if (eligibleGames?.gameLaunchNames?.length > 0 || eligibleGames?.rooms?.length > 0) {
            <div class="vn-carousel eligibleGamesCarousel">
                <div class="ph-eligible-games-title line-clamp-2">{{ eligibleGamesContent?.title }}</div>
                @if (arrows) {
                    <i class="theme-right carousel-next" role="button" aria-label="Next slide"></i>
                }
                @if (arrows) {
                    <i class="theme-left carousel-previous" role="button" aria-label="Previous slide"></i>
                }
                <div #swiperContainer class="swiper">
                    <div class="swiper-wrapper">
                        @if (!eligibleGames?.rooms) {
                            @for (gameLaunchName of eligibleGames.gameLaunchNames; track gameLaunchName; let i = $index) {
                                <div class="swiper-slide" grayoutStyle>
                                    <div (click)="launchGame(eligibleGames?.gameLaunchBaseUrl?.url, gameLaunchName, i, false)">
                                        <div [ngClass]="{ disabled: !isAwarded }">
                                            <ng-container>
                                                <a class="eg-item">
                                                    @if (eligibleGames.gameImageBaseUrl) {
                                                        <img
                                                            class="eg-image"
                                                            [attr.src]="eligibleGames.gameImageBaseUrl | GamesFormat: gameLaunchName" />
                                                    }
                                                    @if (isAwarded) {
                                                        <div class="eg-btn btn btn-secondary btn-sm">
                                                            {{ eligibleGames?.gameLaunchBaseUrl?.text }}
                                                        </div>
                                                    }
                                                </a>
                                            </ng-container>
                                        </div>
                                    </div>
                                </div>
                            }
                        } @else {
                            @for (room of eligibleGames?.rooms; track room; let i = $index) {
                                <div class="swiper-slide" grayoutStyle>
                                    <div (click)="launchGame(eligibleGames?.gameLaunchBaseUrl?.url, room.roomId, i, true)">
                                        <div [ngClass]="{ disabled: !isAwarded }">
                                            <ng-container>
                                                <a class="eg-item">
                                                    @if (room.mobileImage?.src) {
                                                        <img class="eg-image" [attr.src]="room.mobileImage?.src" />
                                                    }
                                                    @if (isAwarded) {
                                                        <div class="eg-btn btn btn-secondary btn-sm">
                                                            {{ contentParameters?.playnow }}
                                                        </div>
                                                    }
                                                </a>
                                            </ng-container>
                                        </div>
                                    </div>
                                </div>
                            }
                        }
                    </div>
                </div>
            </div>
        } @else {
            <div>
                <div class="ph-eligible-games-title">{{ eligibleGamesContent?.allEligibleGamesTitle }}</div>
                <div class="ph-eligible-games-title">{{ eligibleGamesContent?.allEligibleGamesDescription }}</div>

                <div class="smart-reward-store__allgamestext d-flex justify-content-center pt-2">
                    @if (
                        rewardMetaData?.isAwarded &&
                        rewardContent?.playNowCta?.text &&
                        (!eligibleGames?.gameLaunchNames || eligibleGames?.gameLaunchNames?.length == 0)
                    ) {
                        <a
                            class="btn-lg d-inline-flex align-items-center justify-content-center"
                            [href]="rewardContent.playNowCta?.url"
                            [ngClass]="rewardContent.playNowCta?.attributes?.class ? rewardContent.playNowCta?.attributes?.class : 'btn btn-dark'"
                            (click)="onPlayNowCtaClick(rewardContent?.playNowCta); $event.preventDefault()"
                            >{{ rewardContent.playNowCta?.text }}
                        </a>
                    }
                </div>
            </div>
        }
    </div>
}
