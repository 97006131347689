import { NgClass } from '@angular/common';
import { AfterContentChecked, Component, ElementRef, Input, OnInit } from '@angular/core';

import { DeviceService } from '@frontend/vanilla/core';
import { RxFor } from '@rx-angular/template/for';
import { RxIf } from '@rx-angular/template/if';
import { orderBy } from 'lodash-es';

import { SmartRewardsConstants } from '../../constants/smart-rewards-constant';
import { EdsEventContent } from '../../models/base-content';
import { PCImageText } from '../../models/smart-eds-widget-details';
import { SmartRewardContent } from '../../models/smart-rewards-details.model';
import { ChallengesWidgetComponent } from '../../shared/challenges/challenges-widget/challenges-widget.component';
import { SmartRewardTrackingHelperService } from '../../shared/tracking/smart-reward-tracking-helper.service';
import { WeeklyGamesComponent } from '../../shared/weekly-games/weekly-games.component';

@Component({
    standalone: true,
    selector: 'ph-product-tab-view',
    templateUrl: './product-tab-view.component.html',
    styleUrl: './product-tab-view.component.scss',
    imports: [NgClass, RxFor, RxIf, ChallengesWidgetComponent, WeeklyGamesComponent],
})
export class ProductTabViewComponent implements OnInit, AfterContentChecked {
    @Input() smartRewardContent: SmartRewardContent;
    @Input() smartRewardClientContent: PCImageText;

    isDesktop: boolean;
    currentTab: string;
    edsEventContent: EdsEventContent;

    constructor(
        public deviceService: DeviceService,
        private trackingHelperService: SmartRewardTrackingHelperService,
        private elementRef: ElementRef,
    ) {}

    ngOnInit() {
        this.isDesktop = !this.deviceService.isMobilePhone;
        if (this.smartRewardContent?.edsEventsData?.length > 0 && !this.isDesktop) {
            this.smartRewardContent.edsEventsData = orderBy(this.smartRewardContent.edsEventsData, ['isSuccessEvent'], ['asc']);
            this.edsEventContent = this.smartRewardContent.edsEventsData[0] ?? ({} as EdsEventContent);
            this.currentTab = this.edsEventContent?.eventId;
        }
    }

    tabClick(data: EdsEventContent) {
        if (data) {
            this.trackingHelperService.trackOnCtaClick(data?.product, SmartRewardsConstants.DailyRewards, SmartRewardsConstants.Filters);
            this.edsEventContent = data;
            this.currentTab = data.eventId;
        }
    }

    ngAfterContentChecked() {
        if (this.isDesktop && this.elementRef?.nativeElement != null) {
            const elements = this.elementRef.nativeElement.querySelectorAll('.card');
            if (elements?.length > 0) {
                elements?.forEach((ele: HTMLElement) => {
                    if (ele != null) {
                        ele.style.setProperty('--card-height', ele.offsetHeight + 'px');
                    }
                });
            }
        }
    }
}
