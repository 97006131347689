import { OverlayRef } from '@angular/cdk/overlay';
import { AfterViewInit, Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';

import { NativeAppService, ProductHomepagesConfig } from '@frontend/vanilla/core';
import { IFrameComponent } from '@frontend/vanilla/shared/browser';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { GENERIC_MODAL_CONTENT } from '../generic-modal-content';

@Component({
    selector: 'bet-station-offer-overlay',
    templateUrl: './bet-station-offer-overlay.component.html',
})
export class BetStationOfferOverlayComponent implements OnInit, AfterViewInit, OnDestroy {
    @ViewChild(IFrameComponent) iframe: IFrameComponent;
    private readonly destroy$ = new Subject<boolean>();
    url: string;
    origin: string;

    constructor(
        private overlayRef: OverlayRef,
        @Inject(GENERIC_MODAL_CONTENT) public data: any,
        private nativeAppService: NativeAppService,
        private productHomePagesConfig: ProductHomepagesConfig,
    ) {}

    ngAfterViewInit(): void {
        const element = this.iframe?.iframe?.nativeElement;
        if (element && element.style) {
            element.width = '1080px';
            element.height = '968px';
            element.id = 'betstation-offers-iframe';
        }
        this.iframe?.events?.pipe(takeUntil(this.destroy$)).subscribe((data: any) => {
            const action = (data.action || '').toUpperCase();
            if (action === 'CCBPRINT') {
                this.nativeAppService.sendToNative({
                    eventName: 'PRINT_FREE_TO_PLAY_RECEIPT',
                    parameters: data.parameters,
                });
            }
        });
    }

    ngOnInit(): void {
        this.origin = this.productHomePagesConfig?.promo;
        this.url = this.data?.url;
    }

    closeModel() {
        if (this.overlayRef) this.overlayRef.detach();
    }

    ngOnDestroy() {
        this.closeModel();
        this.destroy$.next(true);
        this.destroy$.complete();
    }
}
