import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';

import { Observable } from 'rxjs';

import { BonusDetailsResourceService } from './bonus-details-resource.service';

@Injectable()
export class BonusDetailsResolver implements Resolve<any> {
    constructor(private resource: BonusDetailsResourceService) {}

    resolve(): Observable<any> {
        return this.resource.getContent();
    }
}
