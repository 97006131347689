import { Injectable } from '@angular/core';

import { OfferContentConfiguration } from '@frontend/promo-homewidget';
import { ContentItem, ContentService } from '@frontend/vanilla/core';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class PromoFaqsService {
    constructor(
        private contentService: ContentService,
        private offerContentConfiguration: OfferContentConfiguration,
    ) {}

    getContent(): Observable<ContentItem> {
        return this.contentService.getJsonFiltered<ContentItem>(this.offerContentConfiguration?.promoFaqsSitecoreLocation);
    }
}
