import { Injectable } from '@angular/core';

import { ClientConfigProductName, LazyClientConfig, LazyClientConfigBase, LazyClientConfigService } from '@frontend/vanilla/core';

@LazyClientConfig({
    product: ClientConfigProductName.PROMO,
    key: 'prDetailPageClient', // The key for this specific config
})
@Injectable({
    providedIn: 'root',
    deps: [LazyClientConfigService],
    useFactory: PromoDetailPageConfigFactory,
})
export class PromoDetailPageConfiguration extends LazyClientConfigBase {
    collapseTermsAndConditions: boolean;
    showBack: boolean;
    showClose: boolean;
    enableRedesignedMoreInfo: boolean;
    enableRedesignHeaderIcon: { [key: string]: boolean };
}

export function PromoDetailPageConfigFactory(service: LazyClientConfigService) {
    return service.get(PromoDetailPageConfiguration);
}
