<div *ngIf="!offerContentConfiguration?.enableSkeletonV2; else loadSkeltonV2" class="bg-home-skeleton sk-promohub-container">
    <div *ngIf="showTeaserSkeleton" class="sk-banner"></div>
    <div *ngIf="showTitleSkeleton" class="sk-title-wrapper">
        <div class="sk-title"></div>
    </div>
    <div class="sk-tiles-wrapper">
        <div *ngFor="let number of offersSkeletonArr" class="sk-promohub-tile">
            <div class="sk-tile-image"></div>
            <div class="sk-title" style="width: 100%"></div>
            <div class="sk-title" style="width: 80%"></div>
        </div>
    </div>
</div>

<ng-template #loadSkeltonV2>
    <div class="bg-home-skeleton sk-promohub-container" [ngClass]="{ 'bg-dark-skeleton': offerContentConfiguration?.enableDarkSkeleton }">
        <div *ngFor="let number of offersSkeletonV2Arr">
            <div *ngIf="showTitleSkeleton" class="sk-title-wrapper">
                <div class="sk-circle"></div>
                <div class="sk-info-wrap">
                    <div class="sk-title"></div>
                    <div class="sk-title"></div>
                </div>
            </div>
            <div class="sk-tiles-wrapper">
                <div *ngFor="let number of [0, 1, 2, 3]" class="sk-promohub-tile">
                    <div class="sk-tile-container">
                        <div class="sk-tile-image"></div>
                        <div class="sk-tile-info">
                            <div class="sk-title"></div>
                            <div class="sk-title"></div>
                            <div class="sk-title"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>
