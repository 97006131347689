import { Injectable } from '@angular/core';

import { OffersWebTrackingConstants } from './offers-web-analytic-constants';
import { OffersWebAnalyticModel } from './offers-web-analytic.model';
import { TrackingModelService } from './tracking-model-service';

@Injectable({
    providedIn: 'root',
})
export class OptInTrackService {
    webAnalyticModel: OffersWebAnalyticModel = new OffersWebAnalyticModel();

    constructor(private trackingService: TrackingModelService) {}

    private createTrackingEvent(webAnalyticModel: OffersWebAnalyticModel, actionEvent: string): any {
        const { product, position, promoId, campaignId, messageType, contentPosition, promoOfferName, promotionType, labelEvent } = webAnalyticModel;
        return {
            LabelEvent: labelEvent,
            ActionEvent: actionEvent,
            LocationEvent: position,
            EventDetails: promoId
                ? promoId
                : actionEvent === OffersWebTrackingConstants.SuccessOptIn
                  ? OffersWebTrackingConstants.YouHaveOptedIn
                  : OffersWebTrackingConstants.PromoOptinFail,
            campaignId,
            messageType,
            PositionEvent: contentPosition,
            contentPosition,
            Product: product,
            siteSubSection: OffersWebTrackingConstants.PromoOffersPage,
            PromoOfferName: promoOfferName,
            promotionType: promotionType,
        };
    }

    trackSuccessMessage(webAnalyticModel: OffersWebAnalyticModel): void {
        const trackingEvent = this.createTrackingEvent(webAnalyticModel, OffersWebTrackingConstants.SuccessOptIn);
        this.trackingService.submitTracking(trackingEvent);
    }

    trackErrorMessage(webAnalyticModel: OffersWebAnalyticModel): void {
        const trackingEvent = this.createTrackingEvent(webAnalyticModel, OffersWebTrackingConstants.ErrorOptIn);
        this.trackingService.submitTracking(trackingEvent);
    }
}
