import { Injectable } from '@angular/core';

import { ClientConfigProductName, LazyClientConfig, LazyClientConfigBase, LazyClientConfigService } from '@frontend/vanilla/core';

@LazyClientConfig({
    product: ClientConfigProductName.PROMO,
    key: 'prPromoLobby', // The key for this specific config
})
@Injectable({
    providedIn: 'root',
    deps: [LazyClientConfigService],
    useFactory: lobbyConfigFactory,
})
export class LobbyClientConfiguration extends LazyClientConfigBase {
    items: any[];
}

// eslint:disable-next-line:typedef
export function lobbyConfigFactory(service: LazyClientConfigService) {
    return service.get(LobbyClientConfiguration);
}
