<ng-container *ngIf="isTileContentV2Enabled">
    <div class="offers-teaser-v2-img-cont">
        <img [src]="offer.offerContent?.teaserImage ? offer.offerContent.teaserImage.src : ''" />
        <offer-teaser-status [offer]="offer" [isUserAuthenticated]="isUserAuthenticated" [offerStateClass]="offerStateClass"></offer-teaser-status>
        <div *ngIf="!isCashierDeposit && !offerContentConfiguration?.isBetMgmTileRedesignEnabled">
            <progress-bar
                [offer]="offer"
                *ngIf="showProgressBar"
                [ngClass]="{ 'ph-content-opacify': isParentOnHover }"
                [isTileContentV2Enabled]="isTileContentV2Enabled">
            </progress-bar>
        </div>
    </div>
</ng-container>

<ng-container *ngIf="!isTileContentV2Enabled">
    <div class="pc-t-img-cont" *ngIf="offer.offerContent">
        <img
            class="icon bonus-card-icon"
            *ngIf="offer.offerContent.offerTypeIcon"
            [attr.src]="offer.offerContent.offerTypeIcon.src"
            [attr.alt]="offer.offerContent.offerTypeIcon.alt"
            [width]="offer.offerContent.offerTypeIcon.width"
            [height]="offer.offerContent.offerTypeIcon.height" />
        <div
            [ngClass]="['status-pills', offerStateClass]"
            *ngIf="offer.offerContent?.offerStateString && offer.offerMetadata.isOfferActive && isUserAuthenticated">
            {{ offer.offerContent?.offerStateString }}
        </div>

        <a class="pc-img-a" [attr.href]="moreInfo?.url" target="{{ !!moreInfo?.attributes?.target ? moreInfo?.attributes?.target : '' }}">
            <img
                class="pc-img"
                *ngIf="offer.offerContent.teaserImage"
                [attr.src]="offer.offerContent.teaserImage.src"
                [attr.alt]="offer.offerContent.teaserImage.alt"
                [width]="offer.offerContent.teaserImage.width"
                [height]="offer.offerContent.teaserImage.height"
                (click)="navigateToOfferMoreInfo(moreInfo); $event.stopPropagation(); $event.preventDefault()" />
        </a>
        <div *ngIf="showProgressBar && offer.offerMetadata.offerState == 'PAUSED'" class="pause-icon-container">
            <div class="pause-btn">
                <div class="pause-btn-strokes"></div>
                <div class="pause-btn-strokes right"></div>
            </div>
        </div>

        <progress-bar
            [offer]="offer"
            *ngIf="showProgressBar"
            [ngClass]="{ 'ph-content-opacify': isParentOnHover }"
            [isTileContentV2Enabled]="isTileContentV2Enabled">
        </progress-bar>

        <ng-container *ngrxLet="_isDesktop$ as isDesktop">
            <div *ngIf="isParentOnHover && isDesktop" [@fadeInOut]>
                <div class="banner-tile-hover-content" *ngIf="offer.offerMetadata?.isSpecialOffer || offer.offerMetadata?.isWelcomeOffer">
                    <a
                        *ngIf="!!offer.offerContent.primaryCta?.url"
                        [ngClass]="
                            !!offer.offerContent.primaryCta?.attributes?.class ? offer.offerContent.primaryCta?.attributes?.class : 'btn btn-primary'
                        "
                        target="{{ !!offer.offerContent.primaryCta?.attributes?.target ? offer.offerContent.primaryCta?.attributes?.target : '' }}"
                        [attr.href]="offer.offerContent.primaryCta?.url"
                        >{{ offer.offerContent.primaryCta?.text }}</a
                    >

                    <a
                        [ngClass]="!!moreInfo?.attributes?.class ? moreInfo?.attributes?.class : 'btn btn-dark'"
                        *ngIf="!!moreInfo?.url"
                        target="{{ !!moreInfo?.attributes?.target ? moreInfo?.attributes?.target : '' }}"
                        [attr.href]="moreInfo?.url"
                        >{{ moreInfo?.text }}</a
                    >
                </div>

                <div class="banner-tile-hover-content" *ngIf="!offer.offerMetadata?.isSpecialOffer && !offer.offerMetadata?.isWelcomeOffer">
                    <ng-container *ngFor="let cta of ctaLinks | keyvalue">
                        <a
                            [attr.href]="cta?.value?.url"
                            (click)="navigateToOfferMoreInfo(cta?.value); $event.stopPropagation(); $event.preventDefault()"
                            [ngClass]="!!cta?.value?.attributes?.class ? cta?.value?.attributes?.class : 'btn btn-dark'"
                            target="{{ !!cta?.value?.attributes?.target ? cta?.value?.attributes?.target : '' }}"
                            ><span>{{ cta?.value?.text }}</span></a
                        >
                    </ng-container>
                </div>
            </div>
        </ng-container>
    </div>
</ng-container>
