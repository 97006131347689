<div class="smart-rewards__header-ctrl--msg">
    @if (srSuccessEdsEventsMsgs?.length > 1) {
        <div #swiperContainer class="swiper d-flex">
            <div class="swiper-wrapper">
                @for (msg of srSuccessEdsEventsMsgs; track msg) {
                    <div class="swiper-slide">
                        <div class="welcome-msg d-flex align-items-center">
                            @if (msg?.bannerIcon?.src) {
                                <img [src]="msg?.bannerIcon?.src" [alt]="msg?.bannerIcon?.alt" class="mr-2 welcome-msg__icon" />
                            }

                            <div class="pr-2">
                                <div class="smart-rewards__header-ctrl--msg-title">{{ msg?.msgTitle }}</div>
                                <div class="smart-rewards__header-ctrl--msg-dec">{{ msg?.msgDescription }}</div>
                            </div>
                        </div>
                    </div>
                }
            </div>
            <div class="welcome-msg__pagination-dots">
                <div class="welcome-msg__pagination-dots--count">{{ swiper?.realIndex + 1 + '/' + swiper?.slides?.length }}</div>
                <span class="swiper-pagination"></span>
            </div>
        </div>
    } @else {
        @for (msg of srSuccessEdsEventsMsgs; track msg) {
            <div class="welcome-msg d-flex align-items-center">
                @if (msg?.bannerIcon?.src) {
                    <img [src]="msg?.bannerIcon?.src" [alt]="msg?.bannerIcon?.alt" class="mr-2 welcome-msg__icon" />
                }

                <div class="pr-2">
                    <div class="smart-rewards__header-ctrl--msg-title">{{ msg?.msgTitle }}</div>
                    <div class="smart-rewards__header-ctrl--msg-dec">{{ msg?.msgDescription }}</div>
                </div>
            </div>
        }
    }
</div>
