@if (!showRgStatus) {
    @if (edsSmartWidgetDetails$ | push; as vm) {
        @if (showEdsWidget) {
            <div class="smart-rewards">
                <img
                    [attr.src]="backGroundImage?.src"
                    [attr.alt]="backGroundImage?.alt"
                    [ngClass]="vm?.smartEdsWidgetMetaData?.isOptedIn && isUserAuthenticated ? 'smart-rewards__bgnone' : 'smart-rewards__bg'"
                    fetchpriority="high" />
                <div [ngClass]="vm?.smartEdsWidgetMetaData?.isOptedIn ? 'smart-container__postoptin' : ''" class="smart-container">
                    @if (vm?.smartEdsWidgetMetaData?.isOptedIn && isUserAuthenticated) {
                        <ph-post-optin-widget
                            [smartEdsWidgetContent]="vm?.smartEdsWidgetContent"
                            [smartEdsWidgetMetaData]="vm?.smartEdsWidgetMetaData"
                            (openDialog)="openDialog(vm?.smartEdsWidgetContent?.howItWorksContent)">
                        </ph-post-optin-widget>
                    } @else {
                        <ph-pre-optin-widget
                            [smartEdsWidgetContent]="vm?.smartEdsWidgetContent"
                            [smartEdsWidgetMetaData]="vm?.smartEdsWidgetMetaData"
                            [showOptinCta]="showOptinCta"
                            (optinClick)="optinClick()"
                            (openDialog)="openDialog(vm?.smartEdsWidgetContent?.howItWorksContent)">
                        </ph-pre-optin-widget>
                    }
                </div>
                <div
                    class="smart-container__tnc d-flex align-items-center justify-content-center"
                    [vnDynamicHtml]="vm?.smartEdsWidgetContent?.keyTermsAndConditions"></div>
            </div>

            <ph-smart-reward-toastr></ph-smart-reward-toastr>
        }
    }
}
