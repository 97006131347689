import { Injectable } from '@angular/core';

import { ContentService } from '@frontend/vanilla/core';

@Injectable()
export class CommonDetailsResourceService {
    private _message: any;
    private _showActivateNow: boolean;

    constructor(private contentService: ContentService) {}

    setMessage(message: String) {
        this._message = message;
    }

    getMessage() {
        const toastrMessage = this._message;
        this._message = '';
        return toastrMessage;
    }

    setActivateNow(showActivateNow: boolean) {
        this._showActivateNow = showActivateNow;
    }

    getShowActivateNow() {
        return this._showActivateNow;
    }

    getContent() {
        return this.contentService.getJson('Promo/PromoHub/DetailPagesContent/Common/Content');
    }
}
