import { NgClass } from '@angular/common';
import { AfterContentChecked, Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';

import { CarouselDefaultConfig, IframeCommunicationDirective } from '@frontend/promo-utils';
import { ContentLink, DynamicHtmlDirective, NavigationService } from '@frontend/vanilla/core';
import { RxFor } from '@rx-angular/template/for';
import { RxIf } from '@rx-angular/template/if';
import { Subject, takeUntil } from 'rxjs';
import Swiper from 'swiper';
import { Navigation } from 'swiper/modules';
import { SwiperOptions } from 'swiper/types';

import { SmartRewardsConstants } from '../../constants/smart-rewards-constant';
import { RewardContent, RewardMetaData } from '../../models/reward';
import { EligibleGames } from '../../models/smart-eds-widget-details';
import { SmartRewardsService } from '../../services/smart-reward-service/smart-rewards.service';
import { SmartRewardTrackingHelperService } from '../tracking/smart-reward-tracking-helper.service';
import { GamesFormatPipe } from './games-format.pipe';

@Component({
    standalone: true,
    imports: [NgClass, RxFor, RxIf, DynamicHtmlDirective, GamesFormatPipe],
    providers: [GamesFormatPipe],
    selector: 'ph-smart-reward-eligible-games',
    styleUrl: './smart-reward-eligible-games.component.scss',
    templateUrl: 'smart-reward-eligible-games.component.html',
})
export class SmartRewardEligibleGamesComponent implements OnInit, OnDestroy, AfterContentChecked, OnChanges {
    @Input() contentParameters: { [key: string]: string };
    @Input() isAwarded: boolean;
    @Input() isEdsWidget: boolean = false;
    @Input() rewardMetaData: RewardMetaData;
    @Input() rewardContent: RewardContent;
    @Output() onGameLaunch = new EventEmitter<boolean>();
    @ViewChild('swiperContainer') swiperRef: ElementRef;
    public config: SwiperOptions;
    public arrows: boolean = false;
    eligibleGamesContent: any = {};
    swiper: Swiper;
    isSwiperInit: any = false;
    eligibleGames: EligibleGames;
    showAllEligibleGameText: boolean = false;
    iframeComm: IframeCommunicationDirective;
    private readonly destroy$ = new Subject<void>();

    constructor(
        private navigationService: NavigationService,
        private formatEligibleGames: GamesFormatPipe,
        private carouselDefaultConfig: CarouselDefaultConfig,
        private trackingHelperService: SmartRewardTrackingHelperService,
        private smartRewardsService: SmartRewardsService,
    ) {}

    ngOnInit() {
        const allSelectedGamesText = SmartRewardsConstants.all + this.rewardMetaData?.product + SmartRewardsConstants.games;
        this.eligibleGamesContent = {
            title: this.contentParameters?.EligibleGamesTitle,
            allEligibleGamesTitle: this.contentParameters?.alleligiblegamesdescription,
            allEligibleGamesDescription: this.contentParameters != null ? this.contentParameters[allSelectedGamesText] : '',
        };
        this.iframeComm = this.smartRewardsService.getIframeDetails();
        this.config = Object.assign(
            {
                modules: [Navigation],
                slidesPerView: 3.2,
                slidesPerGroup: 3,
                centerInsufficientSlides: true,
                navigation: { nextEl: '.eligibleGamesCarousel > .carousel-next', prevEl: '.eligibleGamesCarousel > .carousel-previous' },
            },
            this.carouselDefaultConfig.defaultConfig,
        );

        this.smartRewardsService.smartRewardEligibleGames$.pipe(takeUntil(this.destroy$)).subscribe((res: any) => {
            if (res != null && Object.keys(res)?.length > 0) {
                this.eligibleGames = res;
                this.showAllEligibleGameText = true;
            }
        });
    }

    onPlayNowCtaClick(cta: ContentLink) {
        if (cta?.url) {
            if (this.iframeComm?.isGameClient)
                this.iframeComm.sendMessageToParentWindow(SmartRewardsConstants.OPEN_URL, {
                    url: cta.url,
                    action: SmartRewardsConstants.SmartReward,
                });
            else {
                this.navigationService.goTo(cta.url);
            }
        }
    }

    ngAfterContentChecked() {
        if (this.swiperRef?.nativeElement && !this.isSwiperInit) {
            this.swiper = new Swiper(this.swiperRef?.nativeElement, this.config);
            this.isSwiperInit = true;
        }
    }

    ngOnChanges() {
        this.swiper?.slideTo(0);
    }

    launchGame(gameLaunchUrl: string, gameLaunchName: any, position: any, isBingoGame: boolean) {
        if (this.isAwarded) {
            const locationEvent = this.isEdsWidget ? SmartRewardsConstants.WidgetRewardDetails : SmartRewardsConstants.RewardDetails;
            if (isBingoGame) {
                gameLaunchUrl = gameLaunchUrl.replace('{roomId}', gameLaunchName);
            } else {
                gameLaunchUrl = this.formatEligibleGames.transform(gameLaunchUrl, gameLaunchName);
                gameLaunchUrl = gameLaunchUrl?.concat(
                    '&clickTimeStamp=',
                    new Date().getTime().toString(),
                    '&launchSource=PROMOHUB',
                    '&returnurl=',
                    this.navigationService.location.absUrl(),
                    '&gameLaunchedLocation=promo hub:smartrewards',
                    '&gameLaunchCategory=',
                    this.rewardMetaData?.rewardType,
                    '&specialproduct=',
                    this.rewardMetaData?.rewardType,
                    '&gamePosition=',
                    position,
                    '&recommendationType=casino',
                );
            }
            this.trackingHelperService.trackOnCtaClick(
                SmartRewardsConstants.PlayNow,
                locationEvent,
                this.rewardMetaData?.webTrackingPositionEvent,
                gameLaunchUrl,
                position?.toString(),
            );
            if (this.isEdsWidget) {
                this.onGameLaunch.emit(true);
            }
            if (this.iframeComm?.isGameClient) {
                if (isBingoGame) {
                    this.iframeComm.sendMessageToParentWindow(SmartRewardsConstants.OPEN_URL, {
                        url: gameLaunchUrl,
                        action: SmartRewardsConstants.SmartReward,
                    });
                } else {
                    this.iframeComm.sendMessageToParentWindow(SmartRewardsConstants.GAMEVARIANT_OPEN_GAME, {
                        gameVariantName: gameLaunchName,
                        action: SmartRewardsConstants.SmartReward,
                    });
                }
            } else {
                this.navigationService.goTo(gameLaunchUrl);
            }
        }
    }

    ngOnDestroy() {
        if (this.swiper) {
            this.swiper?.destroy();
        }
        this.destroy$.next();
        this.destroy$.complete();
    }
}
