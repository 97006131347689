import { Injectable } from '@angular/core';

import { ApiService } from '@frontend/promo-utils';
import { Observable } from 'rxjs';

import { RiskFreeBetClientContent } from '../risk-free-bet-models/risk-free-bet-content-details';

@Injectable()
export class RiskFreeBetDetailsResourceService {
    oddBoostClientContent: RiskFreeBetClientContent;

    constructor(private apiService: ApiService) {}

    getContent(): Observable<any> {
        return this.apiService.get('phClientContent', {
            paths: ['Promo/PromoHub/DetailPagesContent/Common/Content', 'Promo/PromoHub/DetailPagesContent/RiskFreeBet/RiskFreeBetClientContent'],
        });
    }
}
