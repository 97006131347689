@if (howToContent?.header) {
    <div class="smart-benifits-info">
        <div>
            @if (!isOverlay) {
                <div class="smart-benifits-info__title">{{ howToContent?.header }}</div>
            }
            <div class="smart-benifits-info__wrapper--mobile">
                <div class="d-flex align-items-center justify-content-between">
                    <div class="smart-benifits-info__subtitle">{{ howToContent?.benefitsIncludeText }}</div>
                    <div class="smart-swiper-arrows">
                        @if (arrows && howToContent?.benefitsIncludeDesc?.length > 4) {
                            <i
                                class="theme-left smart-info carousel-previous"
                                role="button"
                                aria-label="Previous slide"
                                (click)="onSlidePrev()"
                                [ngClass]="swiper?.isBeginning ? 'swiper-button-disabled' : ''"></i>
                            <i
                                class="theme-right smart-info carousel-next"
                                role="button"
                                aria-label="Next slide"
                                (click)="onSlideNext()"
                                [ngClass]="swiper?.isEnd ? 'swiper-button-disabled' : ''"></i>
                        }
                    </div>
                </div>
                <div #swiperContainer class="swiper">
                    <div class="swiper-wrapper">
                        @for (step of howToContent?.benefitsIncludeDesc; track step) {
                            <div class="swiper-slide smart-benifits-info__wrapper">
                                <img class="smart-benifits-info__wrapper--img" [src]="step?.image" [alt]="'benifits_include'" />
                                <div class="smart-benifits-info__wrapper--text">{{ step?.text }}</div>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>
        <div class="smart-benifits-info__aboutdata" [vnDynamicHtml]="howToContent?.howItWorksDesc"></div>
        @if (isEdsWidget && howToContent?.goToSmartRewardCta?.text) {
            <a
                [attr.href]="howToContent?.goToSmartRewardCta?.url"
                (click)="trackOnClick(howToContent?.goToSmartRewardCta?.url)"
                [ngClass]="
                    howToContent?.goToSmartRewardCta?.attributes?.class ? howToContent?.goToSmartRewardCta?.attributes?.class : 'btn btn-secondary'
                ">
                {{ howToContent?.goToSmartRewardCta?.text }}
            </a>
        }
    </div>
}
