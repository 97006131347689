import { ContentChild, Directive, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output } from '@angular/core';

import { Subscription, timer } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';

@Directive({
    selector: '[countDownTimer]',
    standalone: true,
})
export class PromoCountDownTimerDirective implements OnInit, OnDestroy, OnChanges {
    private subscription: Subscription;

    private _endDate: Date;

    @Input('countDownTimer') set endDate(value: Date | string) {
        this._endDate = this.parseDate(value);
    }

    get endDate(): Date | string {
        return this._endDate;
    }

    @Output() timeOut: EventEmitter<boolean> = new EventEmitter<boolean>();
    @ContentChild('hours') hoursElement: ElementRef;
    @ContentChild('minutes') minutesElemnet: ElementRef;
    @ContentChild('seconds') secondsElement: ElementRef;

    ngOnInit() {
        const timeDifference = this.endDate instanceof Date ? this.endDate.getTime() - new Date().getTime() : null;
        if (timeDifference !== null && timeDifference <= 24 * 60 * 60 * 1000) {
            this.startTimer();
        }
    }

    ngOnChanges() {
        if (this.subscription) this.subscription.unsubscribe();
        this.startTimer();
    }

    startTimer(): void {
        this.subscription = timer(0, 1000)
            .pipe(
                map(() => this.calculateRemainingTime()),
                map((t: any) => {
                    if (t && t[0] >= 0) {
                        this.hoursElement.nativeElement.innerHTML = t[0].toString().padStart(2, '0') + ':';
                        this.minutesElemnet.nativeElement.innerHTML = t[1].toString().padStart(2, '0') + ':';
                        this.secondsElement.nativeElement.innerHTML = t[2].toString().padStart(2, '0');
                    }
                }),
                takeUntil(this.timeOut),
            )
            .subscribe(() => {
                if (this.endDate && this.endDate instanceof Date && this.endDate < new Date()) {
                    this.timeOut.emit(true);
                }
            });
    }

    calculateRemainingTime(): number[] | null {
        if (this.endDate instanceof Date) {
            let seconds = Math.floor((this.endDate.getTime() - new Date().getTime()) / 1000);
            const minutes = Math.floor(seconds / 60) % 60;
            const hours = Math.floor(seconds / 3600) % 24;
            seconds = seconds % 60;

            return [hours, minutes, seconds];
        }
        return null;
    }

    ngOnDestroy() {
        if (this.subscription) this.subscription.unsubscribe();
    }

    private parseDate(value: Date | string): Date {
        return value instanceof Date ? value : new Date(value);
    }
}
