@if (!isDesktop) {
    <div class="form-control-tabs-segmented-v2-dark three-tabs smart-widget__tab">
        @for (eventData of smartRewardContent?.edsEventsData; track eventData; let i = $index) {
            <input
                (click)="tabClick(eventData)"
                name="3tabs"
                type="radio"
                class="form-control-tabs-segmented-v2-dark-input"
                id="eventData{{ i + 1 }}"
                value="eventData{{ i + 1 }}"
                [checked]="eventData?.eventId == currentTab" />
            <label
                class="form-control-tabs-segmented-v2-dark-label"
                for="eventData{{ i + 1 }}"
                [ngClass]="eventData?.eventId == currentTab ? 'active' : ''">
                {{ eventData?.product }}
                @if (eventData?.isSuccessEvent) {
                    <img
                        class="ml-1"
                        [attr.src]="smartRewardContent?.successEventContent?.successEventBadge?.src"
                        [attr.alt]="smartRewardContent?.successEventContent?.successEventBadge?.alt" />
                }
            </label>
        }
    </div>

    <ph-challenges-widget
        class="card card--lg border-0"
        [successEventContent]="smartRewardContent?.successEventContent"
        [contentParameters]="smartRewardClientContent?.parameters"
        [eventData]="edsEventContent">
    </ph-challenges-widget>
} @else {
    <div class="smart-container__heading text-center">{{ smartRewardClientContent?.parameters?.mychallanges }}</div>
    <div class="smart-container__wrapper d-flex justify-content-center flex-wrap">
        @for (eventData of smartRewardContent?.edsEventsData; track eventData) {
            <ph-challenges-widget
                class="card card--lg border-0"
                [successEventContent]="smartRewardContent?.successEventContent"
                [contentParameters]="smartRewardClientContent?.parameters"
                [eventData]="eventData">
            </ph-challenges-widget>
        }
    </div>
}
