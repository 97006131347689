import { NgStyle } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';

import { DynamicHtmlDirective } from '@frontend/vanilla/core';
import { RxIf } from '@rx-angular/template/if';

import { SmartRewardConfiguration } from '../../config/smart-reward-config';
import { SmartRewardsConstants } from '../../constants/smart-rewards-constant';
import { EdsEventContent } from '../../models/base-content';
import { SmartEdsWidgetContent } from '../../models/smart-eds-widget-details';
import { ChallengesDialogComponent } from '../../shared/challenges/challenges-dialog/challenges-dialog.component';
import { OverlayDialogModal } from '../../shared/dialog/OverlayDialogModal';
import { EdsEventSuccessComponent } from '../../shared/eds-event-success/eds-event-success.component';
import { SmartRewardTrackingHelperService } from '../../shared/tracking/smart-reward-tracking-helper.service';
import { PromoCountDownTimerDirective } from './count-down-timer.directive';

@Component({
    standalone: true,
    selector: 'ph-eds-challenges-widget',
    templateUrl: './eds-challenges-widget.component.html',
    styleUrl: './eds-challenges-widget.component.scss',
    imports: [RxIf, NgStyle, DynamicHtmlDirective, EdsEventSuccessComponent, PromoCountDownTimerDirective],
})
export class EdsChallengesWidgetComponent implements OnInit {
    @Input() eventData: EdsEventContent;
    @Input() smartEdsWidgetContent: SmartEdsWidgetContent;
    @Input() contentParameters: { [key: string]: string };
    showTimer: boolean = false;

    constructor(
        public smartRewardConfig: SmartRewardConfiguration,
        private trackingHelperService: SmartRewardTrackingHelperService,
        private overlayDialogModal: OverlayDialogModal,
    ) {}

    ngOnInit(): void {
        if (new Date(this.eventData?.eventEndDate).getTime() > new Date().getTime()) {
            this.showTimer = true;
        }
    }

    openDialog() {
        const locationEvent = this.eventData?.product?.toLowerCase() + ' ' + SmartRewardsConstants.DailyRewardsWidget;
        this.trackingHelperService.trackOnCtaClick(SmartRewardsConstants.ViewDetails, locationEvent, this.eventData?.webTrackingPositionEvent);
        const componentType = ChallengesDialogComponent;
        const data = {
            successEventContent: this.smartEdsWidgetContent?.successEventContent,
            eventData: this.eventData,
            contentParameters: this.contentParameters,
        };
        this.overlayDialogModal.openDialogModal(componentType, data, '');
    }
}
