import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { Component, Injector, Input, OnChanges, OnDestroy } from '@angular/core';

import { ContentLink, CookieService, UserService } from '@frontend/vanilla/core';
import { Subject, takeUntil } from 'rxjs';

import { Offer } from '../../offers/models';
import { LoadOffersApiService } from '../../offers/offers-service/load-offers.service';
import { TrackingModelService } from '../../tracking/tracking-model-service';
import { BetStationConfiguration } from '../bet-station-client-config';
import { BetStationOfferOverlayComponent } from '../bet-station-offer-overlay/bet-station-offer-overlay.component';
import { GENERIC_MODAL_CONTENT } from '../generic-modal-content';

@Component({
    selector: 'bet-station-offer-tile-teaser',
    templateUrl: 'bet-station-offer-tile-teaser.component.html',
})
export class BetStationOfferTileTeaserComponent implements OnChanges, OnDestroy {
    private readonly NativeApp = 'NativeApp';
    @Input() offer: Offer;
    @Input() teaserNumber: number;
    moreInfoCta: ContentLink;
    clientContentMessages: { [attr: string]: string };
    isSpecialOfferOptin: boolean = false;
    optInMessage: string = '';
    optedInMessage: string = '';
    optinExpiredMessage: string = '';
    optinInvalidMessage: string = '';
    optinUserNotEligibleMessage: string = '';

    private readonly destroy$ = new Subject<void>();

    constructor(
        private overlay: Overlay,
        private injector: Injector,
        private userService: UserService,
        private trackingModelService: TrackingModelService,
        private cookieService: CookieService,
        public betStationConfiguration: BetStationConfiguration,
        public loadOffersApiService: LoadOffersApiService,
    ) {
        this.loadClientContent();
    }

    get Special_offer_Id() {
        return this.offer.offerContent?.primaryCta?.attributes != null ? this.offer.offerContent?.primaryCta?.attributes['data-offer-id'] : null;
    }
    get Special_offer_Type() {
        return this.offer.offerContent?.primaryCta?.attributes != null ? this.offer.offerContent?.primaryCta?.attributes['data-offer-type'] : null;
    }

    ngOnChanges() {
        if (this.offer.offerContent.moreInfoCta?.url) {
            this.moreInfoCta = this.offer.offerContent.moreInfoCta;
        }
        this.isSpecialOfferOptin = this.offer.offerMetadata?.isSpecialOfferOptin;
    }

    loadClientContent() {
        this.loadOffersApiService.clientContentMessages$.pipe(takeUntil(this.destroy$)).subscribe((messages: { [attr: string]: string }) => {
            if (messages) {
                this.clientContentMessages = messages;
                this.optInMessage = this.clientContentMessages['OptIn'] ?? '';
                this.optedInMessage = this.clientContentMessages['Opted_In'] ?? '';
                this.optinExpiredMessage = this.clientContentMessages['Optin_Expired'] ?? '';
                this.optinInvalidMessage = this.clientContentMessages['Optin_Invalid'] ?? '';
                this.optinUserNotEligibleMessage = this.clientContentMessages['Optin_User_Not_Eligible'] ?? '';
            }
        });
    }

    trackBetStationClick(positionEvent: string, position: any, cta: ContentLink) {
        this.trackingModelService.submitTracking({
            CategoryEvent: 'promotions',
            LabelEvent: 'bet station offers',
            ActionEvent: 'click',
            PositionEvent: this.offer.offerMetadata.applicableProducts?.join('|'),
            LocationEvent: this.offer.offerContent.title,
            EventDetails: positionEvent,
            URLClicked: cta?.url,
            contentPosition: position,
        });
    }

    navigateToOfferMoreInfo() {
        this.trackBetStationClick('more info', this.teaserNumber, this.offer.offerContent.moreInfoCta);

        const moreInfoUrlLowerCase = this.moreInfoCta?.url?.toLowerCase();
        const ctaUrl = new URL(this.moreInfoCta?.url);
        const cookieValue = this.cookieService.get(this.NativeApp);
        if (!moreInfoUrlLowerCase?.includes('_nativeapp')) {
            ctaUrl.searchParams.append('_nativeapp', cookieValue);
        }
        if (!moreInfoUrlLowerCase?.includes('.box')) {
            ctaUrl.searchParams.append('.box', '1');
        }
        if (this.userService.isAuthenticated && this.userService.ssoToken) {
            ctaUrl.searchParams.append('_ssotoken', this.userService.ssoToken);
        }
        const requiredModalData = {
            url: ctaUrl.href,
            overlayTitle: this.offer.offerContent.title,
        };

        const overlayRef = this.overlay.create({
            hasBackdrop: true,
            scrollStrategy: this.overlay.scrollStrategies.block(),
            positionStrategy: this.overlay.position().global().centerHorizontally().centerVertically(),
        });

        const portal = new ComponentPortal(
            BetStationOfferOverlayComponent,
            null,
            Injector.create({
                providers: [
                    { provide: OverlayRef, useValue: overlayRef },
                    { provide: GENERIC_MODAL_CONTENT, useValue: requiredModalData },
                ],
                parent: this.injector,
            }),
        );

        overlayRef.attach(portal);
    }

    specialOffer_Click(evt: any) {
        this.trackBetStationClick('opt-in', this.teaserNumber, this.offer.offerContent.moreInfoCta);
        if (evt.currentTarget.attributes['data-offer-status']?.value !== 'offered') {
            this.navigateToOfferMoreInfo();
        }
    }

    ngOnDestroy() {
        this.destroy$.next();
        this.destroy$.complete();
    }
}
