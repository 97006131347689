import { NgClass } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';

import { DynamicHtmlDirective, UserService } from '@frontend/vanilla/core';
import { RxIf } from '@rx-angular/template/if';

import { SmartRewardsConstants } from '../../constants/smart-rewards-constant';
import { BaseMetaData } from '../../models/base-meta-data';
import { SmartEdsWidgetContent } from '../../models/smart-eds-widget-details';
import { ToastrHelperService } from '../../services/toastr-helper-service/toastr-helper.service';
import { SmartRewardTrackingHelperService } from '../../shared/tracking/smart-reward-tracking-helper.service';

@Component({
    selector: 'ph-pre-optin-widget',
    standalone: true,
    imports: [RxIf, NgClass, DynamicHtmlDirective],
    templateUrl: './pre-optin-widget.component.html',
    styleUrl: './pre-optin-widget.component.scss',
})
export class PreOptinWidgetComponent {
    @Input() smartEdsWidgetContent: SmartEdsWidgetContent;
    @Input() smartEdsWidgetMetaData: BaseMetaData;
    @Input() showOptinCta: boolean = false;
    @Output() optinClick = new EventEmitter();
    @Output() openDialog = new EventEmitter();

    constructor(
        public user: UserService,
        private toastrHelperService: ToastrHelperService,
        private trackingHelperService: SmartRewardTrackingHelperService,
    ) {}

    onClickCta(isLogin: boolean) {
        if (isLogin) this.toastrHelperService.goToLogin(this.user.isAuthenticated);
        else this.optinClick.emit();

        const ctaName = isLogin ? SmartRewardsConstants.LogIn : SmartRewardsConstants.Optin;
        this.trackingHelperService.trackOnCtaClick(ctaName, SmartRewardsConstants.Widget);
    }

    onClickOpenDialog() {
        this.openDialog.emit();
    }
}
