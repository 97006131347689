<div id="offers-popup-filter">
    <mat-dialog-content>
        <div class="dialog-box-header">
            <div class="d-flex justify-content-between align-items-center">
                <div class="font-weight-bold">
                    {{ filterOffersContent.filterOffersText.title }}
                </div>
                <a href="#" (click)="closeDialog(true)"><span class="p-0 ui-icon ui-icon-size-sm theme-ex"></span> </a>
            </div>
            <div>
                <ul class="nav nav-pills">
                    <li class="nav-item txt-s m-1 p-0" *ngFor="let tab of filterTabs">
                        <a
                            id="{{ tab.name }}"
                            class="nav-link nav-link-v2 top-tab"
                            [ngClass]="{ active: tab.isActive }"
                            href="#"
                            (click)="selectedTab(tab)"
                            >{{ tab.title }}</a
                        >
                    </li>
                </ul>
            </div>
        </div>
        <fieldset class="content-block-wrapper">
            <div class="content-block" [class.content-block__wrap]="Offers?.length === 2">
                <ng-container *ngIf="!isMobile">
                    <ng-container *ngFor="let offer of Offers; let i = index">
                        <div class="form-element">
                            <div class="form-element-header d-flex flex-nowrap align-items-center">
                                <img class="otgt-image" [attr.src]="offer.messages.iconUrl" />
                                <span class="ml-2">{{ offer.title }}</span>
                            </div>
                            <div class="offer" *ngFor="let filterOffer of offer.filterOffers; let offerIndex = index">
                                <input
                                    class="custom-control-input custom-control-checkbox"
                                    id="{{ filterOffer?.message?.product }}_{{ offerIndex }}"
                                    type="checkbox"
                                    [checked]="filterOffer.isChecked"
                                    (click)="filterSelected(filterOffer, offer.messages.webTrackingTitle)" />
                                <label for="{{ filterOffer?.message?.product }}_{{ offerIndex }}">{{ filterOffer.title }}</label>
                            </div>
                        </div>
                    </ng-container>
                </ng-container>
                <!-- Start Mobile View -->
                <ng-container *ngIf="isMobile">
                    <ng-container *ngFor="let offer of Offers; let i = index">
                        <details class="form-element" open>
                            <summary class="form-element-header d-flex flex-nowrap justify-content-between align-items-center">
                                <div>
                                    <img class="otgt-image" [attr.src]="offer.messages.iconUrl" />
                                    <span class="ml-2">{{ offer.title }}</span>
                                </div>
                                <span class="theme-down"></span>
                            </summary>
                            <div class="offer" *ngFor="let filterOffer of offer.filterOffers; let offerIndex = index">
                                <input
                                    class="custom-control-input custom-control-checkbox"
                                    id="{{ filterOffer?.message?.product }}_{{ offerIndex }}"
                                    type="checkbox"
                                    [checked]="filterOffer.isChecked"
                                    (click)="filterSelected(filterOffer, offer.messages.webTrackingTitle)" />
                                <label for="{{ filterOffer?.message?.product }}_{{ offerIndex }}">{{ filterOffer.title }}</label>
                            </div>
                        </details>
                    </ng-container>
                </ng-container>
                <!-- End Mobile View -->
            </div>
        </fieldset>
        <div class="dialog-box-footer d-flex flex-nowrap justify-content-between">
            <div class="clear-all w-100">
                <a href="#" [ngClass]="{ disabled: selectedFilters && selectedFilters.length === 0 }" (click)="clearAllFilters()">{{
                    filterOffersContent.filterOffersText.messages.clearAll
                }}</a>
            </div>
            <div class="result-button">
                <a href="#" class="btn btn-primary" (click)="resultsByFilter()" [ngClass]="{ disabled: totalItemsByFilter === 0 }">{{
                    showResultButton
                }}</a>
            </div>
        </div>
    </mat-dialog-content>
</div>
