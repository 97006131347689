import { Injectable } from '@angular/core';

import { ApiService } from '@frontend/promo-utils';

@Injectable()
export class BratContentResourceService {
    constructor(private apiService: ApiService) {}

    getContent() {
        const respone = this.apiService.get('phClientContent', {
            paths: [
                'Promo/PromoHub/DetailPagesContent/Common/Content',
                'Promo/PromoHub/DetailPagesContent/Brat/BratClientContent',
                'Promo/PromoHub/DetailPagesContent/Bonus/BonusClientContent',
            ],
        });
        return respone;
    }
}
