import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';

import { FilterConfiguration } from '@frontend/promo-homewidget';
import { ClientConfigService } from '@frontend/vanilla/core';

@Injectable()
export class FilterConfigActivateGuard implements CanActivate {
    constructor(
        private clientConfigService: ClientConfigService,
        private filterConfig: FilterConfiguration,
    ) {}

    async canActivate() {
        if (!this.filterConfig.isConfigReady) await this.clientConfigService.load('', [FilterConfiguration]);
        return true;
    }
}
