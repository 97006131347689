import { Injectable } from '@angular/core';

import { ClientConfigProductName, LazyClientConfig, LazyClientConfigBase, LazyClientConfigService } from '@frontend/vanilla/core';

@LazyClientConfig({
    product: ClientConfigProductName.PROMO,
    key: 'prSpainAndNLLabel', // The key for this specific config
})
@Injectable({
    providedIn: 'root',
    deps: [LazyClientConfigService],
    useFactory: BetStationConfigurationFactory,
})
export class BetStationConfiguration extends LazyClientConfigBase {
    enableOfferTypeBadge: boolean;
}

export function BetStationConfigurationFactory(service: LazyClientConfigService) {
    return service.get(BetStationConfiguration);
}
