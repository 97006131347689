import { createFeatureSelector, createSelector } from '@ngrx/store';

import { TargetPromotionStatus } from '../models/PromotionStatus/promotion-statuses';
import { TargetPromotionContent } from '../models/target-promotion-content';
import { TargetPromotionMetadata } from '../models/target-promotion-metadata';

export const targetPromoModuleStateKey: string = 'targetPromoModuleData';

export interface TargetPromoState {
    metadata: TargetPromotionMetadata;
    content: TargetPromotionContent;
}

export const targetPromoFeatureDetails = createFeatureSelector<TargetPromoState>(targetPromoModuleStateKey);
export const targetPromoState = (state: TargetPromoState) => state;
export const targetPromoDetails = createSelector(targetPromoFeatureDetails, targetPromoState);
export const targetPromoMetaData = createSelector(targetPromoDetails, (state) => state?.metadata);
export const optInDetails = createSelector(targetPromoMetaData, (state) => state?.optinDetails);
export const promoStartTime = createSelector(targetPromoMetaData, (state) => new Date(state?.promoStartTime));
export const promoEndTime = createSelector(targetPromoMetaData, (state) => new Date(state?.promoEndTime));
export const isGeneral = createSelector(targetPromoMetaData, (state) => state?.isGeneral);

export const targetPromoContent = createSelector(targetPromoDetails, (state) => state?.content);
export const optInContent = createSelector(targetPromoContent, (state) => state?.optinContent);
export const isPromoEnded = createSelector(targetPromoContent, (state) => state.promotionStatus === TargetPromotionStatus.Ended);
export const commonContentMessages = createSelector(targetPromoContent, (state) => state.targetPromotionCommonContent?.messages);
export const noPrizeContent = createSelector(targetPromoContent, (state) => state?.noPrizeContent);
export const resultsContent = createSelector(targetPromoContent, (state) => state?.resultsContent);
export const resultsPrizesLength = createSelector(resultsContent, (state) => state?.resultsPrizes?.length);
export const resultsInfos = createSelector(resultsContent, (state) => state?.resultsPlayerInfos);
export const rewardsContent = createSelector(targetPromoContent, (state) => state?.rewardsContent);
