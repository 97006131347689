import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { DynamicHtmlDirective, PlainLinkComponent } from '@frontend/vanilla/core';
import { OfferButtonComponent } from '@frontend/vanilla/features/offer-button';
import { LetDirective } from '@ngrx/component';

import { OfferApplicableSportsLeagueComponent } from './offer-applicable-sports-league/offer-applicable-sports-league.component';
import { OfferTypeBadgeComponent } from './offer-type-badge/offer-type-badge.component';
import { OffersSkeletonComponent } from './offers-skeleton/offers-skeleton.component';
import { OfferTeaserCtaComponent } from './offers-teaser/offer-teaser-cta/offer-teaser-cta.component';
import { OfferTeaserImageComponent } from './offers-teaser/offer-teaser-image/offer-teaser-image.component';
import { OfferTeaserSpecialOfferCtaComponent } from './offers-teaser/offer-teaser-special-offer-cta/offer-teaser-special-offer-cta.component';
import { OfferTeaserStatusComponent } from './offers-teaser/offer-teaser-status/offer-teaser-status.component';
import { OffersTeaserComponent } from './offers-teaser/offers-teaser.component';
import { ProgressBarComponent } from './progress-bar/progress-bar.component';

@NgModule({
    declarations: [
        OffersSkeletonComponent,
        OfferTypeBadgeComponent,
        OffersTeaserComponent,
        OfferTeaserCtaComponent,
        OfferTeaserImageComponent,
        OfferTeaserSpecialOfferCtaComponent,
        OfferApplicableSportsLeagueComponent,
        ProgressBarComponent,
    ],
    imports: [CommonModule, PlainLinkComponent, DynamicHtmlDirective, OfferButtonComponent, LetDirective, OfferTeaserStatusComponent],
    exports: [OffersSkeletonComponent, OfferTypeBadgeComponent, OffersTeaserComponent, OfferTeaserSpecialOfferCtaComponent],
})
export class SharedOffersModule {}
