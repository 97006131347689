import { Injectable } from '@angular/core';

import { ClientConfigProductName, LazyClientConfig, LazyClientConfigBase, LazyClientConfigService } from '@frontend/vanilla/core';

@LazyClientConfig({
    product: ClientConfigProductName.PROMO,
    key: 'prGroupedOffers',
})
@Injectable({
    providedIn: 'root',
    deps: [LazyClientConfigService],
    useFactory: GroupedOfferConfigurationFactory,
})
export class GroupedOfferConfiguration extends LazyClientConfigBase {
    isOfferGroupingByProductEnabled: boolean;
}

export function GroupedOfferConfigurationFactory(service: LazyClientConfigService) {
    return service.get(GroupedOfferConfiguration);
}
