import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';

import { UserService } from '@frontend/vanilla/core';

@Injectable({
    providedIn: 'root',
})
export class AuthorizationGuard implements CanActivate {
    constructor(private userService: UserService) {}
    canActivate() {
        if (this.userService.isAuthenticated) {
            return true;
        }
        return false;
    }
}
