import { Injectable } from '@angular/core';

import { ApiService } from '@frontend/promo-utils';
import { ContentService, UserLoginEvent, UserService } from '@frontend/vanilla/core';
import { Observable, filter, forkJoin, map, shareReplay } from 'rxjs';

import { OffersConstants } from '../offers/common-constants/offers-constants';
import { NLRegulatoryHelperService } from './NL-regulatory/NL-regulatory-helper.service';
import { ReasonCodes } from './reason-codes.model';
import { RegulatoryConfiguration } from './regulatory-client-config';
import { SpanishRegulatoryHelperService } from './spanish-regulatory-helper.service';

@Injectable({
    providedIn: 'root',
})
export class RegulatoryApiService {
    private _reasonCodes$: Observable<any>;

    constructor(
        private regulatoryConfig: RegulatoryConfiguration,
        private spanishRegulatoryHelperService: SpanishRegulatoryHelperService,
        private apiService: ApiService,
        private userService: UserService,
        private contentService: ContentService,
        private nLRegulatoryHelperService: NLRegulatoryHelperService,
    ) {
        this.getReasonCodes();
        this.userService.events.pipe(filter((e) => e instanceof UserLoginEvent)).subscribe(() => {
            this.getReasonCodes();
        });
    }

    getReasonCodes() {
        this._reasonCodes$ = this.apiService.get('reasoncodes').pipe(shareReplay({ bufferSize: 1, refCount: true }));

        this._reasonCodes$.subscribe((reasonCodes: ReasonCodes) => {
            if (reasonCodes) {
                this.spanishRegulatoryHelperService.reasonCodes$.next(reasonCodes?.spainDecreeCodes);
                this.nLRegulatoryHelperService.reasonCodes$.next(reasonCodes?.nlCodes);
            }
        });
    }

    fetchDataWithReasonCode(api$: Observable<any>) {
        if (this.userService.isAuthenticated && (this.regulatoryConfig.enableSpanishRegulatory || this.regulatoryConfig.enableNLRestrictions)) {
            return forkJoin([this._reasonCodes$, api$]).pipe(map(([, api]) => api));
        }
        return api$;
    }

    fetchSpanishDecreeContent() {
        this.contentService.getJsonFiltered(OffersConstants.SpanishDecreeContentPath).subscribe((data: any) => {
            this.spanishRegulatoryHelperService.nonUserComplianceMessages$.next(data);
        });
    }

    fetchNLCashbackContent() {
        this.contentService.getJsonFiltered(OffersConstants.NLMessagesContentPath).subscribe((data: any) => {
            this.nLRegulatoryHelperService.nonUserComplianceMessages$.next(data);
        });
    }
}
