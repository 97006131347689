export enum RewardType {
    Bonus = 'bonus',
    FreeSpins = 'freespin',
    RewardTypeFreeSpin = 'FreeSpin',
    BonusCode = 'BonusCode',
    BingoTicket = 'bingoticket',
    Available = 'available',
    Completed = 'completed',
    Expired = 'expired',
}
