export enum CashierEventTypes {
    target = 'Cashier',
    bonusSelected = 'BonusSelected',
    bonusRemoved = 'BonusRemoved',
    close = 'Close',
}

export class EventData {
    target: string = CashierEventTypes.target;
    event: string;
    bonusCode?: string;
}
