import { PercentPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, OnChanges } from '@angular/core';

import { FreeSpinsProgress, Offer, OfferCrmServiceType } from '../../offers/models';
import { OfferContentConfiguration } from '../../offers/offer-content.client-config';

@Component({
    selector: 'progress-bar',
    templateUrl: 'progress-bar.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProgressBarComponent implements OnChanges {
    @Input() offer: Offer;
    @Input() isTileContentV2Enabled: boolean;

    progressBarValue: string;

    progressBarWidthPercentage: number;
    freeSpinsProgress: FreeSpinsProgress[];

    toShow: boolean;

    constructor(
        private pipe: PercentPipe,
        public offerContentConfiguration: OfferContentConfiguration,
    ) {}

    ngOnChanges() {
        this.progressBarValue = this.bindProgressBarData();
        this.toShow = this.offer.offerMetadata?.serviceType?.toLocaleLowerCase() != OfferCrmServiceType.GoldenChips ? true : false;
    }

    bindProgressBarData(): string {
        let result = '';
        const offerMetadata = this.offer.offerMetadata;
        const offerProgress = offerMetadata.offerProgress;

        if (!offerProgress) return '';

        const curVal = +offerProgress.currentVal;
        const tarVal = +offerProgress.targetVal;
        let progressBarValue = 0;
        const serviceType = offerMetadata.serviceType.toLocaleLowerCase();
        progressBarValue = curVal / tarVal;
        if (isNaN(progressBarValue)) progressBarValue = 0;
        this.progressBarWidthPercentage = progressBarValue * 100;

        switch (serviceType) {
            case OfferCrmServiceType.Bonus: {
                const bonusProgressPercentage = progressBarValue.toString().substring(0, 6);
                result = this.pipe.transform(bonusProgressPercentage, '1.0-2') ?? '';
                break;
            }
            case OfferCrmServiceType.FreeSpins: {
                result = `${curVal}/${tarVal}`;
                break;
            }
            case OfferCrmServiceType.BingoTicket: {
                const currentVal = tarVal - curVal;
                this.progressBarWidthPercentage = (currentVal / tarVal) * 100;
                result = `${currentVal}/${tarVal}`;
                break;
            }
        }
        return result;
    }
}
