<div class="content-message success theme-check">
    <div class="page-matrix">
        <div>
            <div class="pc-text pc-component">
                <section class="pc-richtext">
                    {{ successMessage }}
                </section>
            </div>
        </div>
    </div>
</div>
