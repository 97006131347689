import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';

import { RegulatoryConfiguration } from '@frontend/promo-homewidget';
import { PromoDetailPageConfiguration } from '@frontend/promo-utils';
import { ClientConfigService } from 'packages/vanilla/lib/core/src/core';

@Injectable()
export class ClientConfigActivateGuard implements CanActivate {
    constructor(
        private clientConfigService: ClientConfigService,
        private detailPageClientConfig: PromoDetailPageConfiguration,
        private regulatoryConfig: RegulatoryConfiguration,
    ) {}

    async canActivate() {
        const configs = [] as any;
        if (!this.detailPageClientConfig.isConfigReady) configs.push(PromoDetailPageConfiguration);
        if (!this.regulatoryConfig.isConfigReady) configs.push(RegulatoryConfiguration);
        if (configs.length > 0) await this.clientConfigService.load('', configs);
        return true;
    }
}
