import { Injectable } from '@angular/core';

import { MenuActionOrigin, MenuActionsService } from '@frontend/vanilla/core';
import { BehaviorSubject } from 'rxjs';

import { SmartRewardsConstants } from '../../constants/smart-rewards-constant';
import { TrackingModel } from '../../models/tracking-model';

@Injectable({
    providedIn: 'root',
})
export class ToastrHelperService {
    onToastrActivation$ = new BehaviorSubject({});

    constructor(private menuActionsService: MenuActionsService) {}

    goToLogin(isAuthenticated: boolean) {
        if (!isAuthenticated) {
            this.menuActionsService.invoke(SmartRewardsConstants.GoToLogin, MenuActionOrigin.OfferButton, [undefined, undefined, { returnUrl: '' }]);
        }
    }

    setToastrData(
        title?: string,
        description?: string,
        showCta: boolean = false,
        isOptedIn: boolean = false,
        parameters: { [key: string]: string } = {},
        trackingModel: TrackingModel = {},
    ) {
        return {
            title: title,
            description: description,
            isOptedIn: isOptedIn,
            showCta: showCta,
            parameters: parameters,
            trackingModel: trackingModel,
        };
    }
}
