import { createFeatureSelector, createSelector } from '@ngrx/store';

import { CriteriaCard } from '../../models/my-cards/criteria-card/criteria-card';
import { MyCardsContent, MyCardsStateModel } from '../../models/my-cards/my-cards-state-models';

export const myCardsModuleStateKey = 'myCardsModuleData';

export const myCardsFeatureDetails = createFeatureSelector<MyCardsStateModel>(myCardsModuleStateKey);
export const myCardsState = (state: MyCardsStateModel) => state;
export const myCardsDetails = createSelector(myCardsFeatureDetails, myCardsState);
export const earnedCardResultDetails = createSelector(myCardsFeatureDetails, (state: MyCardsStateModel) => state.earnedCardResultDetails);
export const multiCardsImage = createSelector(myCardsFeatureDetails, (state: MyCardsStateModel) => state.multiCardsImage);
export const myCardsMetaData = createSelector(myCardsFeatureDetails, (state: MyCardsStateModel) => state.myCardsMetaData);
export const myCardsContent = createSelector(myCardsFeatureDetails, (state: MyCardsStateModel) => state.myCardsContent);
export const criteriaCardStatus = createSelector(myCardsFeatureDetails, (state: MyCardsStateModel) => state.criteriaCardStatus);
export const earnedCardsImages = createSelector(myCardsContent, (state: MyCardsContent) => state?.earnedCardsImages);
export const myEarnedCardsContent = createSelector(myCardsContent, (state: MyCardsContent) => state?.myEarnedCardsContent);
export const criteriaCard = createSelector(myCardsContent, (state: MyCardsContent) => state?.criteriaCard);
export const completedCriteria = createSelector(criteriaCard, (state: CriteriaCard) => state?.completedCriteria);
