<ng-container *ngrxLet="{ messages: messages$, showNonComplianceError: showNonComplianceError$ } as vm">
    <ng-container *ngIf="vm?.messages as messages">
        <div *ngIf="messages?.nonComplianceMessage" class="offers-home-page message-panel">
            <div class="theme-info-i">
                <div class="cms-container" [vnDynamicHtml]="messages?.nonComplianceMessage"></div>
            </div>
            <div *ngIf="vm?.showNonComplianceError" class="theme-error-i">
                <div class="cms-container" [vnDynamicHtml]="messages?.nonComplianceSecondMessage"></div>
            </div>
        </div>
    </ng-container>
</ng-container>
