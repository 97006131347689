<div class="offers-carousel vn-carousel topCarousel offers-home-page" *ngIf="carousel?.carouselItems?.length > 0">
    <i *ngIf="arrows" (click)="swipeNext()" class="theme-right carousel-next" role="button" aria-label="Next slide"></i>
    <i *ngIf="arrows" (click)="swipePrev()" class="theme-left carousel-previous" role="button" aria-label="Previous slide"></i>
    <div #swiperContainer class="swiper">
        <div class="swiper-wrapper">
            <div class="swiper-slide" *ngFor="let carouselItem of carousel.carouselItems">
                <vn-page-matrix class="promo-teaser-banner" [content]="carouselItem"></vn-page-matrix>
            </div>
        </div>
        <div class="swiper-pagination" slot="container-end"></div>
    </div>
</div>
