import { ChangeDetectionStrategy, Component, Input, OnChanges } from '@angular/core';

import { ContentLink } from '@frontend/vanilla/core';
import { BehaviorSubject } from 'rxjs';

import { OffersConstants } from '../../offers/common-constants/offers-constants';
import { Offer, OfferCrmServiceType, OfferTeaserClientContent } from '../../offers/models';
import { OfferContentConfiguration } from '../../offers/offer-content.client-config';
import { LoadOffersApiService } from '../../offers/offers-service/load-offers.service';
import { OffersApiService } from '../../offers/offers-service/offers.service';
import { OfferTeaserService } from './offer-teaser.service';

@Component({
    selector: 'offers-teaser',
    templateUrl: 'offers-teaser.component.html',
    changeDetection: ChangeDetectionStrategy.Default,
})
export class OffersTeaserComponent implements OnChanges {
    @Input() offer: Offer;
    @Input() teaserNumber: number;
    @Input() product: string;
    @Input() groupedOffersCount: number;
    @Input() isMyGala: boolean;
    @Input() isGroupedPage: boolean = false;
    @Input() offerType: string = '';

    vm$: BehaviorSubject<OfferTeaserClientContent>;

    moreInfoCta: ContentLink;
    bindUpdateApiCall: boolean;
    isCardOnHover: boolean;
    showProgressBar: boolean;

    constructor(
        private offerContentConfiguration: OfferContentConfiguration,
        private offersApiService: OffersApiService,
        public loadOffersApiService: LoadOffersApiService,
        private offerTeaserService: OfferTeaserService,
    ) {
        this.vm$ = this.loadOffersApiService.offerTeaserClientContent$;
    }

    get isTimeCountdownEnabled() {
        return this.offerContentConfiguration.enableTimeCountDown;
    }
    get isTileContentV2Enabled() {
        return this.offerContentConfiguration.enableTileContentV2;
    }
    get isEpcotEnabled() {
        return this.offerContentConfiguration.isEpcotEnabled;
    }
    get promoId() {
        return this.offer.offerMetadata.crmServiceId;
    }
    get isBetMgmTileRedesignEnabled() {
        return this.offerContentConfiguration.isBetMgmTileRedesignEnabled;
    }

    ngOnChanges() {
        this.bindMoreInfoCtaLink();
        this.bindUpdateApiCallOnCtaLink();
        this.showProgressBar = this.showProgressBarValue();
    }

    offerTileClick() {
        this.offerTeaserService.navigateToOfferMoreInfo(
            this.teaserNumber,
            this.moreInfoCta,
            this.offer,
            true,
            this.product,
            this.isGroupedPage,
            this.offerType,
        );
    }

    private showProgressBarValue(): boolean {
        const serviceType = this.offer.offerMetadata.serviceType?.toLocaleLowerCase();
        return (
            !!(
                !!serviceType &&
                (serviceType === OfferCrmServiceType.Bonus ||
                    serviceType === OfferCrmServiceType.FreeSpins ||
                    serviceType === OfferCrmServiceType.GoldenChips ||
                    serviceType === OfferCrmServiceType.BingoTicket) &&
                this.offer.offerMetadata.isOfferActive
            ) && !this.offer.offerMetadata.isGroupedTile
        );
    }

    private bindMoreInfoCtaLink(): void {
        if ((this.offer.offerMetadata?.isSpecialOffer || this.offer.offerMetadata?.isWelcomeOffer) && !!this.offer.offerContent.moreInfoCta?.url) {
            this.moreInfoCta = this.offerTeaserService.bindMoreInfoUrl(this.offer.offerContent.moreInfoCta, this.product);
            return;
        }

        if (
            !this.offer.offerMetadata?.isSpecialOffer &&
            !this.offer.offerMetadata?.isWelcomeOffer &&
            this.offer.offerContent?.ctaLinks !== undefined &&
            Object.keys(this.offer.offerContent.ctaLinks).length > 0
        ) {
            const isGroupedTile = this.product !== 'inprogress' && this.offer.offerMetadata.isGroupedTile;
            const url = this.offer.offerContent.ctaLinks[OffersConstants.MoreInfo] ?? ({} as ContentLink);
            this.moreInfoCta = this.offerTeaserService.bindMoreInfoUrl(url, this.product, isGroupedTile);
        }
    }

    private bindUpdateApiCallOnCtaLink(): void {
        this.bindUpdateApiCall =
            !this.offer.offerMetadata?.isSpecialOffer &&
            !this.offer.offerMetadata?.isWelcomeOffer &&
            !this.offer.offerMetadata?.isGroupedTile &&
            this.offer.offerMetadata?.isNewOffer;
    }

    updateOffers() {
        if (this.bindUpdateApiCall) {
            this.offersApiService.updateOffer(this.offer.offerMetadata);
        }
    }

    trackClickEvent(position: any, cta: ContentLink) {
        const serviceSubType = this.offer?.offerMetadata?.serviceSubType
            ? this.offer?.offerMetadata?.serviceSubType?.toLowerCase()
            : this.offer?.offerMetadata?.serviceType?.toLowerCase();
        this.offerTeaserService.trackClickEvent(
            position,
            cta,
            this.product,
            this.offer?.offerMetadata?.isGroupedTile,
            serviceSubType,
            this.promoId,
            this.isMyGala,
            this.isGroupedPage,
            this.offerType,
            this.offer,
        );
    }
}
