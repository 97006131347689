<div *ngIf="advanceFilter?.isAdvanceFilterActive" id="offers-popup-filter-v2" class="offers-popup-filters-v2 th-scroll pt-2">
    <fieldset class="content-block-wrapper">
        <div class="content-block d-flex flex-wrap">
            <ng-container *ngFor="let filterByProduct of advanceFilterContent?.filterByProductType; let i = index">
                <div class="form-element filters-column mr-4">
                    <div class="form-element-header d-flex flex-nowrap align-items-center mb-3">
                        <i class="filter-product-icon ui-icon ui-icon-size-lg" [ngClass]="filterByProduct.messages?.producticonclass"></i>
                        <span class="mx-2 filter-product-title">{{ filterByProduct.title }}</span>
                        <span class="filter-product-count status-pills transparent ml-auto" *ngIf="filterByProduct?.count > 0">{{
                            filterByProduct.count
                        }}</span>
                        <span class="filter-product-count status-pills transparent ml-auto" *ngIf="filterByProduct?.count <= 0">{{
                            advanceFilterContent?.messages?.noresultfound
                        }}</span>
                    </div>
                    <div
                        class="offer d-flex align-items-center justify-content-between"
                        *ngFor="let filtersByOffer of filterByProduct.filtersByOfferType; let offerIndex = index">
                        <div [ngClass]="{ disabled: filtersByOffer.count === 0 }" class="check-wrap">
                            <input
                                type="checkbox"
                                id="{{ filtersByOffer.product }}_{{ offerIndex }}"
                                class="custom-control-input custom-control-checkbox"
                                [checked]="filtersByOffer.isActive"
                                (click)="advanceFilterSelected(filtersByOffer)"
                                [disabled]="filtersByOffer.count === 0" />
                            <label for="{{ filtersByOffer.product }}_{{ offerIndex }}">{{ filtersByOffer.title }}</label>
                        </div>
                        <span [ngClass]="{ disabled: filtersByOffer.count === 0 }" class="filtersByOfferCount filters-circle-badge ml-1">
                            {{ filtersByOffer.count }}
                        </span>
                    </div>
                </div>
            </ng-container>
        </div>
    </fieldset>
    <div class="dialog-box-footer d-flex mt-sm-4 align-items-center">
        <div class="border-divider"></div>
        <div class="result-button">
            <a
                [ngClass]="{ 'disabled': selectedFilterIndexes?.totalCount === 0, 'pill-with-badge-v2 ml-2 promo-filter-apply': isOfferPageEnhanced }"
                class="btn btn-primary"
                (click)="applyAllFilters()"
                >{{ advanceFilterContent?.messages?.applyallfilters }}
                <span
                    [ngClass]="isOfferPageEnhanced ? 'badge badge-circle badge-counter ' : 'filter-btn-badge ml-4'"
                    *ngIf="selectedFilterIndexes?.totalCount > 0"
                    >{{ selectedFilterIndexes?.totalCount }}</span
                >
            </a>
        </div>
        <div class="clear-all">
            <a
                href="#"
                class="clear-all btn btn-link btn-link-dark"
                [ngClass]="{ disabled: selectedFilterIndexes?.totalCount === 0 }"
                (click)="clearAllAdvanceFilter()"
                >{{ advanceFilterContent?.messages?.clearallfilters }}</a
            >
        </div>
    </div>
</div>
