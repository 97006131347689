<ng-container *ngrxLet="faqs$ as faqs">
    @if (offerContentConfiguration?.isPromoFaqsEnabled && faqs) {
        <div class="promo-faq d-flex align-items-center justify-content-around">
            @if (!device?.isMobile) {
                <div>
                    <img class="promo-faq__img d-none d-sm-block" [attr.src]="faqs?.image?.src" />
                </div>
            }
            <div>
                <h2 class="promo-faq__heading mt-0 mb-0">{{ faqs?.parameters?.helpwithpromotions }}</h2>
                <h5 class="promo-faq__sub-heading mt-0 mb-0">{{ faqs?.parameters?.checkourfaqpage }}</h5>
            </div>
            <a
                class="promo-faq__btn btn btn-dark btn-lg d-flex align-items-center justify-content-center"
                [attr.href]="faqs?.titleLink?.url"
                (click)="webTrackingFaqs(faqs?.titleLink?.url)">
                {{ faqs?.titleLink?.text }}</a
            >
        </div>
    }
</ng-container>
