import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    standalone: true,
    name: 'GamesFormat',
})
export class GamesFormatPipe implements PipeTransform {
    transform(value: string, gameLaunchName: string = ''): string {
        value = value.replace('{0}', gameLaunchName);
        return value;
    }
}
