import { OverlayRef } from '@angular/cdk/overlay';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';

import { RxIf } from '@rx-angular/template/if';
import { Subject, takeUntil } from 'rxjs';

import { SmartRewardsConstants } from '../../../../constants/smart-rewards-constant';
import { HowItWorksContent } from '../../../../models/smart-rewards-details.model';
import { ModelDefault } from '../../../../models/tracking-model';
import { SmartRewardTrackingHelperService } from '../../../tracking/smart-reward-tracking-helper.service';
import { GENERIC_MODAL_CONTENT } from '../../OverlayDialogModal';
import { SmartRewardInfoComponent } from '../smart-reward-info/smart-reward-info.component';

@Component({
    selector: 'ph-overlay-info-dialog',
    standalone: true,
    imports: [SmartRewardInfoComponent, RxIf],
    templateUrl: './overlay-info-dialog.component.html',
    styleUrl: './overlay-info-dialog.component.scss',
})
export class OverlayInfoDialogComponent implements OnDestroy, OnInit {
    private readonly destroy$ = new Subject<void>();

    howItWorksContent: HowItWorksContent;
    isEdsWidget: boolean = false;
    locationEvent: string;

    constructor(
        @Inject(GENERIC_MODAL_CONTENT) public data: any,
        private overlayRef: OverlayRef,
        private trackingHelperService: SmartRewardTrackingHelperService,
    ) {}

    ngOnInit() {
        this.howItWorksContent = this.data?.howItWorksContent;
        this.isEdsWidget = this.data?.isEdsWidget;
        this.locationEvent = this.isEdsWidget ? SmartRewardsConstants.WidgetSmartRewardMoreInfo : SmartRewardsConstants.SmartRewardMoreInfo;
        this.trackingHelperService.trackOnPageLoad(this.locationEvent, ModelDefault.NA, SmartRewardsConstants.SmartRewardMoreInfo);
        this.overlayRef
            .backdropClick()
            .pipe(takeUntil(this.destroy$))
            .subscribe(() => this.overlayRef.dispose());
    }

    onClosePopup() {
        this.trackingHelperService.trackOnCtaClick(SmartRewardsConstants.CloseX, this.locationEvent);
        this.overlayRef.detach();
    }

    ngOnDestroy() {
        this.overlayRef.detach();
        this.overlayRef.dispose();
        this.destroy$.next();
        this.destroy$.complete();
    }
}
