import { Injectable } from '@angular/core';

import { ApiService, IframeCommunicationDirective, PromoDistributedCacheConfiguration } from '@frontend/promo-utils';
import { AppInfoConfig, ContentService, CookieService, NavigationService, ToastrQueueService } from '@frontend/vanilla/core';
import { BehaviorSubject, Observable, Subject, takeUntil } from 'rxjs';

import { SmartRewardsConstants } from '../../constants/smart-rewards-constant';
import { MonthlyPrizeDrawReportResponse } from '../../models/monthly-prize-draw-report';
import { ProgressBar } from '../../models/progress-bar';
import { Reward } from '../../models/reward';
import { RewardHistoryResponse } from '../../models/reward-history';
import { EligibleGames, PCImageText, SmartEdsWidgetDetails } from '../../models/smart-eds-widget-details';
import { SmartRewardRequest } from '../../models/smart-reward-request';
import { SmartRewardsDetails } from '../../models/smart-rewards-details.model';

@Injectable({ providedIn: 'root' })
export class SmartRewardsService {
    smartRewardDetails$ = new BehaviorSubject<SmartRewardsDetails>({} as SmartRewardsDetails);
    monthlyReport$ = new BehaviorSubject<MonthlyPrizeDrawReportResponse>({} as MonthlyPrizeDrawReportResponse);
    smartRewardCacheDetails$ = new BehaviorSubject<any>({});
    smartRewardEligibleGames$ = new BehaviorSubject<EligibleGames>({} as EligibleGames);
    rewardHistory$ = new BehaviorSubject<RewardHistoryResponse>({} as RewardHistoryResponse);
    smartEdsWidgetDetails$ = new BehaviorSubject<SmartEdsWidgetDetails>({} as SmartEdsWidgetDetails);
    progressBarWidthPercentage: number;
    campaignId: string;
    iframeComm: IframeCommunicationDirective;

    private readonly destroy$ = new Subject<void>();

    constructor(
        private apiService: ApiService,
        private contentService: ContentService,
        private navigationService: NavigationService,
        private toastrQueueService: ToastrQueueService,
        private cacheConfiguration: PromoDistributedCacheConfiguration,
        private appInfo: AppInfoConfig,
        private cookieService: CookieService,
    ) {}

    setCampaignId(campaignId: string) {
        this.campaignId = campaignId;
    }

    loadSmartRewardDetails(request: SmartRewardRequest) {
        this.apiService
            .get('smartReward/initData', null, {
                showSpinner: true,
                params: request,
            })
            .pipe(takeUntil(this.destroy$))
            .subscribe({
                next: (res) => {
                    this.smartRewardDetails$.next(res);
                },
                error: () => {
                    if (request?.requestType === SmartRewardsConstants.Optin) {
                        this.toastrQueueService.add('PromoOptinFail');
                    } else {
                        this.goToOverviewPage();
                    }
                },
            });
    }

    loadMonthlyPrizeDrawReport(campaignId: string) {
        this.apiService.get('smartReward/prizeDrawReport', { campaignId: campaignId }).subscribe((res) => {
            this.monthlyReport$.next(res);
        });
    }

    getClientContent(): Observable<PCImageText> {
        return this.contentService.getJsonFiltered(SmartRewardsConstants.SmartRewardClientContentPath);
    }

    getSmartRewardCacheDetails(key: string): any {
        if (this.cacheConfiguration?.enableHekatonDistributedCache) {
            const request = { cacheKey: key, brandId: this.appInfo.brand };
            this.apiService.get('getCache', null, { params: request, showSpinner: true }).subscribe((res) => {
                if (res != null) {
                    this.smartRewardCacheDetails$.next(res);
                }
            });
        } else {
            const val = this.cookieService.get(key);
            this.smartRewardCacheDetails$.next({ cachedValue: val == 'true' });
        }
    }

    setSmartRewardCacheDetails(key: string, value: any) {
        if (this.cacheConfiguration.enableHekatonDistributedCache) {
            const request = { cacheKey: key, value: value, brandId: this.appInfo.brand };
            this.apiService.post('setCache', request).subscribe();
        } else {
            this.cookieService.put(key, value);
        }
    }

    loadSmartWidgetDetails(request: SmartRewardRequest) {
        this.apiService
            .get('smartEdsWidget/initData', null, {
                showSpinner: true,
                params: request,
            })
            .subscribe({
                next: (res) => {
                    this.smartEdsWidgetDetails$.next(res);
                },
                error: () => {
                    if (request?.requestType === SmartRewardsConstants.Optin) {
                        this.toastrQueueService.add('PromoOptinFail');
                    }
                },
            });
    }

    loadRewardDetails() {
        this.apiService.get('smartReward/rewards', { campaignId: this.campaignId }).subscribe((res) => {
            this.rewardHistory$.next(res);
        });
    }

    activateNow(bonusId: any, rewardType: string): Observable<any> {
        const params = {
            bonusId: bonusId,
            rewardType: rewardType,
        };

        return this.apiService.post('smartReward/activateoffer', params, {
            params: params,
        });
    }

    getRewardDetailById(rewardId: string): Observable<Reward> {
        return this.apiService.get('smartReward/reward', { rewardId: rewardId, campaignId: this.campaignId });
    }

    getSmartRewardsEligibleGames(offerId: string, offerstatus: boolean, type: string) {
        const request = { offerId: offerId, offerstatus: offerstatus, type: type };
        this.apiService.get('smartReward/eligiblegames', null, { params: request, showSpinner: true }).subscribe((res) => {
            this.smartRewardEligibleGames$.next(res);
        });
    }

    bindProgressBarData(reward: Reward): ProgressBar {
        const result = new ProgressBar();
        if (reward?.rewardMetaData != null) {
            const rewardProgress = reward.rewardMetaData.rewardProgress;
            if (!rewardProgress) return result;

            const curVal = +rewardProgress.currentVal;
            const tarVal = +rewardProgress.targetVal;
            const serviceType = reward.rewardMetaData.rewardType?.toLowerCase();
            const progressBarValue = curVal / tarVal;

            result.progressBarWidthPercentage = isNaN(progressBarValue) ? 0 : progressBarValue * 100;
            switch (serviceType) {
                case SmartRewardsConstants.Bonus: {
                    result.progressCurrentValue = reward?.rewardContent?.rewardCurrentValue;
                    result.progressBarValue =
                        tarVal > 0
                            ? `${reward.rewardContent?.rewardCurrentValue}/${reward.rewardContent?.rewardTargetValue}`
                            : result?.progressCurrentValue;
                    break;
                }
                case SmartRewardsConstants.FreeSpin: {
                    const currentVal = tarVal - curVal;
                    result.progressCurrentValue = currentVal.toString();
                    result.progressBarWidthPercentage = (currentVal / tarVal) * 100;
                    result.progressBarValue = `${currentVal}/${tarVal}`;
                    break;
                }
                case SmartRewardsConstants.BingoTicket: {
                    const currentVal = tarVal - curVal;
                    result.progressCurrentValue = currentVal.toString();
                    result.progressBarWidthPercentage = (currentVal / tarVal) * 100;
                    result.progressBarValue = `${currentVal}/${tarVal}`;
                    break;
                }
            }
        }

        return result;
    }

    goToOverviewPage(forceReload = false, queryParamsHandling: any = null) {
        const updatedPageUrl = `/${this.navigationService.location.culture}/${SmartRewardsConstants.HomePage}`;

        this.navigationService.goTo(updatedPageUrl, {
            forceReload: forceReload,
            queryParamsHandling: queryParamsHandling,
        });
    }

    setIframeDetails(value: any) {
        this.iframeComm = value;
    }

    getIframeDetails() {
        return this.iframeComm;
    }
}
