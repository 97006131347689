import { NgClass } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';

import { RxIf } from '@rx-angular/template/if';

@Component({
    standalone: true,
    selector: 'ph-monthly-prize-progress',
    templateUrl: './monthly-prize-progress.component.html',
    styleUrl: './monthly-prize-progress.component.scss',
    imports: [NgClass, RxIf],
})
export class MonthlyPrizeProgressComponent implements OnInit {
    @Input() progressPercentage: number;
    @Input() progressText: string;
    @Input() progressSubText: string;
    @Input() isEdsWidget: boolean = false;

    currentProgress: string;
    totalProgress: string;

    ngOnInit() {
        if (this.progressText != null) {
            const progress = this.progressText.split('/');
            this.currentProgress = progress[0] ?? '0';
            this.totalProgress = progress[1] ?? '0';
        }
    }
}
