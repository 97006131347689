@if (reward?.rewardContent) {
    <div class="smart-widget__content" (click)="openRewardDetailPopup(rewardContent?.rewardCta?.text)">
        <img
            class="smart-widget__bg"
            [attr.src]="rewardContent?.backgroundImage?.src"
            [attr.alt]="rewardContent?.backgroundImage?.alt"
            loading="lazy" />
        <div class="smart-widget__body">
            @if (rewardContent?.rewardBadge?.text) {
                <div class="smart-badge">
                    <div class="smart-badge__content smart-badge__content--white">
                        <div class="smart-badge__title d-flex align-items-center smart-badge__title--white">
                            {{ rewardContent?.rewardBadge?.text }}
                        </div>
                        <img
                            class="smart-badge__content--edge"
                            [attr.src]="rewardContent?.rewardBadge?.image?.src"
                            [attr.alt]="rewardContent?.rewardBadge?.image?.alt" />
                    </div>
                </div>
            }
            <img
                class="smart-widget__reward-icon"
                [attr.src]="rewardContent?.rewardIcon?.src"
                [attr.alt]="rewardContent?.rewardIcon?.alt"
                width="50%" />
            <div class="smart-widget__title smart-widget__title--static">{{ rewardContent?.title }}</div>
            <div class="smart-widget__description smart-widget__description--static" [vnDynamicHtml]="rewardContent?.description"></div>
            <div class="smart-widget__countdown d-flex align-items-center justify-content-center">
                <div
                    class="smart-widget__countdown--expiry d-inline-flex align-items-center"
                    [ngClass]="{
                        'smart-widget__countdown--completed': rewardMetaData?.isCompletedReward,
                        'smart-widget__countdown--expired-date': rewardMetaData?.isExpiredReward
                    }">
                    <span class="theme-clock d-flex pr-1"></span> {{ rewardContent.expiryTimeCountDown }}
                </div>
            </div>
        </div>
        <div class="smart-widget__footer">
            @if ((rewardMetaData?.isAwarded || rewardMetaData?.isExpiredReward || rewardMetaData?.isCompletedReward) && progressBar) {
                <div class="smart-widget__progress-bar">
                    <div class="background-bar overlay-bg">
                        <div class="progress" [style.width.%]="progressBar?.progressBarWidthPercentage"></div>
                    </div>
                    <span class="smart-widget__progressbar-value">{{ progressBar?.progressBarValue }}</span>
                </div>
            }
            @if (rewardContent?.rewardCta?.text) {
                <a
                    class="btn-sm btn--rewards d-inline-flex align-items-center justify-content-center"
                    [ngClass]="rewardContent?.rewardCta?.attributes?.class ? rewardContent?.rewardCta?.attributes?.class : 'btn btn-secondary'">
                    {{ rewardContent?.rewardCta?.text }}
                </a>
            }
        </div>
    </div>
}
