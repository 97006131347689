import { AfterContentChecked, Component, ElementRef, Input, OnChanges, OnDestroy, OnInit, ViewChild } from '@angular/core';

import { CarouselDefaultConfig } from '@frontend/promo-utils';
import { RxFor } from '@rx-angular/template/for';
import { RxIf } from '@rx-angular/template/if';
import { Subject, takeUntil } from 'rxjs';
import Swiper from 'swiper';
import { SwiperContainer } from 'swiper/element';
import { Navigation, Pagination } from 'swiper/modules';
import { SwiperOptions } from 'swiper/types';

import { SmartRewardsConstants } from '../../constants/smart-rewards-constant';
import { PCImageText } from '../../models/smart-eds-widget-details';
import { RewardMessage, SmartRewardContent, SmartRewardMetaData } from '../../models/smart-rewards-details.model';
import { SmartRewardsService } from '../../services/smart-reward-service/smart-rewards.service';

@Component({
    selector: 'ph-welcome-messages',
    standalone: true,
    imports: [RxFor, RxIf],
    templateUrl: './welcome-messages.component.html',
    styleUrl: './welcome-messages.component.scss',
})
export class WelcomeMessagesComponent implements OnInit, AfterContentChecked, OnDestroy, OnChanges {
    @ViewChild('swiperContainer') swiperRef!: ElementRef<SwiperContainer>;
    public config: SwiperOptions;
    swiper: Swiper;
    @Input() smartRewardContent: SmartRewardContent;
    @Input() smartRewardMetaData: SmartRewardMetaData;
    @Input() smartRewardClientContent: PCImageText;
    srSuccessEdsEventsMsgs: RewardMessage[];
    initSwiper: boolean = true;

    private readonly destroy$ = new Subject<void>();

    constructor(
        private carouselDefaultConfig: CarouselDefaultConfig,
        private smartRewardsService: SmartRewardsService,
    ) {}

    ngOnInit(): void {
        this.config = Object.assign(
            {
                modules: [Navigation, Pagination],
                centeredSlides: true,
                slidesPerView: 1,
                spaceBetween: 1,
            },
            this.carouselDefaultConfig.defaultConfig,
            {
                pagination: {
                    el: '.swiper-pagination',
                    clickable: true,
                    dynamicBullets: true,
                    dynamicMainBullets: 3,
                },
            },
        );
        this.config.autoplay = {
            disableOnInteraction: true,
            pauseOnMouseEnter: true,
        };
    }

    ngOnChanges(): void {
        this.srSuccessEdsEventsMsgs = [];
        if (!this.smartRewardMetaData?.isOptedIn && !this.srSuccessEdsEventsMsgs.some((x) => x.key == SmartRewardsConstants.PreOptinWelcomeMessage)) {
            this.srSuccessEdsEventsMsgs.push({
                key: SmartRewardsConstants.PreOptinWelcomeMessage,
                msgTitle: this.smartRewardClientContent?.parameters?.hellotitle ?? '',
                msgDescription: this.smartRewardClientContent?.parameters?.loginhellomsgdescription ?? '',
            });
        }
        if (this.smartRewardMetaData?.isOptedIn) {
            this.smartRewardsService?.rewardHistory$.pipe(takeUntil(this.destroy$)).subscribe((res: any) => {
                if (res != null && Object.keys(res)?.length > 0) {
                    this.setWelcomeMessageAfterOptedIn(res);

                    if (
                        this.srSuccessEdsEventsMsgs?.length == 0 &&
                        !this.srSuccessEdsEventsMsgs.some((x) => x.key == SmartRewardsConstants.PostOptinWelcomeMessage)
                    ) {
                        this.srSuccessEdsEventsMsgs.push({
                            key: SmartRewardsConstants.PostOptinWelcomeMessage,
                            msgTitle: this.smartRewardClientContent?.parameters?.hellotitle ?? '',
                            msgDescription: this.smartRewardClientContent?.parameters?.optedinmsgdescription ?? '',
                        });
                    }
                }
            });
        }
    }

    setWelcomeMessageAfterOptedIn(res: any) {
        if (
            res?.rewardsCountInLast7DaysDescription != null &&
            !this.srSuccessEdsEventsMsgs.some((x) => x.key == SmartRewardsConstants.Last7daysWelcomeMessage)
        ) {
            this.srSuccessEdsEventsMsgs.push({
                key: SmartRewardsConstants.Last7daysWelcomeMessage,
                msgTitle: this.smartRewardClientContent?.parameters?.last7daysrewardscounttitle ?? '',
                msgDescription: res.rewardsCountInLast7DaysDescription,
            });
        }
        if (res?.rewardList != null && res?.rewardList[SmartRewardsConstants.Available] != null) {
            const availableRewards = res?.rewardList[SmartRewardsConstants.Available]?.rewards ?? [];
            if (availableRewards?.length > 0) {
                const earnedReward = availableRewards.find((x: any) => x.rewardContent.rewardBannerDescription != null);
                if (earnedReward != null && !this.srSuccessEdsEventsMsgs.some((x) => x.key == SmartRewardsConstants.EarnedRewardWelcomeMessage)) {
                    this.srSuccessEdsEventsMsgs.push({
                        key: SmartRewardsConstants.EarnedRewardWelcomeMessage,
                        msgTitle: this.smartRewardClientContent?.parameters?.congratsmsgtitle ?? '',
                        msgDescription: earnedReward?.rewardContent?.rewardBannerDescription,
                        bannerIcon: earnedReward?.rewardContent?.rewardIcon,
                    });
                }
            }
        }
    }

    ngAfterContentChecked() {
        if (this.swiperRef?.nativeElement && this.initSwiper) {
            this.initSwiper = false;
            this.swiper = new Swiper(this.swiperRef?.nativeElement, this.config);
        }
    }

    ngOnDestroy() {
        this.swiper?.destroy();
        this.destroy$.next();
        this.destroy$.complete();
    }
}
