import { Injectable } from '@angular/core';

import { ApiService } from '@frontend/promo-utils';
import { BehaviorSubject, catchError, map, of } from 'rxjs';

import { OfferOptInResult } from '../../../offers/models';

const optInStatusInit: OfferOptInResult = {
    optInClicked: false,
    showOptedInMessage: false,
    showOptedInErrorMessage: false,
};

@Injectable()
export class OfferTeaserOptInService {
    optInStatus$ = new BehaviorSubject(optInStatusInit);

    constructor(private apiService: ApiService) {}

    promotionOptIn(promoId: string, serviceSubType: string): void {
        this.apiService
            .get(serviceSubType, null, {
                params: { promoId, requestType: 'optin' },
            })
            .pipe(
                map(() => {
                    return {
                        optInClicked: true,
                        showOptedInMessage: true,
                        showOptedInErrorMessage: false,
                    } as OfferOptInResult;
                }),
                catchError(() => {
                    return of({
                        optInClicked: true,
                        showOptedInMessage: false,
                        showOptedInErrorMessage: true,
                    } as OfferOptInResult);
                }),
            )
            .subscribe(this.optInStatus$);
    }
}
