<div class="smart-reward-faq my-3">
    @if (tncDescription && tncTitle) {
        <div class="smart-reward-faq__questions">
            <div
                id="{{ tncId }}__header"
                [ngClass]="collapseTermsAndConditions ? 'theme-down' : 'theme-up'"
                class="collapsible__header d-flex justify-content-between align-items-center cursor-pointer"
                (click)="tncToggle(tncId)">
                <div class="collapsible__header--title">{{ tncTitle }}</div>
            </div>
            <div id="{{ tncId }}__body" [ngClass]="{ 'collapsible-visible': !collapseTermsAndConditions }" class="collapsible__body pb-0">
                <div class="mb-2 collapsible__body--text-gray" [vnDynamicHtml]="tncDescription"></div>
            </div>
        </div>
    }
</div>
