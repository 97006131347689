@if (howItWorksContent?.header) {
    <div class="smart-rewards">
        <div class="generic-modal-wrapper actions-in-row gm-overlay-title smart-economy-teaser smart-dailog">
            <div class="header-ctrl-wrapper">
                <div class="header-ctrl d-flex justify-content-between align-items-center generic-modal-wrapper__control">
                    <div class="header-ctrl-txt text-truncate generic-modal-wrapper__title">{{ howItWorksContent?.header }}</div>
                    <div class="header-ctrl-r">
                        <span class="ui-icon ui-icon-size-lg ui-close theme-ex generic-modal-wrapper__closeicon" (click)="onClosePopup()"></span>
                    </div>
                </div>
            </div>
            <div class="generic-modal-content-wrapper">
                <div class="generic-modal-content">
                    <ph-smart-reward-info [howToContent]="howItWorksContent" [isEdsWidget]="isEdsWidget" [isOverlay]="true"></ph-smart-reward-info>
                </div>
            </div>
        </div>
    </div>
}
