import { NgClass } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router, RouterModule } from '@angular/router';

import { DeviceService, TrackingService, UserService } from '@frontend/vanilla/core';

import { ToggleNavigationService } from '../services/toggle-navigation.service';

@Component({
    selector: 'toggle-navigation',
    standalone: true,
    imports: [RouterModule, NgClass],
    templateUrl: './toggle-navigation.component.html',
    styleUrls: ['./toggle-navigation.component.scss'],
})
export class ToggleNavigationComponent implements OnInit {
    vipRoute: string = '/vip/home';
    offersRoute: string = '/offers';
    constructor(
        private router: Router,
        public deviceService: DeviceService,
        public userService: UserService,
        public toggleNavigationService: ToggleNavigationService,
        private trackingService: TrackingService,
    ) {}

    ngOnInit() {
        if (!this.toggleNavigationService.promoOffersRoute) {
            const parentRoute = this.router.url.split('/', 3).join('/');
            this.toggleNavigationService.isPromoActive = this.router.url.toString().includes(this.offersRoute);
            this.toggleNavigationService.promoOffersRoute = parentRoute + this.offersRoute;
            this.toggleNavigationService.vipHubRoute = parentRoute + this.vipRoute;
        }
    }

    navigate(): void {
        if (this.toggleNavigationService.isPromoActive) {
            this.toggleNavigationService.promoOffersRoute = this.router.url.replace('?q=1', '');
            this.router.navigate([this.toggleNavigationService.vipHubRoute]);
        } else {
            this.router.navigate([this.toggleNavigationService.promoOffersRoute]);
        }
        this.trackNavigationEvent();
        this.toggleNavigationService.isPromoActive = !this.toggleNavigationService.isPromoActive;
    }

    private trackNavigationEvent(): void {
        const activeRoute = this.toggleNavigationService.isPromoActive
            ? this.toggleNavigationService.vipHubRoute
            : this.toggleNavigationService.promoOffersRoute;
        this.trackingService.triggerEvent('Event.Tracking', {
            'event': 'Event.Tracking',
            'component.CategoryEvent': 'promo hub',
            'component.ActionEvent': 'click',
            'component.LabelEvent': 'offer section',
            'component.PositionEvent': 'not applicable',
            'component.LocationEvent': 'not applicable',
            'component.EventDetails': this.toggleNavigationService.isPromoActive ? 'vip' : 'promotions',
            'component.URLClicked': window.location.origin + activeRoute || 'not applicable',
            'component.ContentPosition': 'not applicable',
            'campaignId': 'not applicable',
            'page.siteSection': 'promo hub',
            'page.siteSubSection': 'promo offers home page',
            'component.PromotionType': '',
            'component.Product': '',
            'component.PromoOfferName': '',
            'component.PromoIntent': '',
        });
    }
}
