import { Component, Input, OnInit } from '@angular/core';

import {
    AdvanceFilter,
    CommonService,
    FilterByProductType,
    FilterTabs,
    FiltersSelected,
    LoadOffersApiService,
    OfferCategory,
    OffersApiService,
    ProductFilter,
    TrackingModel,
    TrackingModelService,
} from '@frontend/promo-homewidget';
import { HtmlNode } from '@frontend/vanilla/core';
import { cloneDeep } from 'lodash-es';
import { Subject, takeUntil } from 'rxjs';

import { FilterBaseComponent } from '../base-filter-page.component';

@Component({
    selector: 'app-filter-popup',
    templateUrl: './filter-popup.component.html',
    styleUrls: ['./filter-popup.component.css'],
})
export class FilterPopupComponent extends FilterBaseComponent implements OnInit {
    @Input() advanceFilter: AdvanceFilter;
    @Input() mainFilter: FilterTabs[];
    @Input() offerCategories: { [key: string]: OfferCategory };
    selectedFilterIndexes: FiltersSelected;
    advanceFilterContent: AdvanceFilter;
    enableApplyFilters: boolean = false;
    private readonly destroy$ = new Subject<void>();
    isOfferPageEnhanced: boolean;
    resetInProgress: boolean;
    constructor(
        offerDataService: OffersApiService,
        htmlNode: HtmlNode,
        loadOffersApiService: LoadOffersApiService,
        private trackingModelService: TrackingModelService,
        private commonService: CommonService,
    ) {
        super(offerDataService, htmlNode, loadOffersApiService);
    }

    ngOnInit(): void {
        this.offerDataService.resetAll$.pipe(takeUntil(this.destroy$)).subscribe((reset) => {
            this.resetInProgress = reset;
            if (reset) {
                this.clearAllAdvanceFilter();
            }
        });
        this.commonService
            .getIsOfferPageEnhanced()
            .pipe(takeUntil(this.destroy$))
            .subscribe((isOfferPageEnhanced) => {
                this.isOfferPageEnhanced = isOfferPageEnhanced;
            });
        this.advanceFilterContent = cloneDeep(this.advanceFilter);
        this.selectedFilterIndexes = {
            totalCount: this.advanceFilterContent?.totalSelectedOffers ?? 0,
            selectedFilterOfferIndexes: this.advanceFilterContent?.selectedOffersIndex ?? {},
        } as FiltersSelected;
    }

    clearAllAdvanceFilter() {
        if (this.advanceFilter?.totalSelectedOffers > 0) {
            this.updateOfferCategories([], this.offerCategories);
        }
        this.clearAdvanceFilter(this.advanceFilter);
        this.clearAdvanceFilter(this.advanceFilterContent);
        this.selectedFilterIndexes = {
            totalCount: 0,
            selectedFilterOfferIndexes: {},
        } as FiltersSelected;
        this.trackClearAllFilterOnClick();
        if (!this.resetInProgress) {
            this.htmlNode.setCssClass('offer-filters-dialog-opened', true);
        }
        this.offerDataService.resetAll$.next(false);
    }

    advanceFilterSelected(selectedTab: ProductFilter) {
        selectedTab.isActive = !selectedTab.isActive;
        this.selectedAdvanceFilter(selectedTab, this.selectedFilterIndexes);
    }

    applyAllFilters() {
        this.updateAdvanceFilterContent();
        this.applyFilters(this.selectedFilterIndexes.selectedFilterOfferIndexes);
        this.loadOffersApiService.areFiltersApplied$.next(true);
        this.clearMainFilters(this.mainFilter ?? []);
        this.advanceFilter.isAdvanceFilterActive = false;
        if (this.isOfferPageEnhanced) {
            this.offerDataService.updateFilteredOffers(this.selectedFilterIndexes);
            this.offerDataService.isResetSubNavOnFilterApplied$.next(true);
        }
    }

    // this is required to update the offerFilterContent values with the same reference.
    // If we assign the this.advanceFilterContent directly, it will change the reference and cause the issue
    updateAdvanceFilterContent() {
        this.advanceFilter.selectedOffersIndex = this.selectedFilterIndexes.selectedFilterOfferIndexes;
        this.advanceFilter.totalSelectedOffers = this.selectedFilterIndexes.totalCount;
        if (Object.keys(this.selectedFilterIndexes.selectedFilterOfferIndexes)?.length > 0) {
            const trackingModelArray = new Array<TrackingModel>();
            for (let i = 0; i < this.advanceFilterContent.filterByProductType?.length; i++) {
                this.trackApplyFilterOnClick(trackingModelArray, this.advanceFilterContent.filterByProductType[i]);
                const ProductTypeOfofferFilterContent = this.advanceFilter?.filterByProductType[i];
                const ProductTypeOfadvanceFilterContent = this.advanceFilterContent.filterByProductType[i];
                for (let index = 0; index < ProductTypeOfadvanceFilterContent.filtersByOfferType.length; index++) {
                    ProductTypeOfofferFilterContent.filtersByOfferType[index].isActive =
                        ProductTypeOfadvanceFilterContent.filtersByOfferType[index].isActive;
                }
            }
            if (trackingModelArray?.length > 0) this.trackingModelService.submitTracking(trackingModelArray, 'Event.OptionLoad');
        }
    }

    trackApplyFilterOnClick(trackingModelArray: Array<TrackingModel>, filterByProductType: FilterByProductType) {
        filterByProductType?.filtersByOfferType?.forEach((res) => {
            if (res && res.isActive) {
                const result = {
                    CategoryEvent: 'promo hub',
                    LabelEvent: 'advance filters',
                    ActionEvent: 'click',
                    PositionEvent: filterByProductType.webAnalyticName,
                    LocationEvent: 'apply filter',
                    EventDetails: res.webAnalyticName,
                };
                trackingModelArray.push(result);
            }
        });
    }

    trackClearAllFilterOnClick() {
        this.trackingModelService.submitTracking({
            CategoryEvent: 'promo hub',
            LabelEvent: 'advance filters',
            ActionEvent: 'click',
            LocationEvent: 'advance filters',
            EventDetails: 'clear all',
        });
    }
}
