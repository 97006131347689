import { Injectable } from '@angular/core';

import { ClientConfigProductName, LazyClientConfig, LazyClientConfigBase, LazyClientConfigService } from '@frontend/vanilla/core';

@LazyClientConfig({
    product: ClientConfigProductName.PROMO,
    key: 'prDistributedCacheSettings', // The key for this specific config
})
@Injectable({
    providedIn: 'root',
    deps: [LazyClientConfigService],
    useFactory: PromoDistributedCacheConfigFactory,
})
export class PromoDistributedCacheConfiguration extends LazyClientConfigBase {
    enableHekatonDistributedCache: boolean;
}

export function PromoDistributedCacheConfigFactory(service: LazyClientConfigService) {
    return service.get(PromoDistributedCacheConfiguration);
}
