export class OffersConstants {
    public static readonly SpainFlag = 'SPAIN_REGULATORY_FLAGS';
    public static readonly Disabled = 'DISABLED';
    public static readonly Enabled = 'ENABLED';
    public static readonly DefaultMessage = 'defaultmessage';
    public static readonly ExtendedReasonCode = '_warningmessage';
    public static readonly NonLoggedInForSpainLabel = 'nonloggedinforspainlabel';
    public static readonly NoMyOffers = 'NoMyoffers';
    public static readonly NoOffersWithoutLogin = 'NoOffersWithoutLogin';
    public static readonly ClientContent = 'Promo/PromoHub/ClientContent';
    public static readonly SpanishDecreeContentPath = 'Promo/PromoHub/ClientContent/SpanishDecreeMessages';
    public static readonly NLMessagesContentPath = 'Promo/PromoHub/ClientContent/CashBackNLMessages';
    public static readonly MobileOfferHistoryPath = 'Promo/PromoHub/PromoPageEnhancement/MobileOfferHistory';
    public static readonly MultipleBrandDetailsPath = 'Promo/PromoHub/ClientContent/MultipleBrandDetails';
    public static readonly MyGalaTitleContent = 'Promo/PromoHub/ClientContent/MyGalaContent';
    public static readonly MoreInfo = 'more_info';
    public static readonly Claim = 'claim';
    public static readonly Portrait = 'portrait';
    public static readonly WelcomeOffer = 'WELCOMEOFFER';
    public static readonly StaticPromotion = 'static promotion';
    public static readonly DynamicPromotion = 'dynamic promotion';
    public static readonly ProductCasino = 'casino';
    public static readonly IntentAcquisition = 'Acquisition';
    public static readonly IntentRetention = 'Retention';
}
export class OffersButtonClass {
    public static readonly BtnSecondary = 'btn-secondary ';
    public static readonly BtnLink = 'btn-link btn-link-light';
    public static readonly BtnTertiaryMr2 = 'btn-tertiary w-100 mr-2';
    public static readonly BtnTertiary = 'btn-tertiary w-100';
}
