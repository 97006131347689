import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { RemoteLogger } from '@frontend/vanilla/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map, retry } from 'rxjs/operators';

import { ErrorHandlerService } from './error-handler.service';

@Injectable()
export class PromoHttpErrorInterceptor implements HttpInterceptor {
    constructor(
        private remoteLogger: RemoteLogger,
        private service: ErrorHandlerService,
    ) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(
            retry(0),
            map((event) => {
                if (event instanceof HttpResponse && event.status === 204) {
                    throw new Error('This request has failed ' + event.status);
                }
                return event;
            }),
            catchError((error: unknown) => {
                if (error instanceof HttpErrorResponse && error.error && error.error.throwToClient == true) {
                    this.service.addError(error.error);
                }
                this.remoteLogger.logError(error);
                return throwError(error);
            }),
        );
    }
}
