@if (smartEdsWidgetContent) {
    <div class="vn-carousel mb-4">
        <div class="d-flex align-items-baseline justify-content-between mb-4">
            <div class="d-flex align-items-baseline">
                <span class="theme-info-i mr-2 smart-container__heading--icon" (click)="onClickOpenDialog()"></span>
                <div>
                    <div class="smart-container__heading">{{ smartEdsWidgetContent.title }}</div>
                    <div class="smart-container__sub-heading" [vnDynamicHtml]="smartEdsWidgetContent?.monthlyProgressDescription"></div>
                </div>
            </div>

            <div class="chart_progress">
                <ph-monthly-prize-progress
                    [progressPercentage]="playerProgressPercentage"
                    [isEdsWidget]="true"
                    [progressText]="playerProgressDescription">
                </ph-monthly-prize-progress>
                @if (!deviceService?.isMobilePhone) {
                    <div class="chart-status">{{ smartEdsWidgetContent?.parameters?.completed }}</div>
                }
            </div>
        </div>
        @if (smartEdsWidgetContent?.edsEventsData?.length > 0) {
            @if (swiper && arrows && smartEdsWidgetContent?.edsEventsData?.length > 3) {
                <div class="smart-swiper-arrows">
                    <div class="vn-carousel">
                        <i
                            class="theme-left carousel-previous"
                            role="button"
                            aria-label="Previous slide"
                            (click)="swipePrev()"
                            [ngClass]="swiper?.isBeginning ? 'swiper-button-disabled' : ''"></i>
                        <i
                            class="theme-right carousel-next"
                            role="button"
                            aria-label="Next slide"
                            (click)="swipeNext()"
                            [ngClass]="swiper?.isEnd ? 'swiper-button-disabled' : ''"></i>
                    </div>
                </div>
            }
            <div #swiperContainer class="swiper mt-4">
                <div class="swiper-wrapper">
                    @for (eventDetails of smartEdsWidgetContent?.edsEventsData; track eventDetails) {
                        <div class="swiper-slide">
                            <ph-eds-challenges-widget
                                [eventData]="eventDetails"
                                [smartEdsWidgetContent]="smartEdsWidgetContent"
                                [contentParameters]="smartEdsWidgetContent?.parameters"
                                [isFromWidget]="true">
                            </ph-eds-challenges-widget>
                        </div>
                    }
                </div>
            </div>
        }
    </div>
}
