import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';

import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, finalize, first, tap } from 'rxjs/operators';

import { State } from '../../../reducers';
import { BratActions } from '../store/action-types';
import { isLoaded } from '../store/brat.selectors';

@Injectable()
export class BratResolver implements Resolve<boolean> {
    loading: boolean = false;

    constructor(private store: Store<State>) {}

    resolve(route: ActivatedRouteSnapshot): Observable<any> {
        return this.store.select(isLoaded).pipe(
            tap((bratLoaded: boolean) => {
                if (!this.loading && !bratLoaded) {
                    this.loading = true;
                    this.store.dispatch(
                        BratActions.BRAT_LOADING({
                            bratId: route.paramMap.get('promoId') ?? '',
                        }),
                    );
                }
            }),
            filter((bratLoaded: boolean) => bratLoaded),
            first(),
            finalize(() => (this.loading = false)),
        );
    }
}
