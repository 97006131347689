import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
    selector: 'offers-success-message',
    templateUrl: 'offers-success-message.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OffersSuccessMessageComponent {
    @Input() successMessage: string;
}
