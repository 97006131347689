import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'WeeklyGamesFormat',
})
export class WeeklyGamesFormatPipe implements PipeTransform {
    transform(value: string, gameLaunchName: string): string {
        value = value.replace('{0}', gameLaunchName);
        return value;
    }
}
