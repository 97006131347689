import { createFeatureSelector, createSelector } from '@ngrx/store';

import { MultiOfferDetails } from '../model/brat-details.model';
import * as fromBrat from './reducers/brat.reducers';

export const selectBratState = createFeatureSelector<fromBrat.BratState>('brat');

export const selectBrat = createSelector(selectBratState, fromBrat.bratReducer);
export const selectPromoId = createSelector(selectBratState, (brat) => brat?.brat?.bratMetaData?.bonusId);

export const getTabs = createSelector(selectBratState, (brat) => brat?.brat?.bratContent?.bratTabs);

export const getOverviewContent = createSelector(selectBratState, (brat) => brat?.brat?.bratContent?.overviewContent);

export const getDetailsContent = createSelector(selectBratState, (brat) => brat?.brat?.bratContent);

export const getHowItWorksSteps = createSelector(selectBratState, (brat) => brat?.brat?.bratContent?.howItWorksContent?.steps);
export const getHowItWorksManualDataPoints = createSelector(selectBratState, (brat) => brat?.brat?.bratContent?.howItWorksContent?.manualDataPoints);

export const getCurrentOfferDetails = createSelector(selectBratState, (brat) => {
    let multiOfferDetails = brat?.brat?.multiOfferDetailsList;
    if (multiOfferDetails?.length < 4) {
        multiOfferDetails = loopMultiOfferDetails(multiOfferDetails);
    }
    return multiOfferDetails;
});

export const getTakeYourPick = createSelector(selectBratState, (brat) => brat?.brat?.bratContent?.takeYourPick);

export const getBrat = createSelector(selectBratState, (brat) => brat?.brat);

export const isLoading = createSelector(selectBratState, (state) => state.callState == fromBrat.LoadingState.LOADING);

export const isLoaded = createSelector(selectBratState, (state) => state.callState == fromBrat.LoadingState.LOADED);
export const getError = createSelector(selectBratState, (state) => fromBrat.getError(state.callState));

export const loopMultiOfferDetails = (multiOfferDetails: MultiOfferDetails[]) => {
    multiOfferDetails = multiOfferDetails.concat(multiOfferDetails);
    if (multiOfferDetails?.length < 4) {
        loopMultiOfferDetails(multiOfferDetails);
    }
    return multiOfferDetails;
};
