import { Injectable } from '@angular/core';

import { ApiService } from '@frontend/promo-utils';
import { Observable } from 'rxjs';

@Injectable()
export class BetAndGetDetailsResourceService {
    constructor(private apiService: ApiService) {}

    getContent(): Observable<any> {
        return this.apiService.get('phClientContent', {
            paths: ['Promo/PromoHub/DetailPagesContent/Common/Content', 'Promo/PromoHub/DetailPagesContent/BetAndGet/BetAndGetClientContent'],
        });
    }
}
