import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';

import { Observable } from 'rxjs';

import { CommonDetailsResourceService } from './common-details-resource.service';

@Injectable()
export class CommonDetailsResolver implements Resolve<any> {
    constructor(private resource: CommonDetailsResourceService) {}

    resolve(): Observable<any> {
        return this.resource.getContent();
    }
}
