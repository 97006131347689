import { ContentImage, ContentLink, LinkTemplate } from '@frontend/vanilla/core';

export class CoinEconomyDetails {
    details: PCImageText;
    teaser: CoinEconomyTeaser;
    collectCoinsContent: CoinEconomyWidget[];
    playCoinsContent: CoinEconomyWidget[];
    helpScreen: { [key: string]: ViewTemplateItem };
    arcadeCtaLink: LinkTemplate;
}

export interface ViewTemplateItem {
    text?: string | undefined;
    title?: string;
    messages?: { [attr: string]: string };
    validation?: { [attr: string]: string };
}

export class PCImageText {
    imageLink?: ContentLink;
    image?: ContentImage;
    title?: string;
    text?: string;
    parameters?: {
        [attr: string]: string;
    };
}

export class PCText {
    title?: string;
    text?: string;
}

export class CoinStoreDetails {
    playerCategory: string;
    storeDetails: StoreDetails[];
    coinExpiryMessageDescription: string;
    coinExpiryMessageTitle: string;
    coinExpiryDays: string;
    cointEarnedInCurrentMonth: number;
    your: string;
    coinStoreDetailsContent: CoinStoreDetailsContent;
    isCoinExpiryDefaultDateEnabled: boolean;
    isLoaded: boolean;
}

export class StoreDetails {
    storeContent: CoinEconomyWidget;
    storeMetaData: StoreMetaData;
    eligibleGames: EligibleGames;
}

export class EligibleGames {
    gameImageBaseUrl: string;
    gameLaunchBaseUrl: ContentLink;
    gameLaunchNames: string[];
}

export class StoreMetaData {
    storeItemId: string;
    costToBuy: string;
    expiryTimeCountDown: string;
    coinStoreBadge: CoinStoreBadge;
    purchaseEligibilityStatus: boolean;
    isLocked: boolean;
    isReward: boolean;
    offerProgress: any;
    itemType: string;
    offerId: string;
    lockTilePopUpDescription: string;
    lockTilePopUpFavoriteDescription: string;
    isStaticTile: boolean;
    isFavourite: boolean;
    currencyAndValue: string;
    product: string;
    purchaseEndDate: Date;
}

export class ContentPCVideo {
    controls: boolean;
    video: Video;
}

export class ProgressBar {
    progressBarValue: string;
    progressBarWidthPercentage: number;
    itemTypeText: string;
    isRedeem: boolean;
    isUpcomingTarget: boolean;
    imgSrc: string;

    constructor(
        progressValue: string = '',
        progressWidthPercentage: number = 0,
        isRedeem: boolean = false,
        itemTypeText: string = '',
        isUpcomingTarget: boolean = false,
        imgSrc: string = '',
    ) {
        this.progressBarValue = progressValue;
        this.progressBarWidthPercentage = progressWidthPercentage;
        this.isRedeem = isRedeem;
        this.itemTypeText = itemTypeText;
        this.isUpcomingTarget = isUpcomingTarget;
        this.imgSrc = imgSrc;
    }
}

export class Video {
    id: string;
    src: string;
    width?: Number;
    height?: Number;
}

export class CoinEconomyTeaser extends ContentPCVideo {
    backgroundImage?: ContentImage;
    iconImage?: ContentImage;
    description: string;
    primaryCTA?: string;
    loginCTA?: string;
    titleText: string;
}

export class CoinEconomyWidget {
    backgroundImage?: ContentImage;
    coinsImage?: ContentImage;
    description: string;
    playNowCta?: ContentLink;
    betNowCta?: ContentLink;
    rewardCta?: ContentLink;
    okCta?: ContentLink;
    title: string;
    isLargeTile: boolean;
    isLargestTile: boolean;
    manualTermsAndConditions: string;
    keyTermsAndConditions: string;
    extendedKeyTermsAndConditions: string;
    rewardsTitle: string;
    rewardSuccessText: string;
    widgetIndex: number;
    scarcityInfoText: string;
    itemId: string;
    costToBuyCta?: ContentLink;
}

export class CoinStoreBadge {
    secondaryBadgeImage: ContentImage;
    storeBadges: StoreBadge[];
}

export class StoreBadge {
    image: ContentImage;
    text: string;
    cssClass: string;
}

export class CoinStoreDetailsContent {
    title?: string;
    parameters?: {
        [attr: string]: string;
    };
    coinImage: ContentImage;
    lockImage: ContentImage;
    confettiImage: ContentImage;
    overlayImage: ContentImage;
}

export class Tab {
    name: string;
    key: string;
    eventName: string;
    count: number;
    isIconRequired: boolean;
}

export class FavoriteItem {
    itemId: string;
    value: string;
}
