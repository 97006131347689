import { PercentPipe } from '@angular/common';
import { ElementRef, Injectable } from '@angular/core';

import { TrackingModel } from '@frontend/promo-homewidget';
import { ApiService, PromoDistributedCacheConfiguration } from '@frontend/promo-utils';
import { AppInfoConfig, ContentService, CookieService, DeviceService, HtmlNode, PCImage } from '@frontend/vanilla/core';
import { BehaviorSubject, Observable, map } from 'rxjs';

import { CoinEconomyConfiguration } from './config/coin-economy-config';
import { CoinEconomyConstants } from './constants/coin-economy-constants';
import {
    CoinEconomyDetails,
    CoinEconomyWidget,
    CoinStoreDetails,
    EligibleGames,
    FavoriteItem,
    PCText,
    ProgressBar,
    StoreDetails,
    StoreMetaData,
    Tab,
} from './model/coin-economy-details.model';
import { CoinEconomyTransactionHistory, CoinTransaction, CoinTransactionRequest } from './model/coin-economy-transaction-history';
import { CoinPurchaseDetails, PurchaseStoreItem } from './model/coin-purchase-details.model';

@Injectable({ providedIn: 'root' })
export class CoinEconomyService {
    coinStoreDetails$ = new BehaviorSubject<CoinStoreDetails>({ isLoaded: false } as CoinStoreDetails);
    coinRewardDetails$ = new BehaviorSubject<CoinStoreDetails>({} as CoinStoreDetails);
    coinStaticDetails$ = new BehaviorSubject<CoinEconomyDetails>({} as CoinEconomyDetails);
    coinTxnHistoryDetails$ = new BehaviorSubject<CoinEconomyTransactionHistory>({ isLoaded: false } as CoinEconomyTransactionHistory);
    coinTxnHistoryList$ = new BehaviorSubject<CoinTransaction[]>([]);
    coinRewardEligibleGames$ = new BehaviorSubject<EligibleGames>({} as EligibleGames);
    coinCacheInformation$ = new BehaviorSubject<any>({});
    userRgStatus$ = new BehaviorSubject<boolean>(false);
    totalCoinBalance$ = new BehaviorSubject<number>(0);
    targetAreaNavigation$ = new BehaviorSubject<any>({});
    previousTransactionList: CoinTransaction[] = [];
    myRewardsElement = new BehaviorSubject<ElementRef>({} as ElementRef);
    iframeComm: any;
    isRewardsCalled$ = new BehaviorSubject<boolean>(false);
    constructor(
        private apiService: ApiService,
        private htmlNode: HtmlNode,
        private pipe: PercentPipe,
        private contentService: ContentService,
        private deviceService: DeviceService,
        private appInfo: AppInfoConfig,
        private cacheConfiguration: PromoDistributedCacheConfiguration,
        private cookie: CookieService,
        private coinEconomyConfig: CoinEconomyConfiguration,
    ) {}

    getInitData(): Observable<CoinEconomyDetails> {
        return this.apiService.get('coin/initData', { showSpinner: true });
    }

    getCoinStoreDetails() {
        this.apiService.get('coin/store', { showSpinner: true }).subscribe((res) => {
            if (res?.storeDetails) {
                res.isLoaded = true;
                res.storeDetails = this.getTileSizeMappingForStoreItems(res.storeDetails);
                this.coinStoreDetails$.next(res);
            }
        });
    }

    getCoinRewardsDetails() {
        this.apiService.get('coin/rewards', { showSpinner: true }).subscribe((res) => {
            if (res?.storeDetails != null) {
                this.coinRewardDetails$.next(res);
                if (!this.isRewardsCalled$.value) {
                    this.recallCoinRewardsDetails();
                }
            }
        });
    }

    recallCoinRewardsDetails() {
        setTimeout(() => {
            this.getCoinRewardsDetails();
        }, this.coinEconomyConfig.defaultDelay);
        this.isRewardsCalled$.next(true);
    }

    getCoinRewardsEligibleGames(offerId: string, type: string) {
        const request = { offerId: offerId, type: type };
        this.apiService.get('coin/eligiblegames', null, { params: request, showSpinner: true }).subscribe((res) => {
            this.coinRewardEligibleGames$.next(res);
        });
    }

    getCoinPurchaseDetails(request: PurchaseStoreItem[]): Observable<CoinPurchaseDetails> {
        return this.apiService.post('coin/purchase', request);
    }

    getFaqDetails(): Observable<any> {
        return this.apiService.get('coin/faq', { showSpinner: true });
    }

    getTransactionHistoryContent(): Observable<any> {
        return this.contentService.getJsonFiltered('Promo/PromoHub/CoinEconomy/History/TransactionHistory');
    }

    getBackgroundImage(): Observable<PCImage> {
        return this.contentService.getJsonFiltered('Promo/PromoHub/CoinEconomy/CoinStore/CoinBackgroundImage');
    }

    getRgStatusContent(): Observable<PCText> {
        return this.contentService.getJsonFiltered('Promo/PromoHub/CoinEconomy/CoinStore/RgStatusContent');
    }

    getCoinHistoryDetails(fromDate: Date, toDate: Date, startIndex: number = 0) {
        const request = this.getCoinHistoryRequest(fromDate, toDate, startIndex);
        this.apiService.get('coin/history', null, { params: request, showSpinner: true }).subscribe((res) => {
            if (res != null) {
                res.isLoaded = true;
                this.previousTransactionList = res.transactions;
                this.coinTxnHistoryDetails$.next(res);
                this.coinTxnHistoryList$.next(res.transactions);
            }
        });
    }

    getCoinHistoryTransactionList(fromDate: Date, toDate: Date, startIndex: number) {
        const request = this.getCoinHistoryRequest(fromDate, toDate, startIndex);
        this.apiService
            .get('coin/history/transactions', null, { params: request })
            .pipe(
                map((res) => {
                    res = [...this.previousTransactionList, ...res];
                    this.previousTransactionList = res;
                    return res;
                }),
            )
            .subscribe((res) => {
                this.coinTxnHistoryList$.next(res);
            });
    }

    getCoinCache(key: string, isCacheRequired: boolean = true): any {
        if (this.cacheConfiguration.enableHekatonDistributedCache && isCacheRequired) {
            const request = { cacheKey: key, brandId: this.appInfo.brand };
            this.apiService.get('getCache', null, { params: request, showSpinner: true }).subscribe((res) => {
                if (res != null) {
                    this.coinCacheInformation$.next(res);
                }
            });
        } else {
            const val = this.cookie.get(key);
            this.coinCacheInformation$.next({ cachedValue: val == 'true' });
        }
    }

    setCoinCache(key: string, value: any) {
        if (this.cacheConfiguration.enableHekatonDistributedCache) {
            const request = { cacheKey: key, value: value, brandId: this.appInfo.brand };
            this.apiService.post('setCache', request).subscribe();
        } else {
            this.cookie.put(key, value);
        }
    }

    getTileSizeMappingForStoreItems(widgets: StoreDetails[]): StoreDetails[] {
        if (widgets?.length > 0) {
            const count = widgets?.length;
            widgets.forEach((item: StoreDetails, index: any) => {
                if (!item.storeContent) return;
                if (!this.deviceService.isMobile && count == 4) {
                    item.storeContent.isLargeTile = true;
                    return;
                }
                if (index > 1) {
                    item.storeContent.isLargeTile =
                        widgets[index - 1]?.storeContent?.isLargeTile == widgets[index - 2]?.storeContent?.isLargeTile
                            ? widgets[index - 1]?.storeContent?.isLargeTile == false
                                ? true
                                : false
                            : widgets[index - 1]?.storeContent?.isLargeTile == false
                              ? false
                              : index + 1 == count
                                ? true
                                : false;
                    if (!this.deviceService.isMobile && count > 4 && index + 1 == count && item.storeContent.isLargeTile) {
                        if (widgets[index - 1]?.storeContent?.isLargeTile == widgets[index - 2]?.storeContent?.isLargeTile) {
                            widgets[index - 3].storeContent.isLargeTile = false;
                            item.storeContent.isLargeTile = false;
                        }
                    }
                } else {
                    item.storeContent.isLargeTile = index == 0 ? true : index + 1 == count ? true : false;
                }
            });
        }
        return widgets;
    }

    getTileSizeMappingForStaticWidgets(
        widgets: CoinEconomyWidget[],
        groupName: string,
        isMobile: boolean = false,
        isAuthenticated: boolean = false,
    ): CoinEconomyWidget[] {
        if (widgets?.length > 0) {
            const collectCoinsTilePattern: { [attr: number]: number[] } = {
                0: [1],
                1: [1, 1],
                2: [0, 0, 1],
                3: [0, 0, 0, 0],
                4: [0, 0, 0, 0, 1],
                5: [0, 0, 0, 0, 0, 0],
                6: [0, 0, 0, 0, 0, 0, 1],
                7: [0, 0, 0, 0, 0, 0, 0, 0],
            };
            const collectPattern = collectCoinsTilePattern[widgets.length - 1];
            widgets.forEach((item: CoinEconomyWidget, index: any) => {
                if (groupName == CoinEconomyConstants.PlayCoins && widgets.length <= 3) {
                    if (!isAuthenticated && index == widgets.length - 1) item.isLargestTile = true;
                    else item.isLargeTile = true;
                    item.widgetIndex = index + 1;
                }
                if (groupName == CoinEconomyConstants.CollectCoins && widgets.length <= (isMobile ? 8 : 4)) {
                    item.isLargeTile = collectPattern[index] === 1;
                }
            });
        }
        return widgets;
    }

    // Adds custom class(page top parent) to implement ovverride css for teaser overlay specificaly when coin economy home page is loaded
    appendCoinEconomyClass() {
        this.htmlNode.setCssClass('coin-economy', true);
    }

    // Removes custom class when coin economy home page component is destroyed
    removeCoinEconomyClass() {
        this.htmlNode.setCssClass('coin-economy', false);
    }

    bindProgressBarData(metaData: StoreMetaData): ProgressBar {
        let result: any;
        const offerProgress = metaData.offerProgress;
        if (!offerProgress) return result;
        result = new ProgressBar();
        const curVal = +offerProgress.currentVal;
        const tarVal = +offerProgress.targetVal;
        const serviceType = metaData.itemType.toLocaleLowerCase();
        result.progressBarValue = curVal / tarVal;
        if (isNaN(result.progressBarValue)) result.progressBarValue = 0;
        result.progressBarWidthPercentage = result.progressBarValue * 100;

        switch (serviceType) {
            case CoinEconomyConstants.BONUS: {
                const bonusProgressPercentage = result.progressBarValue.toString().substring(0, 6);
                result.progressBarValue = this.pipe.transform(bonusProgressPercentage, '1.0-2');
                break;
            }
            case CoinEconomyConstants.FREESPIN: {
                const currentVal = tarVal - curVal;
                result.progressBarWidthPercentage = (currentVal / tarVal) * 100;
                break;
            }
        }
        return result;
    }

    getCoinHistoryRequest(fromDate: Date, toDate: Date, startIndex: number) {
        return {
            fromDate: fromDate?.toDateString(),
            toDate: toDate?.toDateString(),
            startIndex: startIndex,
        } as CoinTransactionRequest;
    }

    checkIfElementVisible(ele: any) {
        const partiallyVisible = (ele.top < 0 && ele.top > 0) || (ele.top > 0 && ele.top <= window.innerHeight);
        const fullyVisible = ele.top >= 0 && ele.bottom <= window.innerHeight;
        return !fullyVisible && !partiallyVisible;
    }

    getEventDetails(widgetMetaData: StoreMetaData, eligibleGames: EligibleGames) {
        const events: string[] = [];
        if (widgetMetaData?.isReward) {
            events.push(widgetMetaData.offerId);
        } else {
            events.push(widgetMetaData.storeItemId);
        }
        if (widgetMetaData.currencyAndValue) events.push(widgetMetaData.currencyAndValue);
        events.push(widgetMetaData.itemType);
        if (eligibleGames?.gameLaunchNames?.length > 0) {
            events.push(eligibleGames.gameLaunchNames.toString());
        }
        if (widgetMetaData.costToBuy) events.push(widgetMetaData.costToBuy);
        if (widgetMetaData.isFavourite) {
            events.push(CoinEconomyConstants.fav);
        } else {
            events.push(CoinEconomyConstants.Nofav);
        }
        return events.join('_');
    }
    getOfferFavouriteAndCoins(data: StoreMetaData) {
        const events: string[] = [];
        if (!data) return '';
        if (data.isReward) {
            events.push(data.offerId);
        } else {
            events.push(data.storeItemId);
        }
        if (data.isFavourite) {
            events.push(CoinEconomyConstants.fav);
        } else {
            events.push(CoinEconomyConstants.Nofav);
        }
        if (data.costToBuy) {
            events.push(data.costToBuy);
        }
        return events.join('_');
    }

    loadDefaultTrackingModel(locationEvent: string, promotionType: string = ''): TrackingModel {
        return {
            CategoryEvent: CoinEconomyConstants.PromoHub,
            LabelEvent: CoinEconomyConstants.CoinEconomy,
            LocationEvent: locationEvent,
            PromoOfferName: CoinEconomyConstants.CoinEconomy,
            SiteSection: CoinEconomyConstants.PromoHub,
            siteSubSection: CoinEconomyConstants.CustomPromotions,
            promotionType: promotionType ? promotionType : CoinEconomyConstants.StaticPromotion,
            ActionEvent: CoinEconomyConstants.Click,
            OfferFavouriteandCoins: '',
        } as TrackingModel;
    }

    calculateScrollPercentage(event: any, scrolledDict: any): number {
        if (event?.target?.scrollingElement) {
            const scrollTop = event.target.scrollingElement.scrollTop;
            const height = event.target.scrollingElement.scrollHeight - event.target.scrollingElement.clientHeight;
            const scrollPer = (scrollTop / height) * 100;
            if (scrollPer > 25 && !scrolledDict[25]) {
                scrolledDict[25] = true;
                return 25;
            }
            if (scrollPer > 50 && !scrolledDict[50]) {
                scrolledDict[50] = true;
                return 50;
            }
            if (scrollPer > 75 && !scrolledDict[75]) {
                scrolledDict[75] = true;
                return 75;
            }
            if (scrollPer > 90 && !scrolledDict[90]) {
                scrolledDict[90] = true;
                return 90;
            }
        }
        return 0;
    }

    getLandingPageTabs(messages: any) {
        const data = {
            EarnTab: { key: CoinEconomyConstants.EarnTab, eventName: '' },
            RedeemTab: { key: CoinEconomyConstants.StoreTab, eventName: '' },
            ArcadeTab: { key: CoinEconomyConstants.ArcadeTab, eventName: '' },
            HistoryTab: {
                key: CoinEconomyConstants.HistoryTab,
                eventName: CoinEconomyConstants.HistoryTab,
                isIconRequired: this.coinEconomyConfig.tabViewVersion?.toUpperCase() == CoinEconomyConstants.TabViewVersionV2,
            },
        };
        let tabs = this.getTabs(data, messages);
        if (!this.coinEconomyConfig?.enableArcadeTab) {
            tabs = tabs.filter((x) => x.key != CoinEconomyConstants.ArcadeTab);
        }
        return tabs;
    }

    getRedeemTabs(messages: any) {
        const data = {
            StoreTab: { key: CoinEconomyConstants.StoreTab, eventName: '' },
            FavouritesTab: { key: CoinEconomyConstants.FavouritesTab, eventName: '' },
        };
        return this.getTabs(data, messages);
    }

    getTabs(tabsData: any, messages: any) {
        const tabs: Tab[] = [];
        if (tabsData && messages) {
            Object.entries(tabsData).forEach(([key, value]: any) => {
                if (messages[key]) {
                    tabs.push({
                        name: messages[key],
                        key: value.key,
                        eventName: value.eventName,
                        count: 0,
                        isIconRequired: value.isIconRequired,
                    });
                }
            });
        }
        return tabs;
    }

    groupByProperty(arr: any[], property: string) {
        return arr.reduce(function (memo, x) {
            if (!memo[x[property]]) {
                memo[x[property]] = [];
            }
            memo[x[property]].push(x);
            return memo;
        }, {});
    }

    getFavoriteItems(request: FavoriteItem[]) {
        return this.apiService.post('coin/favoriteItem', request);
    }

    loadDefaultPromotionType() {
        if (this.targetAreaNavigation$.value && this.targetAreaNavigation$.value.area) {
            const landedPage = this.targetAreaNavigation$.value;
            return landedPage.area.toLocaleLowerCase() == CoinEconomyConstants.AreaStore
                ? CoinEconomyConstants.DynamicPromotion
                : CoinEconomyConstants.StaticPromotion;
        }
        return CoinEconomyConstants.StaticPromotion;
    }

    getReplacedSubRoute(path: string) {
        if (path && this.coinEconomyConfig.navigationConfigs?.subRoutes[path]) return this.coinEconomyConfig.navigationConfigs.subRoutes[path];
        else return CoinEconomyConstants.defaultPath;
    }

    getTabValueFromRoute(url: string) {
        return url?.includes(CoinEconomyConstants.StoreTab)
            ? CoinEconomyConstants.StoreTab
            : url?.includes(CoinEconomyConstants.HistoryTab)
              ? CoinEconomyConstants.HistoryTab
              : CoinEconomyConstants.EarnTab;
    }

    setIframeDetails(value: any) {
        this.iframeComm = value;
    }
    getIframeDetails() {
        return this.iframeComm;
    }
}
