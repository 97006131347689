import { Injectable } from '@angular/core';

import { SmartRewardsConstants } from '../../constants/smart-rewards-constant';
import { ModelDefault, TrackingModel } from '../../models/tracking-model';
import { SmartRewardsService } from '../../services/smart-reward-service/smart-rewards.service';
import { TrackingModelService } from './tracking-model-service';

@Injectable({ providedIn: 'root' })
export class SmartRewardTrackingHelperService {
    defaultTrackingModel: TrackingModel;

    constructor(
        private trackingModelService: TrackingModelService,
        private smartRewardsService: SmartRewardsService,
    ) {
        this.defaultTrackingModel = {
            CategoryEvent: SmartRewardsConstants.PromoHub,
            LabelEvent: SmartRewardsConstants.SmartRewards,
            PromoOfferName: SmartRewardsConstants.SmartRewards,
            SiteSection: SmartRewardsConstants.PromoHub,
            siteSubSection: SmartRewardsConstants.CustomPromotions,
            promotionType: SmartRewardsConstants.DynamicPromotions,
            ActionEvent: SmartRewardsConstants.Click,
        } as TrackingModel;
    }

    trackOnPageLoad(locationEvent: string, positionEvent: string = ModelDefault.NA, eventDetails: string = ModelDefault.NA) {
        const trackingModel = this.createLoadTrackingModel(locationEvent, positionEvent, eventDetails);
        this.trackingModelService.submitTracking(trackingModel, SmartRewardsConstants.ContentView);
    }

    trackOnOptinSuccess(locationEvent: string, eventDetails: string) {
        const trackingModel = Object.assign({}, this.defaultTrackingModel);
        trackingModel.ActionEvent = SmartRewardsConstants.Load;
        trackingModel.campaignId = this.smartRewardsService?.campaignId;
        trackingModel.LocationEvent = locationEvent;
        trackingModel.EventDetails = eventDetails;
        this.trackingModelService.submitTracking(trackingModel, SmartRewardsConstants.ContentView);
    }

    trackOnCtaClick(
        ctaName: string,
        locationEvent: string,
        positionEvent: string = ModelDefault.NA,
        urlClicked: string = ModelDefault.NA,
        contentPosition: string = ModelDefault.NA,
    ) {
        const trackingModel = this.createInteractionsTrackingModel(positionEvent, locationEvent, ctaName, urlClicked, contentPosition);
        this.trackingModelService.submitTracking(trackingModel);
    }

    createLoadTrackingModel(locationEvent: string, positionEvent: string = ModelDefault.NA, eventDetails: string = ModelDefault.NA) {
        const trackingModel = Object.assign({}, this.defaultTrackingModel);
        trackingModel.ActionEvent = SmartRewardsConstants.Load;
        trackingModel.campaignId = this.smartRewardsService?.campaignId;
        trackingModel.LocationEvent = locationEvent;
        trackingModel.EventDetails = eventDetails == ModelDefault.NA ? locationEvent : eventDetails;
        trackingModel.PositionEvent = positionEvent;
        return trackingModel;
    }

    createInteractionsTrackingModel(
        positionEvent: string,
        locationEvent: string,
        ctaName: string = ModelDefault.NA,
        urlClicked: string = ModelDefault.NA,
        contentPosition: string = ModelDefault.NA,
    ) {
        const trackingModel = Object.assign({}, this.defaultTrackingModel);
        trackingModel.campaignId = this.smartRewardsService?.campaignId;
        trackingModel.LocationEvent = locationEvent;
        trackingModel.EventDetails = ctaName;
        trackingModel.PositionEvent = positionEvent;
        trackingModel.URLClicked = urlClicked;
        trackingModel.contentPosition = contentPosition;
        return trackingModel;
    }
}
