import { Injectable } from '@angular/core';

import { DeviceService } from '@frontend/vanilla/core';
import { SwiperOptions } from 'swiper/types/swiper-options';

@Injectable({
    providedIn: 'root',
})
export class CarouselDefaultConfig {
    defaultConfig: SwiperOptions = {
        allowTouchMove: true,
        autoplay: false,
        loop: false,
        speed: 1000,
        pagination: false,
        simulateTouch: true,
        watchSlidesProgress: true,
        watchOverflow: true,
        observer: true,
        longSwipesRatio: 0,
    };
    arrows: boolean;

    constructor(private deviceService: DeviceService) {
        this.arrows = !this.deviceService.isTouch;
    }
}
