import { Injectable } from '@angular/core';

import { ClientConfigProductName, LazyClientConfig, LazyClientConfigBase, LazyClientConfigService } from '@frontend/vanilla/core';

@LazyClientConfig({
    product: ClientConfigProductName.PROMO,
    key: 'promoConfig', // The key for this specific config
})
@Injectable({
    providedIn: 'root',
    deps: [LazyClientConfigService],
    useFactory: promoConfigFactory,
})
export class PromoClientConfiguration extends LazyClientConfigBase {
    customerId: string;
    userName: string;
    displayName: string;
    email: string;
    country: string;
    firstLogin: string;
    isKnown: string;
    isRealPlayer: string;
    loggedIn: string;
    sessionToken: string;
    UserToken: string;
    enableOffersPage: boolean;
    logoutRedirects: Array<LogoutRedirect>;
    isSeoEnabled: boolean;
    collapseSeoBox: boolean;
}
export class LogoutRedirect {
    fromUrl: Array<string>;
    toUrl: string;
}

// eslint:disable-next-line:typedef
export function promoConfigFactory(clientConfigService: LazyClientConfigService) {
    return clientConfigService.get(PromoClientConfiguration);
}
