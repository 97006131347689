@if (keywordsContent && !hideSeoDetails) {
    <div class="collapsible-container card-bg content-box" [ngClass]="showContent ? '' : 'collapsible-container--collapsed'">
        <div (click)="toggle()" class="header-light collapsible-container__header dh">
            <h1 class="h3-v2 collapsible-container__title">{{ header }}</h1>
            <div class="h5-v2 collapsible-container__arrow">
                <span class="theme-arrow-down"></span>
            </div>
        </div>
        <div class="collapsible-container__content content" [vnDynamicHtml]="keywordsContent"></div>
        <div (click)="toggle()" class="collapsible-container__footer dh text-center">
            <span class="animated-underline">{{ showContent ? commonContent?.messages?.showless : commonContent?.messages?.showmore }}</span>
        </div>
    </div>
}
