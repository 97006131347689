import { Injectable } from '@angular/core';

import { ApiBase, ApiServiceFactory, ProductService } from '@frontend/vanilla/core';

@Injectable({
    providedIn: 'root',
    useFactory: engagementApiServiceFactory,
    deps: [ApiServiceFactory, ProductService],
})
export class EngagementApiService extends ApiBase {}

export function engagementApiServiceFactory(apiServiceFactory: ApiServiceFactory, productService: ProductService) {
    const product = productService.getMetadata('engagement').isEnabled ? 'engagement' : 'promo';
    const forwardProductApiRequestHeader =
        product === 'promo' ? productService.getMetadata('promo').isEnabledProductApi : productService.getMetadata('engagement').isEnabled;

    return apiServiceFactory.createForProduct(EngagementApiService, {
        product: product,
        area: '',
        forwardProductApiRequestHeader: forwardProductApiRequestHeader,
    });
}
