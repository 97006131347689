import { NgClass } from '@angular/common';
import { AfterContentChecked, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';

import { CarouselDefaultConfig } from '@frontend/promo-utils';
import { DeviceService } from '@frontend/vanilla/core';
import { RxFor } from '@rx-angular/template/for';
import { RxIf } from '@rx-angular/template/if';
import { Subject, takeUntil } from 'rxjs';
import Swiper from 'swiper';
import { Navigation } from 'swiper/modules';
import { SwiperOptions } from 'swiper/types';

import { SmartRewardsConstants } from '../../../constants/smart-rewards-constant';
import { Reward } from '../../../models/reward';
import { RewardHistory, RewardMainFilter } from '../../../models/reward-history';
import { PCImageText } from '../../../models/smart-eds-widget-details';
import { SmartRewardsService } from '../../../services/smart-reward-service/smart-rewards.service';
import { SmartRewardTrackingHelperService } from '../../../shared/tracking/smart-reward-tracking-helper.service';
import { RewardsWidgetComponent } from '../reward-widget/rewards-widget.component';

@Component({
    standalone: true,
    selector: 'ph-reward-pills',
    templateUrl: './reward-pills.component.html',
    styleUrl: './reward-pills.component.scss',
    imports: [RxFor, RxIf, NgClass, RewardsWidgetComponent],
})
export class RewardPillsComponent implements OnInit, OnDestroy, AfterContentChecked {
    @Input() smartRewardClientContent: PCImageText;

    mainFilterContent: RewardMainFilter[];
    rewardDict: { [key: string]: RewardHistory };
    filterTypes: string[];
    filteredRewards: Reward[];
    selectedMainTab: string;
    selectedSubTab: string;
    isRewardsLoaded: boolean = false;
    swiper: Swiper;
    config: SwiperOptions;
    isSwiperInit: boolean = false;
    arrows: boolean = false;
    private readonly destroy$ = new Subject<void>();

    @ViewChild('swiperContainer') swiperRef: ElementRef;

    breakpoints = {
        0: {
            slidesPerView: 2,
            slidesPerGroup: 2,
            centeredSlides: false,
        },
        599: {
            slidesPerView: 4,
            slidesPerGroup: 4,
            centeredSlides: false,
        },
    };

    constructor(
        private smartRewardsService: SmartRewardsService,
        public deviceService: DeviceService,
        private carouselDefaultConfig: CarouselDefaultConfig,
        private trackingHelperService: SmartRewardTrackingHelperService,
    ) {}

    ngOnInit() {
        this.setSwiperConfig();
        this.smartRewardsService.rewardHistory$.pipe(takeUntil(this.destroy$)).subscribe((res) => {
            if (res != null && Object.keys(res)?.length > 0) {
                this.isRewardsLoaded = true;
                this.mainFilterContent = res.mainFilter;
                this.rewardDict = res.rewardList;
                this.filterTypes = Object.keys(this.rewardDict);
                if (this.mainFilterContent?.length > 0) {
                    this.mainFilterContent.forEach((x) => {
                        if (x.type == SmartRewardsConstants.FreeSpin) {
                            x.isSelected = true;
                        }
                    });
                    this.selectedMainTab = this.mainFilterContent.find((x) => x.isSelected)?.type ?? '';
                }

                this.selectedSubTab = this.filterTypes.find((x) => this.rewardDict[x]?.isSelected) ?? '';
                this.filterRewardListBasedOnSubFilter(this.selectedSubTab, false);
            }
        });
    }

    onClickMainTab(type: string) {
        if (this.mainFilterContent?.length > 0) {
            this.mainFilterContent.forEach((item) => {
                if (item?.type == type) {
                    item.isSelected = true;
                    this.selectedMainTab = type;
                } else item.isSelected = false;
            });
            this.filterRewardListBasedOnSubFilter(this.selectedSubTab, false);
            const ctaName = type == SmartRewardsConstants.BingoTicket ? SmartRewardsConstants.BingoRewards : type;
            this.trackingHelperService.trackOnCtaClick(ctaName, SmartRewardsConstants.MyRewards, SmartRewardsConstants.Filters);
        }
    }

    filterRewardListBasedOnSubFilter(type: string, isTrackingrequired: boolean = true) {
        if (this.filterTypes?.length > 0) {
            this.filterTypes.forEach((item) => {
                if (item != null) {
                    const rewardItem = this.rewardDict[item] ?? ({} as { [key: string]: RewardHistory });
                    const rewardsList = this.rewardDict[item]?.rewards;
                    const filteredRewards = rewardsList?.filter((x: any) => x.rewardMetaData.rewardType?.toLowerCase() == this.selectedMainTab);

                    if (item == type) {
                        rewardItem.isSelected = true;
                        this.selectedSubTab = type;
                        this.filteredRewards = filteredRewards ?? [];
                    } else {
                        rewardItem.isSelected = false;
                    }
                    rewardItem.rewardCount = filteredRewards?.length ?? 0;
                }
            });
            this.isSwiperInit = false;
            if (isTrackingrequired) {
                const ctaName =
                    (this.selectedMainTab == SmartRewardsConstants.BingoTicket ? SmartRewardsConstants.BingoRewards : this.selectedMainTab) +
                    '/' +
                    this.selectedSubTab;
                this.trackingHelperService.trackOnCtaClick(ctaName, SmartRewardsConstants.MyRewards, SmartRewardsConstants.SubFilters);
            }
        }
    }

    setSwiperConfig() {
        this.config = Object.assign(
            {
                modules: [Navigation],
                spaceBetween: 10,
                breakpoints: this.breakpoints,
            },
            this.carouselDefaultConfig.defaultConfig,
        );
        this.arrows = this.carouselDefaultConfig.arrows;
    }

    ngAfterContentChecked(): void {
        if (this.swiperRef?.nativeElement && !this.isSwiperInit) {
            this.swiper?.destroy();
            this.swiper = new Swiper(this.swiperRef?.nativeElement, this.config);
            this.isSwiperInit = true;
        }
    }

    swipePrev() {
        this.swiper?.slidePrev();
    }

    swipeNext() {
        this.swiper?.slideNext();
    }

    ngOnDestroy() {
        this.swiper?.destroy();
        this.destroy$.next();
        this.destroy$.complete();
    }
}
