@if (
    offer.offerContent?.offerStateString &&
    offer.offerMetadata.isOfferActive &&
    isUserAuthenticated &&
    !offerContentConfiguration?.isBetMgmTileRedesignEnabled
) {
    <div [ngClass]="['status-pills', offerStateClass]">
        {{ offer.offerContent?.offerStateString }}
    </div>
}
@if (
    offer.offerContent?.offerStateString &&
    !offer.offerMetadata.isSpecialOffer &&
    (offer.offerMetadata.isOfferActive || offer.offerMetadata.showExpiredStatus) &&
    isUserAuthenticated &&
    offerContentConfiguration?.isBetMgmTileRedesignEnabled
) {
    <div [ngClass]="['status-pills', offerStateClass]">
        @if (offer.offerMetadata?.showOptedInStatus) {
            <div class="status-pills-wrapper" id="Opt-Success">
                <span class="success theme-success-i Opt-Success"></span><span> {{ offer.offerContent?.offerStateString }}</span>
            </div>
        }
        @if (!offer.offerMetadata?.showOptedInStatus) {
            @if (offer.offerMetadata?.showExpiredStatus) {
                <div class="status-pills-wrapper">
                    <span class="danger theme-error-i"></span><span id="showExpiredStatus">{{ offer.offerContent?.offerStateString }}</span>
                </div>
            }
            @if (!offer.offerMetadata?.showExpiredStatus) {
                <div class="status-pills-wrapper">
                    <span>{{ offer.offerContent?.offerStateString }}</span>
                </div>
            }
        }
    </div>
}
