@if (weeklyGame?.games?.length > 0) {
    <div class="ph-eligible-games smart-reward__eligiblegames">
        <div class="vn-carousel eligibleGamesCarousel">
            <div class="d-flex justify-content-between" [ngClass]="{ 'ph-eligible-games-event': isEventTile }">
                <div class="ph-eligible-games-title">{{ weeklyGame?.title }}</div>
                @if (weeklyGame?.viewAllCta?.text) {
                    <a
                        [href]="weeklyGame?.viewAllCta?.url"
                        (click)="trackOnClickCta(weeklyGame?.viewAllCta?.url)"
                        class="btn btn-link-underlined btn-link-light ph-eligible-games-viewbtn"
                        >{{ weeklyGame?.viewAllCta?.text }}</a
                    >
                }
            </div>
            <div
                class="ph-eligible-games-desc"
                [ngClass]="{ 'ph-eligible-games-event': isEventTile }"
                [vnDynamicHtml]="weeklyGame?.description"></div>
            @if (arrows) {
                <i
                    class="theme-right carousel-next"
                    role="button"
                    aria-label="Next slide"
                    (click)="onSlideNext()"
                    [ngClass]="swiper?.isEnd ? 'swiper-button-disabled' : ''"></i>
                <i
                    class="theme-left carousel-previous"
                    role="button"
                    aria-label="Previous slide"
                    (click)="onSlidePrev()"
                    [ngClass]="swiper?.isBeginning ? 'swiper-button-disabled' : ''"></i>
            }
            <div #swiperContainer class="swiper">
                <div class="swiper-wrapper">
                    @for (game of weeklyGame.games; track game; let i = $index) {
                        <div class="swiper-slide" grayoutStyle>
                            <div>
                                <a class="eg-item">
                                    @if (game?.parameters?.gametileimg) {
                                        <img class="eg-image" [attr.src]="game?.parameters?.gametileimg" [attr.alt]="game?.parameters?.gametilealt" />
                                    }
                                    @if (isEventTile) {
                                        <div class="eg-btn btn btn-secondary btn-sm" (click)="launchGame(game?.playNowCta, i)">
                                            {{ game?.playNowCta?.text }}
                                        </div>
                                    }
                                </a>
                            </div>
                        </div>
                    }
                </div>
            </div>
        </div>
    </div>
}
