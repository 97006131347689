import { AfterViewInit, Component, OnDestroy, Renderer2 } from '@angular/core';

import {
    AdvanceFilter,
    FilterOffersV2,
    FilterTabs,
    LoadOffersApiService,
    NLRegulatoryHelperService,
    OfferDetails,
    OffersApiService,
    TrackingModelService,
} from '@frontend/promo-homewidget';
import { HtmlNode, UserLoginEvent, UserService } from '@frontend/vanilla/core';
import { Subject, Subscription, combineLatest } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

import { OffersPopUpFilterService } from '../../offers-popup-filter/offers-popup-filter.service';
import { FilterBaseComponent } from '../base-filter-page.component';
import { FilterV2Constants } from '../filter-constants';
import { FilterService } from '../filter.service';

@Component({
    selector: 'app-filters-strip',
    templateUrl: './filters-strip.component.html',
    styleUrls: ['./filters-strip.component.scss'],
})
export class FiltersStripComponent extends FilterBaseComponent implements AfterViewInit, OnDestroy {
    mainFilterTabs: FilterTabs[] = new Array<FilterTabs>();
    advanceFilter: AdvanceFilter = new AdvanceFilter();
    allOffers: OfferDetails;
    filterOffersContent: boolean;
    isMainFilterSelected: boolean;

    private userServiceSubscription: Subscription;
    private offersPopUpFilterSubscription: Subscription;
    private readonly destroy$ = new Subject<void>();

    constructor(
        public override offerDataService: OffersApiService,
        public override htmlNode: HtmlNode,
        private offersPopUpFilterService: OffersPopUpFilterService,
        private userService: UserService,
        private trackingModelService: TrackingModelService,
        private renderer: Renderer2,
        public nLRegulatoryHelperService: NLRegulatoryHelperService,
        public override loadOffersApiService: LoadOffersApiService,
        public filterService: FilterService,
    ) {
        super(offerDataService, htmlNode, loadOffersApiService);
        //Close the filter pop up if clicking outside
        this.renderer.listen('window', 'click', (e: any) => {
            const advanceFilterToggleDom = document.getElementById('advanceFilterPopupToggle');
            const advanceFilterPopUpDom = document.getElementById('advanceFilterPopup');
            const advanceFilterPopupCloseDom = document.getElementById('advanceFilterPopupClose');
            if (
                !advanceFilterToggleDom?.contains(e.target) &&
                !advanceFilterPopUpDom?.contains(e.target) &&
                !advanceFilterPopupCloseDom?.contains(e.target) &&
                this.advanceFilter
            ) {
                this.advanceFilter.isAdvanceFilterActive = false;
            }
        });
    }

    ngAfterViewInit(): void {
        this.getContent();
        this.userServiceSubscription = this.userService.events
            .pipe(
                filter((e) => e instanceof UserLoginEvent),
                takeUntil(this.destroy$),
            )
            .subscribe(() => {
                this.getContent();
            });
    }

    checkAdvancedFilter() {
        if (this.advanceFilter) {
            this.advanceFilter.isAdvanceFilterActive = false;
        }
    }

    getContent() {
        this.offersPopUpFilterSubscription = combineLatest([
            this.offersPopUpFilterService.offersFilterV2$.asObservable(),
            this.loadOffersApiService.offerDetails$.asObservable(),
            this.loadOffersApiService.showSkeleton$.asObservable(),
        ])
            .pipe(takeUntil(this.destroy$))
            .subscribe(([filterOffersContent, allOffers, showSkeleton]: [FilterOffersV2, OfferDetails, boolean]) => {
                if (!showSkeleton && filterOffersContent && allOffers?.offerDetailsList?.length > 0) {
                    this.filterOffersContent =
                        filterOffersContent.mainFilter?.length > 0 || filterOffersContent?.advanceFilter?.filterByProductType?.length > 0;
                    this.mainFilterTabs = filterOffersContent.mainFilter;
                    this.allOffers = allOffers;
                    this.getMainFilterTabsCount();
                    this.advanceFilter = this.filterService.getAdvanceFilterTabsCount(filterOffersContent?.advanceFilter, this.allOffers);
                    if (sessionStorage.getItem('isFilterShouldApply') === 'false') {
                        sessionStorage.removeItem('isFilterShouldApply');
                        this.resetAdvanceFilter();
                        this.clearMainFilters(this.mainFilterTabs ?? []);
                    }
                }
            });
    }
    // Calculate the Main filter count and filter the offer indexes based on each filter (filteredOfferIndexes)
    getMainFilterTabsCount() {
        if (this.mainFilterTabs && this.mainFilterTabs.length > 0) {
            this.mainFilterTabs.map((q) => {
                (q.filteredOfferIndexes = []), (q.count = 0);
            });
            const newTab = this.mainFilterTabs.find((q) => q.name === FilterV2Constants.NewTab);
            const activeTab = this.mainFilterTabs.find((q) => q.name === FilterV2Constants.ActiveTab);
            const promotionsTab = this.mainFilterTabs.find((q) => q.name === FilterV2Constants.PromotionsTab);
            const rewardsTab = this.mainFilterTabs.find((q) => q.name === FilterV2Constants.RewardsTab);
            for (let i = 0; i < this.allOffers.offerDetailsList.length; i++) {
                //100
                const offerMetaData = this.allOffers.offerDetailsList[i].offerMetadata;
                if (offerMetaData && !offerMetaData.isGroupedTile && offerMetaData.applicableProducts?.length > 0) {
                    if (newTab && offerMetaData.isNewOffer) {
                        newTab.filteredOfferIndexes.push(i);
                        newTab.count++;
                    }

                    if (activeTab && offerMetaData.isOfferActive) {
                        activeTab.filteredOfferIndexes.push(i);
                        activeTab.count++;
                    }

                    if (promotionsTab) {
                        const includeTypeOfPromotions = promotionsTab.messages[FilterV2Constants.TypeOfPromotions]?.split(',');
                        if (
                            includeTypeOfPromotions &&
                            includeTypeOfPromotions.length > 0 &&
                            includeTypeOfPromotions.includes(offerMetaData.serviceType.toLocaleLowerCase())
                        ) {
                            promotionsTab.filteredOfferIndexes.push(i);
                            promotionsTab.count++;
                        }
                    }

                    if (rewardsTab) {
                        const ExcludeTypeOfPromotions = rewardsTab.messages[FilterV2Constants.ExcludePromotiosForRewards].split(',');
                        if (
                            ExcludeTypeOfPromotions &&
                            ExcludeTypeOfPromotions.length > 0 &&
                            !ExcludeTypeOfPromotions.includes(offerMetaData.serviceType.toLocaleLowerCase())
                        ) {
                            rewardsTab.filteredOfferIndexes.push(i);
                            rewardsTab.count++;
                        }
                    }
                }
            }
            this.mainFilterTabs.forEach((x) => {
                if (x.isActive) this.updateOfferCategories(x.filteredOfferIndexes, this.allOffers.offerCategories);
            });
        }
    }

    applyMainFilter(filterTab: FilterTabs, position: any) {
        this.checkAdvancedFilter();
        this.mainFilterTabs.forEach((res) => {
            if (filterTab.name === res.name) {
                filterTab.isActive = !filterTab.isActive;
                this.isMainFilterSelected = filterTab.isActive;
            } else {
                res.isActive = false;
            }
            if (res.isActive) {
                this.updateTabPosition(res);
                this.updateOfferCategories(res.filteredOfferIndexes, this.allOffers.offerCategories);
            }
        });
        this.trackAdvanceFiltersOnClick(filterTab?.text?.toLowerCase(), position + 1);
        if (this.isMainFilterSelected) {
            this.clearAdvanceFilter(this.advanceFilter);
            this.checkAdvancedFilter();
        } else {
            this.updateOfferCategories([], this.allOffers.offerCategories);
        }
    }

    updateTabPosition(filterTab: FilterTabs) {
        //Change the main filter tab position if any main is applied
        this.mainFilterTabs.splice(
            this.mainFilterTabs.findIndex((q) => q.name === filterTab.name),
            1,
        );
        this.mainFilterTabs.splice(0, 0, filterTab);
    }

    filterPopUpClicked(eventData: string) {
        if (this.advanceFilter) {
            this.advanceFilter.isAdvanceFilterActive = !this.advanceFilter.isAdvanceFilterActive;

            this.trackAdvanceFiltersOnClick(eventData);
            if (this.advanceFilter.isAdvanceFilterActive) {
                this.htmlNode.setCssClass('offer-filters-dialog-opened', true);
            } else {
                this.htmlNode.setCssClass('offer-filters-dialog-opened', false);
            }
        }
    }

    filterPopUpClose(): void {
        this.checkAdvancedFilter();
        this.htmlNode.setCssClass('offer-filters-dialog-opened', false);
    }

    resetAdvanceFilter() {
        this.updateOfferCategories([], this.allOffers.offerCategories);
        this.clearAdvanceFilter(this.advanceFilter);
        this.checkAdvancedFilter();
    }

    ngOnDestroy(): void {
        if (this.userServiceSubscription) {
            this.userServiceSubscription.unsubscribe();
        }
        if (this.offersPopUpFilterSubscription) {
            this.offersPopUpFilterSubscription.unsubscribe();
        }
        this.destroy$.next();
        this.destroy$.complete();
    }

    trackAdvanceFiltersOnClick(eventData: string, position?: any) {
        this.trackingModelService.submitTracking({
            CategoryEvent: 'promo hub',
            LabelEvent: 'advance filters',
            ActionEvent: 'click',
            PositionEvent: position,
            EventDetails: eventData?.toLocaleLowerCase(),
            LocationEvent: 'promo hub',
        });
    }

    trackResetFilterOnClick() {
        this.trackingModelService.submitTracking({
            CategoryEvent: 'promo hub',
            LabelEvent: 'advance filters',
            ActionEvent: 'click',
            LocationEvent: 'advance filters',
            EventDetails: 'reset',
        });
    }
}
