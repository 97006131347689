import { Component, OnDestroy, OnInit } from '@angular/core';

import { DynamicHtmlDirective, UserService } from '@frontend/vanilla/core';
import { RxIf } from '@rx-angular/template/if';
import { Subject, takeUntil } from 'rxjs';

import { SmartRewardsConstants } from '../../constants/smart-rewards-constant';
import { ToastrHelperService } from '../../services/toastr-helper-service/toastr-helper.service';
import { TrackingModelService } from '../tracking/tracking-model-service';

@Component({
    selector: 'ph-smart-reward-toastr',
    templateUrl: './smart-reward-toastr.component.html',
    styleUrl: './smart-reward-toastr.component.scss',
    standalone: true,
    imports: [DynamicHtmlDirective, RxIf],
})
export class SmartRewardToastrComponent implements OnDestroy, OnInit {
    showToastr: boolean = false;
    data: any;

    private readonly destory$ = new Subject<void>();

    constructor(
        public user: UserService,
        private toastrHelperService: ToastrHelperService,
        private trackingModelService: TrackingModelService,
    ) {}

    ngOnInit() {
        this.toastrHelperService?.onToastrActivation$?.pipe(takeUntil(this.destory$)).subscribe((data: any) => {
            this.showToastr = false;
            if (Object?.keys(data)?.length > 0) {
                this.showToastr = true;
                this.data = data;
                if (Object.keys(data?.trackingModel)?.length > 0) {
                    this.trackingModelService.submitTracking(data.trackingModel, SmartRewardsConstants.ContentView);
                }
                setTimeout(() => {
                    this.showToastr = false;
                    this.toastrHelperService.onToastrActivation$.next({});
                }, 5000);
            }
        });
    }

    ngOnDestroy(): void {
        this.destory$.next();
        this.destory$.complete();
    }
}
