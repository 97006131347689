<div class="pc-menu-item-container">
    <vn-menu-item class="pc-menu-item" [item]="item" [section]="section" linkClass="pc-menu-link" (onClick)="onClick($event)" />
    @if (item.children && item.children.length) {
        <div class="pc-menu-item-indicator">
            <span
                class="ui-icon ui-icon-size-sm"
                [ngClass]="{ 'theme-plus': !item.expanded, 'theme-minus': item.expanded }"
                (click)="item.expanded = !item.expanded"></span>
        </div>
    }
</div>
@if (item.children) {
    <div class="pc-menu-submenu pc-menu-level-{{ level }}" [class.collapsed]="!item.expanded">
        @for (subItem of item.children; track trackByText($index, subItem)) {
            <ng-container *vnDynamicComponent="type; attr: { item: subItem, section: section, level: level + 1 }" />
        }
    </div>
}
