<div *ngIf="promoClientConfigLoaded" class="bet-station-offer-container">
    <div class="betstation-offers">
        <ng-scrollbar viewClass="scroll-container__viewport" class="scroll-container__scroller ng-scrollbar">
            <offers-skeleton *ngIf="offersAreLoading; else offersLoaded"></offers-skeleton>
            <ng-template #offersLoaded>
                <div class="bonuses-home-page offer-teaser-items-nocarousel">
                    <ng-container *ngFor="let offer of offerDetailsList; let i = index">
                        <bet-station-offer-tile-teaser class="offers-teaser-wrapper" [offer]="offer" [teaserNumber]="i">
                        </bet-station-offer-tile-teaser>
                    </ng-container>
                </div>
            </ng-template>
        </ng-scrollbar>
    </div>
</div>
