import { NgClass } from '@angular/common';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';

import { RGStatusService } from '@frontend/promo-utils';
import {
    ClientConfigService,
    ContentImage,
    DeviceService,
    DynamicHtmlDirective,
    HtmlNode,
    UserLoginEvent,
    UserLogoutEvent,
    UserService,
} from '@frontend/vanilla/core';
import { RxFor } from '@rx-angular/template/for';
import { RxIf } from '@rx-angular/template/if';
import { RxPush } from '@rx-angular/template/push';
import { Observable, Subject, filter, takeUntil, tap } from 'rxjs';

import { SmartRewardConfiguration } from '../../config/smart-reward-config';
import { SmartRewardsConstants } from '../../constants/smart-rewards-constant';
import { SmartEdsWidgetDetails } from '../../models/smart-eds-widget-details';
import { SmartRewardRequest } from '../../models/smart-reward-request';
import { HowItWorksContent } from '../../models/smart-rewards-details.model';
import { SmartRewardsService } from '../../services/smart-reward-service/smart-rewards.service';
import { ToastrHelperService } from '../../services/toastr-helper-service/toastr-helper.service';
import { OverlayDialogModal } from '../../shared/dialog/OverlayDialogModal';
import { OverlayInfoDialogComponent } from '../../shared/dialog/about-smart-rewards/overlay-info-dialog/overlay-info-dialog.component';
import { SmartRewardToastrComponent } from '../../shared/smart-reward-toastr/smart-reward-toastr.component';
import { SmartRewardTrackingHelperService } from '../../shared/tracking/smart-reward-tracking-helper.service';
import { PostOptinWidgetComponent } from '../post-optin-widget/post-optin-widget.component';
import { PreOptinWidgetComponent } from '../pre-optin-widget/pre-optin-widget.component';

@Component({
    selector: 'ph-eds-smart-widget',
    standalone: true,
    templateUrl: './eds-smart-widget.component.html',
    styleUrl: './eds-smart-widget.component.scss',
    imports: [NgClass, RxIf, RxFor, RxPush, SmartRewardToastrComponent, PostOptinWidgetComponent, PreOptinWidgetComponent, DynamicHtmlDirective],
})
export class EdsSmartWidgetComponent implements OnInit, OnDestroy {
    @Input() shouldLoadClientConfig: boolean = true;

    edsSmartWidgetDetails$: Observable<SmartEdsWidgetDetails>;
    isUserAuthenticated: boolean = false;
    isOptinClicked: boolean = false;
    campaignId: string;
    backGroundImage: ContentImage;
    showOptinCta: boolean = false;
    showEdsWidget: boolean = false;
    showRgStatus: boolean = true;

    private readonly destroy$ = new Subject<void>();

    constructor(
        private user: UserService,
        private smartRewardsService: SmartRewardsService,
        private toastrHelperService: ToastrHelperService,
        private trackingHelperService: SmartRewardTrackingHelperService,
        private deviceService: DeviceService,
        private rgStatusService: RGStatusService,
        private smartRewardConfig: SmartRewardConfiguration,
        private clientConfigService: ClientConfigService,
        private overlayDialogModal: OverlayDialogModal,
        private htmlNode: HtmlNode,
    ) {
        this.loadSmartWidgetDetails();
        this.user.events
            .pipe(
                filter((e) => e instanceof UserLoginEvent || e instanceof UserLogoutEvent),
                takeUntil(this.destroy$),
            )
            .subscribe(() => {
                this.showOptinCta = false;
                this.loadSmartWidgetDetails();
            });
    }

    async ngOnInit() {
        await this.loadClientConfig();
        this.edsSmartWidgetDetails$ = this.smartRewardsService.smartEdsWidgetDetails$.pipe(
            tap((res) => {
                if (Object.keys(res)?.length > 0) {
                    this.showEdsWidget = false;
                    if (Object.keys(res?.smartEdsWidgetMetaData)?.length > 0 && !res?.smartEdsWidgetMetaData?.isOptedIn) {
                        this.showOptinCta = true;
                    }
                    this.campaignId = res?.smartEdsWidgetMetaData?.campaignId;
                    this.smartRewardsService.setCampaignId(this.campaignId);
                    this.backGroundImage = this.deviceService.isMobilePhone
                        ? res?.smartEdsWidgetContent?.mobileBackGroundImage
                        : res?.smartEdsWidgetContent?.desktopBackGroundImage;

                    if (this.isOptinClicked && res?.smartEdsWidgetMetaData?.isOptedIn) {
                        this.trackingHelperService.trackOnOptinSuccess(SmartRewardsConstants.Widget, SmartRewardsConstants.OptinSuccess);
                        const parameters = res?.smartEdsWidgetContent?.parameters;
                        const data = this.toastrHelperService.setToastrData(
                            parameters?.optintoastrtitle,
                            parameters?.optintoastrdescription,
                            true,
                            true,
                            parameters,
                        );
                        this.toastrHelperService.onToastrActivation$.next(data);
                        this.isOptinClicked = false;
                    }
                    if (Number(res?.smartEdsWidgetMetaData?.errorCode) != SmartRewardsConstants.ErrorCode) {
                        this.showEdsWidget = true;
                    }
                }
            }),
        );
        this.htmlNode.setCssClass('smart-rewards', true);
    }

    async loadClientConfig() {
        if (this.shouldLoadClientConfig && !this.smartRewardConfig?.isConfigReady) {
            await this.clientConfigService.load('', [SmartRewardConfiguration]);
        }
    }

    loadSmartWidgetDetails(campaignId: string = '1', requestType: string = '') {
        this.isUserAuthenticated = this.user.isAuthenticated;
        this.showRgStatus = !this.rgStatusService.claimsStatusCheck();
        const request = { campaignId: campaignId, requestedFor: SmartRewardsConstants.EDSWidget, requestType: requestType } as SmartRewardRequest;
        this.smartRewardsService.loadSmartWidgetDetails(request);
    }

    openDialog(howItWorksContent: HowItWorksContent) {
        const ctaName = SmartRewardsConstants.SmartRewardMoreInfo;
        this.trackingHelperService.trackOnCtaClick(ctaName, SmartRewardsConstants.Widget);
        const componentType = OverlayInfoDialogComponent;
        const data = {
            howItWorksContent: howItWorksContent,
            isEdsWidget: true,
        };
        this.overlayDialogModal.openDialogModal(componentType, data);
    }

    optinClick() {
        this.isOptinClicked = true;
        this.loadSmartWidgetDetails(this.campaignId, SmartRewardsConstants.Optin);
    }

    ngOnDestroy() {
        this.destroy$.next();
        this.destroy$.complete();
        this.htmlNode.setCssClass('smart-rewards', false);
    }
}
