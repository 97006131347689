@if (vipHubConfiguration.enableVIPFeature) {
    <toggle-navigation></toggle-navigation>
}
<div *ngIf="epcotEnabled && deviceService.isMobile; else normal_subnav_template">
    <div [ngClass]="{ 'offers-navigation-enable-v1': subNavigationV1Enabled }">
        <div *ngIf="promotionsBackButtonEnabled && !hidePromoBackButton">
            <lh-header-bar [content]="promotionsBackButtonText" [disableClose]="true" [showBackButton]="true" (onBack)="backNavigation()">
            </lh-header-bar>
        </div>
    </div>
    <div
        *ngIf="offerTitle && offerContentConfiguration?.subNavigationProperties['showTitle']"
        class="promo-offers-title d-flex justify-content-center h5-v2 header-light">
        {{ offerTitle }}
    </div>
    <div [ngClass]="{ 'offers-navigation-enable-v1': subNavigationV1Enabled }">
        <div class="offers-navigation-epcot">
            <div *ngIf="!isGroupPage; else epcot_mobile_view_template">
                <nav *ngIf="menuItems?.length > 0" class="offers-navigation navbar navbar-expand-sm sub-nav-wrapper" [ngClass]="menuContainer?.class">
                    <ul class="tab-nav navbar-nav">
                        <li *ngFor="let menuItem of menuItems; let i = index" class="tab-nav-item" [class.active]="menuItem.name === sourceItem">
                            <a (click)="processClick($event, menuItem, i + 1)" class="tab-nav-link" [attr.href]="menuItem?.url">
                                <div
                                    id="tab-{{ menuItem.name }}"
                                    class="tab-nav-text"
                                    [ngClass]="subNavigationV1Enabled && !!menuItem?.class ? menuItem.class : ''">
                                    <div *ngIf="offerContentConfiguration?.subNavigationProperties['showImage']" class="tab-nav-img">
                                        <img
                                            [attr.src]="menuItem.image?.src"
                                            [attr.alt]="menuItem.image?.alt"
                                            [width]="menuItem.image?.width"
                                            [height]="menuItem.image?.height" />
                                    </div>
                                    <span>{{ menuItem.text }}</span>
                                </div>
                            </a>
                        </li>
                    </ul>
                    <ul
                        *ngIf="isHomePage && filterOffersContent && !isLobbyEmpty && !!!filterConfiguration?.isFilterV2Enabled"
                        class="navbar-wrapper nav nav-pills offers-filter-nav">
                        <li class="nav-item d-flex align-items-center">
                            <a
                                *ngIf="filterOffersContent.filterOffersText"
                                class="pill-with-badge"
                                (click)="filterPopUpClicked()"
                                [class.active]="selectedFilters && selectedFilters.length > 0">
                                <span class="badge badge-circle badge-warning" *ngIf="selectedFilters && selectedFilters.length > 0">
                                    {{ selectedFilters.length }}
                                </span>
                                <span class="theme-filter-v2"></span>
                                {{ filterOffersContent.filterOffersText.title }}
                            </a>
                        </li>
                    </ul>
                </nav>
            </div>
            <ng-template #epcot_mobile_view_template class="offers-navigation navbar navbar-expand-sm sub-nav-wrapper">
                <div>
                    <div *ngIf="mobileViewTitle">
                        <lh-header-bar
                            [content]="mobileViewTitle"
                            [disableClose]="disableClose"
                            (onClose)="processCancel()"
                            [showBackButton]="showBackArrow"
                            (onBack)="processBack()">
                        </lh-header-bar>
                    </div>
                </div>
            </ng-template>
        </div>
    </div>
</div>
<ng-template #normal_subnav_template>
    <div [ngClass]="{ 'offers-navigation-enable-v1': subNavigationV1Enabled }" class="offers-in-progress-nav">
        <div *ngIf="canRenderMenu; else mobile_view_template">
            <nav *ngIf="menuItems?.length > 0" class="offers-navigation navbar navbar-expand-sm sub-nav-wrapper" [ngClass]="menuContainer?.class">
                <ul class="tab-nav navbar-nav">
                    <li *ngFor="let menuItem of menuItems; let i = index" class="tab-nav-item" [class.active]="menuItem.name === sourceItem">
                        <a (click)="processClick($event, menuItem, i + 1)" class="tab-nav-link" [attr.href]="menuItem?.url">
                            <div class="tab-nav-img">
                                <img
                                    *ngIf="!isDesktop && !subNavigationV1Enabled"
                                    [attr.src]="menuItem.image?.src"
                                    [attr.alt]="menuItem.image?.alt"
                                    [width]="menuItem.image?.width"
                                    [height]="menuItem.image?.height" />
                            </div>
                            <div id="menuItemId" class="tab-nav-text">
                                <span>{{ menuItem.text }}</span>
                            </div>
                        </a>
                    </li>
                </ul>
                <ul
                    *ngIf="isHomePage && filterOffersContent && !isLobbyEmpty && !!!filterConfiguration.isFilterV2Enabled"
                    class="navbar-wrapper nav nav-pills offers-filter-nav">
                    <li class="nav-item d-flex align-items-center">
                        <a
                            *ngIf="filterOffersContent.filterOffersText"
                            class="pill-with-badge"
                            (click)="filterPopUpClicked()"
                            [class.active]="selectedFilters && selectedFilters.length > 0">
                            <span class="badge badge-circle badge-warning" *ngIf="selectedFilters && selectedFilters.length > 0">
                                {{ selectedFilters.length }}
                            </span>
                            <span class="theme-filter-v2"></span>
                            {{ filterOffersContent.filterOffersText.title }}
                        </a>
                    </li>
                </ul>
            </nav>
        </div>
        <ng-template #mobile_view_template class="offers-navigation navbar navbar-expand-sm sub-nav-wrapper">
            <div>
                <div *ngIf="mobileViewTitle">
                    <lh-header-bar
                        [content]="mobileViewTitle"
                        [disableClose]="disableClose"
                        (onClose)="processCancel()"
                        [showBackButton]="showBackArrow"
                        (onBack)="processBack()">
                    </lh-header-bar>
                </div>
            </div>
        </ng-template>
    </div>
</ng-template>
<app-filters-strip
    *ngIf="isHomePage && !!filterConfiguration?.isFilterV2Enabled"
    [ngClass]="{ 'offers-navigation-enable-v1': subNavigationV1Enabled }"></app-filters-strip>
