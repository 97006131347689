import { Injectable } from '@angular/core';

import { NavigationService, Page } from '@frontend/vanilla/core';

@Injectable()
export class ErrorHandlerService {
    errorMessage = new Error();
    constructor(private page: Page, private navigationService: NavigationService) {}

    addError(error: any) {
        this.errorMessage = error;
        const errorPageUrl = `/${this.navigationService.location.culture}/${this.page.product}/servererror`;
        this.navigationService.goTo(errorPageUrl, { skipLocationChange: true });
    }
}
