@if (mainFilterContent) {
    <div class="smart-container__heading--myreward d-flex align-items-center">
        {{ smartRewardClientContent?.parameters?.myrewards }}
    </div>
    <div class="my-rewards d-flex justify-content-center flex-wrap">
        @for (mainTab of mainFilterContent; track mainTab) {
            <div
                class="my-rewards__item d-flex align-items-center flex-column justify-content-between"
                (click)="onClickMainTab(mainTab?.type)"
                [ngClass]="{ active: mainTab?.isSelected }">
                <img class="my-rewards__img" [attr.src]="mainTab?.image?.src" [attr.alt]="mainTab?.image?.alt" />
                @if (mainTab?.isNewOfferAvailable) {
                    <span class="my-rewards__badge badge-circle badge-size-sm badge-secondary"></span>
                }
                <div class="my-rewards__title">{{ mainTab?.title }} ({{ mainTab?.count }})</div>
            </div>
        }
    </div>
}

@if (filterTypes?.length > 0) {
    <div class="d-flex justify-content-center">
        <ul class="nav nav-pills filter-container filter-pills">
            @for (subTab of filterTypes; track subTab) {
                <li class="nav-item filter-pills__item" (click)="filterRewardListBasedOnSubFilter(subTab)">
                    <a class="nav-link pill-btn-active filter-pills__link" [ngClass]="{ active: rewardDict[subTab]?.isSelected }">
                        <span class="filter-pills__text">{{ smartRewardClientContent?.parameters[subTab] }}</span>
                        <span class="filter-pills__count">({{ rewardDict[subTab]?.rewardCount }})</span>
                    </a>
                </li>
            }
        </ul>
    </div>
}

@if (isRewardsLoaded) {
    @if (filteredRewards?.length > 0) {
        @if (filteredRewards.length > 4 && arrows && !deviceService?.isMobilePhone) {
            @if (swiper) {
                <div class="smart-swiper-arrows">
                    <div class="vn-carousel">
                        <i
                            class="theme-left carousel-previous"
                            role="button"
                            aria-label="Previous slide"
                            (click)="swipePrev()"
                            [ngClass]="swiper?.isBeginning ? 'swiper-button-disabled' : ''"></i>
                        <i
                            class="theme-right carousel-next"
                            role="button"
                            aria-label="Next slide"
                            (click)="swipeNext()"
                            [ngClass]="swiper?.isEnd ? 'swiper-button-disabled' : ''"></i>
                    </div>
                </div>
            }
            <div class="vn-carousel topCarousel smart-container__wrapper d-flex justify-content-center">
                <div #swiperContainer class="swiper">
                    <div class="swiper-wrapper">
                        @for (reward of filteredRewards; track reward; let i = $index) {
                            <div class="swiper-slide">
                                <ph-rewards-widget
                                    [reward]="reward"
                                    [smartRewardClientContent]="smartRewardClientContent"
                                    [position]="i"
                                    class="smart-widget smart-widget--sm"></ph-rewards-widget>
                            </div>
                        }
                    </div>
                </div>
            </div>
        } @else {
            <div class="smart-container__wrapper d-flex justify-content-center flex-wrap">
                @for (reward of filteredRewards; track reward; let i = $index) {
                    <ph-rewards-widget
                        [reward]="reward"
                        [smartRewardClientContent]="smartRewardClientContent"
                        [position]="i"
                        class="smart-widget smart-widget--sm"></ph-rewards-widget>
                }
            </div>
        }
    } @else {
        <div class="smart-container__noreward">
            <img
                class="smart-widget__bg"
                [attr.src]="smartRewardClientContent?.image?.src"
                [attr.alt]="smartRewardClientContent?.image?.alt"
                loading="lazy"
                width="150" />
            <div class="pt-3 smart-container__noreward-title">{{ smartRewardClientContent?.parameters?.norewardsfound }}</div>
            <div class="pt-2 smart-container__noreward-subtitle">{{ smartRewardClientContent?.parameters?.keepplaying }}</div>
        </div>
    }
}
