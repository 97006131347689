import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';

import { LastKnownProductConfig } from '@frontend/vanilla/core';

@Injectable()
export class PromoInternalRouteGuard implements CanActivate {
    constructor(private productConfig: LastKnownProductConfig) {}
    canActivate() {
        if (this.productConfig?.product?.toLocaleLowerCase() === 'promo') {
            return true;
        }
        return false;
    }
}
