<ng-container *ngrxLet="vm$ as vm">
    <ng-container *ngIf="vm">
        <div class="offer-badge txt-s" *ngIf="showOfferType">
            <span *ngIf="vm.badgesDot && offer.offerMetadata?.isNewOffer" class="badge-separation-dot">{{ vm.badgesDot }}</span>
            <span
                *ngIf="!!offer.offerContent?.offerType?.text"
                [ngClass]="
                    offer.offerContent?.offerType?.cssClass ? offer.offerContent?.offerType?.cssClass : 'badge-product badge-sitecore-promotions'
                ">
                {{ offer.offerContent?.offerType?.text }}
            </span>
        </div>
    </ng-container>
</ng-container>
