import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { PlainLinkComponent } from '@frontend/vanilla/core';
import { LhHeaderBarComponent } from '@frontend/vanilla/features/header-bar';
import { ToggleNavigationComponent } from '@frontend/vip-hub-feature';
import { LetDirective } from '@ngrx/component';

import { OffersNavigationV2Component } from '../offers-navigation-v2/offers-navigation-v2.component';
import { FilterPopupComponent } from '../offers-popup-filter-v2/filter-popup/filter-popup.component';
import { FiltersStripComponent } from '../offers-popup-filter-v2/filters-strip/filters-strip.component';
import { OffersNavigationComponent } from './offers-navigation.component';

@NgModule({
    imports: [CommonModule, LetDirective, LhHeaderBarComponent, PlainLinkComponent, ToggleNavigationComponent],
    declarations: [OffersNavigationComponent, FiltersStripComponent, FilterPopupComponent, OffersNavigationV2Component],
    exports: [OffersNavigationComponent, OffersNavigationV2Component, FilterPopupComponent],
})
export class OffersNavigationModule {}
