import { OfferType } from '@frontend/promo-homewidget';
import { ContentImage, ContentLink } from '@frontend/vanilla/core';

export class BaseDetailsContent {
    title: string;
    totalOfferInfo: string;
    awardedAmountEarnedInfo: string;
    activateNow: ContentLink;
    playNow: ContentLink;
    depositNow: ContentLink;
    description: string;
    teaserImage: ContentImage;
    keyTermsAndConditions: string;
    manualTermsAndConditions: string;
    duration: string;
    titleIcon: string;
    termsAndConditions: string;
    fullTermsAndConditions: string;
    dataPoints: BaseDetailsDataPoint[];
    offersDetailsPageValidity: OffersDetailsPageValidity;
    primaryDataPoints: BaseDetailsDataPoint[];
    offerType: OfferType;
    betNow: ContentLink;
    showBetNowCta: boolean;
    applicableSports: string;
    sportsGameType: string;
    eventsAndLeaguesAlternateText: string;
    transparencyBoxDescription: string;
    expiryText: string;
    expiryClass: string;
    progressBarImage: ContentImage;
    headerIcon: ContentImage;

    constructor(obj?: any) {
        Object.assign(this, obj);
    }
}

export class BonusDetailsDataPoint {
    title: string;
    bonusDescriptions: BonusDescription[];
    titles: BonusDescription[];
    toolTip: string;
    toolTipType: ToolTipType;
    isToolTipWithTitle: boolean;
    constructor(obj?: any) {
        Object.assign(this, obj);
    }
}

export class BonusDescription {
    key: string;
    value: string;
}

export class BaseDetailsDataPoint {
    title: string;
    description: string;
    toolTip: string;
    toolTipType: ToolTipType;
    toolTipTitle: string;
    isToolTipWithTitle: boolean;
    borderBottomRequired: boolean;
}

export enum ToolTipType {
    popper = 'popper',
    simple = 'simple',
}

export class EligibleGamesResponse {
    gameImageBaseUrl: string;
    gameLaunchBaseUrl: ContentLink;
    gameLaunchNames?: string[];
    constructor(obj?: any) {
        Object.assign(this, obj);
    }
}

export class BaseMetaData {
    offerId: string;
    bonusId: string;
    currencyAmount: string;
    isAwarded: boolean;
    isDepositRequired: boolean;
    bonusCode: string;
    isGeneral: boolean;
    crmServiceSubType: string;
    rewardTargetingType: string;
    isVIPExclusive: boolean;
    showOptedInStatus: boolean;
    showExpiredStatus: boolean;
    showInprogressStatus: boolean;
    offerStatus: any;
}

export class CampaignDetailsPageMetadata extends BaseMetaData {
    promoStartTime: Date;
    promoEndTime: Date;
    ctaList: Array<string>;
    cmsItemIdentifier: string;
}

export class AcceptOffer {
    awardId: number;
    code: number;
    message: string;
}

export enum BonusStatusVanilla {
    New = 'New',
    Accepted = 'Accepted',
    Active = 'Active',
    Waiting = 'Waiting',
    Closed = 'Closed',
}

export class OffersDetailsPageValidity {
    title: string;
    startDateTime: DateAndTime;
    expiryDateTime: DateAndTime;
}

export class DateAndTime {
    header: string;
    date: string;
    time: string;
}
