import { createAction, props } from '@ngrx/store';

import { OptInContent } from '../models/opt-in-content';
import { OptInDetails } from '../models/opt-in-details';
import { NoPrize } from '../models/results/no-prize';
import { ResultsContent } from '../models/results/results-content';
import { TargetPromotionContent } from '../models/target-promotion-content';
import { TargetPromotionMetadata } from '../models/target-promotion-metadata';

export const targetPromotionInit = createAction('[Target Promotion] Init');
export const targetPromotionDestroyed = createAction('[Target Promotion] Destroyed');
export const updateTabs = createAction('[Target Promotion] Update tabs', props<{ tabs: string[] }>());
export const updateResultsContent = createAction('[Target Promotion] Update rewards', props<{ resultsContent: ResultsContent }>());
export const loadTargetPromotionData = createAction('[Target Promotion] LoadData', props<{ promoId: string }>());
export const userOptedInForPromotion = createAction('[Target Promotion] OptedIn', props<{ promoId: string }>());
export const userOptInFailedForPromotion = createAction('[Target Promotion] OptedIn_Failed');
export const userOptInSuccessForPromotion = createAction(
    '[Target Promotion] OptedIn_Success',
    props<{ optinDetails: OptInDetails; optinContent: OptInContent }>(),
);
export const loadTargetPromotionData_Success = createAction(
    '[Target Promotion] Load_Success',
    props<{
        metadata: TargetPromotionMetadata;
        content: TargetPromotionContent;
    }>(),
);
export const loadTargetPromotionData_Fail = createAction('[Target Promotion] Load_Fail');

export const userOptInInvalidPromoIdForPromotion = createAction('[Target Promotion] OptedIn_InvalidPromoId');
export const userOptedFailedForPromotion = createAction('[Target Promotion] OptedIn_AlreadyOpted');
export const userOptedFailedForExpiredPromo = createAction('[Target Promotion] OptedIn_ExpiredPromo');
export const updateNoPrize = createAction('[Target Promotion] Update_No_Prize', props<{ noPrizeContent: NoPrize }>());
