import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class ToggleNavigationService {
    isPromoActive: boolean = true;
    promoOffersRoute: string = '';
    vipHubRoute: string = '';
}
