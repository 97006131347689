export class OffersWebTrackingConstants {
    public static readonly Promo: string = 'promo';
    public static readonly offersPage: string = 'offers page';
    public static readonly PromoHub: string = 'promo hub';
    public static readonly MoreInfoCta: string = 'more info cta';
    public static readonly Click: string = 'click';
    public static readonly PromoOffersPage: string = 'promo offers page';
    public static readonly OptInCta: string = 'opt in cta';
    public static readonly SuccessOptIn: string = 'success opt-in';
    public static readonly YouHaveOptedIn: string = 'you have opted in';
    public static readonly PromoOptinFail: string = 'PromoOptinFail';
    public static readonly ErrorOptIn: string = 'Error opt-in';
    public static readonly StaticPromotion: string = 'Static Promotion';
    public static readonly DynamicPromotion: string = 'Dynamic Promotion';
    public static readonly PromoClicked: string = 'promo - clicked';
    public static readonly Group_: string = 'group_';
    public static readonly PromoGroupOffersPage: string = 'promo group offers page';
    public static readonly PromoOffersHomePage: string = 'promo offers home page';
}
