import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { DeviceService, NavigationService, Page } from '@frontend/vanilla/core';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject } from 'rxjs';

import { OffersConstants } from '../offers/common-constants/offers-constants';
import { OfferContentConfiguration } from '../offers/offer-content.client-config';
import { OffersPageEnhancedConfiguration } from '../offers/offers-page-enhanced.client-config';
import { LoadOffersApiService } from '../offers/offers-service/load-offers.service';
import { RoutingHistory } from '../route-history/route-history.service';

@Injectable({
    providedIn: 'root',
})
export class CommonService {
    private position: string = 'top';
    private timeout: number = 5000;
    isOfferPageEnhanced$ = new BehaviorSubject<boolean>(false);

    constructor(
        private toastrService: ToastrService,
        private navigationService: NavigationService,
        private page: Page,
        private location: Location,
        private route: ActivatedRoute,
        private routingHistory: RoutingHistory,
        private offerContentConfiguration: OfferContentConfiguration,
        private deviceService: DeviceService,
        public offerPageEnhancedConfig: OffersPageEnhancedConfiguration,
        public loadOffersApiService: LoadOffersApiService,
    ) {}

    showMessage(text?: string, title?: string, type?: string) {
        if (type == undefined) {
            type = 'success';
        }
        this.toastrService.show(
            text,
            title,
            {
                positionClass: `toast-${this.position}`,
                timeOut: this.timeout,
                disableTimeOut: this.timeout === 0,
                closeButton: true,
            },
            this.toastrService.toastrConfig.iconClasses[type],
        );
    }

    getOrientation() {
        this.deviceService.orientation.subscribe((mode: string) => {
            if (this.offerPageEnhancedConfig.isOffersPageEnhancedEnabled && this.deviceService.isMobile && mode == OffersConstants.Portrait) {
                this.isOfferPageEnhanced$.next(true);
            } else {
                this.isOfferPageEnhanced$.next(false);
            }
        });
    }

    getIsOfferPageEnhanced() {
        return this.isOfferPageEnhanced$.asObservable();
    }

    goToOverviewPage(forceReload = false, queryParamsHandling: any = null) {
        const updatedPageUrl = `/${this.navigationService.location.culture}/${this.offerContentConfiguration.homePage}`;

        this.navigationService.goTo(updatedPageUrl, {
            forceReload: forceReload,
            queryParamsHandling: queryParamsHandling,
        });
    }

    getBonusPageUrl() {
        return `${this.navigationService.location.protocol}/${this.navigationService.location.hostname}/${this.navigationService.location.culture}/${this.page.product}/bonus/o/`;
    }

    changeUrlToAwardedState(offerId: number, detailsPage: string) {
        const updatedPageUrl = `/${this.navigationService.location.culture}/${this.page.product}/${detailsPage}/a/`;
        this.location.replaceState(updatedPageUrl + offerId?.toString());
    }

    goBack() {
        if (this.route.snapshot.queryParams['rurl']) {
            this.navigationService.storeReturnUrl();
            this.navigationService.goToReturnUrl();
        } else {
            let url = sessionStorage.getItem('promoPreviousUrl');
            if (sessionStorage.getItem('filterPreviousUrl') && this.isOfferPageEnhanced$.value) url = sessionStorage.getItem('filterPreviousUrl');
            this.loadOffersApiService.loadOffers([], true);
            if (url) {
                // TODO: remove this condition when it's no longer required
                if (!url?.includes('filter=') && !url?.includes('product=')) {
                    url = url.split('?')[0] ?? url; // Added because of galabingo game client changes
                }
                this.routingHistory.deleteRoute();
                this.navigationService.goTo(url);
            } else {
                this.goToOverviewPage();
            }
        }
    }

    getPositionEventForTracking(rewardTargetingType: string, serviceSubType: string) {
        return rewardTargetingType == OffersConstants.WelcomeOffer ? 'riskfreebet_welcomeoffer' : serviceSubType;
    }
}
