@if (!showCustomHeaderTemplate) {
    <lh-header-bar (onClose)="close()" [content]="item.title" />
} @else {
    <div class="custom-header" [ngClass]="customHeaderClass">
        <div class="header-ctrl-l">
            <span class="ui-icon ui-icon-size-lg ui-back theme-arrow-left d-flex align-items-center" (click)="onBackClick()"></span>
        </div>
        @if (item.title) {
            <div class="header-ctrl-txt">{{ item.title }}</div>
        }
    </div>
}
<div class="pc-text pc-component" [ngClass]="item.class">
    <div class="pc-txt" [vnDynamicHtml]="item.text"></div>
</div>
