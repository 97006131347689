import { Injectable } from '@angular/core';

import { ApiService } from '@frontend/promo-utils';

@Injectable()
export class FreeSpinsDetailsResourceService {
    constructor(private apiService: ApiService) {}

    getContent() {
        return this.apiService.get('phClientContent', {
            paths: ['Promo/PromoHub/DetailPagesContent/Common/Content', 'Promo/PromoHub/DetailPagesContent/Freespins/FreeSpinsClientContent'],
        });
    }
}
