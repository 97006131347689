@if (item.title) {
    <vn-pc-component-header-v2 [item]="item" />
}
<ng-template #pic>
    @if (item.image) {
        <img [vnProfilesSrc]="item.image.src" [vnProfilesWidth]="item.image.width" [alt]="item.image.alt" class="pc-img" />
    }
</ng-template>

@if (item.iconName) {
    <vn-icon [name]="item.iconName"></vn-icon>
} @else {
    @if (item.imageLink) {
        <a [href]="item.imageLink.url" [vnHtmlAttrs]="item.imageLink.attributes" class="pc-img-a">
            <ng-container *ngTemplateOutlet="pic" />
        </a>
    } @else {
        @if (item.image) {
            <img [vnProfilesSrc]="item.image.src" [vnProfilesWidth]="item.image.width" [alt]="item.image.alt" class="pc-img" />
        }
    }
}
