import { Injectable } from '@angular/core';

import {
    AdvanceFilter,
    CommonService,
    FilterByProductType,
    FilterTabs,
    FiltersSelected,
    LoadOffersApiService,
    Offer,
    OfferDetails,
    OffersApiService,
    ProductFilter,
} from '@frontend/promo-homewidget';
import { Subscription } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class FilterService {
    allOffers: OfferDetails;
    mainFilterTabs: FilterTabs[] = new Array<FilterTabs>();
    advanceFilter: AdvanceFilter = new AdvanceFilter();
    offersPopUpFilterSubscription: Subscription;
    totalCount: number;
    isOfferPageEnhanced: boolean = false;
    constructor(
        public loadOffersApiService: LoadOffersApiService,
        public offerDataService: OffersApiService,
        public commonService: CommonService,
    ) {
        this.commonService.isOfferPageEnhanced$.subscribe((mode: boolean) => {
            this.isOfferPageEnhanced = mode;
        });
    }
    getAdvanceFilterTabsCount(advanceFilter: AdvanceFilter, offers: OfferDetails) {
        this.advanceFilter = advanceFilter;
        this.allOffers = offers;
        const selectedFilterIndexes = {
            totalCount: 0,
            selectedFilterOfferIndexes: {},
        } as FiltersSelected;
        if (this.advanceFilter && this.advanceFilter.filterByProductType?.length > 0) {
            this.advanceFilter.filterByProductType.forEach((filterByProductTypeTab: FilterByProductType) => {
                const offerIndexes = offers.offerCategories[filterByProductTypeTab.product]?.offerDetailsIndexes;
                filterByProductTypeTab.count = 0;
                if (offerIndexes?.length > 0) {
                    filterByProductTypeTab.filtersByOfferType.forEach((filtersByOfferType) => {
                        this.getOffersCountBasedOnServiceType(offerIndexes, filtersByOfferType);
                        filterByProductTypeTab.count = filterByProductTypeTab.count + filtersByOfferType.count;
                        if (filtersByOfferType.isActive) {
                            this.selectedAdvanceFilter(filtersByOfferType, selectedFilterIndexes);
                        }
                    });
                } else {
                    filterByProductTypeTab.filtersByOfferType.map((q) => {
                        q.count = 0;
                        return q;
                    });
                }
            });
            if (Object.keys(selectedFilterIndexes?.selectedFilterOfferIndexes)?.length > 0) {
                this.advanceFilter.selectedOffersIndex = selectedFilterIndexes.selectedFilterOfferIndexes;
                this.advanceFilter.totalSelectedOffers = selectedFilterIndexes.totalCount;
                if (this.advanceFilter.totalSelectedOffers > 0) {
                    this.loadOffersApiService.filterV2$.next(selectedFilterIndexes.selectedFilterOfferIndexes);
                    if (this.isOfferPageEnhanced) {
                        this.offerDataService.updateFilteredOffers(selectedFilterIndexes);
                    }
                } else {
                    this.loadOffersApiService.filterV2$.next({});
                }
                this.loadOffersApiService.filteredProducts();
            }
        }
        return this.advanceFilter;
    }
    selectedAdvanceFilter(selectedTab: ProductFilter, selectedFilterIndexes: FiltersSelected) {
        if (selectedTab.isActive) {
            if (!selectedFilterIndexes.selectedFilterOfferIndexes[selectedTab.product]) {
                selectedFilterIndexes.selectedFilterOfferIndexes[selectedTab.product] = [];
            }
            selectedFilterIndexes.selectedFilterOfferIndexes[selectedTab.product].push(...selectedTab.filteredOfferIndexes);
            selectedFilterIndexes.totalCount += selectedTab.filteredOfferIndexes.length;
        } else {
            selectedTab.filteredOfferIndexes.forEach((val) => {
                const index = selectedFilterIndexes.selectedFilterOfferIndexes[selectedTab.product].indexOf(val, 0);
                if (index > -1) {
                    selectedFilterIndexes.totalCount -= 1;
                    selectedFilterIndexes.selectedFilterOfferIndexes[selectedTab.product].splice(index, 1);
                }
                if (selectedFilterIndexes.selectedFilterOfferIndexes[selectedTab.product]?.length <= 0) {
                    delete selectedFilterIndexes.selectedFilterOfferIndexes[selectedTab.product];
                }
            });
        }
    }
    getOffersCountBasedOnServiceType(offerIndexes: number[], filtersByOfferType: ProductFilter) {
        filtersByOfferType.count = 0;
        filtersByOfferType.filteredOfferIndexes = [];
        if (offerIndexes !== undefined) {
            offerIndexes.forEach((result) => {
                const offer = this.allOffers.offerDetailsList[result];
                if (
                    offer &&
                    !offer.offerMetadata?.isGroupedTile &&
                    filtersByOfferType.serviceType.some((q) => q.toLocaleLowerCase() === offer.offerMetadata.serviceType.toLocaleLowerCase()) &&
                    this.checkForServiceSubTypes(offer, filtersByOfferType)
                ) {
                    filtersByOfferType.filteredOfferIndexes.push(result);
                    filtersByOfferType.count++;
                }
            });
        }
    }
    checkForServiceSubTypes(offer: Offer, filtersByOfferType: ProductFilter): boolean {
        let status = true;
        if (filtersByOfferType.subType?.length > 0)
            status = filtersByOfferType.subType.some((q) => q.toLocaleLowerCase() === offer.offerMetadata.serviceSubType?.toLocaleLowerCase());
        if (filtersByOfferType.excludeSubType?.length > 0)
            status = !filtersByOfferType.excludeSubType.some(
                (q) => q.toLocaleLowerCase() === offer.offerMetadata.serviceSubType?.toLocaleLowerCase(),
            );
        return status;
    }
}
