@if (smartRewardContent?.monthlyPrizeDrawContent) {
    <div class="monthlyPrizeDrawContent">
        <div class="smart-widget__content">
            <img class="smart-widget__bg" [attr.src]="backGroundImage?.src" [attr.alt]="backGroundImage?.alt" fetchpriority="high" />
            <div class="smart-widget__body smart-container">
                <div class="d-flex justify-content-end">
                    <div>
                        @if (smartRewardMetaData?.isOptedIn && user.isAuthenticated) {
                            <div class="d-block d-sm-none">
                                <i class="smart-widget__info-icon theme-info-i" (click)="openDialog(true)"></i>
                            </div>
                        }
                    </div>
                </div>
                <div class="d-flex justify-content-between align-items-center">
                    <div class="smart-widget__body--inner">
                        <div
                            [ngClass]="smartRewardMetaData?.isOptedIn ? 'smart-widget__optedtitle' : 'smart-widget__title'"
                            [vnDynamicHtml]="smartRewardContent.monthlyPrizeDrawContent?.title"></div>
                        <div class="smart-widget__title--sub-title" [vnDynamicHtml]="smartRewardContent.monthlyPrizeDrawContent?.subTitle"></div>
                        <div class="smart-widget__description" [vnDynamicHtml]="smartRewardContent.monthlyPrizeDrawContent?.description"></div>
                        @if (smartRewardMetaData?.isOptedIn && user.isAuthenticated) {
                            @if (smartRewardContent?.monthlyPrizeDrawContent?.showMonthlyPrizeSection) {
                                <a
                                    class="btn btn-lg btn--rewards btn-secondary d-inline-flex align-items-center justify-content-center smart-widget__btn"
                                    (click)="openDialog()"
                                    [ngClass]="{ disabled: !(completedEntriesCount > 0) }"
                                    >{{ smartRewardContent.monthlyPrizeDrawContent?.findAllEntriesCtaText }}
                                </a>
                            }
                            <div class="d-sm-block d-none pt-2">
                                <a (click)="openDialog(true)" class="smart-widget__info-text btn btn-link-underlined btn-link-dark theme-info-i">{{
                                    smartRewardClientContent?.parameters?.moreinfo
                                }}</a>
                            </div>
                        }
                    </div>
                    @if (
                        smartRewardContent?.monthlyPrizeDrawContent?.showMonthlyPrizeSection && smartRewardMetaData?.isOptedIn && user.isAuthenticated
                    ) {
                        <div class="smart-widget__body--progress">
                            <ph-monthly-prize-progress
                                [progressSubText]="smartRewardClientContent?.parameters?.entrieswon"
                                [progressPercentage]="playerProgressPercentage"
                                [progressText]="playerProgressDescription"></ph-monthly-prize-progress>

                            @if (smartRewardMetaData?.isOptedIn && user.isAuthenticated) {
                                <div class="d-flex justify-content-center">
                                    <div class="smart-widget__badge--light d-flex align-items-center justify-content-center">
                                        {{ smartRewardContent.monthlyPrizeDrawContent?.prizeDrawBadge?.text }}
                                    </div>
                                </div>
                            }
                        </div>
                    }
                </div>
            </div>
        </div>
        <div class="smart-widget__footer">
            <div class="smart-container">
                @if (smartRewardContent.monthlyPrizeDrawContent?.keyTermsAndConditions) {
                    <div class="smart-widget__footer--text">
                        <div [vnDynamicHtml]="smartRewardContent.monthlyPrizeDrawContent?.keyTermsAndConditions"></div>
                    </div>
                }
            </div>
        </div>
    </div>
}
