<div class="offers-teaser-v2-actions d-flex align-items-center">
    <a *ngIf="moreInfoCta?.text" class="btn btn-link btn-link-light btn-md col-4">{{ moreInfoCta?.text }}</a>
    <button
        *ngIf="!offer?.offerMetadata?.isBonusApplied"
        (click)="depositOfferSelect($event, offer?.offerMetadata); $event.stopPropagation()"
        class="btn btn-primary btn-md w-100">
        {{ clientContent?.messages?.apply }}
    </button>
    <button
        *ngIf="offer?.offerMetadata?.isBonusApplied"
        (click)="depositOfferDeselect($event, offer?.offerMetadata); $event.stopPropagation()"
        class="success theme-success-i offer-button w-100 offer-button-md d-inline showPointer">
        {{ clientContent?.messages?.applied }}
    </button>
</div>
