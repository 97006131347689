import { NgClass } from '@angular/common';
import { Component, Input, OnChanges, OnDestroy } from '@angular/core';

import { DeviceService, DynamicHtmlDirective } from '@frontend/vanilla/core';
import { RxIf } from '@rx-angular/template/if';
import { Subject, takeUntil } from 'rxjs';

import { SmartRewardsConstants } from '../../constants/smart-rewards-constant';
import { RewardType } from '../../enum/reward-type';
import { EdsEventContent, SuccessEventContent } from '../../models/base-content';
import { ProgressBar } from '../../models/progress-bar';
import { Reward } from '../../models/reward';
import { EligibleGames } from '../../models/smart-eds-widget-details';
import { SmartRewardsService } from '../../services/smart-reward-service/smart-rewards.service';
import { ToastrHelperService } from '../../services/toastr-helper-service/toastr-helper.service';
import { OverlayDialogModal } from '../dialog/OverlayDialogModal';
import { SmartRewardsPopupComponent } from '../smart-rewards-popup/smart-rewards-popup.component';
import { SmartRewardTrackingHelperService } from '../tracking/smart-reward-tracking-helper.service';

@Component({
    standalone: true,
    selector: 'eds-event-success',
    templateUrl: './eds-event-success.component.html',
    styleUrl: './eds-event-success.component.scss',
    imports: [NgClass, RxIf, DynamicHtmlDirective],
    providers: [OverlayDialogModal],
})
export class EdsEventSuccessComponent implements OnChanges, OnDestroy {
    @Input() eventData: EdsEventContent;
    @Input() successEventContent: SuccessEventContent;
    @Input() contentParameters: { [key: string]: string };
    @Input() isEdsWidget: boolean = true;
    @Input() collapseTermsAndConditions: boolean = false;
    private readonly destroy$ = new Subject<void>();
    reward: Reward;
    progressBar: ProgressBar;

    constructor(
        private overlayDialogModal: OverlayDialogModal,
        private smartRewardsService: SmartRewardsService,
        private trackingHelperService: SmartRewardTrackingHelperService,
        public deviceService: DeviceService,
        private toastrHelperService: ToastrHelperService,
    ) {}

    ngOnChanges(): void {
        this.reward = {} as Reward;
        if (this.isEdsWidget) {
            this.smartRewardsService
                .getRewardDetailById(this.eventData.rewardId)
                .pipe(takeUntil(this.destroy$))
                .subscribe((response) => {
                    this.reward = response;
                    if (this.reward) {
                        this.progressBar = this.smartRewardsService.bindProgressBarData(this.reward);
                    }
                });
        } else {
            this.smartRewardsService.rewardHistory$.pipe(takeUntil(this.destroy$)).subscribe((res) => {
                if (res?.rewardList) {
                    const rewardsList = res?.rewardList[RewardType.Available]?.rewards ?? [];
                    if (rewardsList?.length > 0) {
                        rewardsList.forEach((x) => {
                            if (x?.rewardMetaData?.rewardCode == this.eventData.rewardId) {
                                this.reward = x;
                                this.progressBar = this.smartRewardsService.bindProgressBarData(this.reward);
                            }
                        });
                    }
                }
            });
        }
    }

    openRewardDetailPopup() {
        const locationEvent =
            this.eventData?.product?.toLowerCase() +
            ' ' +
            (this.isEdsWidget ? SmartRewardsConstants.DailyRewardsWidget : SmartRewardsConstants.DailyRewards);
        this.trackingHelperService.trackOnCtaClick(SmartRewardsConstants.GoToReward, locationEvent, this.eventData?.webTrackingPositionEvent);

        if (this.reward && this.reward?.rewardMetaData?.isAvailableReward) {
            const componentType = SmartRewardsPopupComponent;
            this.smartRewardsService.smartRewardEligibleGames$.next({} as EligibleGames);
            this.smartRewardsService.getSmartRewardsEligibleGames(
                this.reward?.rewardMetaData.rewardId,
                this.reward?.rewardMetaData?.isAwarded,
                this.reward?.rewardMetaData.rewardType,
            );
            const data = {
                reward: this.reward,
                contentParameters: this.contentParameters,
                isEdsWidget: this.isEdsWidget,
                collapseTermsAndConditions: this.collapseTermsAndConditions,
                progressBar: this.progressBar,
            };
            this.overlayDialogModal.openDialogModal(componentType, data);
        } else {
            const trackingModel = this.trackingHelperService.createLoadTrackingModel(
                locationEvent,
                this.eventData?.webTrackingPositionEvent,
                SmartRewardsConstants.InactiveReward,
            );
            const data = this.toastrHelperService.setToastrData(
                this.contentParameters?.rewardIsNotActiveTitle,
                this.contentParameters?.rewardIsNotActiveDesc,
                false,
                false,
                {},
                trackingModel,
            );
            this.toastrHelperService.onToastrActivation$.next(data);
        }
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }
}
