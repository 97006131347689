import { Injectable } from '@angular/core';

import { ClientConfigProductName, LazyClientConfig, LazyClientConfigBase, LazyClientConfigService } from '@frontend/vanilla/core';

@LazyClientConfig({
    product: ClientConfigProductName.PROMO,
    key: 'prCoinEconomy', // The key for this specific config
})
@Injectable({
    providedIn: 'root',
    deps: [LazyClientConfigService],
    useFactory: CoinEconomyConfigFactory,
})
export class CoinEconomyConfiguration extends LazyClientConfigBase {
    isCoinExpirySectionEnabled: boolean;
    historyDDLValues: Array<HistoryDDLConfig>;
    coinExpiryDay: number;
    isPreLoadVideoTeaser: boolean;
    enableHelpScreen: boolean;
    transactionFilters: any;
    enablePrimaryFilters: boolean;
    enableFavourites: boolean;
    navigationConfigs: any;
    tabViewVersion: string;
    enableArcadeTab: boolean;
    defaultDelay: number;
}

export function CoinEconomyConfigFactory(service: LazyClientConfigService) {
    return service.get(CoinEconomyConfiguration);
}

export class HistoryDDLConfig {
    public id: string;
    public type: string | null;
    public value: number | null;
}
