export class CoinEconomyConstants {
    public static readonly PAYMENT_CONFIRM: string = 'PAYMENT_CONFIRM';
    public static readonly ERROR_CONFIRM: string = 'ERROR_CONFIRM';
    public static readonly GAMEVARIANT_OPEN_GAME: string = 'GAMEVARIANT_OPEN_GAME';
    public static readonly OPEN_URL: string = 'OPEN_URL';
    public static readonly COINECONOMY_COINUPDATE: string = 'COINECONOMY_COINUPDATE';
    public static readonly OPEN_ARCADE_LOBBY: string = 'OPEN_ARCADE_LOBBY';
    public static readonly COINECONOMY: string = 'COINECONOMY';
    public static readonly EventName: string = 'data-event-name';
    public static readonly DATA_RURL: string = 'data-rurl';
    public static readonly HomePath: string = 'home';
    public static readonly defaultPath: string = '/promo/coral-coins';
    public static readonly FaqPath: string = 'faq';
    public static readonly CustomDate: string = 'customdate';
    public static readonly CoralPath: string = 'coral-coins';
    public static readonly LadbrokesPath: string = 'ladbucks';
    public static readonly PaymentConfirmationKey: string = 'PaymentConfirmation';
    public static readonly ERROR_CODE_RETRY: string = '1002';
    public static readonly DEFAULT_PURCHASE_ERROR: string = 'DefaultPurchaseError';
    public static readonly FREESPIN: string = 'freespin';
    public static readonly BONUS: string = 'bonus';
    public static readonly Days: string = 'days';
    public static readonly Month: string = 'month';
    public static readonly all: string = 'all';
    public static readonly games: string = 'games';

    //Tracking
    public static readonly CoinEconomy = 'coin economy';
    public static readonly PromoHub = 'promo hub';
    public static readonly CoinShop = 'coin shop';
    public static readonly CoinStore = 'coin store';
    public static readonly CustomPromotions = 'custom promotions';
    public static readonly StaticPromotion = 'Static Promotion';
    public static readonly Click = 'click';
    public static readonly Scroll = 'scroll';
    public static readonly Load = 'load';
    public static readonly VideoIcon = 'video icon';
    public static readonly GoToTop = 'go to top';
    public static readonly Offers = 'offers';
    public static readonly Login = 'login';
    public static readonly WithoutLogin = 'without login';
    public static readonly HowToEarnCoins = 'how to earn coins';
    public static readonly HowToEarnCoinsDaily = 'how to earn coins daily';
    public static readonly ContentView = 'contentView';
    public static readonly EventTracking = 'Event.Tracking';
    public static readonly Faq = 'FAQ';
    public static readonly Back = 'back';
    public static readonly ReplayHelpScreens = 'replay help screens';
    public static readonly CoinHistory = 'coin history';
    public static readonly CoinHistoryRange = 'coin history range';
    public static readonly CustomDateEvent = 'custom date';
    public static readonly CoinHistoryRangeCustom = 'coin history range - custom';
    public static readonly CustomDateRange = 'custom date range';
    public static readonly SpentCoins = 'spent coins';
    public static readonly AllEarnedCoins = 'all earned coins';
    public static readonly CloseX = 'close x';
    public static readonly MyRewards = 'my rewards';
    public static readonly DynamicPromotion = 'dynamic promotion';
    public static readonly CoinEarn = 'coin earn';
    public static readonly CoinSpend = 'coin spend';
    public static readonly PurchaseMoreInfo = 'purchase - more info';
    public static readonly Error = 'error -';
    public static readonly PaymentError = 'payment error';
    public static readonly PaymentConfirmation = 'payment confirmation';
    public static readonly Cancel = 'cancel';
    public static readonly Ok = 'ok';
    public static readonly Confirm = 'confirm';
    public static readonly RewardDetails = 'reward details';
    public static readonly PurchaseSuccess = 'purchase success';
    public static readonly OfferDetails = 'offer details';
    public static readonly Purchase = 'purchase';
    public static readonly PaymentFailed = 'payment failed';
    public static readonly Retry = 'retry';
    public static readonly HowToEarnTeaser = 'how to earn teaser';
    public static readonly PlayNow = 'play now';
    public static readonly Tnc = 'T&C - ';
    public static readonly Expand = 'expand';
    public static readonly Hide = 'hide';

    public static readonly EarnTab = 'collect';
    public static readonly StoreTab = 'store';
    public static readonly HistoryTab = 'history';
    public static readonly ArcadeTab = 'arcade';
    public static readonly RedeemTab = 'redeem';
    public static readonly FavouritesTab = 'favourites';
    public static readonly TabViewVersionV2 = 'V2';
    public static readonly PageKey = 'page';
    public static readonly FaqRoute = 'faqhelpscreen';
    public static readonly FaqBack = 'faqbackctaclick';

    public static readonly CollectCoins = 'collectcoins';
    public static readonly PlayCoins = 'playcoins';
    public static readonly AreaHome = 'home';
    public static readonly AreaStore = 'store';
    public static readonly AreaFavouritesProgressBar = 'favouritesprogressbar';

    public static readonly CostToBuyProperty = 'costToBuy';
    public static readonly SubTextPostFix = '_SUB_TEXT';
    public static readonly fav = 'fav';
    public static readonly Nofav = 'no fav';
    public static readonly Emptystring = '';
    public static readonly Tabs = 'tabs';
    public static readonly ProgressBar = 'progress bar';
    public static readonly AddFavourites = 'Add Favourites rewards';
    public static readonly RedeemFavourites = 'redeem favourites';
    public static readonly RedeemNow = 'redeem now';
    public static readonly MyFavouritesTab = 'my favourites';
    public static readonly AddToFavourites = 'Add to Favourites';
    public static readonly RemoveFromFavourites = 'remove from Favourites';
    public static readonly AddSomeFavourites = 'add some favourites';
    public static readonly PreviousFavourites = 'previous favourites';
    public static readonly PreviousRewards = 'previous rewards';
    public static readonly HelpScreenCacheKey = 'Help_Screen_Tour';
    public static readonly Clear = 'clear';
    public static readonly Filter = 'filter';
    public static readonly Redeemed = 'redeemed';
    public static readonly Collected = 'collected';
    public static readonly Expired = 'expired';
    public static readonly Step = 'step';
    public static readonly HelpScreen = 'help screens';
    public static readonly Finish = 'Finish';
    public static readonly Next = 'Next';
    public static readonly Previous = 'Previous';
    public static readonly PlayOrRiskCoins = 'play or risk coins';
    public static readonly stickyProgressTopStyle = 'top: 0';
    public static readonly Tracking_Freespins = 'Free Spins';
    public static readonly Tracking_Freebet = 'Free Bet';
    public static readonly Tracking_Bonus = 'Bonus';
    public static readonly Tracking_Cash = 'Cash';
}
