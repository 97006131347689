import { Injectable } from '@angular/core';

import { EmbeddableComponentsService, OnFeatureInit } from '@frontend/vanilla/core';
import { firstValueFrom } from 'rxjs';

import { OfferButtonConfig } from './offer-button.client-config';
import { OfferButtonComponent } from './offer-button.component';

@Injectable()
export class OfferButtonBootstrapService implements OnFeatureInit {
    constructor(private embeddableComponentsService: EmbeddableComponentsService, private config: OfferButtonConfig) {}

    async onFeatureInit() {
        await firstValueFrom(this.config.whenReady);
        this.embeddableComponentsService.registerEmbeddableComponent(OfferButtonComponent, 100);
    }
}
