import { OverlayRef } from '@angular/cdk/overlay';
import { Component, Inject, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';

import { RxIf } from '@rx-angular/template/if';
import { Subject, takeUntil } from 'rxjs';

import { SmartRewardsConstants } from '../../../constants/smart-rewards-constant';
import { GENERIC_MODAL_CONTENT } from '../../dialog/OverlayDialogModal';
import { SmartRewardTrackingHelperService } from '../../tracking/smart-reward-tracking-helper.service';
import { ChallengesWidgetComponent } from '../challenges-widget/challenges-widget.component';

@Component({
    selector: 'ph-challenges-dialog',
    standalone: true,
    imports: [ChallengesWidgetComponent, RxIf],
    templateUrl: './challenges-dialog.component.html',
    styleUrl: './challenges-dialog.component.scss',
    encapsulation: ViewEncapsulation.None,
})
export class ChallengesDialogComponent implements OnDestroy, OnInit {
    private readonly destroy$ = new Subject<void>();

    locationEvent: string;

    constructor(
        @Inject(GENERIC_MODAL_CONTENT) public data: any,
        private overlayRef: OverlayRef,
        private trackingHelperService: SmartRewardTrackingHelperService,
    ) {}

    ngOnInit() {
        this.locationEvent = this.data?.eventData?.product?.toLowerCase() + ' ' + SmartRewardsConstants.DailyRewardsWidget;
        this.trackingHelperService.trackOnPageLoad(this.locationEvent, this.data?.eventData?.webTrackingPositionEvent);
        this.overlayRef
            .backdropClick()
            .pipe(takeUntil(this.destroy$))
            .subscribe(() => this.overlayRef.dispose());
    }

    onClosePopup() {
        this.trackingHelperService.trackOnCtaClick(SmartRewardsConstants.CloseX, this.locationEvent, this.data?.eventData?.webTrackingPositionEvent);
        this.overlayRef.detach();
    }

    ngOnDestroy() {
        this.overlayRef.detach();
        this.overlayRef.dispose();
        this.destroy$.next();
        this.destroy$.complete();
    }
}
