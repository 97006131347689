import { Injectable } from '@angular/core';

import { ClientConfigProductName, LazyClientConfig, LazyClientConfigBase, LazyClientConfigService } from '@frontend/vanilla/core';

@LazyClientConfig({
    product: ClientConfigProductName.PROMO,
    key: 'prLatamNavigation', // The key for this specific config
})
@Injectable({
    providedIn: 'root',
    deps: [LazyClientConfigService],
    useFactory: PromoLatamNavigationConfigFactory,
})
export class PromoLatamNavigationConfiguration extends LazyClientConfigBase {
    subNavigationV1: boolean;
    hidePromotionBackButton: string;
}

export function PromoLatamNavigationConfigFactory(service: LazyClientConfigService) {
    return service.get(PromoLatamNavigationConfiguration);
}
