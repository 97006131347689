import { Component } from '@angular/core';

import {
    AdvanceFilter,
    FilterByProductType,
    FilterTabs,
    FiltersSelected,
    LoadOffersApiService,
    OfferCategory,
    OffersApiService,
    ProductFilter,
} from '@frontend/promo-homewidget';
import { HtmlNode } from '@frontend/vanilla/core';

@Component({
    template: '',
})
export abstract class FilterBaseComponent {
    filteredOfferIndexByCategories: { [key: string]: number[] } = {};
    constructor(
        public offerDataService: OffersApiService,
        public htmlNode: HtmlNode,
        public loadOffersApiService: LoadOffersApiService,
    ) {}

    clearAdvanceFilter(advanceFilter: AdvanceFilter) {
        if (advanceFilter) {
            if (advanceFilter?.filterByProductType?.length > 0) {
                advanceFilter.filterByProductType.forEach((productType: FilterByProductType) => {
                    productType.filtersByOfferType.forEach((offerType) => {
                        offerType.isActive = false;
                    });
                });
            }
            advanceFilter.selectedOffersIndex = {};
            advanceFilter.totalSelectedOffers = 0;
        }
        this.offerDataService.updateFilteredOffers(null);
        this.offerDataService.isFilterPopupClicked$.next(true);
        this.offerDataService.itemSelected$.next('promotions');
    }

    clearMainFilters(mainFilterTabs: FilterTabs[]) {
        if (mainFilterTabs?.length > 0) {
            mainFilterTabs.forEach((val: FilterTabs) => {
                val.isActive = false;
            });
        }
    }

    selectedAdvanceFilter(selectedTab: ProductFilter, selectedFilterIndexes: FiltersSelected) {
        if (selectedTab.isActive) {
            if (!selectedFilterIndexes.selectedFilterOfferIndexes[selectedTab.product]) {
                selectedFilterIndexes.selectedFilterOfferIndexes[selectedTab.product] = [];
            }
            selectedFilterIndexes.selectedFilterOfferIndexes[selectedTab.product].push(...selectedTab.filteredOfferIndexes);
            selectedFilterIndexes.totalCount += selectedTab.filteredOfferIndexes.length;
        } else {
            selectedTab.filteredOfferIndexes.forEach((val) => {
                const index = selectedFilterIndexes.selectedFilterOfferIndexes[selectedTab.product].indexOf(val, 0);
                if (index > -1) {
                    selectedFilterIndexes.totalCount -= 1;
                    selectedFilterIndexes.selectedFilterOfferIndexes[selectedTab.product].splice(index, 1);
                }
                if (selectedFilterIndexes.selectedFilterOfferIndexes[selectedTab.product]?.length <= 0) {
                    delete selectedFilterIndexes.selectedFilterOfferIndexes[selectedTab.product];
                }
            });
        }
    }

    applyFilters(selectedOffers: { [key: string]: number[] }) {
        this.htmlNode.setCssClass('offer-filters-dialog-opened', false);
        this.loadOffersApiService.filterV2$.next(selectedOffers);
        this.loadOffersApiService.filteredProducts();
    }

    updateOfferCategories(filteredOffersIndex: number[], offerCategories: { [key: string]: OfferCategory }) {
        this.filteredOfferIndexByCategories = {};
        const products = Object.keys(offerCategories);
        let areFiltersApplied;
        if (filteredOffersIndex && filteredOffersIndex.length > 0 && products && products.length > 0) {
            for (let i = 0; i < products.length; i++) {
                if (products[i] !== 'inprogress') {
                    const offerIndexes = offerCategories[products[i]].offerDetailsIndexes.filter((x) => filteredOffersIndex.includes(x));
                    if (offerIndexes.length > 0) {
                        this.filteredOfferIndexByCategories[products[i]] = offerIndexes;
                    }
                }
            }
            areFiltersApplied = true;
        } else {
            for (const product of Object.entries(offerCategories)) {
                this.filteredOfferIndexByCategories[product[0]] = product[1].offerDetailsIndexes;
            }
            areFiltersApplied = false;
        }
        this.applyFilters(this.filteredOfferIndexByCategories);
        this.loadOffersApiService.areFiltersApplied$.next(areFiltersApplied);
    }
}
