<div class="challenge-dialog">
    <span class="ui-icon ui-icon-size-sm ui-close theme-close-small close-icon" (click)="onClosePopup()"></span>
    <ph-challenges-widget
        class="card card--lg border-0"
        [successEventContent]="data?.successEventContent"
        [contentParameters]="data?.contentParameters"
        [eventData]="data?.eventData"
        [isEdsWidget]="true">
    </ph-challenges-widget>
</div>
