import { Injectable } from '@angular/core';

import { ApiService } from '@frontend/promo-utils';
import { UserLoginEvent, UserService, ViewTemplate } from '@frontend/vanilla/core';
import { BehaviorSubject, EMPTY, Observable, filter } from 'rxjs';

import { RegulatoryApiService } from '../../spanish-regulatory/regulatory-api.service';
import { Carousel, Offer, OfferCategory, OfferDetails, OfferFiltersModel, OfferMetadata, OfferTeaserClientContent } from '../models';
import { OfferContentConfiguration } from '../offer-content.client-config';
import { OffersApiService } from './offers.service';

@Injectable({
    providedIn: 'root',
})
export class LoadOffersApiService {
    filteredProducts$ = new BehaviorSubject<Array<string>>([]);
    loadOffersAfterLogin$ = new BehaviorSubject<boolean>(false);
    clientContentMessages$ = new BehaviorSubject<{ [attr: string]: string }>({});
    multiBrandDetails$ = new BehaviorSubject<{ [attr: string]: string }>({});
    offerDetails$ = new BehaviorSubject<OfferDetails>({} as OfferDetails);
    splashOffers$ = new BehaviorSubject<OfferDetails>({} as OfferDetails);
    loadBetStationSpecialOffers$ = new BehaviorSubject<Array<Offer>>([]);
    isFilterV2Enabled$ = new BehaviorSubject<any>(false);
    areFiltersApplied$ = new BehaviorSubject<any>(false);
    filterV1$ = new BehaviorSubject<OfferFiltersModel>({} as OfferFiltersModel);
    filterV2$ = new BehaviorSubject<{ [key: string]: number[] }>({});
    groupedFilter$ = new BehaviorSubject<{ [key: string]: number[] }>({});
    isGroupedFilterApplied$ = new BehaviorSubject<boolean>(false);
    pokerTicketFilteredIndexes$ = new BehaviorSubject<Array<number>>([]);
    offerTeaserClientContent$ = new BehaviorSubject<OfferTeaserClientContent>({} as OfferTeaserClientContent);
    isLobbyEmpty$ = new BehaviorSubject<boolean>(false);
    showSkeleton$ = new BehaviorSubject<boolean>(false);
    carousel: Carousel;
    clientContent: ViewTemplate;
    multiBrandDetails: { [attr: string]: string };
    areOffersLoaded: boolean = false;

    constructor(
        private userService: UserService,
        private apiService: ApiService,
        private regulatoryApiService: RegulatoryApiService,
        public offerservice: OffersApiService,
        public offerContentConfiguration: OfferContentConfiguration,
    ) {
        this.userService.events.pipe(filter((e) => e instanceof UserLoginEvent)).subscribe(() => {
            if (this.userService.isAuthenticated) {
                this.areOffersLoaded = false;
                this.loadOffersAfterLogin$.next(true);
            }
        });
    }

    loadOffers(products: string[] = [], force: boolean = false): Observable<OfferDetails> {
        if (!force && this.areOffersLoaded) {
            return EMPTY;
        }
        const queryParams = {
            products: products,
        };
        this.showSkeleton$.next(force || !this.areOffersLoaded);
        const offers$ = this.apiService.get('offers', queryParams);
        offers$.subscribe({
            next: (res: OfferDetails) => {
                this.areOffersLoaded = true;
                this.offerDetails$.next(res);
                this.showSkeleton$.next(false);
                this.filteredProducts();
                this.isLobbyEmpty();
                this.loadOffersAfterLogin$.next(false);
            },
        });
        return this.regulatoryApiService.fetchDataWithReasonCode(offers$);
    }

    isLobbyEmpty() {
        const isLobbyEmpty =
            this.offerDetails$.value.offerDetailsList === undefined ||
            this.offerDetails$.value.offerDetailsList.length === 0 ||
            this.offerDetails$.value.offerCategories === undefined ||
            Object.keys(this.offerDetails$.value.offerCategories).length === 0;
        this.isLobbyEmpty$.next(isLobbyEmpty);
    }

    filteredProducts() {
        this.filteredProducts$.next(this.getFilteredProducts());
    }

    getFilteredProducts() {
        if (this.offerDetails$.value.offerCategories) {
            if (this.isGroupedFilterApplied$.value) {
                return Object.keys(this.groupedFilter$.value);
            } else if (this.isFilterV2Enabled$.value) {
                return this.filterProductsV2(this.filterV2$.value);
            }
            return this.filterProductsV1(this.filterV1$.value);
        }
        return [];
    }

    private filterProductsV1(filtersByProductAndServiceType: OfferFiltersModel): string[] {
        let offersByProduct: string[] = [];
        const selectedFilters = this.filterV1$.value;
        if (this.offerDetails$.value.offerCategories !== undefined) {
            if (
                filtersByProductAndServiceType?.selectedFiltersDict === undefined ||
                Object.keys(filtersByProductAndServiceType.selectedFiltersDict)?.length === 0
            ) {
                offersByProduct = Object.keys(this.offerDetails$.value.offerCategories);
            } else {
                for (const [product] of Object.entries(this.offerDetails$.value.offerCategories)) {
                    if (filtersByProductAndServiceType.selectedFiltersDict[product]) {
                        const filterOfferCount = this.filteredOfferIndexes(
                            this.offerDetails$.value.offerCategories,
                            this.offerDetails$.value.offerDetailsList,
                            product,
                        );
                        if (filterOfferCount.length > 0) offersByProduct.push(product);
                        else {
                            delete selectedFilters?.selectedFiltersDict[product];
                            this.filterV1$.next(selectedFilters);
                        }
                    }
                }
            }

            if (offersByProduct.length == 0) {
                this.areFiltersApplied$.next(false);
                this.ClearFilterV1();
                this.offerservice.globalSelectedFilters.next([]);
                offersByProduct = Object.keys(this.offerDetails$.value.offerCategories);
            }
        }
        return offersByProduct;
    }

    private filterProductsV2(filterV2: { [key: string]: number[] }): string[] {
        const filterProducts = Object.keys(filterV2);
        let products: string[] = [];
        if (filterProducts?.length > 0) {
            products = filterProducts;
        } else if (this.offerDetails$.value.offerCategories) {
            products = Object.keys(this.offerDetails$.value.offerCategories);
        }
        return products;
    }

    filteredOfferIndexes(offerCategories: { [key: string]: OfferCategory }, offerDetailsList: Offer[], product: string): number[] {
        if (offerCategories && offerCategories[product]) {
            const offerCategory = offerCategories[product];
            if (offerCategory != null) {
                const filterKeys = this.groupedFilter$.value;

                if (this.isGroupedFilterApplied$.value && Object.keys(filterKeys)?.length > 0 && offerCategory) {
                    let groupedOfferIndexes = this.filterDeeplinkingGroupedOffers(offerCategory, product, filterKeys);
                    groupedOfferIndexes = groupedOfferIndexes?.filter((i) => !offerDetailsList[i]?.offerMetadata.isGroupedTile);
                    return groupedOfferIndexes;
                }

                let offerIndexes: number[];
                if (this.isFilterV2Enabled$.value && offerCategory) {
                    offerIndexes = this.filterOffersV2(offerCategory, product, this.filterV2$.value);
                } else {
                    offerIndexes = this.filterOffersV1(product, this.filterV1$.value);
                }

                offerIndexes = this.filterGroupedOffers(offerIndexes, offerCategory, offerDetailsList, this.areFiltersApplied$.value);
                return offerIndexes;
            }
        }
        return [];
    }

    private filterOffersV1(product: string, filterV1: OfferFiltersModel): number[] {
        let offerIndexes: number[] = [];
        const offerCategory = this.offerDetails$.value.offerCategories[product];
        const offerDetailsList = this.offerDetails$.value.offerDetailsList;
        if (offerCategory !== undefined) {
            if (filterV1?.selectedFiltersDict === undefined || filterV1.selectedFiltersDict[product] === undefined) {
                offerIndexes = offerCategory.offerDetailsIndexes;
            } else if (offerDetailsList?.length > 0) {
                if (!filterV1.isNewSelected && !filterV1.isActiveSelected) {
                    const serviceTypes = filterV1.selectedFiltersDict[product];
                    offerIndexes = offerCategory.offerDetailsIndexes.filter((i: number) => {
                        const serviceType = offerDetailsList[i]?.offerMetadata?.serviceType?.toLowerCase();
                        return serviceType && serviceTypes?.includes(serviceType) && !offerDetailsList[i]?.offerMetadata.isGroupedTile;
                    });
                } else if (filterV1.isNewSelected && filterV1.isActiveSelected) {
                    offerIndexes = offerCategory.offerDetailsIndexes.filter(
                        (i: number) =>
                            (offerDetailsList[i]?.offerMetadata?.isOfferActive || offerDetailsList[i]?.offerMetadata?.isNewOffer) &&
                            !offerDetailsList[i]?.offerMetadata.isGroupedTile,
                    );
                } else if (filterV1.isNewSelected) {
                    offerIndexes = offerCategory.offerDetailsIndexes.filter(
                        (i: number) => offerDetailsList[i]?.offerMetadata?.isNewOffer && !offerDetailsList[i]?.offerMetadata.isGroupedTile,
                    );
                } else if (filterV1.isActiveSelected) {
                    offerIndexes = offerCategory.offerDetailsIndexes.filter(
                        (i: number) => offerDetailsList[i]?.offerMetadata?.isOfferActive && !offerDetailsList[i]?.offerMetadata.isGroupedTile,
                    );
                }
            }
        }
        return offerIndexes;
    }

    private filterOffersV2(offerCategory: OfferCategory, product: string, filterV2: { [key: string]: number[] }): number[] {
        const products = Object.keys(filterV2);
        if (products?.length > 0 && product) {
            return filterV2[product] ?? [];
        }
        return offerCategory?.offerDetailsIndexes;
    }

    private filterGroupedOffers(offerIndexes: number[], offerCategory: OfferCategory, offerDetailsList: Offer[], filtersApplied: boolean): number[] {
        return offerIndexes?.filter((i) => {
            const offerMetadata = offerDetailsList[i]?.offerMetadata;
            return (
                (offerMetadata && !(!filtersApplied && offerMetadata.hideBehindGroup && this.hasGroupedTile(offerMetadata, offerCategory))) ||
                (filtersApplied && !offerMetadata?.isGroupedTile)
            );
        });
    }

    private hasGroupedTile(offerMetadata: OfferMetadata, offerCategory: OfferCategory): boolean {
        return this.getGroupedOffersCount(offerMetadata, offerCategory) > 0;
    }

    private getGroupedOffersCount(offerMetadata: OfferMetadata, offerCategory: OfferCategory): number {
        if (offerMetadata.optInApi !== undefined && offerCategory?.noOfGroupedOffersPerOfferType !== undefined) {
            return offerCategory.noOfGroupedOffersPerOfferType[offerMetadata.optInApi] ?? 0;
        }
        return 0;
    }

    filterDeeplinkingGroupedOffers(offerCategory: OfferCategory, product: string, groupedOffers: { [key: string]: number[] }): number[] {
        const products = Object.keys(groupedOffers);
        if (products?.length > 0 && product) {
            return groupedOffers[product] ?? [];
        }
        return offerCategory?.offerDetailsIndexes;
    }

    groupTileOffersByProductAndServiceSubTypes(product: string, serviceSubTypes: string[]) {
        if (!!this.offerDetails$.value.offerCategories && this.offerDetails$.value.offerDetailsList?.length > 0) {
            if (product) {
                const offerCategory = this.offerDetails$.value.offerCategories[product];
                return offerCategory?.offerDetailsIndexes?.filter((i: number) => {
                    const offerMetadata = this.offerDetails$.value.offerDetailsList[i]?.offerMetadata;
                    const serviceSubType = offerMetadata?.serviceSubType;

                    return (
                        serviceSubType &&
                        serviceSubTypes.includes(serviceSubType) &&
                        offerMetadata &&
                        offerMetadata.hideBehindGroup &&
                        !offerMetadata.isGroupedTile
                    );
                });
            }
            const indexes: number[] = [];
            for (let i = 0; i < this.offerDetails$.value.offerDetailsList.length; i++) {
                const offerMetadata = this.offerDetails$.value.offerDetailsList[i]?.offerMetadata;
                const serviceSubType = offerMetadata?.serviceSubType;
                if (
                    offerMetadata &&
                    offerMetadata.isOfferActive &&
                    offerMetadata.hideBehindGroup &&
                    !offerMetadata.isGroupedTile &&
                    serviceSubType &&
                    serviceSubTypes.includes(serviceSubType)
                ) {
                    indexes.push(i);
                }
            }
            return indexes;
        }
        return [];
    }

    groupTileOffersByProductAndServiceType(offerDetails: OfferDetails, product: string, serviceTypes: string[], isGroupedTile: boolean = true) {
        if (!!offerDetails.offerCategories && offerDetails.offerDetailsList?.length > 0) {
            if (product) {
                const offerCategory = offerDetails.offerCategories[product];
                return offerCategory?.offerDetailsIndexes.filter((i: number) => {
                    const offerMetadata = offerDetails.offerDetailsList[i]?.offerMetadata;

                    return isGroupedTile
                        ? offerMetadata &&
                              serviceTypes.includes(offerMetadata.serviceType) &&
                              offerMetadata.hideBehindGroup &&
                              !offerMetadata.isGroupedTile
                        : offerMetadata && serviceTypes.includes(offerMetadata.serviceType) && !offerMetadata.isGroupedTile;
                });
            }
            const indexes: number[] = [];
            for (let i = 0; i < offerDetails.offerDetailsList.length; i++) {
                const offerMetadata = offerDetails.offerDetailsList[i]?.offerMetadata;
                const offerExist =
                    isGroupedTile && offerMetadata
                        ? offerMetadata.isOfferActive &&
                          offerMetadata.hideBehindGroup &&
                          !offerMetadata.isGroupedTile &&
                          serviceTypes.includes(offerMetadata.serviceType)
                        : offerMetadata &&
                          offerMetadata.isOfferActive &&
                          !offerMetadata.isGroupedTile &&
                          serviceTypes.includes(offerMetadata.serviceType);
                if (offerExist) {
                    indexes.push(i);
                }
            }
            return indexes;
        }
        return [];
    }

    loadCarousel(force: boolean) {
        this.offerservice.carouselDetails$.subscribe((carousel: Carousel) => {
            this.carousel = carousel;
        });
        if (!force && Object.keys(this.carousel)?.length > 0) {
            return;
        }
        this.offerservice.loadCarousel();
    }

    loadClientContent() {
        const clientContent = this.clientContentMessages$.value;
        if (clientContent && Object.keys(clientContent).length > 0) {
            return;
        }
        this.offerservice.loadContent().subscribe((clientContent: ViewTemplate) => {
            const clientContentMessages = clientContent?.messages;
            if (clientContentMessages) this.clientContentMessages$.next(clientContentMessages);
            if (clientContentMessages !== undefined) {
                this.offerTeaserClientContent$.next({
                    newTag: clientContentMessages['new'] ?? '',
                    expiresTag: clientContentMessages['expires'] ?? '',
                    expiresColon: clientContentMessages['expirescolon'] ?? '',
                    badgesDot: clientContentMessages['badgesdot'] ?? '',
                    optInMessage: clientContentMessages['OptIn'] ?? '',
                    optedInMessage: clientContentMessages['Opted_In'] ?? '',
                    optedInErrorMessage: clientContentMessages['Optin_Error'] ? clientContentMessages['Optin_Error'] : 'Error Optin',
                    optInExpiredMessage: clientContentMessages['Optin_Expired'] ?? '',
                    optInInvalidMessage: clientContentMessages['Optin_Invalid'] ?? '',
                    optInUserNotEligibleMessage: clientContentMessages['Optin_User_Not_Eligible'] ?? '',
                });
            }
        });
    }

    loadMultiBrandDetails() {
        this.multiBrandDetails$.subscribe((multiBrandDetailsMessages) => {
            this.multiBrandDetails = multiBrandDetailsMessages;
        });
        if (!this.offerContentConfiguration.enableOnePartyBrand || Object.keys(this.multiBrandDetails)?.length > 0) {
            return;
        }
        this.offerservice.loadMultipleBrandsDetails().subscribe((multiBrand: any) => {
            const multiBrandDetailsMessages = multiBrand?.messages;
            if (multiBrandDetailsMessages) this.multiBrandDetails$.next(multiBrandDetailsMessages);
        });
    }

    loadPromotionOffers() {
        this.apiService.get('promotionoffers').subscribe((res) => {
            this.splashOffers$.next(res);
        });
    }

    loadWelcomeOffers() {
        this.apiService.get('welcomeoffers').subscribe((res) => {
            this.splashOffers$.next(res);
        });
    }
    loadBetStationSpecialOffers() {
        this.apiService.get('betstationspecialoffers').subscribe((res) => {
            this.loadBetStationSpecialOffers$.next(res);
        });
    }

    ClearFilterV1() {
        this.filterV1$.next({} as OfferFiltersModel);
    }

    ClearFilterV2() {
        this.filterV2$.next({});
        this.areFiltersApplied$.next(false);
    }

    ApplyGroupedFilter(groupedFilter: { [key: string]: number[] }) {
        this.groupedFilter$.next(groupedFilter);
        this.isGroupedFilterApplied$.next(true);
    }

    ClearGroupedFilter() {
        this.groupedFilter$.next({});
        this.pokerTicketFilteredIndexes$.next([]);
        this.isGroupedFilterApplied$.next(false);
    }

    ApplyPokerTicketFilter(pokerTicketFilteredIndexes: number[]) {
        this.pokerTicketFilteredIndexes$.next(pokerTicketFilteredIndexes);
    }
}
