import { Component, Input, OnDestroy, OnInit } from '@angular/core';

import { ClientConfigService, UserLoginEvent, UserService } from '@frontend/vanilla/core';
import { ReplaySubject, filter, takeUntil } from 'rxjs';

import { OfferDetails } from '../../offers/models';
import { OfferContentConfiguration } from '../../offers/offer-content.client-config';
import { LoadOffersApiService } from '../../offers/offers-service/load-offers.service';
import { OffersByUserPriorityComponent } from '../offers-by-user-priority/offers-by-user-priority.component';

@Component({
    selector: 'ph-splash-offers-page',
    templateUrl: './splash-offers-page.component.html',
    styleUrls: ['./splash-offers-page.component.css'],
    standalone: true,
    imports: [OffersByUserPriorityComponent],
})
export class SplashOffersPageComponent implements OnInit, OnDestroy {
    @Input() isNewUser: boolean;
    @Input() shouldLoadOffersContentConfig: boolean;

    offerDetails: OfferDetails;
    isLobbyEmpty: boolean = false;
    offerSwimlanes: Array<string>;
    noOffers: string;
    offerContentConfigLoaded: boolean;
    public isUserLoggedIn: boolean = false;
    private offersDestroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

    constructor(
        public userService: UserService,
        private clientConfigService: ClientConfigService,
        private offerContentConfiguration: OfferContentConfiguration,
        public loadOffersApiService: LoadOffersApiService,
    ) {}

    async ngOnInit() {
        await this.loadOfferContentConfig();
        if (this.isNewUser) {
            this.loadOffersApiService?.loadWelcomeOffers();
        } else {
            this.loadOffersApiService?.loadPromotionOffers();
        }
        this.loadOffers();
        this.isUserLoggedIn = this.userService && this.userService?.isAuthenticated;
        this.userService?.events
            ?.pipe(
                filter((e) => e instanceof UserLoginEvent),
                takeUntil(this.offersDestroyed$),
            )
            ?.subscribe(() => {
                this.isUserLoggedIn = this.userService?.isAuthenticated;
                this.loadOffersApiService?.loadPromotionOffers();
                this.loadOffers();
            });
    }

    async loadOfferContentConfig(): Promise<void> {
        if (this.shouldLoadOffersContentConfig && !this.offerContentConfiguration?.isConfigReady) {
            await this.clientConfigService?.load('', [OfferContentConfiguration]);
        }
        this.offerContentConfigLoaded = true;
    }

    loadOffers() {
        this.loadOffersApiService?.splashOffers$?.pipe(takeUntil(this.offersDestroyed$))?.subscribe((res: OfferDetails) => {
            this.isLobbyEmpty = true;
            if (res?.offerDetailsList?.length > 0) {
                this.offerDetails = res;
                this.checkIfLobbyIsEmpty();
                this.offerSwimlanes = Object.assign([], Object.keys(this.offerDetails?.offerCategories));
            }
        });
    }

    checkIfLobbyIsEmpty() {
        this.isLobbyEmpty =
            this.offerDetails?.offerDetailsList === undefined ||
            this.offerDetails?.offerDetailsList?.length === 0 ||
            this.offerDetails?.offerCategories === undefined ||
            Object.keys(this.offerDetails?.offerCategories)?.length === 0;
    }

    ngOnDestroy(): void {
        this.offersDestroyed$?.next(true);
        this.offersDestroyed$?.complete();
    }
}
