import { Directive, Input, inject } from '@angular/core';

import { MenuActionOrigin, MenuActionsService, MenuContentItem, MenuSection, VanillaElements, trackByProp } from '@frontend/vanilla/core';

/**
 * A base class for responsive header components registered with {@link HeaderService}.
 *
 * @stable
 */
@Directive()
export abstract class HeaderItemBase {
    @Input() item: MenuContentItem;
    readonly trackByText = trackByProp<MenuContentItem>('text');

    menuActionsService = inject(MenuActionsService);
    MenuSection = MenuSection;
    VanillaElements = VanillaElements;

    processClick(event: Event, item?: any) {
        this.menuActionsService.processClick(event, item || this.item, MenuActionOrigin.Header);
    }
}
