import { createAction, props } from '@ngrx/store';

import { RevealCardRequest } from '../../models/my-cards/earned-card/reveal-card-request';
import { MyCardsStateModel } from '../../models/my-cards/my-cards-state-models';

export const myCardsInit = createAction('[My Cards] Init');
export const myCardsDestroyed = createAction('[My Cards] Destroyed');
export const loadMyCardsData = createAction('[My Cards] LoadMyCardData', props<MyCardsStateModel>());
export const getRevealCardData = createAction('[My Cards] RevealMyCard', props<RevealCardRequest>());
export const userRevealedMyCard_Success = createAction('[My Cards] RevealMyCard_Success', props<MyCardsStateModel>());
