import { AfterViewChecked, Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import {
    CommonService,
    FilterConfiguration,
    FilterOffersContent,
    LoadOffersApiService,
    NavigationTab,
    OfferContentConfiguration,
    OffersApiService,
    RoutingHistory,
    SelectedFilters,
    TrackingModelService,
} from '@frontend/promo-homewidget';
import {
    DeviceService,
    LastKnownProductService,
    MediaQueryService,
    MenuActionsService,
    MenuContentItem,
    NavigationService,
    ProductHomepagesConfig,
    UserLoginEvent,
    UserService,
} from '@frontend/vanilla/core';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

import { PromoLatamNavigationConfiguration } from '../../client-configs/latam-navigation-config';
import { VIPHubConfiguration } from '../../client-configs/viphub-config';
import { OffersPopupFilterComponent } from '../offers-popup-filter/offers-popup-filter.component';
import { OffersPopUpFilterService } from '../offers-popup-filter/offers-popup-filter.service';
import { OffersNavigationConstants } from './constants/Offers-navigation-contants';
import { OffersNavigationService } from './offers-navigation.service';

@Component({
    selector: 'offers-navigation',
    templateUrl: 'offers-navigation.component.html',
    styleUrls: ['../../../../../themepark/themes/whitelabel/components/nav-main/styles.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class OffersNavigationComponent implements OnInit, OnDestroy, OnChanges, AfterViewChecked {
    @Input() showBackArrow: boolean = true;
    @Input() disableClose: boolean = true;
    @Input() showInMobile: boolean;
    @Input() isHomePage: boolean;
    @Input() isGroupPage: boolean;
    @Input() sourceItem: string;
    @Input() mobileViewTitle: string;
    @Input() eventName: string;

    menuContainer: MenuContentItem;
    menuItems: MenuContentItem[];
    isDesktop: boolean;
    canRenderMenu: boolean = true;
    currentMenuItem: MenuContentItem;
    filterSelected: number;
    IsOffersPageEnhancedEnabled: boolean;
    filterOffersContent: FilterOffersContent;
    tabPosition: string = NavigationTab.Home;
    selectedFilters: SelectedFilters[];
    epcotEnabled: boolean = false;
    offerTitle: string;
    promotionsBackButtonText: string;
    promotionsBackButtonEnabled: boolean = false;
    isLobbyEmpty: boolean;

    private readonly destroy$ = new Subject<void>();
    subNavigationV1Enabled: boolean = false;
    previousProduct: string;
    hidePromoBackButton: boolean = true;
    menuItemsLoaded: boolean = false;
    constructor(
        public deviceService: DeviceService,
        public filterConfiguration: FilterConfiguration,
        public offerContentConfiguration: OfferContentConfiguration,
        public vipHubConfiguration: VIPHubConfiguration,
        private offerApiService: OffersApiService,
        private userService: UserService,
        private commonService: CommonService,
        private routingHistory: RoutingHistory,
        private offersPopUpFilterService: OffersPopUpFilterService,
        private dialog: MatDialog,
        private offersNavigationService: OffersNavigationService,
        private media: MediaQueryService,
        private menuActionsService: MenuActionsService,
        private navigationService: NavigationService,
        private trackingModelService: TrackingModelService,
        public loadOffersApiService: LoadOffersApiService,
        private lastKnownProductService: LastKnownProductService,
        private productHomePagesConfig: ProductHomepagesConfig,
        private lataNavigationConfig: PromoLatamNavigationConfiguration,
    ) {}

    ngOnInit() {
        this.loadOffersApiService.isLobbyEmpty$.pipe(takeUntil(this.destroy$)).subscribe((isLobbyEmpty: boolean) => {
            this.isLobbyEmpty = isLobbyEmpty;
        });
        this.epcotEnabled = this.offerContentConfiguration.isEpcotEnabled;
        this.subNavigationV1Enabled = this.lataNavigationConfig.subNavigationV1;
        this.isDesktop = !this.deviceService.isMobile;
        this.media
            .observe()
            .pipe(takeUntil(this.destroy$))
            .subscribe(() => {
                this.canRenderMenu = this.canRender();
                this.populateMenuItem();
            });
        this.populateMenuItem();
        this.offersPopUpFilterService.offersFilter$.pipe(takeUntil(this.destroy$)).subscribe((filterOffersContent) => {
            this.filterOffersContent = filterOffersContent;
        });
        this.userService.events
            .pipe(
                filter((e) => e instanceof UserLoginEvent),
                takeUntil(this.destroy$),
            )
            .subscribe(() => {
                this.offersPopUpFilterService.loadOffersFilter();
                this.offersPopUpFilterService.loadOffersFilterV2();
                this.offersNavigationService.getSubNavigationItems();
            });
        this.offerApiService.globalSelectedFilters.pipe(takeUntil(this.destroy$)).subscribe((filters) => {
            this.selectedFilters = filters;
        });
        this.loadClientContent();
        this.menuItemsLoaded = false;
    }

    ngAfterViewChecked() {
        if (!this.menuItemsLoaded) {
            this.populateMenuItem();
        }
    }
    ngOnChanges(changes: SimpleChanges) {
        if (changes.sourceItem) {
            if (this.menuItems?.length > 0 && this.sourceItem && document.getElementById('tab-' + this.sourceItem) && this.deviceService.isMobile) {
                document.getElementById('tab-' + this.sourceItem)?.scrollIntoView({ inline: 'nearest' });
            }
        }
    }

    ngOnDestroy() {
        if (this.tabPosition != NavigationTab.Home && this.tabPosition != '1') {
            this.offerApiService.globalFilterTabs.next([]);
            this.offerApiService.globalSelectedFilters.next([]);
            this.loadOffersApiService.ClearFilterV1();
            this.loadOffersApiService.filteredProducts();
        }
        this.destroy$.next();
        this.destroy$.complete();
    }

    processCancel() {
        const url = sessionStorage.getItem('filterdeeplinkingreturnurl');
        if (url) this.navigationService.goTo(url);
        this.commonService.goBack();
    }

    processBack() {
        this.trackEventDetails(this.eventName);
        const url = sessionStorage.getItem('filterPreviousUrl');
        const filterDeepLinkingUrl = sessionStorage.getItem('filterdeeplinkingreturnurl');
        if (url) {
            this.navigationService.goTo(url);
        } else if (filterDeepLinkingUrl) {
            this.navigationService.goTo(filterDeepLinkingUrl);
        } else {
            this.commonService.goBack();
        }
    }

    trackEventDetails(eventName: string) {
        this.trackingModelService.submitTracking({
            CategoryEvent: 'promotions',
            LabelEvent: 'Sub Navigation',
            ActionEvent: 'back',
            LocationEvent: 'Promo Hub Sub Navigation',
            EventDetails: eventName,
        });
    }

    processClick(event: Event, item: MenuContentItem, tabPosition: any) {
        this.tabPosition = tabPosition;
        this.routingHistory.clearReturnUrl();
        this.trackingModelService.submitTracking({
            LabelEvent: 'Sub Navigation',
            ActionEvent: item.text + ' - Clicked',
            PositionEvent: tabPosition,
            LocationEvent: 'Promo Hub Sub Navigation',
            URLClicked: item.url,
        });
        this.offerApiService.pageSelected = item.text; //Saved selected page name for web tracking
        this.menuActionsService.processClick(event, item, 'TopNavigation');
    }

    populateMenuItem() {
        this.offersNavigationService.subNavigationItems.pipe(takeUntil(this.destroy$)).subscribe((res) => {
            if (res != null && res.children !== undefined) {
                this.menuContainer = res;
                this.menuItems = res.children; // TODO: Cleanit up
                if (res.children !== undefined) {
                    this.currentMenuItem = res.children.find((x) => x.name == this.sourceItem) ?? ({} as MenuContentItem);
                    // New TODO: map
                    this.canRenderMenu = this.canRender();
                    if (
                        this.menuItems?.length > 0 &&
                        this.sourceItem &&
                        document.getElementById('tab-' + this.sourceItem) &&
                        this.deviceService.isMobile
                    ) {
                        document.getElementById('tab-' + this.sourceItem)?.scrollIntoView({ inline: 'nearest' });
                        window.scrollTo(0, 0);
                        this.menuItemsLoaded = true;
                    }
                    if (this.currentMenuItem?.text) {
                        this.offerApiService.pageSelected = this.currentMenuItem.text;
                        if (this.mobileViewTitle == null) {
                            this.mobileViewTitle = this.currentMenuItem.text;
                        }
                    }
                }
            }
        });
    }

    filterPopUpClicked() {
        //Filter Web tracking 'Filter button clicked'
        this.trackingModelService.submitTracking({
            LabelEvent: 'Promo Filters',
            ActionEvent: 'Filters Icon - Clicked',
            PositionEvent: this.tabPosition,
        });
        this.filterOffersContent.tabPosition = this.tabPosition;
        this.dialog.open(OffersPopupFilterComponent, {
            width: '650px',
            data: this.filterOffersContent,
            id: 'mat-dialog-filter-popup',
            panelClass: 'mat-dialog-panel-filter-popup',
            // backdropClass: 'custom-Overlay-filter'
        });
    }

    canRender(): boolean {
        if (this.isDesktop) return true;
        if (this.menuItems == null) return false;

        if (this.showInMobile) {
            return true;
        }
        return false;
    }

    loadClientContent() {
        this.offersNavigationService.enabled$.pipe(takeUntil(this.destroy$)).subscribe((enabled) => {
            this.hidePromoBackButton = enabled;
        });
        this.previousProduct = this.lastKnownProductService.get()?.previous;
        this.promotionsBackButtonEnabled =
            this.offerContentConfiguration.enablePromotionsBackButton && this.previousProduct != OffersNavigationConstants.Product_Unknown
                ? true
                : false;
        if (this.epcotEnabled && !this.isDesktop) {
            this.loadOffersApiService.clientContentMessages$.pipe(takeUntil(this.destroy$)).subscribe((res: any) => {
                if (res) {
                    this.offerTitle = res['epcottitle'];
                    this.promotionsBackButtonText = res['PromotionsBackButtonText'];
                }
            });
        }
    }

    backNavigation() {
        switch (this.previousProduct.toLowerCase()) {
            case 'sports': {
                this.trackBackButtonEventDetails(this.productHomePagesConfig.sports);
                this.navigationService.goTo(this.productHomePagesConfig.sports);
                break;
            }
            case 'casino': {
                this.trackBackButtonEventDetails(this.productHomePagesConfig.casino);
                this.navigationService.goTo(this.productHomePagesConfig.casino);
                break;
            }
            case 'poker': {
                this.trackBackButtonEventDetails(this.productHomePagesConfig.poker);
                this.navigationService.goTo(this.productHomePagesConfig.poker);
                break;
            }
            case 'bingo': {
                this.trackBackButtonEventDetails(this.productHomePagesConfig.bingo);
                this.navigationService.goTo(this.productHomePagesConfig.bingo);
                break;
            }
            case 'portal': {
                this.trackBackButtonEventDetails(this.productHomePagesConfig.bingo);
                this.navigationService.goTo(this.productHomePagesConfig.portal);
                break;
            }
            default: {
                this.navigationService.goTo(this.productHomePagesConfig.promo);
                break;
            }
        }
    }

    trackBackButtonEventDetails(productHomePageUrl: string) {
        this.trackingModelService.submitTracking({
            CategoryEvent: OffersNavigationConstants.Promohub,
            ActionEvent: OffersNavigationConstants.BackButtonClick,
            LabelEvent: this.sourceItem.toLowerCase(),
            LocationEvent: OffersNavigationConstants.Navigation,
            EventDetails: OffersNavigationConstants.Back,
            URLClicked: productHomePageUrl,
        });
    }
}
