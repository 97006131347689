import { createReducer, on } from '@ngrx/store';

import { TargetPromotionContent } from '../models/target-promotion-content';
import { TargetPromotionMetadata } from '../models/target-promotion-metadata';
import { TargetPromoState } from './target-promotion.selectors';
import {
    loadTargetPromotionData_Success,
    targetPromotionDestroyed,
    updateNoPrize,
    updateResultsContent,
    updateTabs,
    userOptInSuccessForPromotion,
} from './target-promotions.actions';

export const initialState: TargetPromoState = {
    metadata: {} as TargetPromotionMetadata,
    content: {} as TargetPromotionContent,
};

export const targetPromoReducer = createReducer(
    initialState,
    on(loadTargetPromotionData_Success, (state, action) => ({
        ...state,
        ...action,
    })),
    on(userOptInSuccessForPromotion, (state, action) => ({
        ...state,
        metadata: {
            ...state.metadata,
            optinDetails: { ...action.optinDetails },
        },
        content: {
            ...state.content,
            optinContent: { ...action.optinContent },
        },
    })),
    on(updateTabs, (state, action) => ({
        ...state,
        metadata: {
            ...state.metadata,
        },
        content: {
            ...state.content,
            tabs: [...action.tabs],
        },
    })),
    on(updateNoPrize, (state, action) => ({
        ...state,
        metadata: {
            ...state.metadata,
        },
        content: {
            ...state.content,
            noPrizeContent: { ...action.noPrizeContent },
        },
    })),
    on(updateResultsContent, (state, action) => ({
        ...state,
        metadata: {
            ...state.metadata,
        },
        content: {
            ...state.content,
            resultsContent: { ...action.resultsContent },
        },
    })),
    on(targetPromotionDestroyed, () => initialState),
);
