import { Injectable } from '@angular/core';

import { ClientConfigProductName, LazyClientConfig, LazyClientConfigBase, LazyClientConfigService } from '@frontend/vanilla/core';

@LazyClientConfig({
    product: ClientConfigProductName.PROMO,
    key: 'prFilter', // The key for this specific config
})
@Injectable({
    providedIn: 'root',
    deps: [LazyClientConfigService],
    useFactory: filterConfigurationFactory,
})
export class FilterConfiguration extends LazyClientConfigBase {
    isFilterV2Enabled: boolean;
}

export function filterConfigurationFactory(service: LazyClientConfigService) {
    return service.get(FilterConfiguration);
}
