import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

import { Page } from '@frontend/vanilla/core';

import { OfferContentConfiguration } from '../../offers/offer-content.client-config';

@Component({
    selector: 'offers-skeleton',
    templateUrl: 'offers-skeleton.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OffersSkeletonComponent implements OnInit {
    @Input() showTeaserSkeleton: boolean;
    @Input() productsLength: number = 3;
    @Input() showTitleSkeleton: boolean = true;

    offersSkeletonArr: number[];
    offersSkeletonV2Arr: number[];

    constructor(public offerContentConfiguration: OfferContentConfiguration, public page: Page) {}

    ngOnInit(): void {
        this.offersSkeletonArr = new Array<number>(this.productsLength === 1 ? 3 : 6);
        this.offersSkeletonV2Arr = new Array<number>(this.productsLength ? this.productsLength : 3);
    }
}
