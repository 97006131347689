@if (offerContentConfigLoaded && !isLobbyEmpty) {
    <div class="offers-home-page">
        <div class="offers-teaser-groups has-slider wrapper-height">
            @for (swimlane of offerSwimlanes; track swimlane; let i = $index) {
                @if (swimlane) {
                    <offers-by-user-priority
                        [offerCategory]="offerDetails?.offerCategories[swimlane]"
                        [categoryType]="swimlane"
                        [offerDetailsList]="offerDetails?.offerDetailsList"
                        class="offers-teaser-group-wrapper items-3">
                    </offers-by-user-priority>
                }
            }
        </div>
    </div>
}
