import { Injectable } from '@angular/core';

import { ClientConfigProductName, LazyClientConfig, LazyClientConfigBase, LazyClientConfigService } from '@frontend/vanilla/core';

@LazyClientConfig({
    product: ClientConfigProductName.PROMO,
    key: 'prOfferContentClient', // The key for this specific config
})
@Injectable({
    providedIn: 'root',
    deps: [LazyClientConfigService],
    useFactory: OfferContentConfigurationFactory,
})
export class OfferContentConfiguration extends LazyClientConfigBase {
    enableTileContentV2: boolean;
    enableTimeCountDown: boolean;
    disableSubNavigation: boolean;
    isEpcotEnabled: boolean;
    isPromoFaqsEnabled: boolean;
    isBetMgmTileRedesignEnabled: boolean;
    promoFaqsSitecoreLocation: string;
    homePage: string;
    filterKeys: FilterKeyValue[];
    isVirtualSlidesLoadEnabled: boolean;
    viewAllCssClass: string;
    enableOnePartyBrand: boolean;
    subNavigationProperties: { [key: string]: boolean };
    enableSkeletonV2: boolean;
    enablePromotionsBackButton: boolean;
    enableBetNowCta: boolean;
    subNavigationV1: boolean;
    isOfferHistoryV2Enabled: boolean;
    enableDarkSkeleton: boolean;
    hideEmptySubNavigation: boolean;
}

export function OfferContentConfigurationFactory(service: LazyClientConfigService) {
    return service.get(OfferContentConfiguration);
}

export class FilterKeyValue {
    key: string;
    value: FilterValue;
}
export class FilterValue {
    product: string;
    offerType: string;
}
