import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';

import { PromoDistributedCacheConfiguration } from '@frontend/promo-utils';
import { ClientConfigService } from '@frontend/vanilla/core';

import { CoinEconomyConfiguration } from './coin-economy-config';

@Injectable()
export class CoinEconomyGuard implements CanActivate {
    constructor(
        private clientConfigService: ClientConfigService,
        private coinEconomyConfig: CoinEconomyConfiguration,
        private cacheConfiguration: PromoDistributedCacheConfiguration,
    ) {}

    async canActivate() {
        const configs = [] as any;
        if (!this.coinEconomyConfig.isConfigReady) configs.push(CoinEconomyConfiguration);
        if (!this.cacheConfiguration.isConfigReady) configs.push(PromoDistributedCacheConfiguration);
        if (configs.length > 0) await this.clientConfigService.load('', configs);
        return true;
    }
}
