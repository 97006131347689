import { createAction, props } from '@ngrx/store';

import { StaticPromotionDetails } from '../model/static-promotion.model';

export const loadStaticPromotionData = createAction('[Static Promotion] LoadData', props<{ promoId: string }>());
export const loadStaticPromotionData_Success = createAction(
    '[Static Promotion] LoadData_Success',
    props<{ staticPromotionDetails: StaticPromotionDetails }>(),
);
export const load_Failed = createAction('[Static Promotion] Load Failed', props<{ errorMessage: string }>());
