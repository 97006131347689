import { NgStyle } from '@angular/common';
import { Component, Input } from '@angular/core';

import { DynamicHtmlDirective } from '@frontend/vanilla/core';
import { RxIf } from '@rx-angular/template/if';

import { SubEventCompletionData } from '../../../models/monthly-prize-draw-report';
import { PCImageText } from '../../../models/smart-eds-widget-details';

@Component({
    standalone: true,
    selector: 'ph-completed-challenge-tile',
    templateUrl: './completed-challenge-tile.component.html',
    styleUrl: './completed-challenge-tile.component.scss',
    imports: [RxIf, NgStyle, DynamicHtmlDirective],
})
export class CompletedChallengeTileComponent {
    @Input() smartRewardClientContent: PCImageText;
    @Input() completedEvent: SubEventCompletionData;

    constructor() {}
}
