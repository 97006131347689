<ng-container *ngrxLet="vm$ as vm">
    <ng-container *ngIf="vm">
        <div
            (click)="offerTileClick()"
            *ngIf="!isTileContentV2Enabled"
            class="offers-teaser pc-component pc-teaser pc-banner-tile banner-tile-hover dh"
            (mouseover)="isCardOnHover = true"
            (mouseleave)="isCardOnHover = false">
            <offer-teaser-image
                [isParentOnHover]="isCardOnHover"
                [offer]="offer"
                [moreInfo]="moreInfoCta"
                [product]="product"
                [teaserNumber]="teaserNumber"
                [showProgressBar]="showProgressBar"
                [isTileContentV2Enabled]="isTileContentV2Enabled">
            </offer-teaser-image>
            <div class="pc-header">
                <h3 class="pc-h">
                    <a
                        class="pc-h-a"
                        (click)="updateOffers()"
                        [attr.href]="moreInfoCta?.url"
                        target="{{ !!moreInfoCta?.attributes?.target ? moreInfoCta?.attributes?.target : '' }}"
                        >{{ offer.offerContent?.title }}</a
                    >
                </h3>
            </div>

            <div class="pc-t-h-cont">
                <div class="offer-badge txt-s text-white offer-type-container">
                    <span class="badge-product badge-new" *ngIf="offer.offerMetadata?.isNewOffer">
                        {{ vm.newTag }}
                    </span>
                    <div *ngIf="offer.offerMetadata?.isGroupedTile && groupedOffersCount" class="offer-badge txt-s text-white offer-type-container">
                        <span class="badge-product mr-1">
                            {{ groupedOffersCount }}
                        </span>
                    </div>
                    <offer-type-badge [offer]="offer"></offer-type-badge>
                    <offer-applicable-sports-league [offerContent]="offer?.offerContent"> </offer-applicable-sports-league>
                </div>
                <div class="offer-badge txt-s eligibleLeague-wrapper" *ngIf="offer?.offerContent?.eligibleLeagueName">
                    <span class="eligibleLeague">
                        {{ offer?.offerContent?.eligibleLeagueName }}
                    </span>
                </div>
                <div class="offers-teaser-v2-badges">
                    <div class="offer-badge txt-s offer-expire" *ngIf="offer.offerMetadata?.showExpiryDate">
                        <div class="badge-countdown" *ngIf="!isTimeCountdownEnabled">
                            <div class="badge-countdown__text">
                                {{ vm.expiresTag }}:
                                {{ offer.offerMetadata?.formattedEndDate }}
                            </div>
                        </div>
                        <div class="badge-countdown" *ngIf="isTimeCountdownEnabled">
                            <span *ngIf="!isEpcotEnabled" class="badge-countdown__icon theme-clock"></span>
                            <div class="badge-countdown__text">
                                {{ offer?.offerContent?.expiryTimeCountDown }}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="offer-content-conditions">
                    <p class="txt-xtr-small mb-0" [vnDynamicHtml]="offer.offerContent?.keyTermsAndConditions"></p>
                </div>
            </div>
        </div>

        <div
            *ngIf="isTileContentV2Enabled"
            class="offers-teaser-v2"
            (mouseover)="isCardOnHover = true"
            (mouseleave)="isCardOnHover = false"
            (click)="offerTileClick()">
            <offer-teaser-image
                [isParentOnHover]="isCardOnHover"
                [offer]="offer"
                [moreInfo]="moreInfoCta"
                [product]="product"
                [showProgressBar]="showProgressBar"
                [isGroupedPage]="isGroupedPage"
                [offerType]="offerType"
                [teaserNumber]="teaserNumber"
                [isTileContentV2Enabled]="isTileContentV2Enabled">
            </offer-teaser-image>
            <div class="offers-teaser-v2-content-wrapper d-flex flex-column h-100">
                <div class="offers-teaser-v2-content">
                    <h3 *ngIf="!isBetMgmTileRedesignEnabled" class="offers-teaser-v2-title">{{ offer.offerContent?.title }}</h3>
                    <div class="offers-teaser-v2-badges">
                        <div class="offers-teaser-v2-badges-row" [class.offers-teaser-flex-wrapping]="offer.offerMetadata?.isNewOffer">
                            <!-- If else check -->
                            <div *ngIf="offer.offerMetadata?.offerBadges" class="offer-badge">
                                <span class="badge-countdown__icon" [ngClass]="badge" *ngFor="let badge of offer.offerMetadata?.offerBadges"></span>
                            </div>
                            <div *ngIf="offer.offerMetadata?.isNewOffer" class="offer-badge txt-s text-white offer-type-container">
                                <span class="badge-product badge-new">
                                    {{ vm.newTag }}
                                </span>
                            </div>
                            <div
                                *ngIf="offer.offerMetadata?.isGroupedTile && groupedOffersCount"
                                class="offer-badge txt-s text-white offer-type-container">
                                <span class="badge-product mr-1">
                                    {{ groupedOffersCount }}
                                </span>
                            </div>
                            <div
                                *ngIf="offer.offerMetadata.offersCount && !offer.offerMetadata?.isGroupedTile"
                                class="offer-badge txt-s text-white offer-type-container">
                                <span class="badge-product mr-1">
                                    {{ offer.offerMetadata.offersCount + 'X' }}
                                </span>
                            </div>
                            <offer-type-badge [offer]="offer"></offer-type-badge>
                            <div
                                class="offer-sports-overflow"
                                *ngIf="(offer.offerContent.applicableSports || offer.offerContent.sportsGameType) && !isBetMgmTileRedesignEnabled">
                                <offer-applicable-sports-league [offerContent]="offer.offerContent"> </offer-applicable-sports-league>
                            </div>
                        </div>
                        <div
                            class="offer-badge txt-s eligibleLeague-wrapper"
                            *ngIf="!isBetMgmTileRedesignEnabled && offer?.offerContent?.eligibleLeagueName">
                            <span class="eligibleLeague">
                                {{ offer.offerContent.eligibleLeagueName }}
                            </span>
                        </div>
                        <div class="offer-badge txt-s offer-expire" *ngIf="offer.offerMetadata?.showExpiryDate">
                            <div class="badge-countdown" *ngIf="!isTimeCountdownEnabled">
                                <div class="badge-countdown__text">
                                    {{ vm.expiresTag }}{{ vm.expiresColon }}
                                    {{ offer.offerMetadata?.formattedEndDate }}
                                </div>
                            </div>
                            <div
                                [ngClass]="offer?.offerContent?.expiryDateClass ? offer?.offerContent?.expiryDateClass : 'badge-countdown'"
                                *ngIf="isTimeCountdownEnabled">
                                <span class="badge-countdown__icon" [ngClass]="isBetMgmTileRedesignEnabled ? 'theme-time' : 'theme-clock'"></span>
                                <div class="badge-countdown__text">
                                    {{ offer?.offerContent?.expiryTimeCountDown }}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="isBetMgmTileRedesignEnabled">
                        <progress-bar [offer]="offer" *ngIf="showProgressBar" [isTileContentV2Enabled]="isTileContentV2Enabled"> </progress-bar>
                        <h3 class="offers-teaser-v2-title">{{ offer.offerContent?.title }}</h3>
                    </div>
                    <div class="offers-teaser-v2-conditions" *ngIf="offer.offerContent?.keyTermsAndConditions">
                        <span class="mb-0 txt-xtr-small" [vnDynamicHtml]="offer.offerContent.keyTermsAndConditions"></span>
                    </div>
                </div>

                <offer-teaser-cta
                    *ngIf="!offer.offerMetadata?.isSpecialOffer && !offer.offerMetadata?.isWelcomeOffer"
                    class="offers-teaser-v2-actions"
                    [offer]="offer"
                    [teaserNumber]="teaserNumber"
                    [product]="product"
                    [moreInfoCta]="moreInfoCta"
                    [isGroupedPage]="isGroupedPage"
                    [offerType]="offerType"></offer-teaser-cta>

                <offer-teaser-special-offer-cta
                    *ngIf="offer.offerMetadata?.isSpecialOffer || offer.offerMetadata?.isWelcomeOffer"
                    class="offers-teaser-v2-actions"
                    [offer]="offer"
                    [teaserNumber]="teaserNumber"
                    [product]="product"
                    [isMyGala]="isMyGala"
                    [moreInfoCta]="moreInfoCta"></offer-teaser-special-offer-cta>

                <ng-content select="[isCashierDepositOffers]"></ng-content>
            </div>
        </div>
    </ng-container>
</ng-container>
