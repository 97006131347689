import { TargetPromotionRequest } from '../../common/models/target-promotion-request';

export class ClickCardRequest extends TargetPromotionRequest {
    cardId: string;
    cmsItemId: string;
    currency: string;
    isAutoOptin: boolean;
    constructor(promoId: string, requestType: string, cardId: string = '', isAutoOptin: boolean = false) {
        super();
        this.promoId = promoId;
        this.requestType = requestType;
        this.cardId = cardId;
        this.isAutoOptin = isAutoOptin;
    }
}
