<ng-container *ngrxLet="vm$ as vm">
    <div class="offers-teaser-group" *ngIf="vm">
        <div class="offers-teaser-group-header" *ngIf="vm.indexes?.length > 0 && (showTeaserGroupTitle || vm.isDesktop || offerCategory?.text)">
            <div class="offers-teaser-group-title otgt-title">
                <a
                    *ngIf="showBackArrow && vm.isDesktop"
                    class="offers-teaser-group-back theme-arrow-left dh"
                    (click)="processBackClick()"
                    queryParamsHandling="merge"></a>

                <img
                    *ngIf="!offersProductStyle?.textColour && !!offerCategory.productImage"
                    class="otgt-image"
                    [ngClass]="offerCategory.class"
                    [attr.src]="offerCategory.productImage.src"
                    [attr.alt]="offerCategory.productImage.alt"
                    [width]="offerCategory.productImage.width"
                    [height]="offerCategory.productImage.height" />

                <div
                    *ngIf="offersProductStyle?.textColour"
                    class="otgt-image"
                    [style.alt]="offerCategory.productImage?.alt"
                    [style.width]="offerCategory.productImage?.width"
                    [style.height]="offerCategory.productImage?.height"
                    [style.background-color]="offersProductStyle?.textColour"
                    style="filter: invert(0);
                            -webkit-mask: url('{{ offerCategory.productImage?.src }}') no-repeat center / contain;"></div>

                <div class="otgt-wrapper">
                    <div class="otgt-header" [style.color]="offersProductStyle?.textColour">
                        {{ offerCategory?.categoryTitle }}
                    </div>
                    <div *ngIf="offerCategory?.subtitle" class="otgt-subheader" [style.color]="offersProductStyle?.textColour">
                        {{ offerCategory?.subtitle }}
                    </div>
                </div>
            </div>
            <div *ngIf="offerCategory?.text && userService.isAuthenticated" [vnDynamicHtml]="offerCategory.text"></div>
            <div *ngIf="showSeeAllLink && !isFreeOffer && vm.indexes.length > seeAllLinkItemLength" class="offers-teaser-group-see-all">
                <a
                    [attr.href]="offerCategory.viewAllLink?.url"
                    [style.color]="offersProductStyle?.textColour"
                    [ngClass]="[viewAllCtaClass]"
                    (click)="processViewAllClick(); $event.preventDefault()"
                    >{{ vm.clientContentMessages?.ShowAll }} ({{ vm.indexes.length }})
                </a>
            </div>
        </div>

        <div *ngIf="offerDetailsList?.length > 0 && vm.indexes?.length > 0" class="offers-teaser-group-items vn-carousel">
            <ng-container *ngIf="swiper?.navigation && arrows && !isMyGala">
                <i
                    class="theme-left carousel-previous"
                    role="button"
                    aria-label="Previous slide"
                    (click)="onSlidePrev()"
                    [ngClass]="swiper?.isBeginning ? 'swiper-button-disabled' : ''"></i>
                <i
                    class="theme-right carousel-next"
                    role="button"
                    aria-label="Next slide"
                    (click)="onSlideNext()"
                    [ngClass]="swiper?.isEnd ? 'swiper-button-disabled' : ''"></i>
            </ng-container>

            <ng-container *ngIf="myGalaArrows && isMyGala">
                <div
                    [ngClass]="product"
                    role="button"
                    class="swiper-button-prev theme-left"
                    slot="container-end"
                    (click)="trackPrevNextEvent('left')"></div>
                <div
                    [ngClass]="product"
                    role="button"
                    class="swiper-button-next theme-right"
                    slot="container-end"
                    (click)="trackPrevNextEvent('right')"></div>
            </ng-container>
            <div #swiperContainer class="swiper" *ngIf="showLhSlider">
                <div class="swiper-wrapper">
                    <div class="swiper-slide" *ngFor="let idx of vm.indexes; let i = index; trackBy: trackByKey">
                        <div
                            [class]="
                                !vm.filtersApplied && offerDetailsList[idx]?.offerMetadata?.isGroupedTile
                                    ? 'offers-teaser-wrapper-multi'
                                    : 'offers-teaser-wrapper-single'
                            ">
                            <offers-teaser
                                class="offers-teaser-wrapper"
                                [offer]="offerDetailsList[idx]"
                                [product]="product"
                                [groupedOffersCount]="offerDetailsList[idx]?.offerMetadata | groupedOffersCount: offerCategory"
                                [teaserNumber]="i + 1"
                                [isMyGala]="isMyGala">
                            </offers-teaser>
                            <span
                                *ngIf="!vm.filtersApplied && offerDetailsList[idx]?.offerMetadata?.isGroupedTile"
                                class="offers-teaser-wrapper-multi-list"></span>
                        </div>
                    </div>
                </div>
                <span *ngIf="isMyGala" [ngClass]="product" class="swiper-pagination d-block" slot="container-end"></span>
            </div>

            <div *ngIf="!showLhSlider" class="row offer-teaser-items-nocarousel">
                <ng-container *ngFor="let idx of vm.indexes; let i = index; trackBy: trackByKey">
                    <div
                        [class]="
                            !vm.filtersApplied && offerDetailsList[idx]?.offerMetadata?.isGroupedTile
                                ? 'offers-teaser-wrapper-multi'
                                : 'offers-teaser-wrapper-single'
                        ">
                        <offers-teaser
                            class="offers-teaser-wrapper col-12 col-sm-6 col-md-4"
                            [ngClass]="vm.carouselItemCssClass"
                            [offer]="offerDetailsList[idx]"
                            [product]="product"
                            [groupedOffersCount]="offerDetailsList[idx]?.offerMetadata | groupedOffersCount: offerCategory"
                            [teaserNumber]="i + 1"></offers-teaser>
                        <span
                            *ngIf="!vm.filtersApplied && offerDetailsList[idx]?.offerMetadata?.isGroupedTile"
                            class="offers-teaser-wrapper-multi-list"></span>
                    </div>
                </ng-container>
            </div>
        </div>
    </div>
</ng-container>
