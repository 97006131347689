import { Component, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';

import {
    CommonService,
    FilterConfiguration,
    FilterOffersContent,
    LoadOffersApiService,
    NavigationTab,
    OffersApiService,
    RoutingHistory,
    TrackingModelService,
} from '@frontend/promo-homewidget';
import {
    DeviceService,
    HtmlNode,
    MediaQueryService,
    MenuActionsService,
    MenuContentItem,
    NavigationService,
    PCImage,
    UserLoginEvent,
    UserService,
} from '@frontend/vanilla/core';
import { BehaviorSubject, Subject, combineLatest } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

import { OffersNavigationService } from '../offers-navigation/offers-navigation.service';
import { FilterV2Constants } from '../offers-popup-filter-v2/filter-constants';

@Component({
    selector: 'offers-navigation-v2',
    templateUrl: 'offers-navigation-v2.component.html',
    styleUrls: ['../../../../../themepark/themes/whitelabel/components/nav-main/styles.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class OffersNavigationV2Component implements OnInit, OnDestroy {
    @Input() showBackArrow: boolean = true;
    @Input() disableClose: boolean = true;
    @Input() showInMobile: boolean;
    @Input() isHomePage: boolean;
    @Input() isGroupPage: boolean;
    @Input() sourceItem: string;
    @Input() mobileViewTitle: string;
    @Input() eventName: string;
    @Input() selectedItem: string;
    @Input() isOffersHistoryPage: boolean;
    @Input() offersNavigationGroupedProducts: string[];
    menuContainer: MenuContentItem;
    menuItems: MenuContentItem[];
    isDesktop: boolean;
    canRenderMenu: boolean = true;
    currentMenuItem: MenuContentItem;
    IsOffersPageEnhancedEnabled: boolean;
    filterSelected: number;
    filterOffersContent: FilterOffersContent;
    tabPosition: string = NavigationTab.Home;
    epcotEnabled: boolean = false;
    offerTitle: string;
    isLobbyEmpty: boolean;
    menuList: any = [];
    private readonly destroy$ = new Subject<void>();
    lastSavedItem: { selectedTab: string };
    totalCount: any;
    mobileOfferHistory$: BehaviorSubject<PCImage>;
    constructor(
        public deviceService: DeviceService,
        public filterConfiguration: FilterConfiguration,
        private offerApiService: OffersApiService,
        public userService: UserService,
        private routingHistory: RoutingHistory,
        private offersNavigationService: OffersNavigationService,
        private media: MediaQueryService,
        private menuActionsService: MenuActionsService,
        public router: Router,
        private navigationService: NavigationService,
        private trackingModelService: TrackingModelService,
        public htmlNode: HtmlNode,
        private commonService: CommonService,
        public loadOffersApiService: LoadOffersApiService,
    ) {
        this.mobileOfferHistory$ = this.offerApiService.mobileofferhistory$;
    }

    ngOnInit() {
        this.offerApiService.itemSelected$.next(this.selectedItem);
        this.loadTopNavigationContent();
        this.loadOfferhistoryContent();
        this.isLobbyempty();
        this.isDesktop = !this.deviceService.isMobile;
        this.media
            .observe()
            .pipe(takeUntil(this.destroy$))
            .subscribe(() => {
                this.canRenderMenu = this.canRender();
                this.populateMenuItem();
            });
        this.populateMenuItem();
        this.loadSubNav();
    }

    ngOnDestroy() {
        this.destroy$.next();
        this.destroy$.complete();
    }

    processCancel() {
        this.commonService.goBack();
    }

    processBack() {
        this.navigationService.goTo(this.menuItems[0].url);
    }

    processClick(event: Event, item: MenuContentItem, tabPosition: any) {
        this.tabPosition = tabPosition;
        this.routingHistory.clearReturnUrl();
        this.trackingModelService.submitTracking({
            LabelEvent: 'Sub Navigation',
            ActionEvent: item.text + ' - Clicked',
            PositionEvent: tabPosition,
            LocationEvent: 'Promo Hub Sub Navigation',
            URLClicked: item.url,
        });
        this.offerApiService.pageSelected = item.text;
        this.offerApiService.tabChanged$.next(true);
        this.offerApiService.itemSelected$.next(this.selectedItem);
        if (this.offersNavigationGroupedProducts?.length > 0 && item?.url) {
            this.navigationService.goTo(item?.url, { skipLocationChange: true });
        } else {
            this.menuActionsService.processClick(event, item, 'TopNavigation');
        }
    }

    populateMenuItem() {
        combineLatest([
            this.offersNavigationService.subNavigationItems,
            this.offerApiService.filteredOffers$,
            this.offerApiService.isResetSubNavOnFilterApplied$,
            this.offerApiService.isFilterPopupClicked$,
        ])
            .pipe(takeUntil(this.destroy$))
            .subscribe(([subNavItem, filterMenu, resetSubnav, clearfilter]) => {
                if (subNavItem != null && subNavItem.children !== undefined) {
                    this.menuContainer = subNavItem;
                    const subNavList = subNavItem.children;
                    if (subNavList?.length > 0 && this.offersNavigationGroupedProducts?.length > 0) {
                        this.menuItems = subNavList.filter((x: any) => this.offersNavigationGroupedProducts.includes(x.name));
                        this.totalCount = this.offersNavigationGroupedProducts.length; // used to disable Promotions/Rewards tabs
                    } else {
                        if (filterMenu != null && filterMenu?.selectedFilterOfferIndexes != null) {
                            const updatedSelectedOffers = Object.keys(filterMenu.selectedFilterOfferIndexes)
                                .filter((key) => filterMenu.selectedFilterOfferIndexes[key].length > 0)
                                .reduce((obj: any, key) => {
                                    obj[key] = filterMenu.selectedFilterOfferIndexes[key];
                                    return obj;
                                }, {});
                            this.menuItems = subNavList.filter((item: any) => updatedSelectedOffers.hasOwnProperty(item.name));
                            this.totalCount = filterMenu.totalCount;
                            this.offerApiService.resetAll$.next(false);
                            if (resetSubnav) {
                                this.sourceItem = this.menuItems[0].name;
                                this.navigationService.goTo(this.menuItems[0].url);
                                this.offerApiService.isResetSubNavOnFilterApplied$.next(false);
                            }
                        } else {
                            this.menuItems = subNavList;
                            if (clearfilter) {
                                this.totalCount = 0;
                                this.sourceItem = this.menuItems[0].name;
                                this.navigationService.goTo(this.menuItems[0].url);
                                this.offerApiService.isFilterPopupClicked$.next(false);
                            }
                        }
                    }
                }
            });
    }

    canRender(): boolean {
        if (this.isDesktop) return true;
        if (this.menuItems == null) return false;

        if (this.showInMobile) {
            return true;
        }
        return false;
    }

    itemClick(item: MenuContentItem, tabPosition: any) {
        if (this.offersNavigationGroupedProducts.length == 0) {
            this.tabPosition = tabPosition;
            this.selectedItem = item.type;
            this.offerApiService.itemSelected$.next(item.type);
            this.offerApiService.tabChanged$.next(true);
        }
    }

    loadTopNavigationContent() {
        this.offerApiService.topNavigationContent$.pipe(takeUntil(this.destroy$)).subscribe((res) => {
            this.menuList = [];
            if (res && res?.messages) {
                const promoObj = {
                    name: res?.messages.promotions,
                    type: FilterV2Constants.PromotionsTab,
                };
                const rewardsobj = {
                    name: res?.messages.rewards,
                    type: FilterV2Constants.RewardsTab,
                };
                this.menuList.push(promoObj);
                this.menuList.push(rewardsobj);
            }
        });
    }

    loadOfferhistoryContent() {
        this.offerApiService.loadMobileOfferHistory();
        this.mobileOfferHistory$.pipe(takeUntil(this.destroy$)).subscribe((mobileOfferHistory: PCImage) => {
            if (mobileOfferHistory?.imageLink?.text) {
                this.mobileViewTitle = mobileOfferHistory?.imageLink?.text;
            }
        });
    }

    loadSubNav() {
        this.userService.events
            .pipe(
                filter((e) => e instanceof UserLoginEvent),
                takeUntil(this.destroy$),
            )
            .subscribe(() => {
                this.offersNavigationService.getSubNavigationItems();
            });
    }

    isLobbyempty() {
        this.loadOffersApiService.isLobbyEmpty$.pipe(takeUntil(this.destroy$)).subscribe((isLobbyEmpty: boolean) => {
            this.isLobbyEmpty = isLobbyEmpty;
        });
    }
}
