import { ContentImage, ContentLink, ViewTemplateForClient } from '@frontend/vanilla/core';

import { BaseDetailsContent } from '../../../base-details/base-details.model';
import { TargetPromotionStatus } from './PromotionStatus/promotion-statuses';
import { HowItWorks } from './how-it-works-details';
import { OptInContent } from './opt-in-content';
import { OverviewContent } from './overview-content';
import { NoPrize } from './results/no-prize';
import { ResultsContent } from './results/results-content';
import { RewardContent } from './reward-content';

export class TargetPromotionContent extends BaseDetailsContent {
    overviewContent: OverviewContent;
    howItWorksContent: HowItWorks;
    rewardsContent: RewardContent;
    tabs: string[];
    checkForOtherPromotions: ContentLink;
    targetPromotionCommonContent: ViewTemplateForClient;
    resultsContent: ResultsContent;
    optinContent: OptInContent;
    noPrizeContent: NoPrize;
    hideOptInTime: boolean = false;
    promotionStatus: TargetPromotionStatus;
    cards: Cards;
    promotionOfferStatus: string;
}

export enum PromotionViewType {
    LeaderBoard = 'LeaderBoard',
    PlayerActivity = 'Player activity Promotions',
    ClickCard = 'Click Cards',
    CashBack = 'Cashback Promo',
    CardRush = 'Card Rush',
    Eds = 'EDS Campaings',
}

export class Cards {
    cardImage: ContentImage;
    freeCardImage: ContentImage;
}
